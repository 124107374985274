import * as types from '../reducers/types';
import DataHelper from "../../utils/DataHelper";


// keeping this as initial will add in future
const initialState = {   
    isLoading:false,
    isOpen: false,
    itemInfo: {},
    editMode: false,
    drawerWidth: '',
    menuDropDown: false,
    publish:null, // current course updates
    publishVersion:[], // publish version history
    panel: 1,
    target: {},
    tpList: [], // all templates list - global
    savedTp: [], // saved template response
    courseList: [],
    searchListLrns: [],
    isAddVideoSupplimentaries:false,
    legacyCrcStructure:[], 
    existingLrnAdd:{},
    personalizehistory:null
}


const courseEditReducer = (state = initialState, action) => {
    switch (action.type) {
        case types.TOGGLE_DRAWER:
            return {
                ...state,
                isOpen: action.value.open, itemInfo: action.value.itemInfo,
                target: action.value.target,
                panel: action.value.panel,
                drawerWidth: action.value.drawerWidth,
                isAddVideoSupplimentaries:action.value.open ? state?.isAddVideoSupplimentaries : false
            }
        case types.SEARCH_LRN_AIDS:
        case types.ADD_NEW_LEARNING_AID_REQ:
            return {
                ...state,
                isLoading:true
            }
        case types.EDIT_STATE:
            return {
                ...state,
                editMode: action.value
            }
            //clone history
        case types.EDIT_MENU_DROPDOWN:
            return {
                ...state,
                menuDropDown: action.value
            }
        case types.SET_PERSONALIZE_HISTORY:
            let history=action?.payload;
            //making that view
            history= history.map((tp, index) => {
                let temp = {...tp};
                let markup=tp?.markup||null;
                if(!markup){
                    markup=temp?.lrncontent?.map((e)=>{
                    return `<section name=${e?.name} compid=${e?.compid}></section>`
                    }).filter(Boolean)
                 }
                return {...temp,cmpdata:temp.lrncontent ,markup:markup?.join('')};
            });
        return {
            ...state,
            personalizehistory: [...history]
        }
        case types.UPDATE_PERSONALIZE_HISTORY:
            const { payload } = action;
            const personalize = state?.personalizehistory || null;
            let updatedData= personalize; // Clone the array
            if(Array.isArray(personalize))updatedData=updatedPersonalize(payload,[...updatedData]);
            return{
                ...state,
                personalizehistory:updatedData
            }
        case types.TEMPLATE_LIST:
            let filtered = action?.value?.filter(item => {
                return item.cmpdata !== null && (item?.cmpdata !== "null")
            })?.sort((a, b) => a?.name?.localeCompare(b?.name));
            return {
                ...state,
                tpList: filtered
            }
        case types.ASSIGN_TEMPLATE:
            return {
                ...state,
                savedTp: action.value
            }
        case types.HIGHLIGHT_LEARNING_AID:
            return {
                ...state, itemInfo: action.value
            }
            break;

            case types.LEARNING_AID_ADDED_RES:
                return {
                    ...state, isLoading:false
                }

        case types.UPDATE_STATUS_LEARNING_AID:
            // const updateObj = action.value;
            // console.log(action.value)
            // let modifiedObj = {...state.itemInfo}
            // for (const key in updateObj) {
            //     if (Object.hasOwnProperty.call(updateObj, key) && key !== 'id' && key !== 'props') {
            //         modifiedObj[key] = updateObj[key]
            //     }
            //     else if (Object.hasOwnProperty.call(updateObj, key) && key !== 'props') {

            //     }
            // }
            // case types.LEARNING_AID_ADDED_RES:
            //     return {
            //         ...state, aidAdded:action.value
            //     }
            //     break;
            return {
                ...state,
                itemInfo: action.value.item
            }
        case types.TEMPLATE_ADDED_RES:
            let tpList = [...state.tpList]
            if (action.payload?.status === 'success') {
                tpList = [...tpList, action.payload?.data]
            }
            return {
                ...state,
                tpList: tpList
            }
        case types.ACTIVE_COURSES_LIST:
           let isActiveCrs=action.payload.filter(e=>{ return e.status !== -1})
           // sort the courses array alphabetically by the coursename property
           isActiveCrs?.sort((a, b) => a?.coursename?.localeCompare(b?.coursename, 'en', { sensitivity: 'base' }));
            return {
                ...state,
                courseList:isActiveCrs
            }     
        case types.ADD_VIDEO_SUPPLIMENTARIES:
                return {
                    ...state,
                    isAddVideoSupplimentaries:action.payload
                }

        case types.SEARCH_RESULT_LRN_AIDS:
            return {
                ...state,
                isLoading:false,
                searchListLrns: action.payload
            }
        case types.SET_CRS_STRUCTURE:
            DataHelper.listIterator(
                action.payload.data.coursedata,
                0,
                "",
                action.payload.topicdata,
                state.legacyCrcStructure,
                action?.payload?.data?.CfgClientComm
              );
        // eslint-disable-next-line no-fallthrough
        case types.EXISTING_LRN_AID_ADD_REQ:
        return {
            ...state,
            existingLrnAdd:action?.payload
        }
        case types.PUBLISH_CRS_INFO:{
            return {
                ...state,
                publish:action?.payload
            }
        }
        case types.PUBLISH_VERSION:
            //Once published, the same window prevents re-publishing without refreshing.
            return {
                ...state,
                publishVersion:action?.payload,
                publish:null,
        }
    

        default:
            return state
    }
}


export const updatedPersonalize=(payload,personalize)=>{
    try{
    const findIndex = personalize.findIndex(e => e?._id === payload?.topicid);
    if (findIndex !== -1) { // Use !== -1 to ensure the index is found
        const pHistory ={ ...payload,name:payload?.props?.cename||payload?.name }
        personalize[findIndex] = {
            ...personalize[findIndex],
            cmpdata: [
                ...(personalize[findIndex]?.cmpdata || []),
                pHistory
            ]
        };
    } else {
        const newPHistory = {
            ...payload,
            cmpdata: [{ ...payload,name:payload?.props?.cename||payload?.name }],
            _id: payload?.topicid
        };
        delete newPHistory.props; // Remove props from the new object
        personalize.push(newPHistory);
     }
     return personalize;
    }catch(e){
        return personalize
    }
}

export default courseEditReducer