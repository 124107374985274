import React, { useState, useEffect } from 'react';
import Acc from '@material-ui/core/Accordion';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import PropTypes from 'prop-types'
import DeleteIcon from '@material-ui/icons/Delete';
import Typography from '@material-ui/core/Typography';
import { AddCircle } from '@material-ui/icons';
import RichTextEditor from '../RichTextEditor/RichTextEditor';
import TextField from '@material-ui/core/TextField';

const DragandMatchPanel = ({ description, styleObj, explanation, bucket, drags, handleProperties, handleCeAssets, cename, id, enable_strict }) => {
    const [data, setData] = useState({ description, explanation, bucket, drags, enable_strict });
    const [expanded, setExpanded] = useState(0);
    const isReorder=(cename==="ReorderingList")
    
    useEffect(() => {
        handleProperties({ ...data });
    }, [data]);

    const handleResponse = (value,key) => {
        const newExplanation = {
            ...data.explanation,
            [key]: value
        };

        setData(prevData => ({
            ...prevData,
            explanation: newExplanation
        }));

        handleProperties({ ...data, explanation: newExplanation });
    }

    const handleText = (value, key) => {
        const updatedData = { ...data, [key]: value };
        setData(updatedData);
        handleProperties(updatedData);
    }

    const handleChange = (panel) => (event, isExpanded) => {
        setExpanded(isExpanded ? panel : false);
    };

    const handleDeleteDrop = (event, id) => {
        event.stopPropagation();

        if (bucket?.length === 2) {
            return; // Don't delete the last item
        }

        const updatedDropContainer = bucket?.filter(e => e?.id !== id);
        let updatedData = { ...data, bucket: [...updatedDropContainer] }
        if(!isReorder){
         const updatedDragItems = drags?.filter(e => e.target !== id);
         updatedData = { ...data, bucket: [...updatedDropContainer], drags: [...updatedDragItems] };
        }
        setData(updatedData);
        handleProperties(updatedData);
    };

    const handleChangeDragQuestion = (value, index) => {
        const updatedContainer = [...bucket];
        updatedContainer[index] = { ...updatedContainer[index], text: value };

        const updatedData = { ...data, bucket: updatedContainer };
        setData(updatedData);
        handleProperties(updatedData);
    };

    const handleChangeAnswer = (value, index) => {
        const updatedDragItems = [...drags];
        updatedDragItems[index] = { ...updatedDragItems[index], text: value };

        const updatedData = { ...data, drags: updatedDragItems };
        setData(updatedData);
        handleProperties(updatedData);
    };

    // const generateUniqueId = () => {
    //     try {
    //         const randomNum = Math.random().toString(16).substring(2);
    //         return randomNum;
    //     } catch (e) {
    //         console.error(e);
    //     }
    // }

    const handleStyle=(e)=>{
        try{
            const updatedData={...data,
            styleObj:{
                ...data?.styleObj,
                [e.target.name]: e.target.value
            }};
            handleProperties(updatedData);
            setData(updatedData);
        }catch(e){}
    }

    const addDropContainer = () => {
        try {
            let newId = Math.max(...bucket?.map(item => item?.id), 0)||1;
            if(id < bucket?.length-1) newId=bucket?.length+1;
            const newDrop = { id: newId+1, container: [], text: '<p>Enter your question here</p>' };
            drags?.push({ id: newId+1, target:newDrop?.id||newId, audio: "", image: "", text: "<p>Enter your Answer here</p>" });
            if(isReorder){
                delete newDrop?.container;
                newDrop.text='<p>Enter your content here</p>';
                drags=null; 
            } 
            bucket?.push(newDrop);
            const updatedData = { ...data, bucket: bucket, drags: drags };
            setData(updatedData);
            handleProperties(updatedData);
        } catch (e) {
            console.error(e);
        }
    }

    return (
        <div className="accordion-prop-container drag-and-match-panel dragging-prop-container">
                <div className='draggable-global-property'>
                 <div className="drag-des-col-label">
                   <label className='label'>Description</label>
                   <TextField type="color" id="outlined-basic" name="dr_bg_color" label="Drag Bg Color" variant="outlined" defaultValue={styleObj?.dr_bg_color||"#f8f9fa"} onChange={handleStyle}  />
                 </div>
                    <RichTextEditor className="color-label" data={description || ""} styles={{ minHeight: 100 }} eventHandler={(e) => handleText(e, "description")} />
                    <br />
                    <div className={``}>
                        {bucket?.map((con, index) => {
                            return (
                                <React.Fragment key={index}>
                                    <Acc expanded={expanded === index} onChange={handleChange(index)}>
                                        <AccordionSummary aria-controls="panel1a-content" id="panel1a-header">
                                            <Typography dangerouslySetInnerHTML={{ __html: con?.text || '' }} />
                                            <DeleteIcon className='delete-prop-accordion' onClick={(e) => handleDeleteDrop(e, con?.id)} />
                                        </AccordionSummary>
                                        <AccordionDetails>
                                            <div className="row">
                                                <div className='col-sm-12 col-md-12 col-lg-12'>
                                                    <label className='label'>{!isReorder ? "Question" : "Content"}</label>
                                                    <RichTextEditor data={con?.text || ""} styles={{ minHeight: 100 }} eventHandler={(e) => handleChangeDragQuestion(e, index)} />
                                                    {drags &&<> <label className='label'>Answer</label>
                                                    <RichTextEditor data={drags[index||0]?.text || ""} styles={{ minHeight: 100 }} eventHandler={(e) => handleChangeAnswer(e, index)} /></>}
                                                </div>
                                            </div>
                                        </AccordionDetails>
                                    </Acc>
                                </React.Fragment>
                            );
                        })}
                    </div>
                    <div className='add-box'>
                        <div className='add-new-slide' title={'Drag Match'} onClick={addDropContainer}><AddCircle className='add-accordion' /> Add New Drag{!isReorder && " Match"}</div>
                    </div>
                    <br/>
                    <label className='label'>Explanation</label>
                    <RichTextEditor data={explanation?.right || ""} styles={{ minHeight: 100 }} eventHandler={(e) => handleResponse(e, "right")} />
                </div>
        </div>
    );
}


DragandMatchPanel.propTypes = {
    /** bucket will have number of drag objects  */
    bucket: PropTypes.array,
    /** drags items will be object contains list of items for drag and drop */
    drags: PropTypes.array,
    /** enable_voice manage the voice option */
    enable_voice: PropTypes.bool,
    /** string it will contain the description  */
    description: PropTypes.string,
    /** head it will contain the head question  */
    head: PropTypes.string,
    /** subhead it will contain the subhead question  */
    subhead: PropTypes.string,
    /** explanation it will contain the question  explanation */
    explanation: PropTypes.object,
    handleProperties: PropTypes.func,
    /** function handler used to update the Assets */
    handleCeAssets: PropTypes.func,
    /** styleObj it will contain styles */
    styleObj: PropTypes.object,

}

export default DragandMatchPanel;
