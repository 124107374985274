import React from 'react'
import PropTypes from 'prop-types';
import BackgroundImagePanel from './BackgroundImagePanel';
import profile_icon from '../../../assets/img/profile_icon.png';

const QuotesPanel = ({profileImage, quotes, backgroundObj, styleObj, handleProperties, cename, id, handleCeAssets }) => {

    const props={
        bgImageUrl:backgroundObj?.img,
        description:quotes,
        backgroundSize: backgroundObj?.size,
        textPosition: styleObj?.textPosition,
        backgroundColor: backgroundObj?.color,
        opacity:backgroundObj?.opacity,
        padding:styleObj?.padding,
        styleObj:styleObj,
        background:backgroundObj?.background,
        profileImage:profileImage,
        handleProperties:handleProperties,
        cename:cename,
        id:id,
        handleCeAssets:handleCeAssets
      }

  return <BackgroundImagePanel {...props} />
}

QuotesPanel.propTypes = {
    /** backgroundObj of the component */
    backgroundObj: PropTypes.object,
    /** profileImage of the component */
    profileImage: PropTypes.string,
    /** Heading title of the component */
    header: PropTypes.string,
    /** quotes content of component */
    quotes: PropTypes.string,
  }
  

export default QuotesPanel