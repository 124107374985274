import React from 'react'
import PropTypes from 'prop-types'
import './tradetutor.scss';
import Updates from './Updates';
import Vignettes from './Vignettes';
import Poster from './Poster'
import PrintCertificate from '../PrintCertificate/PrintCertificate';
import PrintCourseMap from '../PrintCourseMap/PrintCourseMap';
import { useSelector } from 'react-redux';


/**
 * Here we are just passing section, vignettas and updates data as props to the drawer component.
 * And conditionally showing the three different sections based on user action. 
*/

/**
 * 
 * @param {*} slides 
 * @returns each data of poster section including notes, vignettes and updates
 */

/**
 * 
 * @param {*} topic 
 * @returns either notes, vignettes and updates
 */

/**
 * TradeTutor component present on navbar contains poster, vignettes and updates
*/

/*
*------------Stype-------------
* 1 Updates
* 2 notes
* 3 vignettes
*/
const TradeTutor = ({topic}) => {
    const courseInfo = useSelector(state => state.courseInfo);
    const { notes, vignettes, updates } = courseInfo?.supplementaries

    /**
     * initial state variables where data is about to set into the variable
    */

    return (
        <div className='trade-tutor-container'>
            <div className='trade-tutor-row'>
                <Poster topic={topic} notes={notes} />
                <Vignettes topic={topic} vignettes={vignettes} />
                <Updates topic={topic} updates={updates}/>
                <PrintCertificate topic={topic} />
                <PrintCourseMap topic={topic} />
            </div>
        </div>
    )
}

TradeTutor.propTypes = {
    /** Data of poster section including notes, vignettes and updates */
    notes: PropTypes.object,
    /**User Selects topics either notes, vignettes and updates */
    topic: PropTypes.string
}

export default TradeTutor
