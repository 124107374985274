import React from 'react'
import PropTypes from 'prop-types'
import CloseIcon from '@material-ui/icons/Close';
import './profileheader.scss'

/**
 * This will be applied on the profile component where user can view his own information
*/

/**
 * 
 * @param { string } name 
 * @returns name of the user
 */

/**
 * Profile Header component will dislay the user logged in
*/
const ProfileHeader = ({name}) => {
    return (
        <div className='profile-header'>
            <h3 className='profile-title'>Hello, {name}</h3>
            <p className='profile-welcome'>Welcome to Content Enablers Learning Management System</p>
            {/* <span className='close-icon'><CloseIcon /></span> */}
        </div>
    )
}

ProfileHeader.propTypes = {
    /** Name prop contains name of the user */
    name: PropTypes.string
}

export default ProfileHeader
