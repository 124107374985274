import { List, ListItem, ListItemText, TextField } from '@material-ui/core'
import { AddCircle } from '@material-ui/icons'
import PropTypes from 'prop-types'
import { useEffect, useState } from 'react'
import EditIcon from '@material-ui/icons/Edit';
import DeleteIcon from '@material-ui/icons/Delete';
import CheckIcon from '@material-ui/icons/Check';
import RichTextEditor from '../RichTextEditor/RichTextEditor';


const OrderedListPanel = ({snippetOne,snippetTwo,list, handleProperties}) => {

    const [data, setData] = useState({snippetOne,snippetTwo,list})
    const [editMode, setEdit] = useState(null)

    useEffect(() => {
        handleProperties(data)
    }, [data])


    const handleEdit = (e, i) => {
        setEdit(i)
        e.stopPropagation()
    }

    const handleDelete = (e, index) => {
        e.stopPropagation()
        setData({
            ...data,
            list: [...list].filter((e, i) => i !== index)
        })
    }

    const handleChangeInput = (e, i) => {
        list[i] = e.target.value
        setData({
            ...data,
            list: list
        })
    }

    const handleSave = (e, index) => {
        setEdit(null)
        e.stopPropagation()
    }

    /** Set Rule with Maximum Count */

    const addList = () => {
        const MAX = 8;
        if(list.length < MAX ){
            let items = [...list,'New List Item Added ']
            setData({
                ...data,
                list:items
            })
        }
    }

    const editIntroduction = (value)=>{
        setData({
            ...data,
            snippetOne: value
        })
    }

    const editOutText = (value)=>{
        setData({
            ...data,
            snippetTwo: value
        })
    }

    return (
        <div className='orderedlist-edit-container'>
            <div>
            <h5>Introduction</h5>
            <RichTextEditor data={snippetOne} eventHandler={(e) => editIntroduction(e)}  height={100}/>
            </div>
            <div className='list-items'>
                <h5>List</h5>
                <div className='order-list-main'>
                    <List component="ul" className='order-list-fixed'>
                        {list && list.map((el, index) =>   <ListItem component="li" button key={index}> 
                            {editMode === index ?  <>
                                <TextField id="outlined-basic" spellCheck={false} name='title' onChange={(e) => handleChangeInput(e, index)} defaultValue={el} label="List text" variant="outlined" />
                                <CheckIcon className='save-prop-accordion' onClick={(e) => handleSave(e, index)}/>
                                </> :<><ListItemText primary={el} /> <EditIcon className="edit-order-list" onClick={(e) => handleEdit(e, index)}/> <DeleteIcon  className='delete-order-list' onClick={(e) => handleDelete(e, index)}/>  </>}
                        </ListItem> )}
                    </List>
                </div>
                <span title={'Add Orderlist'} className='add-order-list' ><AddCircle className='add-plus' onClick={addList}/></span>
            </div>
            <div>
            <RichTextEditor data={snippetTwo} eventHandler={(e) => editOutText(e)} height={100}/>
            </div>
        </div>
    )
}

OrderedListPanel.propTypes = {
    list: PropTypes.array,
    handleProperties: PropTypes.func
}

export default OrderedListPanel
