import React from 'react'
import LinkIcon from '@mui/icons-material/Link';
import Audio from '../../../../components/content/Audio/Audio';
import Video from '../../../../components/content/Video/Video';
import Box from '@mui/material/Box';
import SwipeableDrawer from '@mui/material/SwipeableDrawer';
import PropTypes from 'prop-types';
import CloseIcon from '@material-ui/icons/Close';
import Typography from '@material-ui/core/Typography';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import Close from '@material-ui/icons/Close';
import ArchiveVideo from '../../../../components/content/ArchiveVideo/ArchiveVideo';
// import Card from '@mui/material/Card';
// // import { useSelector } from 'react-redux';

const AssetsPlayer = ({ type, handle, content, handleSubmit }) => {
  // const { courseEdit: { target: { cmp: { name } } } } = useSelector(state => state);
  const isQuestionAsset = type === "question"

  const toggleDrawer = (anchor, open) => (event) => {
    if (event && event?.type === 'keydown' && (event?.key === 'Tab' || event?.key === 'Shift')) { return; }
  };

  const player = () => (
    <>
      {type === 'image' && <div className='player'> <img className='img' src={content?.path} alt={content?.name} /> </div>}
      {type === 'audio' && <div className='player'><Audio src={content?.path} className='audio-player' /></div>}
      {type === 'lgVideo' && <div className='player'><ArchiveVideo src={content?.path} poster={''} /></div>}
      {type === 'video' && <div className='player'> <Video src={content?.path} poster={''} /></div>}
    </>
  )


  const slectedContent = () => (
    <Box
      sx={{ width: '40vw', height: '80vh' }}
      role="presentation"
      onClick={toggleDrawer('right', false)}
      onKeyDown={toggleDrawer('right', false)}
    >
      <div className='selected-container'>
        <div className='image-details-container'>
          <div className='header-div'><h4 className='detail-heading'>{content?.name}</h4> <span className='close-icon' onClick={handle}><CloseIcon /></span></div>
          {!isQuestionAsset && <div className='player-container'>{content?.path ? player() : <p>{content?.path}Loading...</p>}</div>}
          {content?.isMigrated && <p className='migrated-msg mb-0'>( {content?.isMigrated?.courseName} is not migrated )</p>}
          {content?.ctext && !isQuestionAsset && <> <h3 className='dis-head'>Text Version</h3>  <div className="dis" dangerouslySetInnerHTML={{ __html: content?.ctext }} /> </>}
          {isQuestionAsset && <>
          <div className='selected-question'>
          <label className="question-label mb-3">Question</label>
            <div className='mb-3'>{content?.question}</div>
              <label className="question-label mb-3">Question Type</label>
              <Typography  className="mb-3 col-md-4">{content?.qtype === 2 ? "Multiple":content?.qtype === 1?"Single":"True/false"} </Typography>
              <label className="question-label mb-3">Options</label>
              {content?.options?.map((ans,index) => <div className='options'key={index} >
                   <div  className="check-icon">{content?.answers?.some((e) =>{
                   let answer=Number(e);
                      if(content?.qtype==0) answer=answer-1;
                      return answer == index
                    }) ? <CheckCircleIcon  className='correct-option' /> : <Close className='wrong-option' />}</div>
                   <div className='option-text'>{ans}</div>
                 </div>)}
            </div>
          </>}
          <div className='details'>
            <div className='link-button'>
              <button type="button" className="btn btn-outline-primary mr-20" onClick={handle}><span>Cancel</span></button>
              <button type="button" className="btn btn-outline-primary mr-20" onClick={() => handleSubmit(content)}><span>{`Link this ${type} ${isQuestionAsset?"":"asset"}`}</span><LinkIcon /></button>
            </div>
          </div>
        </div>
      </div>
    </Box>
  );

  return (
    <div className='divider'>
      <SwipeableDrawer
        style={{ zIndex: '99999' }}
        anchor='right'
        open={true}
        onClose={() => false}
        onOpen={() => true}
      >
        {slectedContent()}
      </SwipeableDrawer>
    </div>
  )
}

AssetsPlayer.propTypes = {
  /** type of the assets */
  type: PropTypes.string,
  /** link Assets function  */
  handleSubmit: PropTypes.func,
  /** handle preview  close  */
  handle: PropTypes.func,
  /** content selected data  */
  content: PropTypes?.object,
}

export default AssetsPlayer
