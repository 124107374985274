import React, {useEffect} from 'react'
import { makeStyles } from '@material-ui/core/styles';
import LinearProgress from '@material-ui/core/LinearProgress';
import './linearprogress.scss'

const useStyles = makeStyles({
    root: {
    width: '100%',
    position: 'sticky',
    top: 71,
    zIndex: 2147483
    },
});

/**
 * 
 * @param {*} props 
 * @returns 
 */

/**
 * Linear progress bar (i.e horizontal)  bar for tracking
*/
const ProgressLinear = ({progress}) => {

    const classes = useStyles();
    // const [progress, setProgress] = React.useState(20);

    useEffect(() => {
        // setProgress(20)
    }, [])

    return (
        <div className={classes.root + ' ' + 'linear-progress-main'}>
            <LinearProgress variant="determinate" value={progress} className='linear-header-progress' />
        </div>
    )
}

export default ProgressLinear
