import React, { useEffect } from 'react'
import Acc from '@material-ui/core/Accordion';
import useForceUpdate from 'use-force-update';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import Typography from '@material-ui/core/Typography';
import DeleteIcon from '@material-ui/icons/Delete';
import EditIcon from '@material-ui/icons/Edit';
import TextField from '@material-ui/core/TextField';
import CheckIcon from '@material-ui/icons/Check';
import { AddCircle } from '@material-ui/icons';
import UploaderPanel from '../UploaderPanel/UploaderPanel';
import PropTypes from 'prop-types'
import Datahelper from '../../../utils/DataHelper'
import RichTextEditor from '../RichTextEditor/RichTextEditor';
import {FormControlLabel, FormLabel, Radio, RadioGroup } from '@material-ui/core';
import ACLHelper from '../../../utils/ACLHelper';
import { getPlaceHolderImg } from '../../../utils/MediaConfig';
/**
 * 
 * ImageSelectionPanel editor used to modify the propertis of ImageSelectionPanel player component
 */

const ImageSelectionPanel = ({text,items, handleProperties, cename,explanation,id, handleCeAssets,answer}) => {

    const [data, setData] = React.useState({ text,items,answer,explanation})
    const [value, setValue] = React.useState(0);
    const [info,setExplination] = React.useState(explanation)

    const [expanded, setExpanded] = React.useState(0);
    const forceUpdate = useForceUpdate()
    const [editMode, setEdit] = React.useState(null);
    const [list, setImageList] = React.useState(JSON.parse(JSON.stringify(items)))
    let max_count = 6
    // const item = Object.values(PlayerConstants.COMPONENTS_LIST).filter((el) => el.name === cename)
    // max_count = item[0].max_count

    useEffect(()=>{
        handleProperties({...data})
    },[data]);

    const handleChange = (panel) => (event, isExpanded) => {
        setExpanded(isExpanded ? panel : false);
        setEdit(null)
    };

    const handleChangeInput = (e, id) => {
        list[id][e.target.name] = e.target.value
        setImageList(list)
        setData({...data,items:list})
        handleProperties({...data,items:list})
    }

    const handleEdit = (e, index) => {
        setEdit(index)
        e.stopPropagation()
    }

    const handleSave = (e, index) => {
        setEdit(null)
        e.stopPropagation()
        setImageList(list)
        setData({...data,items:list})
        handleProperties({...data,items:list})
    }

    const handleDelete = (e, index) => {
        const removed = list.filter((e, i) => i !== index)
        setImageList(removed)
        setData({...data,items:removed})
        handleProperties({...data,items:removed})
        forceUpdate()
        e.stopPropagation()
    }

    const addNewImgItem = () => {
        if(max_count > list.length) {
            const maxId = Math.max(...list?.map(item => item?.id), 0)||1;
            let ar = [...list,{
                id: maxId + 1,
                name: 'Image card '+(maxId +1),
                img_url: getPlaceHolderImg(120)
            }]
            setImageList(ar)
            setData({...data,items:ar})
            handleProperties({...data,items:ar})
            forceUpdate()
        }
    }


    // Dynamic uploaded file path has to replaced
    const handleMedia = (data,index)=>{
        try{
            if(data){
                // let fname = data.name?.split('.')[0]
                // let id = fname?.split('-')[1];
                // let index = Number(id)-1;
                const updatedList=[...list];
                updatedList[index] = {...updatedList[index],img_url:data?.name}
                handleCeAssets(list[index]?.img_url,data?.name,{items:updatedList});
                setImageList(updatedList)
                setData({...data,items:updatedList,text:text})
                handleProperties({...data,items:updatedList,text:text})
            }
        }
        catch(e){
            console.debug(e);
        }
       
    }

    const imageStyleChange = (e, index) => {
        if(!list[index]["styles"]){
             list[index]["styles"] ={};
        };
        list[index]["styles"][e.target.name] = e?.target?.value || "";
        setImageList([...list])
        setData({...data,items:list,text:text})
        handleProperties({...data,items:list,text:text})
    };

    const onContentChange = (value) =>{
        try{
            text = value;
            setData({...data,text:value})
            handleProperties({...data,text:value})
        }catch(e){
            console.log(e);
        }
    } 
    
    const handleRadioChange = (e)=>{
        let value = Number(e.target.value)
        setValue(value);
        setData({...data,answer: value})
        handleProperties({...data,answer: value})
    }

    const changeRInformation = ( value ) => {
        setExplination( {
            ...info,
            right: value
        } )
        setData({...data,explanation:info})
        handleProperties({...data,explanation:info})
    }

    const changeWInformation = ( value ) => {
        setExplination( {
            ...info,
            wrong:value
        } )

        setData({...data,explanation:{
            ...info,
            wrong:value
        }})
        handleProperties({...data,explanation:{
            ...info,
            wrong:value
        }})
    }

    return (
        <div className='tabs-prop-container'>
        <div className='row mb-2 x-align'>
            <div className="mb-12 col-md-12">
                <label className='label'>Description</label>
                <RichTextEditor  data={text} height={200} eventHandler={onContentChange}/>
            </div>
        </div>
        <div className={`mt-15`}>
            {

                (list && list.length > 0) && list.map((el, index) => {
                    return <Acc key={index} expanded={expanded ===  index} onChange={handleChange(index)}>
                    <AccordionSummary
                        aria-controls="panel1a-content"
                        id="panel1a-header"
                    >
                        {editMode === index ?  <>
                         <TextField id="outlined-basic" className='list-title' spellCheck={false} name='name'  defaultValue={el.name} onClick={(e) => e.stopPropagation()} onChange={(e) => handleChangeInput(e, index)}/>
                         <CheckIcon className='save-prop-accordion' onClick={(e) => handleSave(e, index)}/>
                         </> : <><Typography className={ `label-bold`}>{el.name}  </Typography><EditIcon className='edit-prop-tabs' onClick={(e) => handleEdit(e, index)}/> <DeleteIcon className='delete-prop-tabs' onClick={(e) => handleDelete(e, index)}/> </>}
                    </AccordionSummary>
                    <AccordionDetails>
                        <div className='row mt-15'>
                            { ACLHelper.isFeatureEnabled(4,2) &&
                            <div className="mb-6 col-md-12">
                                {/* <label htmlFor="formFile" className="form-label">Browse Image</label> */}
                                <UploaderPanel accept={'image'} 
                                fileName={list[index]?.img_url}  
                                name={`${id}_${Datahelper.getTime()}-${index+1}`}  
                                setUploadedData={(el)=>handleMedia(el,index)} 
                                styles={{
                                    position:
                                      data?.items[index]["styles"]?.position ||
                                      "center  / contain no-repeat",
                                    color:
                                      data?.items[index]["styles"]?.color ||
                                      "rgba(0, 0, 0, 0)",
                                  }}
                                  bgChange={(e) => imageStyleChange(e, index)}
                                  pChange={(e) => imageStyleChange(e, index)}
                                />
                            </div>}
                        </div> 
                    </AccordionDetails>
                </Acc>
                })
            }
            <div className='add-new-slide' title={'Add Item'} onClick={()=>addNewImgItem()}><AddCircle className='add-tabs'  />  Add Answer Image</div>
        </div>

        <div className='mt-5'>
                <label className='label-bold x-align' id="demo-radio-buttons-group-label"> Select Correct Answer</label>
                <RadioGroup 
                row
                aria-label="Options"
                name="radio-options"
                value={answer}
                onChange={handleRadioChange}
                className='row-radio-group'
                >
                    {list && list?.length > 0 && list.map((el) => <FormControlLabel  
                    value={el?.id} 
                    className='radio-chk'
                    control={<Radio/>} 
                    label={el?.name} key={el?.id}/>)}
                </RadioGroup>
            </div>
            <div className='x-align'>
                <p className='label'>Right Answer Feedback</p>
                <RichTextEditor data={ explanation?.right } height={150} eventHandler={ changeRInformation } styles={{minHeight:80}} />
            </div>
            <div className='x-align'>
                <p className='label'>Wrong Answer Feedback</p>
                <RichTextEditor data={ explanation?.wrong } height={150} eventHandler={ changeWInformation } styles={{minHeight:80}} />
            </div>

        </div>
    )
}

ImageSelectionPanel.propTypes = {
   /** items array used for having list of tabs section */
   items: PropTypes.array,
   /** function handler used to save the edited props */
   handleProperties: PropTypes.func,
    /** function handler used to update the Assets */
    handleCeAssets : PropTypes.func,
}


export default ImageSelectionPanel
