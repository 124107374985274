import React from 'react'
import PropTypes from 'prop-types'
import Button from "@material-ui/core/Button";
import './reportcard.scss'


/**
 * 
 * @param {*} serial 
 * @returns data which includes evealuation of quiz answers
 */

/**
 * ReportCard component will give feedback to the users after completing the Quiz Test
*/
const ReportCard = (props) => {
    let obtained = 0;
    /** Look for answer flag true then count mark  */
    if(props.serial && props.serial.length > 0 ){
        props.serial.forEach((el)=>{
            if(el.evaluation?.mark){
                obtained+= el.evaluation.mark
                //console.log('obtained', obtained)
            }
        })
    }

    console.debug("obtained",obtained,props.serial);
    let percen = Math.round((obtained/props.config.numberOfQuestion)* 100)

    const handleRetake = () => {
        props.retake()
    }

    return (
        <div className='fb-container'>
            <h5>Your score {percen}%</h5>
            <h5>Passing score {props.config.minScorePercentage}%</h5>
            <div className='quiz-btn-retake-container' style={{textAlign: 'right'}}>
                <Button  className='quiz-retake-btn' onClick={handleRetake}>Retake</Button>
            </div>
        </div>
    )
}

ReportCard.propTypes = {
    /** Serial data which includes evalauation of quiz */
    serial: PropTypes.array
}

export default ReportCard
