import React, {useEffect} from 'react'
import Switch from '@material-ui/core/Switch';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import PropTypes from 'prop-types'



/**
 * 
 * @param {*} topic 
 * @returns current topic selected by user
 */

/**
 * 
 * @param {*} updates 
 * @returns current updates of the course info
 */

/**
 * Updates contains the feed contents
*/
const Updates = ({topic, updates}) => {

    const [checked, setChecked] = React.useState(false);
    const [checkLabel, setLabel] = React.useState('Show Archived')
    const [list, setList] = React.useState([]);
    let t = new Date()

    const getUpdateList = () => {
        const getUpdates = updates?.filter(el => {
            let updates = new Date(el?.createdAt)
            return (t.getFullYear() - updates?.getFullYear()) < 1
        })
        return getUpdates
    }

    /**
     * function to get archived list from the updates data.
    */

    const getArchivedList = () => {
        const getArchived = updates?.filter(el => {
            let updates = new Date(el?.createdAt)
            return ((t.getFullYear() - updates?.getFullYear()) >= 1 && updates?.getMonth() <=6)
        })
        return getArchived
    }
    
    const handleChange = (event) => {
      setChecked(event?.target?.checked);
      if(event.target?.checked) {
        setList(getArchivedList())
      } else {
        setList(getArchivedList()?.slice(0,4))
      }
    }

    useEffect(() => {
       if(updates) setList(updates);
    }, [updates])
    return (
        <div className={`updates-container row ${topic === 'updates' ? 'update-show' : ''}`}>
                    {/* <FormControlLabel
                        control={<Switch checked={checked} onChange={handleChange} />}
                        label={checkLabel}
                    /> */}
                {list?.length > 0  ? list?.map((el, index) => <div className='col-sm-12 col-md-12 update-list' key={index}>
                        <div className='content-update-list'>
                            <h4 className='title-header'>{el?.content?.title}</h4>
                            <p className='date-added-updates'>{el?.createdAt}</p>
                            <p className='inner-content' dangerouslySetInnerHTML={{__html: el?.content?.description}}></p>
                        </div>
                    </div>)
                : <h3 className='no-supplimentarie'>No New Updates</h3>}
        </div>
    )
}

Updates.propTypes = {
    /** Topic of current section */
    topic: PropTypes.string,
    /** Updates will contain array of content updates related to updates */
    updates: PropTypes.array
}

export default Updates
