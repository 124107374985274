import * as types from '../reducers/types'

export function initTracking(response,progress) {
        return {
            type:types.COURSE_TRACKING_INIT,
            payload : response,
            progress : progress
        }
}

export function responseProgress(payload) {
    return {
        type : types.CRS_PROGRESS_RES,
        payload
    }
}

export function progressSaved(d) {
    return {
        type:types.COURSE_TRACKING_SAVED,
        d:d
    }
}

export function saveCourseProgress(topic) {
    return {
        type : types.COURSE_TRACKING_SAVE,
        payload:topic
    }
}

export function intialiseLrs(lrs) {
    return {
        type : types.LRS_INITIALISE,
        payload:lrs
    }
}

export function intialiseScorm(sco) {
    return {
        type : types.SCORM_INITIALISE,
        payload:sco
    }
}

export function shortcutCompletion(data) {
    return {
        type : types.SHORTCUT_COMPLETION,
        payload:data
    }
}

export function courseCompletionNotifying() {
    return {
        type : types.COURSE_COMPLETION_NOTIFIED,
    }
}

export function sendQuizAttemptMail(data) {
    return {
        type : types.QUIZ_TRACKING_EMAIL_REQUEST,
        payload:data
    }
}


export function publishRes(data) {
    return {
        type : types.PUBLISH_RESPONSE,
        payload:data
    }
}

export function skipPreTest() {
    return {
        type : types.EXIT_PRE_TEST,
    }
}

export function hasLanguage() {
    return {
        type : types.HAS_LANGUAGE_VIEW
    }
}

