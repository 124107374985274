import { applyMiddleware, createStore } from "redux"
import createSagaMiddleware from 'redux-saga';
import { logger } from 'redux-logger';
import rootReducer from "../reducers";
import rootSaga from "../middleware";

const sagaMiddleware = createSagaMiddleware();

const store = createStore(
    rootReducer,
    applyMiddleware( sagaMiddleware, logger ),
);

sagaMiddleware.run( rootSaga );

export default store;