
import React, { useState } from 'react';
import './imageselect.scss';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import CheckIcon from '@material-ui/icons/Check'
import ClearIcon from '@material-ui/icons/Clear';
import PropTypes from 'prop-types'
import ContentTracking from '../../../core/progress/ContentTracking';
import PlayerConstants from '../../../utils/PlayerConstants'
import Instruction from '../../ui/Instruction/Instruction';
import Close from '@material-ui/icons/Close';
import DataHelper from '../../../utils/DataHelper'
import ImageTag from '../../ui/ImageTag';
import t from '../../../translation/useTranslate';

/**
 * 
 * @param {*} items 
 * @returns lists of images 
 */

/**
 * ImageSelect can be used as assesment component for selecting correct image 
*/
const ImageSelect = (props) => {

  const {items, text,track,handler,cename, isQuiz, evaluate, instructions,explanation,answer} = props

  const [ value, setValue ] = React.useState( 0 );
  const [ selected, setSelected ] = useState( false );
  const [instruction, setInstruct] = useState(instructions);
  const [status, setStatus] = useState({completed: false, text: PlayerConstants.COMPONENT_CONSTANTS.STATUS_INCOMPLETE})

  const UPDATE_STATUS = PlayerConstants.COMPONENT_CONSTANTS.UPDATE_STATUS
  const SAVE_PROGRESS_STATUS = PlayerConstants.COMPONENT_CONSTANTS.SAVE_PROGRESS_STATUS

  const showResponse = (id, el) => {
    if(id === Number(answer)){
      setValue(id);
      updateProgress(1)
    }else{
      setValue(id);
    }
    setSelected(true);
  }


   /**
     * Manage the component update 
     * progress logic in this method
     * 
     * Update the view status when ever the user interacts
     * Update the progess status ONLY ONCE, when status completes
     * 
     */
    const updateProgress = (currentItem) => {

      /**
       * View status, will update while navigating to next topic
       */
      track.state = { ...track.state, c: currentItem }
      /**
       * Progress updated as and when its completed
       */
  
      // if (!track.status) {
        if(currentItem) {
          track.status = 1
        } else {
          track.status = 0
        }
        handler({ type: SAVE_PROGRESS_STATUS, id: track.id, name:cename })
      // }
      /**
       * If anything to be intimated always
       * call this
       */
      handler({ type: UPDATE_STATUS, id: track.id, name:cename })
  
    }

  const getImageUrl = (path)=>{
    return DataHelper.getResourcePath(0,path);
  }

  return (
    <>
    <Instruction isInstruction={instruction?.enabled} completed={(track.status === 1 || status.completed)} title={(track.status === 1 || status.completed) ? PlayerConstants.COMPONENT_CONSTANTS.STATUS_COMPLETE : PlayerConstants.COMPONENT_CONSTANTS.STATUS_INCOMPLETE} classText={`${(track.status === 1 || status.completed) && PlayerConstants.COMPONENT_CONSTANTS.COMPLETED_CLASS}`} text={(track.status === 1 || status.completed) ? PlayerConstants.COMPONENT_CONSTANTS.INSTRUCTIONS_PASSED : instruction?.text}/>
    <div className="img-selection">
        <div className='text-content' dangerouslySetInnerHTML={{__html:text}}></div>
        <ul className="list-items">
        {items?.length > 0 && items?.map((el,index)=>{
          return <li key={el.id} onClick={()=>showResponse(el.id, el)}>
                   <ImageTag 
                   img={el?.img_url||"*"}
                   maxHeight={150}
                   styles={{
                    borderRadius: 5,
                    backgroundColor: "#ffffff",
                  }}
                  position={
                    el?.styles?.position ||
                    "center  / contain no-repeat"
                  }
                  bgColor={el?.styles?.color || "rgba(0, 0, 0, 0)"}
                    />
                  {el?.id === selected && <div className="overlay">{el?.correctAnswer ? <CheckIcon className='check-icon'/>: <ClearIcon className='clear-icon'/>}</div> }
                  <p>{el?.name}</p>
            </li>
        })}
        </ul>
        {selected && <div className='response-main'>
          <p>{ value === answer ? <span><CheckCircleIcon /> {t("CORRECT")}</span> : <span><Close color="secondary" />{t("INCORRECT")}</span> }</p>
          <div>{ value === answer ? <div dangerouslySetInnerHTML={{__html:explanation.right}}></div>  : 
          <div dangerouslySetInnerHTML={{__html:explanation.wrong}}></div> }
          </div>
        </div>}
    </div>
    </>
  );
}

ImageSelect.defaultProps = {
  instructions: {
    text: 'Click on the appropriate image',
    enabled: false
  },
  explanation: {
    wrong: "Information:wrong answer",
    right: "Information:right answer",
  },
  answer:1
}

ImageSelect.propTypes = {
  /** Items will have the array of images for rendering the images */
  items: PropTypes.array,
  /** Text is used for displaying the selection of answer is correct/wrong */
  text: PropTypes.string,
  /** Tracking the component progress */
  track: PropTypes.instanceOf(ContentTracking),
  /** object for wrong and right feedback */
  explanation:PropTypes.object,
  /** answer number */
  answer:PropTypes.number,
  /** Func description for tracking*/
  handler:PropTypes.func
}

export default ImageSelect