import React, { useState, useEffect } from 'react'
import data from './quiz.json'
import Button from "@material-ui/core/Button";
import ReactDOM from 'react-dom';
import './quiz.scss'
import ReportCard from '../ReportCard/ReportCard';
import Badge from '@mui/material/Badge';
import { useDispatch, useSelector } from 'react-redux';
import * as courseInfoAction from '../../../redux/actions/courseInfoAction'

/**
 * 
 * @param {*} quizData 
 * @returns object that has lot of info about the questions, answers, assesment component intergration for this quiz component
 */

/**
 * Quiz Component will intergrate the radio, multiselect, imageselect together for having sequence of test assesment
*/
const Quiz = ({quizData}) => {
    const { serial, config, validate } = quizData
    const [attempt, setAttempt] = useState(0)
    const [index, setIndex] = useState(0)
    const [showResult, setResult] = useState(false)
    const [prevDisabled, setPrev] = useState(true)
    let quizIndex = useSelector(state => state.courseInfo.resumeQuiz)
    const dispatch = useDispatch()

    const handleEvaluate = (value) => {
        serial[index]['evaluation'] = value
    }

    /**
     * configure questions count
     */
    if(config && config.numberOfQuestion){
        serial.length = Number(config.numberOfQuestion)
    }

    useEffect(() => {
 		setIndex(quizIndex)
        if(serial?.length > 0)
            componentInjector(0)
    }, []);


    /** before creating the component 
     *  previously created will be removed created newly
     */

    const createPlcElement=()=>{
        let plcEle = document.getElementById('crender');
        if(plcEle && plcEle.childNodes.length > 0){
            ReactDOM.unmountComponentAtNode(plcEle);
        }
        let rEl = document.createElement('div');
        rEl.setAttribute('id','crender_'+index);
        plcEle.appendChild(rEl);
        return plcEle;
    }

    /**
     * child component injector when the quiz moved to next question
     * will clear the previous mounted child component and render fresh new component 
    */

    const componentInjector = (i) => {
        try {
            let el = createPlcElement();
            // console.debug("el",el)
            let RenderComponent = {}
            RenderComponent = require(`../${serial[i]?.type}/${serial[i]?.type}`).default
            ReactDOM.render(<RenderComponent {...serial[i]} evaluate={handleEvaluate} isQuiz={true}/>,el)
        } catch(e) {
            console.debug('Error in Component Injection=====>',e)
        }
    }

    /**
     * Handle action for going next item quiz
    */

    const handleNextQuiz = () => {
        //console.log(serial.length)
        if(serial.length > (index + 1)) {
            setIndex(index + 1)
            setPrev(false);
            componentInjector((index + 1));
            console.log('index is', index + 1)
			dispatch(courseInfoAction.resumeQuiz(index + 1))
        } else {
            dispatch(courseInfoAction.resumeQuiz(0))
            setAttempt(attempt + 1)
            setResult(true)
        } 
    }

    const handlePrevQuiz = () => {
        if(index > 0) {
            setIndex(index - 1);
            componentInjector(index - 1);
        } else {
            setPrev(true)
        }
    }

    /**
     * will reset the quiz when the user reach final progress.
     * Only reset the question when retake is available
    */

    const resetQuiz = () => {
        serial.map(el => {
            el.evaluation = {}
            return el
        })
        dispatch(courseInfoAction.resumeQuiz(0))
        setPrev(true)
        setResult(false)
        setIndex(0)
        setTimeout(() => {
            componentInjector(0);
        }, 100)
    }

    const handleRetakeQuiz = () => {
        resetQuiz();
    }

    return (
        <>
        {validate.attempt > attempt?<div className='quiz-main-container'>
            {!showResult ? serial.length > 0 && 
            <>
                <div className='head-item'>
                    <div>
                        <Badge badgeContent={ index + 1 + '/' + config.numberOfQuestion } color="primary">
                        </Badge>
                    </div>
                    <div>
                        {/* <Button disabled={ prevDisabled } className='quiz-next-btn' onClick={ handlePrevQuiz }>Prev</Button> */}
                        <Button className='quiz-next-btn' onClick={ handleNextQuiz }>{ index + 1 === Number( config.numberOfQuestion ) ? "Submit" : 'Next' }</Button>
                    </div>
                </div>
                <div className='quiz-render-section' id="crender">
                </div>
            </> 
            : <ReportCard {...data} retake={handleRetakeQuiz}/> }
        </div> : <p className='attempt-message'>Maximum attempt exceeded</p>}
        </>
    )
}

Quiz.defaultProps = {
    /** Static json data imported from other file for implementing the Quiz Component */
    quizData: data
}

export default Quiz
