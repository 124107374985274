import React, {useEffect, useState} from 'react'
import PropTypes from 'prop-types'
import RichTextEditor from '../RichTextEditor/RichTextEditor';
import { FormLabel } from '@material-ui/core';

/**
 * 
 * TopicDescriptionPanel editor used to modify the propertis of TopicDescription component
 */

const TopicDescriptionPanel = ({ Header, description, title, handleProperties}) => {
    const [data, setData]= useState({ Header, description, title})
    const handleChange = (e) => {
        setData({
            ...data,
            [e.target.name]: e.target.value
        })
    }

    useEffect(() => {
        handleProperties(data)
     }, [data]);

    /**
    * 
    * @param {} value 
    * data html string used for description of the component
    */
    const eventHandlers = (value) => {
        let contentValue = value==='<p></P>' ? '<p>Empty content</p>' : value
        setData({
            ...data,
            description: contentValue
        })
        handleProperties(data)
    }
    return (
        <div className='topic-desc-prop-container topic-desc-edit'>
            <div className='topic-desc-prop-description x-align'>
                <label className="label">Content</label>
                <RichTextEditor data={description} eventHandler={eventHandlers} />
            </div>
        </div>
    )
}

TopicDescriptionPanel.propTypes = {
    /** Heading title of the component */
    Header: PropTypes.string,
    /** related extended content of the component  */
    description: PropTypes.string,
    /** function handler used to save the edited props */
    handleProperties: PropTypes.func
}

export default TopicDescriptionPanel
