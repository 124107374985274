import React, { useEffect, useState, useRef } from 'react'
import UploaderPanel from '../UploaderPanel/UploaderPanel';
import PropTypes from 'prop-types'
import Datahelper from '../../../utils/DataHelper'
import RichTextEditor from '../RichTextEditor/RichTextEditor';
import ACLHelper from '../../../utils/ACLHelper';
import AudioPlayer from "react-h5-audio-player";
import "react-h5-audio-player/lib/styles.css";
import PlayArrowIcon from '@material-ui/icons/PlayArrow'
import { PauseOutlined } from "@material-ui/icons";
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import PlayerConstants from '../../../utils/PlayerConstants';
import DataHelper from '../../../utils/DataHelper';
import useToastNotifications from '../../../utils/hooks/useToastNotifications';


/**
 * 
 * Audio editor panel used to modify the propertis of Audio component
 */

const AudioPanel = ({ src, readable, handleProperties, description, audioFileName, id, isVisited, handleCeAssets }) => {
  const [data, setData] = useState({ src, readable, audioFileName, description, isVisited})
  const [audioSrc, setAudioSrc] = useState('');
  const [isPlay, setPlay] = useState(false)
  const [isVisitedFlag, setIsVisitedFlag] = useState(isVisited||false)
  const player = useRef();
  const [status, setStatus] = useState({ completed: false, text: PlayerConstants.COMPONENT_CONSTANTS.STATUS_INCOMPLETE })
  const { showSuccess} = useToastNotifications();
  //   const [instruction, setInstruct] = useState(instructions);
  //   const [isDragEnabled, setDragEnabled] = useState(track?.status === true ? true:false)
  const UPDATE_STATUS = PlayerConstants.COMPONENT_CONSTANTS.UPDATE_STATUS
  const SAVE_PROGRESS_STATUS = PlayerConstants.COMPONENT_CONSTANTS.SAVE_PROGRESS_STATUS

  useEffect(() => {
    if (data.src) {
      setAudioSrc(data.src);
    }

    /**
    * Manage the component update 
    * progress logic in this method
    * 
    * Update the view status when ever the user interacts
    * Update the progess status ONLY ONCE, when status completes
    * 
    */
    // const updateProgress = (currentItem) => {
    //   switch (currentItem.type) {
    //     case 'PLAYHEAD':
    //       track.state = { ...track.state, t: currentItem.time }
    //       handler({ type: UPDATE_STATUS, id: track?.id, name: cename })
    //     break
    //     case 'END':
    //       if (track.status === 0) {
    //         track.status = 1
    //         setInstruct({
    //           ...instruction,
    //           text: `You have completed ${cename}.`,
    //           className: 'completed'
    //         })
    //         setStatus({completed: true, text:PlayerConstants.COMPONENT_CONSTANTS.STATUS_COMPLETE})
    //         handler({ type: SAVE_PROGRESS_STATUS, id: track?.id, name: cename })
    //       }
    //     break
    //   }
    // }

    /*
    * will update the progress when audio has entirely played 
    */

    let auplayer = player.current.audio.current;
    auplayer.onended = function () {
      //   updateProgress({ type: 'END' });
      setPlay(false);
      //   setDragEnabled(true)
    }
    auplayer.onplaying = (e) => {

      //   updateProgress({ type: 'PLAYHEAD', time: auplayer.currentTime })
      const mediaEle = document.querySelectorAll('video, audio')
      for (const media of mediaEle) {
        if (media !== e.target) {
          media.pause()
        }
      }
    }
    auplayer.onpause = (e) => {
      setPlay(false)
    }
  }, [isPlay, data.src]);


  const playAudio = () => {
    setPlay(true);
    player.current.audio.current.play();
  }

  const pauseAudio = () => {
    setPlay(false);
    player.current.audio.current.pause();
  };

  const getAudioUrl = (path) => {
    return DataHelper.getResourcePath(3, path);
  }

  const updateCurrentTime = (secs) => {
    try {
      let currentTime = player.current?.audio?.current?.currentTime
      let time = currentTime + secs;
      if (time < 0) {
        time = 0;
      }
      player.current.audio.current.currentTime = time;
    } catch (e) {
      console.log(e)
    }
  }

  const rewind = () => {
    updateCurrentTime(-10)
  }

  const forward = () => {
    updateCurrentTime(10)
  }


  //   const enablehotKeys = () => {
  //     hotkeys('ctrl+alt+7, ctrl+alt+d', function (event, handler) {
  //         switch (handler.key) {
  //             case 'ctrl+alt+7':
  //                 setDragEnabled(true)
  //                 console.log('enabled video seek', isDragEnabled)
  //                 break;
  //             case 'ctrl+alt+d':
  //                 setDragEnabled(false)
  //                 console.log('disabled video seek', isDragEnabled)
  //                 break;
  //           default: return 1;
  //         }
  //       });
  // }

  // useEffect(() => {
  //   enablehotKeys()
  //   let controlBar = document.querySelectorAll('.rhap_progress-section')
  //   if(isDragEnabled) {
  //       controlBar.forEach(el => el.style.pointerEvents = 'initial')
  //   } else {
  //       controlBar.forEach(el => el.style.pointerEvents = 'none')
  //   }


  // }, [isDragEnabled])


  useEffect(() => {
    handleProperties(data)
  }, [data])


  /**
   * 
   * @param {} value 
   * data html string used for description of the component
   */
  const eventHandlers = (value) => {
    setData({
      ...data,
      description: value
    })
    handleProperties(data)
  }

  const handleMedia = (uploaded) => {
    try {
      let item = { ...data };
      item.src = uploaded.name
      item.audioFileName = uploaded.name
      handleCeAssets(data?.src,uploaded?.name,item);
      setData(item);
    } catch (e) {
      console.debug(e);
    }
  }
  const copyToClipboard = (param) => {
    navigator.clipboard.writeText(param)
    showSuccess('Audio id copied successfully');
  }

  const handleSwitchChange = (param) => {
    setIsVisitedFlag(param)
    setData({
      ...data,
      isVisited: param
    })
  }


  return (
    <div className='audio-prop-container'>

      <div className="row">
        {ACLHelper.isFeatureEnabled(4, 2) && <>
          <div className='col-sm-12 col-md-12 col-lg-12'>
            <UploaderPanel accept={'audio'} fileName={data?.audioFileName}  name={`${id}_${Datahelper.getTime()}-audio`} setUploadedData={handleMedia} />
          </div>
          <div className='col-sm-12 col-md-12 col-lg-12'>
                  <FormControlLabel label={'Optional listening '} className="optional-listening label" labelPlacement="start" control={<Checkbox checked={isVisitedFlag} onChange={(e) => handleSwitchChange(e.target.checked)} sx={{ '& .MuiSvgIcon-root': { fontSize: 26 } }}/>}  />
            </div>

          <div style={{display:'flex'}} >
            <section className='audio-section'>
              <div >
                <div className='audio-container'style={{marginTop:'2px'}}>
                  <div className='control-icon'>
                    <span> {!isPlay ? <PlayArrowIcon style={{ marginLeft: '30px' }} className="play-pause-icon" onClick={() => playAudio()} /> : <PauseOutlined className="play-pause-icon" onClick={() => pauseAudio()} />}</span>
                    <AudioPlayer
                      preload='metadata'
                      src={getAudioUrl(audioSrc)}
                      onPlay={e => console.log("onPlay")}
                      autoPlayAfterSrcChange={isPlay}
                      ref={player}
                      volume={0.5}
                      muted={false}
                      loop={false}
                    />
                  </div>
                  <div>
                    <img alt='backword-icon' className="fr-btns back-10" src={require(`../../../assets/img/back-ten_1.png`).default} onClick={() => rewind()} />
                    <img alt='forward-icon' className="fr-btns front-10" src={require(`../../../assets/img/forward-ten_1.png`).default} onClick={() => forward()} />
                  </div>
                </div>
              </div>
            </section>
          </div>
        </>}
      </div>
      <div className='audio-prop-readable x-align'>
        <RichTextEditor data={description ? description : readable} eventHandler={eventHandlers} />
      </div>

    </div>
  )
}

AudioPanel.propTypes = {
  /** audio url source */
  src: PropTypes.string,
  /** video description content */
  description: PropTypes.string,
  /** function handler used to save the edited props */
  handleProperties: PropTypes.func,
  /** function handler used to update the Assets */
  handleCeAssets : PropTypes.func,
}

export default AudioPanel
