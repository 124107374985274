import React from 'react'
import PropTypes from 'prop-types'
import './badge.scss'

const Badge = ({courses, icon, text, color, textColor}) => {
    return (
        <div className='badge-container row' style={{background: color}}>
            <div className='icon-side col-md-4 col-sm-4 col-lg-4 col-4'>
                <span className='icon-badge-cap' style={{color: textColor}}>{icon}</span>
            </div>
            <div className='course-info col-md-8 col-sm-8 col-lg-8 col-8'>
                <h2 className='header-icon' style={{color: textColor}}>{courses}</h2>
                <p className='text-badge'>{text}</p>
            </div>
        </div>
    )
}

Badge.propTypes = {
    courses: PropTypes.string,
    text: PropTypes.string,
    textColor: PropTypes.string,
    icon: PropTypes.node
}

export default Badge
