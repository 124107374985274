import { combineReducers } from "redux"; 
import courseInfoReducer from "./courseInfoReducer";
import courseTrackingReducer from "./courseTrackingReducer";
import courseEditReducer from './courseEditReducer'
import userInfoReducer from './userInfoReducer'
import utilityReducer from "./utilityReducer";
import ceAssetsInfoReducer from "./ceAssetsInfoReducer";
import globalStyleReducer from "./globalStyleReducer";

const rootReducer = combineReducers({
    courseInfo:courseInfoReducer,
    courseTracking:courseTrackingReducer,
    courseEdit: courseEditReducer,
    userInfo:userInfoReducer,
    utility:utilityReducer,
    ceAssetsInfo:ceAssetsInfoReducer,
    globalStyle:globalStyleReducer
})

export default rootReducer;