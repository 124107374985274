import React, { useState, useRef, useEffect } from 'react';
import PropTypes from 'prop-types';
import './customselect.scss';

/**
 * CustomSelect Component
 * 
 * @param {string} label - Label for the select dropdown
 * @param {Array<{ value: string, label: string }>} options - List of options for the dropdown
 * @param {function} onChange - Callback function when selection changes
 * @param {boolean} multiple - Flag to allow multiple selections
 * @param {Array|Object} value - Selected value(s)
 */ 
const CustomSelect=({ label, options, onChange, multiple, value=[], style, className })=> {
  // State to store selected options
  const [selectedOptions, setSelectedOptions] = useState(value);
  // State to control dropdown visibility
  const [isOpen, setIsOpen] = useState(false);
  // Ref for dropdown container to handle outside clicks
  const dropdownRef = useRef(null);

  // Toggle dropdown visibility
  const handleToggleDropdown = () => {
    setIsOpen(!isOpen);
  };

  // Handle option selection
  const handleOptionClick = (option) => {
    let newSelectedOptions;
    if (multiple) {
      if (selectedOptions.some((selected) => selected?.value === option?.value)) {
        // Remove option if already selected
        newSelectedOptions = selectedOptions.filter((selected) => selected?.value !== option?.value);
      } else {
        // Add option if not selected
        newSelectedOptions = [...selectedOptions, option];
      }
    } else {
      // Handle single selection
      newSelectedOptions = [option];
      setIsOpen(false);
    }
    setSelectedOptions(newSelectedOptions);
    if (onChange) {
      onChange(multiple ? newSelectedOptions : option);
    }
  };

  // Handle click outside dropdown to close it
  const handleClickOutside = (event) => {
    if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
      setIsOpen(false);
    }
  };
  
  useEffect(() => {
    if (multiple) {
      if (JSON.stringify(selectedOptions) !== JSON.stringify(value)) {
        setSelectedOptions(value);
      }
    } else {
      if (JSON.stringify(selectedOptions[0]?.value||selectedOptions[0]) !==  JSON.stringify(value?.value||value)) {
        setSelectedOptions([value]);
      }
    }
  }, [value, multiple, selectedOptions]);


  // Attach event listener for detecting outside clicks
  useEffect(() => {
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);


  return (
    options?.length > 0 &&<div className={`custom-select-container ${className}`} ref={dropdownRef} style={style}>
      {label && <div className="custom-select-label">{label}</div>}
      <div className="custom-select-box" onClick={handleToggleDropdown}>
        <div className="custom-select-selected">
        {multiple 
        ? (selectedOptions.length > 0 
            ? selectedOptions.map(option => option.label).join(',') 
            : 'Select...')
        : (selectedOptions.length > 0 
            ? selectedOptions[0]?.label || options.find(option => option.value === (selectedOptions[0]||value))?.label 
            : 'Select...') || 'Select...' }
        </div>
        <div className="custom-select-arrow">
          {isOpen ? '▲' : '▼'}
        </div>
      </div>
      {isOpen && (
        <div className="custom-select-dropdown">
          {options?.map((option) => (
            <div
              key={option?.value}
              className={`custom-select-option ${multiple && selectedOptions?.some((selected) => selected?.value === option?.value) ? 'selected' : ''}`}
              onClick={() => handleOptionClick(option)}
            >
              {multiple && <input
                type="checkbox"
                checked={selectedOptions?.some((selected) => selected?.value === option?.value)}
                readOnly
              />}
              {option?.label}
            </div>
          ))}
        </div>
      )}
    </div>
  );
}

CustomSelect.propTypes = {
  label: PropTypes.string,
  options: PropTypes.arrayOf(
    PropTypes.shape({
      value: PropTypes.string,
      label: PropTypes.string,
    })
  ),
  onChange: PropTypes.func,
  multiple: PropTypes.bool,
};

export default CustomSelect;
