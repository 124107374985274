class ArchiveVideo {
    _id = 0
    constructor ( { id, name, props, status,ccid } ) {
        this.id = id;
        this.name = name;
        this.props = props;
        this.status = status;
        this.ccid = ccid;
    }
    
}
export default ArchiveVideo
