import React from 'react';
import PropTypes from 'prop-types';
import CircularProgress from '@material-ui/core/CircularProgress';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';
import './progress.scss'

/**
 * Course completion process will be tracked using this progress component
*/

/**
 * 
 * @param {number} value 
 * @returns returns current progress value of the course
 */

/**
 * CircularProgress wil display the topics completed
*/
function CircularProgressWithLabel({value}) {
  return (
    <Box position="relative" display="inline-flex">
      <CircularProgress variant="determinate" color="primary" value={value} />
      <Box
        top={0}
        left={0}
        bottom={0}
        right={0}
        position="absolute"
        display="flex"
        alignItems="center"
        justifyContent="center"
      >
        <Typography variant="caption" style={{textAlign: 'center', fontSize: '8px', paddingTop: '0'}} component="div" color="textSecondary">{`${Math.round(
          value,
        )}%`}</Typography>
      </Box>
    </Box>
  );
}

CircularProgressWithLabel.propTypes = {
  /** The value of the progress indicator for the determinate variant. Value between 0 and 100.*/
  value: PropTypes.number.isRequired,
};

/**
 * 
 * @param {number} progress 
 * @returns current value of the course progress
 */

const Progress = ({progress}) => {
  let p  = isNaN(progress) ? 0 : progress;
  return <CircularProgressWithLabel color="primary" value={p} />;
}

Progress.propTypes = {
  /** Progress gives the number of completed topics percent  */
  progress: PropTypes.number
}

export default Progress