import PlayerConstants from './PlayerConstants';
import BuildHelper from './BuildHelper'
let hostNames = ['localhost','contentenablers.dev','alpha.rlm.contentenablers.com'];
export const ASSET_PATH = hostNames.indexOf(window.location.hostname) !== -1 ? 'https://s3.us-east-1.amazonaws.com/thumbs.contentenablers.com/assetslib/' : 'https://s3.us-east-1.amazonaws.com/library.contentenablers.com/ceauthor/' 
export const IMAGE_PATH = `${ASSET_PATH}/images`
export const VIDEO_PATH = `${ASSET_PATH}/videos`
export const AUDIO_PATH = `${ASSET_PATH}/audio`

export const MEDIA_PATH = 'https://assets.contentenablers.com/player/'
export const COURSE_PATH = 'https://contentenablers.com'
// export const SUBTITLE_PATH = 'https://lms.contentenablers.com/storefront/'
export const SUBTITLE_PATH = 'https://learner.contentenablers.com/storefront/'
export const TEMP_SUBTITLE_PATH = 'https://alpha.rlm.contentenablers.com/subtitle/'

export const getPlaceHolderImg = (size) =>  {
    return `./assets/images/ph_${size}.png`
}


export const pathCreater = (type) => {
    let RESOURCE_PATH = ASSET_PATH
    let externalPath = window.sessionStorage.getItem('external-path') || ''
    /* 
        * Switch your path based on scorm or platform
        * Should be Configured as a Player , Should be configured as SCORM  package
        * then switch the path
     */
    if(window.ce?.isScormPack === true || window.ce?.isStandalone === true){
        RESOURCE_PATH = `./content/${BuildHelper?.defaultLanguage()||"en_US"}/`
        // console.debug("RESOURCE_PATH",RESOURCE_PATH)
    }

    if(type === 0) {
        return `${RESOURCE_PATH}images/`
    } else if(type === 1) {
        return `${RESOURCE_PATH}videos/`
    } else if (type === 2) {
        return `${COURSE_PATH}`
    } else if (type === 4) {
        return `${COURSE_PATH}/vignettes/images/`
    } else if(type === 5) {
        return `${SUBTITLE_PATH}`
    } else if(type === 6) {
        return `${RESOURCE_PATH}docs/`
    }
    else {
        return `${RESOURCE_PATH}audio/`
    }
}