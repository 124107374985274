import React, { useState, useEffect } from 'react'
import AccordionItem from '../../../components/ui/AccordionItem/AccordionItem'; // Assuming your AccordionItem component is in a separate file
import { DndProvider } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';
import EditIcon from '@material-ui/icons/Edit';
import SaveIcon from '@mui/icons-material/Save';
import AppButton from '../../../components/ui/AppButton/AppButton';
import AddIcon from '@mui/icons-material/Add';
import RadioSelect from '../../../components/ui/RadioSelect/RadioSelect';
import ResizableTextArea from '../../../components/ui/ResizableTextArea/ResizableTextArea';
import CustomCheckbox from '../../../components/ui/CustomCheckbox/CustomCheckbox'
import TaskAltIcon from '@mui/icons-material/TaskAlt';
import UnpublishedIcon from '@mui/icons-material/Unpublished';
import DeleteIcon from '@material-ui/icons/Delete';
import { AddCircle } from '@material-ui/icons';
import UploaderPanel from '../UploaderPanel/UploaderPanel';

// Initial quiz object structure
export const quizObj = {
    answer: [{
        answerText: "",
        isCorrectAnswer: true
      }],
    rightExplanationText:'',
    wrongExplanationText:'',
    questionText: ``,
    explanation:false, 
    questionType: null,
    img:null
}

/**
 * Handles the update of properties.
 * 
 * @param {Function} handleProperties - Function to handle updating of properties.
 * @returns {void}
 * 
 * Handles the questions data.
 * 
 * @param {Array} items - Array of question items to be processed.
 */

const KnowledgeCheckPanel = ({ handleProperties, id, items, handleCeAssets }) => {
    const [data, setData] = useState({items });
    const [quizForm, setQuizForm] = useState(quizObj);
    const [questionData, setQuestionData] = useState(items);
    const [isActive, setIsActive] = useState(null);
    const [isAddNew, setIsAddNew] = useState(false);
    const [isImage, setIsImage] = useState(false);

    useEffect(() => {
        handleProperties(data)
    }, [data])


    const handleUpdateAccList=(e, updated)=>{
        setQuestionData([...updated]);
        handleProperties({ ...data, items: [...updated] });
        setData({ ...data, items: [...updated] });
        setQuizForm({ ...quizObj });
    }

    const handleChange=()=>{};


    const handleEdit = (event, index) => {   // Handle quiz edit
        setIsActive(index);
        setQuizForm(questionData[index] || quizObj);
        document.getElementById("myDiv").scrollIntoView();
        event.stopPropagation()
    }


    const handleSave = (event) => {
        let updatedList = [...questionData];
        if (isActive || isActive === 0) {
            updatedList[isActive] = { ...quizForm }
            setIsActive(null);
        } else {
            updatedList = [...updatedList, quizForm];
        }
        setQuestionData([...updatedList]);
        setData({ ...data, items: [...updatedList] });
        handleProperties({ ...data, items: [...updatedList] });
        setQuizForm(null);
        setIsAddNew(false);
        event.stopPropagation()
    }

    const handleCancel = (event) => {
        setQuizForm(null);
        setIsActive(null);
        setIsAddNew(false);
        event.stopPropagation()
    }



    const handleType = (e) => {
        let Form = { ...quizForm, [e?.target?.name]: e?.target?.value }
        if (e?.target?.value === 'true/false') { // type true false default option 
            Form = {
                ...Form, answer: [
                    { answerText: 'True', isCorrectAnswer: true },
                    { answerText: 'False', isCorrectAnswer: false },
                ]
            }
        } else if (e?.target?.value === 'single'  && Form?.answer?.length > 0) { // single answer only allow single answer
            let answer = Form?.answer?.map(e => { return { ...e, isCorrectAnswer: false } });
            answer[0].isCorrectAnswer = true
            Form = { ...Form, answer: answer }
        }
        setQuizForm({ ...Form })
        e.stopPropagation()
    }

    const handleQuestion = (e) => {
        let Form = { ...quizForm, [e?.target?.name]: e?.target?.value };
        setQuizForm({ ...Form })
        e.stopPropagation()
    }

    const handleImage=(data)=>{
        let Form = { ...quizForm, 'img': data?.name };
        setQuizForm({ ...Form })
    }

    // Handle checkbox change for feedback
    const handleCheck = (e) => {
        setQuizForm({ ...quizForm, [e?.target?.name]: (e?.target?.checked ? 1 : 0) })
        e.stopPropagation()
    }

    const handleCorrection = (e, index) => {
        if (['single', 'true/false'].includes(quizForm.questionType)) {
            // For True/False and Single Answer question types only one answer
            let answer = quizForm?.answer?.map(e => { return { ...e, isCorrectAnswer: false } })
            answer[index].isCorrectAnswer = true;
            setQuizForm({ ...quizForm, answer: [...answer] })
            return;
        }
        // For Multiple Answer question type
        quizForm.answer[index].isCorrectAnswer = !(quizForm?.answer[index]?.isCorrectAnswer)
        setQuizForm({ ...quizForm })
    }

    const handleOption = (e, key) => {
        quizForm[e?.target?.name][key]['answerText'] = e?.target?.value || ""
        setQuizForm({ ...quizForm })
        e.stopPropagation()
    }

    const handleDelete = (index) => {
        const removed = quizForm?.answer?.filter((e, i) => i !== index)
        setQuizForm({ ...quizForm, answer: [...removed] })
    }

    const handleAddNewOptions = () => {
            quizForm?.answer?.push({
                answerText: '',
                isCorrectAnswer:false
            })
            setQuizForm({ ...quizForm })
    }


    // Handle adding a new quiz question
    const handleAddNewQuiz = () => {
        setIsActive(null)
        setQuizForm({ ...quizObj });
        setIsAddNew(true);
        document.getElementById("myDiv").scrollIntoView();
    }

    const formValidation = () => {
    try{
        if (quizForm?.answer?.length < 2 || !(quizForm?.questionType) || quizForm.questionText.length < 2) { // Check that qtype is not null
            return false;
        }
        // Check that at least one option has ans - true
        let hasTrueAnswer = false;
        for (let i = 0; i < quizForm?.answer?.length; i++) {
            if (quizForm?.answer[i]?.answerText.length < 2) {
                hasTrueAnswer = false;
                break;
            }
            if(quizForm?.answer[i]?.isCorrectAnswer) hasTrueAnswer = true;
        }
        
        if (!hasTrueAnswer) {
            return false;
        }
        // Check that feedback array has at least 2 non-empty strings with minimum length of 2
        // if (Number(quizForm?.hasfeedback)=== 1) {
        //     for (let i = 0; i < quizForm?.feedback?.length; i++) {
        //         if (quizForm?.feedback[i]?.length < 1) {
        //             return false;
        //         }
        //     }
        // }
        // If all conditions are met, return true
        return true;
     }catch(e){return false}
    }


    const editPanel = (editItem) => {
        const type = editItem?.questionType||'';
        const options = [
            { label: 'True/false', value: 'true/false' },
            { label: 'Single Answer', value: 'single' },
            { label: 'Multiple Answer', value: 'multiple' },
        ];
    
        return <>
            <div className={`quiz-summary`}>
                <div className='edit-area label'>
                    <label>Question Type </label>
                    {/* <div className={`${!formValidation()&& 'disabled'}`}><SaveIcon  onClick={handleSave}  /></div> */}
                    <div className='px-2'>
                        < AppButton size="small" theme="secondary2" className="mx-2" onClick={handleCancel} >Cancel  </AppButton>
                        < AppButton size="small" theme="secondary2" onClick={handleSave} disabled={!formValidation()}>{(isActive || isActive === 0) ? 'Update' : <><AddIcon />Add</>}</AppButton>
                    </div>
                </div>
                {/* <label className='label'>Question Type <SaveAltIcon onClick={(e)=>handleUpload(e, questionData?.length)} /> </label> */}
                <RadioSelect
                    options={options}
                    name="questionType"
                    direction='horizontal'
                    selectedValue={String(editItem?.questionType)}
                    onChange={handleType}
                />
                <ResizableTextArea
                    placeholder="Question"
                    onChange={handleQuestion}
                    value={editItem?.questionText}
                    name="questionText"
                    minRows={3}
                />
                {editItem?.questionType==='true/false' && <div>
                <CustomCheckbox
                    checked={isImage}
                    label="Select Image"
                    className="py-2"
                    name="is_image"
                    onChange={(e)=>setIsImage(e?.target?.checked)}
                />
                {isImage && <UploaderPanel  
                accept={"image"} 
                fileName={editItem?.img || ""}
                setUploadedData={(e) => handleImage(e)}
                /> }</div>}
                <CustomCheckbox
                    checked={(Number(editItem?.explanation) === 1)}
                    label="Explanation / Feedback"
                    className="py-2"
                    name="explanation"
                    onChange={handleCheck}
                />
                {editItem?.explanation && <div className='row'>
                    <div className='col-sm-12 col-md-12 col-lg-6'>
                        <label className='label'>Right Answer</label>
                        <ResizableTextArea
                            placeholder="Right Answer"
                            name="rightExplanationText"
                            minRows={2}
                            value={editItem?.rightExplanationText|| ""}
                            onChange={(e) => handleQuestion(e)}
                        />
                    </div>
                    <div className='col-sm-12 col-md-12 col-lg-6'>
                        <label className='label'>Wrong Answer</label>
                        <ResizableTextArea
                            placeholder="Wrong Answer"
                            name="wrongExplanationText"
                            value={editItem?.wrongExplanationText || ""}
                            onChange={(e) => handleQuestion(e)}
                            minRows={2}
                        />
                    </div>
                </div> || ''}
                {/* <br/> */}
                <label className='label d-flex'>Options</label>
                <div className='options-list'>
                    {editItem?.answer?.map((e, idx) => (<>
                        <div className="option-container" key={idx}>
                            {editItem?.answer[idx]?.isCorrectAnswer ? <TaskAltIcon className='correct-ans option-ans' onClick={(e) => handleCorrection(e, idx)} /> : <UnpublishedIcon onClick={(e) => handleCorrection(e, idx)} className='option-ans' />}
                            <ResizableTextArea
                                placeholder="Enter your Option"
                                name="answer"
                                className={'option-textarea'}
                                value={e?.answerText}
                                onChange={(event) => handleOption(event, idx)}
                                minRows={1}
                            />
                            {quizForm?.answer?.length > 2 && <DeleteIcon className='option-delete p-0 m-0' onClick={() => handleDelete(idx)} />}
                        </div>
                    </>
                    ))}
                    {!type && <p className='option-error'>  Please select a question type and add the relevant options. Ensure that each question field is mandatory and contains at least 2 characters.</p>}

                </div>
                { type && <div className='add-quiz-option py-2' title={'Add Option'} onClick={handleAddNewOptions} ><AddCircle className='add-accordion' /> Add New Option </div>}
            </div>

        </>

    }


    const questionList=()=>{
        return <>
         <DndProvider backend={HTML5Backend}>
                {questionData && questionData?.length > 0 &&
                    questionData?.map((el, index) => {
                        const isDisable = index !== isActive;
                        let editItem = isDisable ? el : quizForm
                        return <AccordionItem
                            className={`inner-summary`}
                            key={index}
                            name={`${editItem?.questionText}`}
                            index={index}
                            el={editItem}
                            isDrag={false}
                            listUpdate={handleUpdateAccList}
                            data={questionData}
                            handleAccordionChange={handleChange}
                            expanded={false}
                            summary={{
                                delete: { min: 1 },
                                custom: {
                                    element: <div className='edit-prop-accordion'>
                                        {isDisable ? <span data-tooltip='Click to Edit your quiz' data-position='bottom' onClick={(e) => handleEdit(e, index)}><EditIcon /></span>
                                            : <span data-tooltip='Click to save the quiz' data-position='bottom' onClick={(e) => handleSave(e, index)} > <SaveIcon /></span>}
                                    </div>
                                }
                            }}
                        />
                    })}
            </DndProvider>
            <div className='add-new-question' title={'Add Option'} onClick={() => handleAddNewQuiz()}><AddCircle className='add-accordion' /> Add New Question </div>
        </>
    }


  return (
    <div className='row p-2'>
        <div className='col-sm-12 col-md-12 col-lg-6 list-area knowledge-check-prop quizzes-panel-prop card-prop-main-ui acccordion-item-list'>
          {questionList()}
        </div>
        <div className='col-sm-12 col-md-12 col-lg-6'>
            {(isAddNew || isActive || (isActive===0)) && <div className='knowledge-check-prop edit-area quizzes-panel-prop card-prop-main-ui acccordion-item-list' id='myDiv'>
             {editPanel(quizForm)}
            </div>}
        </div>
        
    </div>
  )
}

export default KnowledgeCheckPanel