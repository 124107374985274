import Button from '@material-ui/core/Button';
import React, { useEffect, useRef, useState } from 'react'
import RadioSelect from '../RadioSelect/RadioSelect';
import PropTypes from 'prop-types'
import './casestudy.scss'
import Data from './data.json'

/**
 * 
 * @param {*} caseData 
 * @returns array of items including video_url, checkpoints for quiz intergration
 */

/**
 * CaseStudy component will teach topic and have test assesement for each topic
*/
const CaseStudy = ({caseData}) => {
    const { video_url, subhead, head, knowledge_check } = caseData
    const [videoUrl, setUrl] = useState(video_url)
    const [cue, setCue] = useState({})
    const [currentTime, setTime] = useState(0)
    const videoRef = useRef('')
    const [index, setIndex] = useState(0)
    const [contentId, setId] = useState(0)
    const [showQuiz, setQuiz] = useState(false)

    const handleTime = () => {
        let currTime = Math.round(videoRef.current.currentTime)
        if((knowledge_check[index].content.length > ( contentId + 1)) && knowledge_check[index].content[contentId]['cue'] === currTime) {
            setId(contentId + 1)
        }
        if(cue.e === currTime) {
            videoRef.current.pause()
        }
    }

    const handleNext = () => {
        setQuiz(false)
        if (knowledge_check.length > (index + 1)) {
            setId(0)
            setIndex( index + 1 )
            setCue(knowledge_check[index + 1].cue)
            videoRef.current.currentTime = knowledge_check[index + 1].cue.s
            videoRef.current.play()
        } else {
            setIndex(0)
            setId(0)
            videoRef.current.currentTime = 0
            setCue(knowledge_check[0].cue)
            videoRef.current.play()
        }
    }

    const handleQuiz = () => {
        setQuiz(true)
        videoRef.current.pause()
    }

    useEffect(() => {
        setCue(knowledge_check[index].cue)
    }, [])
    return (
        <div className='case-study-main'>
            <div className='case-study-content'>
                <h4 className='title'>{subhead}</h4>
                <div className='case-row row'>
                    <div className='col-md-6'>
                        <div className="video-container">
                            <video ref={videoRef} width="500" autoPlay onTimeUpdate={handleTime} muted>
                                <source src={videoUrl} type="video/mp4" />
                            </video>
                        </div>
                    </div>
                    <div className='col-md-6'>
                        <div className='case-study-text-content'>
                            { showQuiz ?  <RadioSelect {...knowledge_check[index]} isQuiz={true}/>: <> <h4 className='case-study-title'>Case Study Description</h4>
                            <p className='case-description'>{knowledge_check[index].content[contentId]['text']}</p> </>}
                        </div>
                        <div className='continue-btn'>
                            {!showQuiz ? <Button onClick={handleQuiz} >Take Quiz</Button>:
                            <Button onClick={handleNext} >Continue</Button> }
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

CaseStudy.defaultProps = {
    caseData: Data
}

CaseStudy.propTypes = {
    /** Data includes the cuepoints, video url  */
    caseData: PropTypes.object
}

export default CaseStudy
