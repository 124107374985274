import React, {useState, useEffect} from 'react'
import PropTypes from 'prop-types'
import { FormControlLabel, FormLabel, Radio, RadioGroup } from '@material-ui/core';
import RadioSelect from '../../../components/ui/RadioSelect/RadioSelect';
import { AddCircle } from '@material-ui/icons';
import UploaderPanel from '../UploaderPanel/UploaderPanel';
import useForceUpdate from 'use-force-update';
import Datahelper from '../../../utils/DataHelper'
// import CKeditor from '../CKeditor/CKeditor';
import RichTextEditor from '../RichTextEditor/RichTextEditor';
import ACLHelper from '../../../utils/ACLHelper';
import { getPlaceHolderImg } from '../../../utils/MediaConfig';
import Checkbox from '@mui/material/Checkbox';
import AccordionItem from '../../../components/ui/AccordionItem/AccordionItem'; 
import { DndProvider } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';

const CarouselsPanel = ({carousalData, dynamicHeight, defaultHeight, handleProperties,id,isTextSeprate,handleCeAssets,autoPlay,  ...rest}) => {

    const forceUpdate = useForceUpdate()
    const [expanded, setExpanded] = useState(0);
    const [list, setList] = useState(JSON.parse(JSON.stringify(carousalData)));
    const [drag, setDrag] = useState(false);
    useEffect(()=>{
        handleProperties({carousalData: list, isTextSeprate, dynamicHeight,autoPlay, defaultHeight, ...rest})
    },[list])

    const handleChange = (panel) => (event, isExpanded) => {
        setExpanded(isExpanded ? panel : false);
    };

    const handleDelete = (event, index) => {
        if (list.length !== 1) {
            const removed = list.filter((e, i) => i !== index)
            setList([...removed])
            handleProperties({carousalData:removed, isTextSeprate, ...rest})
            forceUpdate()
        }
        event.stopPropagation()
    }

    const addCarousels = () => {
            let eList = [...list,{
                image: getPlaceHolderImg(600)
            }]
            setList(eList)
            handleProperties({carousalData:list,isTextSeprate, ...rest})
    }

     /**
     * 
     * @param {} value 
     * data html string used for description of the component
     */
      const eventHandlers = (media, i) => {
        list[i]['imageFile'] = media
        setList(list)
   
        handleProperties({carousalData: list,isTextSeprate, ...rest})
    }

    /**
     * 
     * @param {*} data 
     * only file name sending here in player component its concat with default path
     */
         const handleMedia =(data,index)=>{
            try{
                if(data){
                    const updatedList=[...list]
                    updatedList[index] = {...updatedList[index],image:data.name}
                    let prop={carousalData:[...updatedList],isTextSeprate,...rest}
                    handleCeAssets(list[index].image,data.name,prop);
                    setList([...updatedList]);
                    handleProperties(prop)
                }
            }
            catch(e){
                console.log(e);
            }
        }

        const imageStyleChange = (e, index) => {
            if(!list[index]["styles"]){
                list[index]={...list[index],styles:{}};
            }
            list[index]["styles"][e.target.name] = e?.target?.value || "";
            setList([...list]);
            handleProperties({carousalData:[...list],isTextSeprate, ...rest});
          };

        
        const descriptionChange = (value, i) => {
            list[i]['description'] = value
            setList(list)
            console.log('list carousel', {carousalData: list, ...rest})
            handleProperties({carousalData: list,isTextSeprate, ...rest})
        }

        const textPositionChange = ( e ) => {
            let value= e?.target?.value;
            let isSeprate = ( value === 'outside_image' ) ? true : false
            setList( list );
            handleProperties( { carousalData: list,isTextSeprate:isSeprate } );
        }

        const ImageHeight = [
            { name: 'Small', value:300 },
            { name: 'Medium', value:400},
            { name: 'Large', value:500},
            { name: 'Extra Large', value:600},
        ];

        const handleDynamicHeight =(e)=>{
            try{
                let prop={dynamicHeight:e.target.checked,carousalData: list, ...rest};
                delete prop?.defaultHeight;
                handleProperties({...prop});
            }catch(e){}
        }
        const handleUpdateAccList = (e, listData) => {
            setList([...listData]);
            handleProperties({carousalData:[...listData],...rest});
        }

        const handleDefaultHeight =(e)=>{
            handleProperties({defaultHeight:e.target.value, ...rest});
        }

        const carouselSettings=(e)=>{
            handleProperties({[e.target.name]:e?.target?.checked, ...rest});
        }

    return (
        <div className='carousels-panel-container'>
           <div className={`carousel-global-property`}>
                <div className="card-prop-file">
                <p className='label' id="demo-radio-buttons-group-label"> Align Text </p>
                    <RadioSelect
                      options={[
                        {label:"On Image",value:"on_image",name:'isTextSeprate'},
                        {label:"Outside Image",value:"outside_image",name:'isTextSeprate'}
                      ]}
                      color="#000"
                      name="qtype"
                      direction='horizontal'
                      selectedValue={isTextSeprate ? 'outside_image' : 'on_image'}
                      onChange={textPositionChange}
                    />
                </div>
                <div className="dynamic-height-box">
                    <FormControlLabel label={'DynamicImageHeight'} className="ck-check-box" labelPlacement="end" control={<Checkbox checked={dynamicHeight || false} onChange={(e) => handleDynamicHeight(e)} />} />
                    <select
                        value={defaultHeight || 400}
                        onChange={(e) => handleDefaultHeight(e)}
                        className="dropdown"
                        inputProps={{ 'aria-label': 'Without label' }}
                        size="small"
                        disabled={dynamicHeight || false}
                    >
                        {ImageHeight.map((item, idx) => <option key={idx} className='select-option-list' value={item.value}>{item.name}</option>)}

                    </select>
                </div>
                <div className="dynamic-height-box">
                    <FormControlLabel label={'Auto Scroll'} className="ck-check-box" labelPlacement="end" control={<Checkbox checked={autoPlay || false} name="autoPlay" onChange={(e) => carouselSettings(e)} />} />
                </div>
            </div>  
            <div className={`card-prop-main-ui acccordion-item-list`}>
        <div className="acccordion-item-switch"><FormControlLabel label={'Allow reorder'} className="ck-check-box" labelPlacement="end" name="isCharacter" control={<Checkbox checked={drag} onChange={(e) => setDrag(!drag)} />} /> </div>
      <DndProvider backend={HTML5Backend}>
            {
               list && (list.length > 0) && list.map((el, index) => {
                    return(<AccordionItem
                className='inner-summary'
                key={index}
                name={`${index+1} Card`}
                index={index}
                el={el}
                data={list}
                listUpdate={handleUpdateAccList}
                expanded={expanded}
                handleAccordionChange={handleChange}
                isDrag={drag}
                summary={{delete: { min: 1 }}}
                dynamicDetailsComponent={<> {ACLHelper.isFeatureEnabled(4,2) &&
                <div className='row'>
                <div className="card-prop-file col-lg-12 col-md-12 col-sm-12">
                    <label htmlFor="formFile" className="label">Carousel Image</label>
                    <UploaderPanel accept={'image'} 
                    fileName={list[index]?.image}  
                    name={`${id}_${Datahelper.getTime()}-${index+1}`}  
                    setUploadedData={(el)=>handleMedia(el,index)}
                    styles={{
                        position:
                        list[index]["styles"]?.position ||
                          "50% 50% / cover no-repeat",
                        color:
                           list[index]["styles"]?.color ||
                          "rgba(0, 0, 0, 0)",
                      }}
                      bgChange={(e) => imageStyleChange(e, index)}
                      pChange={(e) => imageStyleChange(e, index)}
                    />
                </div>
                </div>
                }
                <p className='label'>Content</p>
                <RichTextEditor data={el?.description} eventHandler={(e) => descriptionChange(e,index)}  height={150}/></>}
                    />)}) }
                 </DndProvider>
            <div className='add-new-slide' title={'Add Card'} onClick={addCarousels}><AddCircle className='add-card'  /> Add New Slide</div>

        </div>
    </div>
    )
}

CarouselsPanel.propTypes = {
    carouselData: PropTypes.array,
    handleProperties: PropTypes.func,
    /** handleCeAssets - This function handles the CE Library asset usage update actions ( previousAsset | newAsset | updatedProps ).  */
    handleCeAssets : PropTypes.func,
}

export default CarouselsPanel
