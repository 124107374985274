import { useState, useEffect } from 'react';
import { DragDropContainer, DropTarget } from 'react-drag-drop-container';
import Tooltip from '@material-ui/core/Tooltip';
import { makeStyles } from '@material-ui/core/styles';
import PropTypes from 'prop-types'
import './dragndrop.scss'
import ContentTracking from '../../../core/progress/ContentTracking';
import ErrorOutlineIcon from '@material-ui/icons/ErrorOutline';
import PlayerConstants from '../../../utils/PlayerConstants';
import Instruction from '../../ui/Instruction/Instruction';
import DataHelper from '../../../utils/DataHelper';

const useStyles = makeStyles((theme) => ({
    customStyle: {
        backgroundColor: '#a1a1a1',
        borderRadius: 0,
        marginTop: '30px'
    },
  }));

let DragCount = 0

/**
 * 
 * @param {*} items 
 * @returns sequence list of string items
 */

/**
 * 
 * @param {*} draggableItems 
 * @returns object contains array of images in each object items
 */

/**
 * DragnDrop component is used for items to drag and put it one box items 
 * Mainly used for some assesment component 
*/
const DragnDrop = (props) => {
    const {items, draggableItems,track,handler, isQuiz, instructions} = props
    const classes = useStyles();
    const [status, setStatus] = useState({completed: false, text: PlayerConstants.COMPONENT_CONSTANTS.STATUS_INCOMPLETE})
  
    const IconArr = [ 'window_tab.png', 'alert_warning.png', 'certificate.png']

    const [text] = useState('Drag and drop the file here')
    let dragLength = 0
    
    const [ draggablelist, setDraggableList ] = useState(JSON.parse(JSON.stringify(draggableItems)));
    const [instruction, setInstruct] = useState(instructions)

    const [ dropedItems, setDropedList ] = useState({'1': [], '2': [], '3': []});

    const UPDATE_STATUS = PlayerConstants.COMPONENT_CONSTANTS.UPDATE_STATUS
    const SAVE_PROGRESS_STATUS = PlayerConstants.COMPONENT_CONSTANTS.SAVE_PROGRESS_STATUS

    useEffect(() => {
        DragCount = 0
    }, [])

    /**
     * Manage the component update 
     * progress logic in this method
     * 
     * Update the view status when ever the user interacts
     * Update the progess status ONLY ONCE, when status completes
     * 
     */
  const updateProgress = (dropedItems) => {

    /**
     * View status, will update while navigating to next topic
     */
    track.state = { ...track.state, c: dropedItems }
    /**
     * Progress updated as and when its completed
     */

    if (!track.status) {

        track.status = 1
        setStatus({completed: true, text:PlayerConstants.COMPONENT_CONSTANTS.STATUS_COMPLETE})
      if (track.status) {
        handler({ type: SAVE_PROGRESS_STATUS, id: track.id, name: props.cename })
      }
    }
    /**
     * If anything to be intimated always
     * call this
     */
    handler({ type: UPDATE_STATUS, id: track.id, name: props.cename })

  }

  for (let key in draggableItems) {
      dragLength += draggableItems[key].length
  }


    const onDropEndOfItem = ( el, key ) => {
        DragCount++
        console.log('draglength', DragCount, dragLength)
        const res = dropedItems[ key ].filter( ( i ) => {
            return i.id === el.id
        } );

        if ( res.length === 0 ) {
            dropedItems[ key ].push( el );
            setDropedList( { ...dropedItems } )
        }

        draggablelist[key].forEach((item,index) => {
            if(item.id === el.id){
                draggablelist[key].splice(index, 1);
            }
        });

        setDraggableList({...draggablelist})

        if (DragCount === dragLength) {
            if (!isQuiz)
                setInstruct({
                    ...instruction,
                    text: `You have completed this interactivity, you can proceed to next section`,
                    className: 'completed'
                })
                updateProgress(key)
        }
    }

   
    const getImageUrl = (path)=>{
        return DataHelper.getResourcePath(0,path);
    }

    const renderBoxItems = ( list, node ) => {

        return <div className="draggable-items">
            {list?.length > 0 && list?.map( ( el ) => {
                return<DragDropContainer
                    key={ node + '_' + el.id }
                    targetkey={ "container_" + node }
                    // onDragStart={ () => ( console.log( 'start' ) ) }
                    // onDrag={ () => ( console.log( 'dragging' ) ) }
                    onDragEnd={ () => onDropEndOfItem( el, node ) }
                    // onDrop={ ( e ) => ( console.log( "------" ) ) }
                >
                    <DropTarget
                        targetkey={ "container_" + node }
                    >
                   <Tooltip className='drag-drop-tooltip' placement={'bottom'} title={
                       <>
                       <h6 className='tooltip-title'>
                           Information
                       </h6>
                       <p className='tooltip-snippet'>Lorem ipsum dicta minus molestiae vel beatae natus eveniet ratione temporibus aperiam.</p>
                       </>
                   } arrow classes={{ tooltip: classes.customStyle }}
                    // placement="bottom-start"
                    >
                    <div className="draggable-item">
                            <img className='drag-drop-image'
                            alt={el.image} 
                            src={getImageUrl(el?.image)}
                            />
                        </div>
                   </Tooltip>
                    <div className="h-info"> info</div>
                    </DropTarget>
                </DragDropContainer>
            } ) }
        </div>
    }

  


    return <> 
    <Instruction isInstruction={instruction?.enabled} completed={(track.status === 1  || status.completed)} title={(track.status === 1 || status.completed) ? PlayerConstants.COMPONENT_CONSTANTS.STATUS_COMPLETE : PlayerConstants.COMPONENT_CONSTANTS.STATUS_INCOMPLETE} classText={`${(track.status === 1 || status.completed) && PlayerConstants.COMPONENT_CONSTANTS.COMPLETED_CLASS}`} text={(track.status === 1 || status.completed) ? PlayerConstants.COMPONENT_CONSTANTS.INSTRUCTIONS_PASSED : instruction?.text}/>
    <div className='main-drag-drop-container'>
        <h4 className='drag-drop-title'>Mouse over icon to see more informations, Drag the icon to the appropriate location</h4>
        <div className="dnd-container">
            {items && items?.length > 0 && items?.map( ( box, index ) => {
                return <section className={`drag_${index}`} key={box}>
                    <p className='top-icon'><img src={require(`../../../assets/img/${IconArr[index]}`).default} alt='' /></p>
                    <ul targetkey={ "container_" + box } className="container-box">
                        { dropedItems[ box ]?.length > 0 && dropedItems[ box ]?.map( ( el ) => {
                            return <li key={el.id}><img className='drag-drop-image' alt="" src={require(`../../../assets/img/${el.image}`).default} /></li>
                        } ) }
                        {dropedItems[ box ]?.length === 0 && <span className='drop_text'>{text}</span> }
                    </ul>
                    { renderBoxItems( draggablelist[ box ], box ) }
                </section>

            } ) }
        </div>
</div>
</>
}

DragnDrop.defaultProps = {
    instructions: {
        text: 'Drag & Drop the icons to the appropriate location, Mouse over icon for more information',
        enabled: false
    }    
}

DragnDrop.propTypes = {
    /** Items will have number of drag objects  */
    items: PropTypes.array,
    /** Draggable items will be object contains list of items for drag and drop */
    draggableItems: PropTypes.object,
    /** Tracking the component progress */
    track:PropTypes.instanceOf(ContentTracking),
    /** Func description for tracking*/
    handler:PropTypes.func
}

export default DragnDrop


