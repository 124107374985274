import * as types from '../reducers/types'


export const toggleDrawer = (value, comp) => {
    return {
        type: types.TOGGLE_DRAWER,
        value,
        comp
    }
}

export const setEditState = (value) => {
    return {
        type: types.EDIT_STATE,
        value
    }
}

export const isMenuDropDown = (value) => {
    return {
        type: types.EDIT_MENU_DROPDOWN,
        value
    }
}

export const setTemplateList = (list)=>{
    return {
        type: types.TEMPLATE_LIST,
        value:list
    }
}

export const assignTemplate = (payload)=>{
    return {
        type: types.ASSIGN_TEMPLATE,
        payload
    }
}


export const updateLearningAid = (payload)=>{
    return {
        type: types.UPDATE_STATUS_LEARNING_AID,
        value:payload
    }
}

export const updateLrnStatusInBulkStatus = (payload)=>{
    return {
        type: types.UPDATE_LRN_STATUS_IN_BULK,
        payload:payload
    }
}


export const highlightLearningAid = (payload)=>{
    return {
        type: types.HIGHLIGHT_LEARNING_AID,
        value:payload
    }
}
export const addNewLearningAid = (payload) =>{
    return{
        type:types.ADD_NEW_LEARNING_AID_REQ,
        payload
    }
}

export const deleteLearningAid = (payload) =>{
    return{
        type:types.DELETE_LEARNING_AID_REQ,
        payload
    }
}

export const saveAsTemplate = (payload) =>{
    return{
        type:types.SAVE_AS_TEMPLATE_REQ,
        payload
    }
}

export const templateAdded = (payload) =>{
    return{
        type:types.TEMPLATE_ADDED_RES,
        payload
    }
}

export const setCourseList = (payload)=>{
    return {
        type: types.ACTIVE_COURSES_LIST,
        payload
    }
}

export const searchLearningAids = (payload)=>{
    return {
        type: types.SEARCH_LRN_AIDS,
        payload
    }
}

export const lrnAidsSearchResult = (payload)=>{
    return {
        type: types.SEARCH_RESULT_LRN_AIDS,
        payload
    }
}



export function addExistingLearningAid(payload) {
    return {
        type : types.EXISTING_LRN_AID_ADD_REQ,
        payload
    }
}

export function addsupplimentary(payload) {
    return {
        type : types.ADD_VIDEO_SUPPLIMENTARIES,
        payload
    }
}

export function saveCuepoints(payload){
    return{
        type: types.SAVE_VIDEO_CUEPOINTS,
        payload
    }
}

export function requestCrcStructure(payload){
    return{
        type: types.GET_CRS_STRUCTURE,
        payload
    }
}

export function responseCrcStructure(payload){
    return{
        type: types.SET_CRS_STRUCTURE,
        payload
    }
}

export function publishCourseInfo(payload){
    return{
        type: types.PUBLISH_CRS_INFO,
        payload
    }
}
 
export const getPublishCourseLRNId = (payload) =>{
    return{
        type:types.PUBLISH_CRS_GET_LRN_ID,
        payload
    }
}

export function publishChangesToCourse(payload){
    return{
        type: types.PUBLISH_CHANGES_TO_CRS,
        payload
    }
}

export function publishVersion(payload){
    return{
        type: types.PUBLISH_VERSION,
        payload
    }
}

export function getPersonalizeHistory(payload){
    return {
        type: types.GET_PERSONALIZE_HISTORY,
        payload
    }
}

export function setPersonalizeHistory(payload){
    return {
        type: types.SET_PERSONALIZE_HISTORY,
        payload
    }
}

export function updatePersonalizeHistory(payload){
    return {
        type: types.UPDATE_PERSONALIZE_HISTORY,
        payload
    }
}