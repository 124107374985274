import React from 'react';
import PlayCircleIcon from '@mui/icons-material/PlayCircle';

const TopicVideoList = ({data, handleSelection, isloading, archiveCrsList}) => {

    const timeStringFormat = (s) => {
        try {
          const date = new Date(0);
          date?.setSeconds(s); // specify value for SECONDS
          const timeString = date?.toISOString()?.substring(11, 19);
          return timeString;
        } catch (e) {
          // console.log();
        }
      };


    return (
        <div className='col-12 topic-video-container'>
        {data?.map((course, index)=>{ return (
            <div className=' tab-video-list' key={index} onClick={()=>handleSelection(course)}>
                <div className='listing-card list-view card'>
                    <div className='card-body no-pointer'>
                        <div className='course-card-img'>
                            <PlayCircleIcon />
                            <p className='time-duration'> {timeStringFormat(course?.duration)}</p>
                        </div>
                        <div className='d-flex flex-column'>
                            <h5 className='card-title'>{course?.title}</h5>
                            <p className='card-topic'>{archiveCrsList[course.cid]?.coursename}</p>
                            <div className='card-text crs-text'>
                                <div className='list-info'>
                                    <div className="dis" dangerouslySetInnerHTML={{ __html:course?.ctext  }} />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div> 
            )})}
        </div>
    )
}

export default TopicVideoList