import React from "react";
import PropTypes from 'prop-types';
import ColorPickr from '../ColorPickr/ColorPickr';


const WysiwygPickr = ({
  expanded,
  onExpandEvent,
  currentState,
  onChange
}) => {

  const handleColor =(value)=>{
    onChange('color', value);
  }
  const handleBgColor =(value)=>{
    onChange('bgcolor', value);
  }
  const colorPickr={
    color:currentState?.color || '#000',
    saveEvent:handleColor,
    cancelEvent:handleBgColor,
    btn:{
      cancel:{isCancel:true,name:'Background',ishide:false},
      save:{isSave:true,name:'Font',ishide:false}
    },
    type:'HEX'
  }

  return ( 
    <div
      aria-haspopup="true"
      aria-expanded={expanded}
      aria-label="rdw-color-picker"
    >
      <div>
      </div>
      <ColorPickr {...colorPickr} />
    </div>
  );
};

WysiwygPickr.propTypes = {
  expanded: PropTypes.bool,
  onExpandEvent: PropTypes.func,
  onChange: PropTypes.func,
  currentState: PropTypes.object,
};

export default WysiwygPickr;