import React from 'react'
// import PropTypes from 'prop-types'
// import styles from './highlight.scss';
import QuickAccessBar from '../QuickAccessBar/QuickAccessBar';
import LearningAidList from '../LearningAidList/LearningAidList';
import { useSelector } from 'react-redux';

const ElementHighlighter = ({ target, placement, className, classes, ...props }) => {
  const courseInfo = useSelector( state => state.courseInfo );
  let curItem = courseInfo?.topic?.lrncontent?.find( item => { return item?._id === target?.element?.id } )
  const qabarPos = {h:20}
  /** sending highlighted componentId */
  const getComponentId = ()=>{
    try{
      if(target.cmp){
        let splitted = target.cmp.compid.split("_");
        if(splitted.length > 0){
          return splitted[1]
        }
      }
    }catch(e){
      console.log(e)
    }
  }


   /**
    * The hover target is different because we are not displaying this element's.
    */
   if(!curItem){
    return null;
   }

  /**
   * info icon takes a space so reduing
   * const qabarPos = {h:70}
   */
  return (
    <>
      {target?.element?.offsetWidth !== 0 ? <div className='highlighter'
        style={{
          width: target?.element?.offsetWidth, height: target?.element?.offsetHeight +  (target?.element?.offsetHeight < 100 ? 60 : qabarPos.h), position: 'absolute',
          left: target?.element?.offsetLeft, top: target?.element?.offsetTop- (target?.element?.offsetHeight < 100 ? 60 : qabarPos.h)
        }}
      >
      
       <span className='label'>   <QuickAccessBar target={target} /></span>
       <div className='heilighted-bottom'>
          <LearningAidList cmpName={target?.cmp?.name} compId={getComponentId()} />
        </div>
      </div>: '' }
    </>
  )
}



export default ElementHighlighter;