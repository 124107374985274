import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import Swal from 'sweetalert2';
const InactivityRedirector = ({ children, timeout, redirectPath }) => {
  const history = useHistory();
  const [isActive, setIsActive] = useState(true);

  useEffect(() => {
    let inactivityTimer;

    // const resetTimer = () => {
    //   clearTimeout(inactivityTimer);
    //   inactivityTimer = setTimeout(() => {
    //     setIsActive(false);
    //     if(!window.ce?.isScormPack)
    //     window.location.replace = redirectPath;
    //   }, timeout);
    // };
    // let remainingTime = 60;
    let originalRemainingTime = 60; // Set the original remaining time
    let currentRemainingTime = originalRemainingTime; // Use a separate variable for countdown
    let timerInterval;
    const resetTimer = () => {
      clearTimeout(inactivityTimer);
  
      // Calculate the initial remaining time to start from 60 seconds
      
  
      const updateTimerMessage = (rt) => {
        Swal.update({
          title: `Your session will expire in ${rt<1 ? 1 : rt } seconds.`,
        });
      };
  
      inactivityTimer = setTimeout(() => {
        setIsActive(false);
  
        if (!window.ce?.isScormPack) {
          Swal.fire({
            title: `Your session will expire in ${currentRemainingTime < 1 ? 1 : currentRemainingTime} seconds.`,
            icon: 'warning',
            showCancelButton: true,
            confirmButtonText: 'Logout',
            cancelButtonText: 'Continue',
            cancelButtonColor:'#F3C465',
            confirmButtonColor:'#4caf50',
            overlay: false,
            backdrop:"rgba(0,0,0,.7)",
            customClass: {
              overlay: 'my-custom-overlay-class',
            },
            didOpen: () => {
              const overlay = document.querySelector('.swal2-overlay');
              if (overlay) {
                overlay.style.backgroundColor = 'rgba(0, 0, 0, 0.7)';
              }
              const backdrop = document.querySelector('.swal2-backdrop-transparent');
              if (backdrop) {
                backdrop.style.backgroundColor = 'rgba(0, 0, 0, 0.7)'; // Adjust the opacity by changing the alpha value (0.5 in this case)
              }
              setIsActive(true);
              timerInterval = setInterval(() => {
                currentRemainingTime -= 1;
                updateTimerMessage(currentRemainingTime);
  
                if (currentRemainingTime <= 0) {
                  clearInterval(timerInterval);
                  // window.location.replace(redirectPath);
                  if (window.parent) {
                    window.parent.postMessage({ "close_course": window.ce?.platform_scorm?.apiReferrer }, "*");
                  }
                  // window.ce?.platform_scorm?.apiReferrer === 'storefront' ? window.location.replace(PlayerConstants.STOREFRONT_URL) : window.location.replace(PlayerConstants.LMS_URL);
                }
              }, 1000);
            },
            willClose: () => {
              const overlay = document.querySelector('.swal2-popup.my-custom-popup-class .swal2-overlay');
              if (overlay) {
                overlay.style.backgroundColor = ''; // Reset the overlay background color
              }
            },
            didDestroy: () => {
              clearInterval(timerInterval); // Ensure the interval is cleared on modal destroy
            },
          }).then((result) => {
            if (result.isConfirmed) {
              // User clicked 'Stay Logged In', you can add any action here
              if (window.parent) {
                window.parent.postMessage({ "close_course": window.ce?.platform_scorm?.apiReferrer }, "*");
              }
              // window.ce?.platform_scorm?.apiReferrer === 'storefront' ? window.location.replace(PlayerConstants.STOREFRONT_URL) : window.location.replace(PlayerConstants.LMS_URL);
            } else {
              currentRemainingTime = 60;
              clearTimeout(inactivityTimer);
              clearInterval(timerInterval) ;
              Swal.close(); // Close the Swal modal
              Swal.closeModal(); // Reset all data associated with the modal
              resetTimer();
            }
          });
        }
      }, timeout - 60000); // Start the timer 1 minute (60000 milliseconds) before the actual timeout
    };
    const handleUserActivity = () => {
      setIsActive(true);
      resetTimer();
    };

    // attach event listeners for user activity (e.g., mousemove, keydown)
    window.addEventListener('mousemove', handleUserActivity);
    window.addEventListener('keydown', handleUserActivity);

    // set up initial timer
    resetTimer();

    // clean up event listeners on component unmount
    return () => {
      window.removeEventListener('mousemove', handleUserActivity);
      window.removeEventListener('keydown', handleUserActivity);
      clearTimeout(inactivityTimer);
    };
  }, [timeout, history, redirectPath]);

  return isActive ? <>{children}</> : null;
};

export default InactivityRedirector;
