import React, { useState } from 'react'
import MuiIcons from '../../../components/ui/MuiIcons/MuiIcons'
import './topicvalidationpanel.scss';
import { useDispatch, useSelector } from 'react-redux'
import AppButton from '../../../components/ui/AppButton/AppButton'
import Loader from '../../../components/ui/Loader/Loader';
import TEMPLATE_MODEL from '../../../utils/TemplateModel';
import * as courseInfoAction from '../../../redux/actions/courseInfoAction';
import * as courseEditAction from '../../../redux/actions/courseEditAction'

const TopicValidationPanel = () => {
  const courseInfo = useSelector(state => state.courseInfo);
  const [isLoading, setIsLoading] = useState(false);
  const dispatch = useDispatch()
  const [validation, setValidation] = useState({
    has_false_value: false,
    count: false,
    data_validation: false,
    has_comp_mismatch: false,
    fix: false
  });
  const [fixedObj, setFixedObj] = useState({})

  // Define validation items
  const validationItems = [
    { key:'has_false_value', label: 'Learning Aid Validation', icon: 'registration' },
    { key:'count', label: 'Count Validation', icon: 'number' },
    { key:'data_validation', label: 'Data Consistency Verification', icon: 'status' },
    { key:'has_comp_mismatch', label: 'Mismatch Validation', icon: 'compare' },
  ];


  const getCompIdFromSection = (sectionString) => {
    // Create a temporary DOM element
    const tempDiv = document.createElement('div');
    // Set its innerHTML to the section string
    tempDiv.innerHTML = sectionString.trim();

    // Extract the first <section> element
    const sectionElement = tempDiv.querySelector('section');

    // Return the compId attribute value if the <section> element exists
    return sectionElement ? sectionElement.getAttribute('compId') : null;
  };

  // Function to get the object by name
  const getObjectByName = (name) => {
    return Object.values(TEMPLATE_MODEL).find(item => item.name === name)?.props||{};
  };



  const handleResolve=()=>{
   try{
    const saveLearningAid= async (lrns) => {
      if (lrns) {
        for (const e of lrns) {
          await dispatch(courseInfoAction.saveLearningAid({ id: e?._id, data: e?.props }));
        }
      }
    };
    // If validation fix exists, update the topic and learning aids
    if((validation?.has_comp_mismatch||validation?.has_false_value||validation?.count)&& fixedObj?.topic?.lrncontent.length > 0){
      const topicPayload= {
        id:fixedObj?.topic?.id,
        lrncontent:fixedObj?.topic?.lrncontent?.map(e=>e?._id)||[],
        markup:fixedObj?.topic?.markup||""
      }
      dispatch(courseInfoAction.editTopic(Object.assign({}, topicPayload)));
    }
    if(validation?.data_validation && fixedObj?.lrn?.length > 0 ){
      saveLearningAid(fixedObj?.lrn);
    }
    dispatch( courseEditAction.toggleDrawer( { open: false, drawerWidth: 0 } ) )
   }catch(e){}
  }

  const handleValidation = () => {
    try {
      setIsLoading(true)
      if (!validation?.fix) {
        
        const currentTopic =courseInfo?.topic || {};
        let validationObj = { ...validation };
        let fixedTopic = JSON.parse(JSON.stringify(currentTopic));

        // Extract all <section> tags from the markup
        let sections = currentTopic?.markup?.match(/<section\b[^>]*>/g) || [];
        const lrnsLength = currentTopic?.lrncontent?.length || 0;

        // Create a Map to store compIds from sections for fast lookup
        let sectionCompIdMap = new Map(
          sections?.map(section => {
            const compId = getCompIdFromSection(section);
            return compId ? [`${compId}`, section] : null;
          })?.filter(Boolean)
        );

        // Create a Set to track unmatched sectionCompIds
        const unmatchedLRNCompIds = [];
        let updateLRN=[];

        let hasFalseValue, dataValidation, isCompMismatch, hasFix = false;


        // Check each item in lrncontent
        currentTopic?.lrncontent?.forEach((item,index) => {
          // Check if item is falsysectionCompIdMap
          if (!item) {
            hasFalseValue = true;
            hasFix = true;
            fixedTopic.lrncontent = fixedTopic?.lrncontent?.filter(Boolean)
            return;
          }

          // Check if item.props is not an object
          if (typeof item?.props !== 'object' || Object.keys(item?.props)?.length < 4) {
              let props= {...(fixedTopic?.lrncontent[index]?.props||{}),...getObjectByName(item?.name)}
              props.track={...props.track,state:{},status:0}
              const fIndex=fixedTopic.lrncontent.findIndex(e=>e?._id===item._id);
              fixedTopic.lrncontent[fIndex].props={...props};
              updateLRN.push({props:props,_id:fixedTopic?.lrncontent[index]?._id});
              dataValidation = true;
          }

          // Check for matching compId in sectionCompIds
          if (!item?.compid || !sectionCompIdMap.has(`com_${item?.compid}`)) {
            isCompMismatch = true;
            hasFix = true;
            unmatchedLRNCompIds?.push(item);
          } else {
            // Remove matched compId from unmatchedCompIds
            sectionCompIdMap.delete(`com_${item?.compid}`);
          }
        });

        // Update validationObj with the results
        validationObj.has_comp_mismatch = isCompMismatch;
        validationObj.data_validation = dataValidation;
        validationObj.has_false_value = hasFalseValue;
        validationObj.count = (lrnsLength !== sections?.length || hasFalseValue);


        const unmatchedSections = Array.from(sectionCompIdMap.values());

        // Remove unmatched sections from the original sections array
        let updatedSections = sections.filter(section => !unmatchedSections.includes(section));
        unmatchedLRNCompIds.forEach((lrn) => {
          updatedSections.push(`<section name=${lrn?.name} compId=${`com_${lrn?.compid}`}>`)
        })
        fixedTopic.markup = updatedSections?.join('</section>') + '</section>';
        validationObj.fix = hasFix || validationObj?.count


        // Update the validation state
        setValidation({ ...validationObj,has_validated:true });
        setFixedObj({topic:fixedTopic,lrn:updateLRN});
      }else{
      handleResolve();
      console.log("Updating the API------------> ",fixedObj);
      }
      // Reset loading state after a delay
      setTimeout(() => {
        setIsLoading(false);
      }, 1800);
    } catch (e) { }
  };

  return (
    <div className='issue-panel'>
      {isLoading && <Loader />}
      <ul>
        {validationItems.map((item,index) => (
          <li key={index} className={`${validation[item?.key] && 'issue_section'}`}>
            <div className='inner-container'>
              <MuiIcons iconName={item.icon} className="icons" />
              {item.label}
            </div>
          </li>
        ))}
      </ul>
      <div className='validation-btn p-3'>
        <AppButton theme="secondary2" onClick={handleValidation} disabled={(validation?.has_validated&&!validation?.fix)}>{validation?.fix ? 'Resolve' : 'Validate'}</AppButton>
      </div>
    </div>
  );
}

export default TopicValidationPanel