import React from 'react';
import FocusLock from 'react-focus-lock';

// global counter to manage `tabIndex` across the app
let globalTabIndex = 2;

const FocusLockWrapper = ({ children, disabled = false, group, ...rest }) => {
  return (
    <FocusLock disabled={disabled} group={group} {...rest}>
      {React.Children.map(children, (child) => {
        // increment the global counter for each child
        const currentTabIndex = globalTabIndex++;
        return React.cloneElement(child, {
          tabIndex: currentTabIndex, // use the global tabIndex
        });
      })}
    </FocusLock>
  );
};

export default FocusLockWrapper;
