import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import Datahelper from "../../../utils/DataHelper";
import RichTextEditor from "../RichTextEditor/RichTextEditor";
import { AddCircle } from "@material-ui/icons";
import UploaderPanel from "../UploaderPanel/UploaderPanel";
import AccordionItem from '../../../components/ui/AccordionItem/AccordionItem'; // Assuming your AccordionItem component is in a separate file
import { DndProvider } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';
import Checkbox from '@mui/material/Checkbox';
import { FormControlLabel } from '@material-ui/core';
import TEMPLATE_MODEL from "../../../utils/TemplateModel";
import UnpublishedIcon from '@mui/icons-material/Unpublished';
import CustomCheckbox from '../../../components/ui/CustomCheckbox/CustomCheckbox';
import CustomSelect from '../../../components/ui/CustomSelect/CustomSelect';
import TaskAltIcon from '@mui/icons-material/TaskAlt';
import MuiIcons from "../../../components/ui/MuiIcons/MuiIcons";
import ResizableTextArea from "../../../components/ui/ResizableTextArea/ResizableTextArea";
import RadioSelect from "../../../components/ui/RadioSelect/RadioSelect";

const CatchGamePanel = ({handleProperties,handleCeAssets,items,bg_image,target_img,gameSpeed,hasRandom,id}) => {

    const [data, setData] = useState({items,bg_image,target_img,gameSpeed,hasRandom});
    const [expanded, setExpanded] = useState(0);
    const [drag, setDrag] = useState(false);


    useEffect(()=>{
      handleProperties(data)
    },[data])

    const handleChange = (panel) => (event, isExpanded) => {
        setExpanded(isExpanded ? panel : false);
      };

    const handleUpdateAccList = (e, list) => {
        let updated={...data,items:[...list]}
        setData({...updated});
    }

    const handleMedia = (imgData, index) => {
      try {
        if (imgData) {
          setData((prev) => {
            const list = prev;
            const updatedList = {...data,bg_image:imgData.name};
            handleCeAssets(list[index]?.bg_image, imgData.name, {...updatedList });
            handleProperties({ ...updatedList }); 
            return updatedList;
          });
        }
      } catch (e) {
        console.error(e);
      }
    };

    const handleCheck = (e) => {
      const { name, checked } = e.target;
      setData((prev) => ({
        ...prev,
        [name]: checked, 
      }));
    };
    const handleSelect=(value)=>{
      setData((prev) => ({
        ...prev,
        gameSpeed: value?.value, 
      }));
    }
    const handleData=(e)=>{
      const { name,value } = e.target;
      setData((prev) => ({
        ...prev,
        [name]: value, 
      }));
    }

    const handleCorrection = (e,optionIndex ,index) => {
      setData(prev => {
        const updatedItems = [...prev?.items];
        updatedItems[index] = {
            ...updatedItems[index],
            ans: [optionIndex]
        };
    
        return {
            ...prev,
            items: updatedItems
        };
    });
  
    }
    

    /**
     *
     * @param {} value
     * data html string used for description of the component
     */
    const eventHandlers = (value, i) => {
        data.items[i]["question"] = value;
        setData(data);
        handleProperties({ ...data });
    };

    const addOptions=(index)=>{
      setData(prev => {
        const updatedItems = [...prev?.items];

        const updatedOptions = [...(updatedItems[index]?.op || []), "newOption"];
        updatedItems[index] = {
            ...updatedItems[index],
            op: updatedOptions
        };

        // return the updated state
        return {
            ...prev,
            items: updatedItems
        };
    });
    }


    const addAccordion = () => {
        if (data?.items?.length > 1) {
          const newobj =  {
            question: "New question",
            op:["true","false"],
            type:0,
            ans:[0]
          };
          setData(prev => {
            const updatedItems = [...prev?.items];
               updatedItems.push(newobj)
            return {
                ...prev,
                items: updatedItems
            };
        });
        }
      };

      const options = [
        // { label: 'True/false', value: '0' },
        { label: 'Single Answer', value: 1 },
        // { label: 'Multiple Answer', value: '2' },
    ];
    const LevelOptions = [
      { label:'Easy', value: 1 },
      { label:'Medium', value: 2 },
      { label:'Hard', value: 4 },
  ];

      

  return (
    <div className={`card-prop-main-ui catch-game-panel quizzes-panel-prop ca`}>
                <div className="card-prop-file">
              <UploaderPanel
                accept={"image"}
                fileName={data?.bg_image || ""}
                name={`${id}_${Datahelper.getTime()}-${0 + 1}`}
                setUploadedData={(el) =>handleMedia(el)}
                // styles={{
                //   position:
                //     data?.items[0]["styles"]?.position ||
                //     "center  / contain no-repeat",
                //   color:
                //     data?.items[0]["styles"]?.color ||
                //     "rgba(0, 0, 0, 0)",
                // }}
                // bgChange={(e) => {}}
                // pChange={(e) => {}}
              />
                  {/* <CustomCheckbox 
                      checked={data?.hasRandom}
                      name="hasRandom" 
                      className='quiz-label'
                      onChange={(e) =>handleCheck(e)} 
                      label="Randomize"
                  /> */}
                  <CustomSelect
                        value={data?.gameSpeed}
                        name="gameSpeed"
                        options={LevelOptions}
                        className="pt-2"
                        onChange={(e)=>handleSelect(e)}
                        label='Speed Level'
                        style={{width:'100%'}}
                    />
            </div>
          <div className="acccordion-item-switch"><FormControlLabel label={'Allow reorder'} className="ck-check-box" labelPlacement="end" name="isCharacter" control={<Checkbox checked={drag} onChange={(e) => setDrag(!drag)} />} /> </div>
          <DndProvider backend={HTML5Backend}>
        {data?.items &&
          data?.items?.length > 0 &&
          data?.items?.map((el, index) => {
            return ( <AccordionItem
              className='quiz-summary'
              key={index}
              name={`${index+1} Card`}
              index={index}
              el={el}
              data={data?.items}
              listUpdate={handleUpdateAccList}
              expanded={expanded}
              handleAccordionChange={handleChange}
              isDrag={drag}
              summary={{delete: { min: 1 }}}
              dynamicDetailsComponent={<>
               <div className="card-prop-file">
               <RadioSelect
                    options={options}
                    name="qtype"
                    direction='horizontal'
                    selectedValue={1}
                    onChange={()=>{}}
                />
            </div>
            <div className="card-prop-description">
              <p className="description-text label">Content</p>
              <RichTextEditor
                data={el?.question || ""}
                eventHandler={(e) => eventHandlers(e, index)}
              />
              <label className='label d-flex'>Options</label>
                <div className='options-list'>
                    {el?.op?.map((e, idx) =>{ 
                      return(<>
                        <div className="option-container" key={idx}>
                        {el?.ans?.includes(idx)  ? <TaskAltIcon className='correct-ans option-ans' onClick={(e) => handleCorrection(e, idx,index)} /> : <UnpublishedIcon onClick={(e) => handleCorrection(e, idx,index)} className='option-ans' />}
                            {/* {el?.ans ? <MuiIcons className='correct-ans option-ans' iconName="tick"/> : <MuiIcons iconName="nonTic" className='option-ans'  />} */}
                            <ResizableTextArea
                                placeholder="Enter your Option"
                                name="options"
                                className={'option-textarea'}
                                value={e}
                                onChange={(event) => {}}
                                minRows={3}
                            />
                              {/* <div className="option-selection" key={idx}><MuiIcons className='correct-ans option-ans' iconName="delete"/></div> */}
                            {el?.op?.length > 2 && <MuiIcons className='option-delete p-0 m-0' iconName="delete"/>}
                        </div>
                    </>
                    )})}
                    {/* {(true) && <p className='option-error'>Select a question type and add options, and ensure all fields are required with at least 2 characters each.</p>} */}

                </div>
                {el?.op?.length < 3 && <div className='add-new-slide pb-2' title={'Add Option'} onClick={()=>addOptions(index)} ><AddCircle className='add-accordion' /> Add New Option </div>}
            </div> </>}
              /> );
          })}
          </DndProvider>
        <div
          className="add-new-slide"
          title={"Add Card"}
          onClick={addAccordion}
        >
          <AddCircle className="add-card" /> Add New Card
        </div>

    </div>
  )
}

export default CatchGamePanel