/* eslint-disable jsx-a11y/alt-text */
import React, { useState, useEffect } from 'react';
import MusicNoteIcon from '@mui/icons-material/MusicNote';
import PlayCircleFilledIcon from '@mui/icons-material/PlayCircleFilled';
import CeAssets from '../../../../assets/img/CeAssets.png';
import { useSelector } from 'react-redux';
import clcid_Mapping from '../../../../clcid_Mapping.json';

const AssetsCard = ({ data, handleSelection, type, listType }) => {

  const [isloading, setIsloading] = useState(false);
  const {ceAssetsInfo: { archiveCourses} } = useSelector(state => state);
  let arrayList = [];

  useEffect(()=>{if([1,5].indexOf(type)!==-1)setIsloading(true);setIsloading(false) },[data])


  const sourceTag = (src, rtype) => (
    <>
      { 
      rTypes[rtype]?.name === 'image' && <div className='card-source'>
        <img
          className='asset-fit'
          style={isloading ? { display: 'inline-block' } : { display: 'none' }}
          src={listType === 'wishlist' ? src : src.replace('/images/', '/thumbs/')}
          onLoad={() => setIsloading(true)}
          onError={({ currentTarget }) => {
            currentTarget.onerror = null;
            currentTarget.src = CeAssets;
          }}
        />
      </div>}
      {rTypes[rtype]?.name === 'audio' && <div className='card-source audio'>
        <MusicNoteIcon className='audio-icon' />
        <audio className="asset-fit"  >
          <source src={src} />
        </audio>
      </div>}
      {["video","lgVideo"].includes(rTypes[rtype]?.name) && <div className='card-source'>
        <video className="asset-fit" key={src}>
          <track kind="captions" />
          <source src={src} />
          Your browser does not support the video tag.
        </video>
        <PlayCircleFilledIcon className='play-icon' />
      </div>}
    </>
  );

  const getPath = (list) =>{
  try{
      if(list?.rtype===4){
        let lgPath='';
        const flattenClcid = archiveCourses[list.cid]?.info?.config?.clcid;
        if (flattenClcid) {
          lgPath = `https://d14rwvged4djyq.cloudfront.net/type3/${flattenClcid}/${list?.filepath}.mp4`;
        } else {
          const mappedData = clcid_Mapping[list?.cid];
          lgPath = `https://d14rwvged4djyq.cloudfront.net/type3/${mappedData?.clcid}/${list?.filepath}.mp4`;
        }
        return {...list,path:lgPath}
      }
  }catch(e){
    return list
  }

  }


  return (
    <>

      {data && data?.map((item, index) => {
        let id = item?.id ? item?.id : item?._id;
        if (rTypes[item?.rtype]?.name === type) {
          arrayList?.push({ item });
          let list= (type === "lgVideo") ? getPath(item) : item ;
          return (
            <div className="col-lg-2" key={index}>
              <div className="listing-card-container" onClick={() => handleSelection(list)}>
                <div className="card-body">{sourceTag(list?.path, item?.rtype)}
                  <div className="d-flex flex-column">
                    <p className="card-title">{item?.name}</p>
                    <div className="card-text">
                      <div className="list-info">
                        <span className="label">ID:</span>
                        <span>
                          {id?.slice(0, 10)}
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          )
        }
      })}
      {arrayList?.length === 0 && <p>No data found</p>}
    </>
  )
}

export default AssetsCard

export const rTypes = {
  '1': { name: 'image' },
  '5': { name: 'image' },
  '2': { name: 'video' },
  '3': { name: 'audio' },
  '4': { name: 'lgVideo' }
}