import i18n from 'i18next';

/**
 * Custom translation function that retrieves translation for a given key.
 * Falls back to English ('en_US') if the translation key is missing or empty.
 * 
 * @param {string} key - The translation key.
 * @returns {string} - Translated text or the English version if the key is not found.
 */
const t = (key) => {
  // Check if i18n is initialized and the t function exists
  if (i18n && typeof i18n.t === 'function') {
    const translation = i18n.t(key);

    // If the translation is missing or empty, fallback to English ('en_US')
    if (!translation || translation === key) {
      return i18n.t(key, { lng: 'en_US' }); // Return English translation if current one is missing
    }

    return translation; // Return the translated text
  }

  // Return English translation if i18n is not initialized or t function is not available
  return i18n.t(key, { lng: 'en_US' });
};

export default t;
