import { useEffect, useState } from 'react';

/**
 * useDebounce
 * 
 * A custom hook that debounces a value, delaying its update until after a specified delay.
 * This is useful for reducing the frequency of expensive operations that depend on rapid changes,
 * such as API calls or updates based on user input.
 * 
 * @param {any} value - The value to be debounced.
 * @param {number} delay - The debounce delay in milliseconds.
 * @returns {any} - The debounced value.
 */

const useDebounce = (value, delay) => {
  const [debouncedValue, setDebouncedValue] = useState(value);

  useEffect(() => {
    
    // set a timer to update the debounced value after the specified delay
    const timer = setTimeout(() => {
      setDebouncedValue(()=>value)
    }, delay || 500);

    //clean up the timer on component unmount or when value/delay changes
    return () => {
      clearTimeout(timer);
    };
  }, [value, delay]);

  return debouncedValue;
};

export default useDebounce;
