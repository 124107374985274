class TopicTracking {
    constructor(fid, tid, pc, st, ts)   {
        this.flatId = fid;
        this.topicId = tid;
        this.content = [];
        this.percent  = pc || 0
        this.status  = (st === undefined ? -1 : st);
        this.timestamp = ts || Date.now();
    }
   
}

export default TopicTracking;