// icons.js
import {
    Delete,
    Edit,
    Add,
    Save,
    Search,
    PhoneAndroid,
    TabletMac,
    Laptop,
    Home,
    Menu,
    DesktopMac,
    PowerSettingsNew,
    Close,
    AccountCircle,
    ArrowDropUp,
    ArrowDropDown,
    CloudUpload,
    LinkedIn,
    GTranslate,
    DragIndicator,
    AspectRatio,
    Info,
    AppRegistration,
    QueryStats,
    Compare,
    BugReport,
    Numbers,
    Translate,
    RestartAlt,
    VisibilityOff,
    RemoveRedEye,
    BorderColor,
    Star,
    FilterAlt,
    MarkChatUnread,
    MarkChatRead,
    Feedback,
    TouchApp,
    TaskAlt,
    Unpublished,
    SpatialTracking,
    PlayArrow,
    PauseOutlined,
    Forward10,
    Replay10,
    ArrowForwardIos,
    ArrowBackIos,
    Loop,
    ChevronLeft,
    ChevronRight,
    Cancel
  } from '@mui/icons-material';
  const Icons = {
    delete: Delete,
    edit: Edit,
    add: Add,
    save: Save,
    search: Search,
    phone: PhoneAndroid,
    tablet: TabletMac,
    laptop: Laptop,
    home: Home,
    menu: Menu,
    desktop: DesktopMac,
    power_settings: PowerSettingsNew,
    close: Close,
    account_circle: AccountCircle,
    arrow_drop: ArrowDropUp,
    down_arrow: ArrowDropDown,
    cloud_upload: CloudUpload,
    linkedin:LinkedIn,
    translate:Translate,
    drag:DragIndicator,
    resize:AspectRatio,
    info:Info,
    registration:AppRegistration,
    status:QueryStats,
    compare:Compare,
    bug:BugReport,
    number:Numbers,
    re_start:RestartAlt,
    eye_hide:VisibilityOff,
    eye_show:RemoveRedEye,
    pen:BorderColor,
    star:Star,
    filter:FilterAlt,
    active_comments:MarkChatUnread,
    resolved_comments:MarkChatRead,
    todo_comments:Feedback,
    tuch:TouchApp,
    tick:TaskAlt,
    nonTic:Unpublished,
    audio_play:SpatialTracking,
    play:PlayArrow,
    Pause:PauseOutlined,
    Forward10:Forward10,
    Replay10:Replay10,
    arrow_fr:ArrowForwardIos,
    arrow_bk:ArrowBackIos,
    loop:Loop,
    left_arrow:ChevronLeft,
    right_arrow:ChevronRight,
    cancel:Cancel
    // Add other icons as needed
  };
  
  export default Icons;
  