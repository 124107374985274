import React, { useEffect, useState } from "react";
import "react-responsive-carousel/lib/styles/carousel.min.css";
import { Carousel } from "react-responsive-carousel";
import PropTypes from "prop-types";
import "../../../assets/scss/carousal.scss";
import ArrowPrev from "../../../assets/img/arrow_prev.png";
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import ArrowNext from "../../../assets/img/arrow_next.png";
import ContentTracking from "../../../core/progress/ContentTracking";
import Tooltip from "@material-ui/core/Tooltip";
import ErrorOutlineIcon from "@material-ui/icons/ErrorOutline";
import PlayerConstants from "../../../utils/PlayerConstants";
import Instruction from "../../ui/Instruction/Instruction";
import DataHelper from "../../../utils/DataHelper";
import ImageTag from "../../ui/ImageTag";
import t from "../../../translation/useTranslate"
import MuiIcons from "../../ui/MuiIcons/MuiIcons";

/**
 *
 * @param {*} carousalData
 * @returns  Array of images which is used to map into carousel
 */

/**
 *
 * @param {*} isTextSeprate
 * @returns  isTextSeprate Seprate Tracker
 */

/**
 * Carousels displays the images one by one at time
 *
 *
 * During image gallery preview mode, the Carousels component is rendered.
 *  If there are any changes within the Carousels component, ensure to test the image gallery LRN accordingly.
 *        **********Please test the ImageGallery preview mode.**********
 *
 */
const Carousels = (props) => {
  const [currentIndex, setcurrentIndex] = useState(0);
  const {
    width,
    autoPlay,
    selectedItem,
    dynamicHeight,
    defaultHeight,
    carousalData,
    track,
    handler,
    isTextSeprate,
    // These keys are from previously added static data and are available for use if needed. 
    // The previous lrn is also available inside the props.
    // axis,    
    // infiniteLoop,
    // interval,
    // swipeScrollTolerance,
    // stopOnHover,
    // showArrows,
    // showStatus,
    // showIndicators,
    // showThumbs,
    // swipeable,
    // verticalSwipe,
    // useKeyboardArrows,
    // thumbWidth,
  } = props;
  const [status, setStatus] = React.useState({
    completed: false,
    text:t("CAROUSELS_INFO"),
  });
  const UPDATE_STATUS = PlayerConstants.COMPONENT_CONSTANTS.UPDATE_STATUS;
  const SAVE_PROGRESS_STATUS =
    PlayerConstants.COMPONENT_CONSTANTS.SAVE_PROGRESS_STATUS;
  useEffect(() => {
    setcurrentIndex(selectedItem || 0);
  }, []);
  /**
   * Manage the component update
   * progress logic in this method
   *
   * Update the view status when ever the user interacts
   * Update the progess status ONLY ONCE, when status completes
   *
   */
  const updateProgress = (currentItem) => {
    if (track) {
      /**
       * View status, will update while navigating to next topic
       */
      track.state = { ...(track?.state || {}), c: currentItem };
      /**
       * Progress updated as and when its completed
       */
      if (!track?.status && (carousalData?.length-1 <= currentItem)) {
        track.status = 1;
        if (track.status === 1) {
          setStatus({
            completed: true,
            text: t("LRN_COMPLETED_INFO"),
          });
          handler({
            type: SAVE_PROGRESS_STATUS,
            id: track?.id,
            name: props.cename,
          });
        }
      }
      /**
       * If anything to be intimated always
       * call this
       */
      handler({ type: UPDATE_STATUS, id: track?.id, name: props.cename });
    }
  };

  /** AUTO PLAY enabled setting as completed with status 1 */
  if (autoPlay || carousalData?.length < 2) {
    if (track?.status !== 1) {
      track.status = 1;
      updateProgress(carousalData?.length);
    }
  } 
  // else {
  //   updateProgress(1);
  // }

  const getImageUrl = (path) => {
    return DataHelper.getResourcePath(0, path);
  };

  const handleChange=(idx)=>{
    setcurrentIndex(idx);
    updateProgress(idx)
  }
  
  const handleArrowClick = (direction) => {
    const newIndex =
      direction === "prev"
        ? (currentIndex - 1 + carousalData.length) % carousalData.length
        : (currentIndex + 1) % carousalData.length;
    handleChange(newIndex);
  };
  const carousel = () => {
    try {
      let maxHeight = !dynamicHeight ? `${defaultHeight || 400}` : "unset";
  
      return (
        <Carousel
          width={width}
          axis={"horizontal"}
          autoPlay={autoPlay}
          dynamicHeight={false}
          infiniteLoop={true}
          interval={3000}
          selectedItem={currentIndex}
            showArrows={true}
          showStatus={0}
          showIndicators={carousalData?.length > 1}
          showThumbs={false}
          stopOnHover={true}
          swipeable={true}
          verticalSwipe={"natural"}
          useKeyboardArrows={true}
            transitionTime
          thumbWidth={80}
          onChange={(idx) => handleChange(idx)}
          swipeScrollTolerance={5}
            labels
        >
          {carousalData &&
            carousalData?.map((res, index) => {
              return (
                <div key={index} className="carousel-container">
                  <div className="image-wrapper">
                    <ImageTag
                      img={res?.image || "*"}
                      bgColor={res?.styles?.color || "#0000"}
                      position={res?.styles?.position || "center / cover no-repeat"}
                      opacity="1"
                      maxHeight={maxHeight}
                      children=""
                      minHeight={defaultHeight || 400}
                    />
                    <div className="arrow-icons">
                      <ChevronLeftIcon
                        className="icon arrow-prev"
                        onClick={() => handleChange(currentIndex - 1)}
                      />
                      <ChevronRightIcon
                        className="icon arrow-next"
                        onClick={() => handleChange(currentIndex + 1)}
                      />
                    </div>
                  </div>
                  {!isTextSeprate && (
                    <div className="slide_info">
                      <div
                        className="description"
                        dangerouslySetInnerHTML={{ __html: res?.description }}
                      ></div>
                    </div>
                  )}
                </div>
              );
            })}
        </Carousel>
      );
    } catch (e) {}
  };
  

  return (
    <>
      <Instruction
        isInstruction={!autoPlay && carousalData?.length > 1}
        completed={track?.status === 1 || status?.completed}
        title={
          track?.status === 1 || status?.completed
            ? PlayerConstants.COMPONENT_CONSTANTS.STATUS_COMPLETE
            : PlayerConstants.COMPONENT_CONSTANTS.STATUS_INCOMPLETE
        }
        classText={`${
          (track?.status === 1 || status?.completed) &&
          PlayerConstants?.COMPONENT_CONSTANTS.COMPLETED_CLASS
        }`}
        text={status?.text}
      />
     {/* {carousalData?.length > 1 && <div className="arrow-icons">
        <ChevronLeftIcon className="red-icon icon arrow-prev"/>

        <ChevronRightIcon className="red-icon icon arrow-next"/>
      </div>} */}
      {carousel()}
      {isTextSeprate && (
        <div className="slide_info-bottom">
          <div
            className="description"
            dangerouslySetInnerHTML={{
              __html: carousalData[currentIndex]?.description,
            }}
          ></div>
        </div>
      )}
    </>
  );
};

Carousels.propTypes = {
  /** CarouselData having array of images to render into it */
  carousalData: PropTypes.array.isRequired,
  /** Tracking the component progress */
  track: PropTypes.instanceOf(ContentTracking),
  /** Func description for tracking*/
  handler: PropTypes.func,
  /** boolean value for isTextSeprate tracking*/
  isTextSeprate: PropTypes.bool,
};

export default Carousels;
