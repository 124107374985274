import React, {useState} from 'react'
import PropTypes from 'prop-types'
import ContentTracking from '../../../core/progress/ContentTracking'
import './unorderedlist.scss';
import PlayerConstants from '../../../utils/PlayerConstants';
import Instruction from '../../ui/Instruction/Instruction';


const debouncing = (selector, callback, isLongContent) => {
    /**
     * @boolean
     * isLongContent prop flag is for tracking the long content area of component .
     * will complete the progress when it sees the root element will not wait for scrolling till end
    */
    const isView = isLongContent || null
    setTimeout(() => {
        let element = document.querySelector(selector);
        let position = element?.getBoundingClientRect();
        const isMobile = (window.matchMedia('max-width: 768px').matches || window.matchMedia('max-height: 450px').matches)
            // checking for partial visibility
        if((position?.top < window.innerHeight && position?.bottom >= 0) && (isMobile || isView)) {
            callback()
        } else {
            // checking whether fully visible
            if(position?.top >= 0 && position?.bottom <= window.innerHeight) {
                callback()
            }
        }
    
    }, 100)
}


const UPDATE_STATUS = PlayerConstants.COMPONENT_CONSTANTS.UPDATE_STATUS
const SAVE_PROGRESS_STATUS = PlayerConstants.COMPONENT_CONSTANTS.SAVE_PROGRESS_STATUS

/**
 * 
 * @param {*} list 
 * @returns Array of text to render as list
 */

/**
 * UnorderedList displays the content as list
*/
const UnorderedList = ({ snippetOne, snippetTwo, list,track,handler,cename}) => {
    const contentLayout = document.querySelector('.content')
    const [status, setStatus] = useState({completed: false, text: PlayerConstants.COMPONENT_CONSTANTS.STATUS_INCOMPLETE})
  /**
     * Manage the component update 
     * progress logic in this method
     * 
     * Update the view status when ever the user interacts
     * Update the progess status ONLY ONCE, when status completes
     * 
     */
   const updateProgress = () => {

    /**
     * View status, will update while navigating to next topic
     */
    track.state = { ...track.state, c:1}
    /**
     * Progress updated as and when its completed
     */

    if (!track.status) {
      track.status = 1;
      if (track.status) {
        setStatus({completed: true, text:PlayerConstants.COMPONENT_CONSTANTS.STATUS_COMPLETE})
        handler({ type: SAVE_PROGRESS_STATUS, id: track.id, name:cename })
      }
    }
    /**
     * If anything to be intimated always
     * call this
     */
    // handler({ type: UPDATE_STATUS, id: track.id, name:cename })

    }

    // componentObserver('section[name=UnorderedList]', updateProgress)
    contentLayout?.addEventListener('scroll', ()=> {
        debouncing('section[name=UnorderedList]', updateProgress, true)
    })



    return (
        <>
        <Instruction isInstruction={false} completed={(track.status === 1 || status.completed)} title={(track.status === 1 || status.completed) ? PlayerConstants.COMPONENT_CONSTANTS.STATUS_COMPLETE : PlayerConstants.COMPONENT_CONSTANTS.STATUS_INCOMPLETE} classText={`${(track.status === 1 || status.completed) && PlayerConstants.COMPONENT_CONSTANTS.COMPLETED_CLASS}`}/>
            <div className='list-div'>
                <div className='list-div-head'>
                    <div className='list-description' dangerouslySetInnerHTML={{__html:snippetOne}}>
                    </div>
                        <ul className='list-ul'>
                            {list.length > 0 && list.map((val, index) => <li className='list-un-li' data-index={index+ 1} key={index}>{val}</li>) }
                        </ul>
                        <div className='list-description' dangerouslySetInnerHTML={{__html:snippetTwo}}>
                        </div>
                </div>
            </div>
            </>
    )
}

UnorderedList.propTypes = {
    /** SnippetOne can display Outer content description above the list */
    snippetOne: PropTypes.string,
    /** SnippetTwo can display Outer content description below the list */
    snippetTwo: PropTypes.string,
    /** List will display the ordered list style type array  */
    list: PropTypes.array,
    /** Tracking the component progress */
    track:PropTypes.instanceOf(ContentTracking),
    /** Func description for tracking*/
    handler:PropTypes.func
}

export default UnorderedList