import React from 'react';
import PropTypes from 'prop-types';
import './socialshare.scss';
import CryptoJS from 'crypto-js';
import MuiIcons from '../MuiIcons/MuiIcons';
import { useSelector } from 'react-redux';

/**
 * SocialShare Component
 * 
 * This component is used to share a user's course completion on LinkedIn.
 * constructs an encrypted URL to share this information on LinkedIn.
 */
const SocialShare = ({ completedDate, userName, courseName, totalTime, style }) => {
  const globalStyle = useSelector(state => state.globalStyle )

  /**
   * handleLNUrl
   * 
   * This function handles the generation of an encrypted URL containing
   * the user's course completion data. It then opens a LinkedIn share
   * popup window with the encoded URL.
   */
  const handleLNUrl = () => {
    // Retrieve the course ID from the global object
    const courseId = window.ce.platform_scorm.cid;

    // Default CPD points
    let cpdPoints = 1;

    // Uncomment this block if you have logic to set CPD points based on course ID
    // let Constants = {};
      if (globalStyle?.info?.cert?.cpd) {
        cpdPoints = globalStyle?.info?.cert.cpd;
      }

    // Construct the input string with necessary parameters
    const inputString = `&user=${userName}&course=${courseName}&courseid=${courseId}&cpdPoints=${cpdPoints}&date=${completedDate}&duration=${totalTime}&logo=clientlogo.png`;

    // Define initialization vector and encryption key
    const iv = CryptoJS.enc.Utf8.parse('0123456789123456');
    const key = CryptoJS.enc.Utf8.parse('HG58YZ3CR9HG58YZ3CR93CR9');

    // Encrypt the input string using AES encryption
    const encryptedValue = CryptoJS.AES.encrypt(inputString, key, { iv, mode: CryptoJS.mode.CBC, padding: CryptoJS.pad.Pkcs7 });
    const encrypted = encryptedValue.toString();

    // Construct the redirect URL with the encrypted parameter
    const redirectUrl = `https://apps.contentenablers.com/linkedincert/linkedinshr01.php?&param=${encrypted}&source=contentenablers.com`;

    // Open LinkedIn share popup with the encoded redirect URL
    const encodedRedirectUrl = encodeURIComponent(redirectUrl);
    window.open(`https://www.linkedin.com/shareArticle?mini=true&url=${encodedRedirectUrl}`, 'popup', 'width=600,height=600');

    return redirectUrl;
  };

  return (
    <div className='social-share-container' style={style} >
      <div className='inner-container'>
        <p className='text-content' onClick={handleLNUrl}>
          <MuiIcons className="ln-icon" iconName='linkedin' size='medium' />
          Click here to share your accomplishment on LinkedIn!
        </p>
      </div>
    </div>
  );
}

// prop types for the component
SocialShare.propTypes = {
  completedDate: PropTypes.string.isRequired,
  userName: PropTypes.string.isRequired,
  courseName: PropTypes.string.isRequired,
  totalTime: PropTypes.string.isRequired,
};

// default prop values for the component
SocialShare.defaultProps = {
  completedDate: '2023-07-03',  // Default completion date
  userName: 'John Doe',  // Default user name
  courseName: 'Example Course', 
  totalTime: '5 hours',  
  styles:{}
};

export default SocialShare;
