import styled from 'styled-components'


export const ContainerHead = styled.div`
height: 100%;

`

export const Imgtag = styled.img`
    width: 100%;
    object-fit: cover;
    
    height: 100%;
    @media (max-width: 768px) {
        width: 100%;
    }
`

export const Links = styled.a`
    color: var(--primary-color-1);
    text-decoration: none;
    position: absolute;
    top: 92%;
    font-weight: 600;
    @media (max-width: 768px) {
        bottom: 0;
        margin: 10px 10px;
    }
`

export const SecondHeader = styled.h2`
    font-weight: bolder;
    margin-top: 15px;
    @media (max-width: 768px) {
        margin-top: 15px;
    }
`

export const SnippetDesc = styled.p`
    margin-top: 15px;
    line-height:var(--global-line-height);
    font-size: 14px;
    font-weight: normal;
    @media (max-width: 768px) {
        margin-bottom: 35px;
        padding: 0 10px;
    }
`

