class CuePoint {
    constructor({id,shown,point,ctype,description}){
        this.id = id;
        this.shown = shown || false
        this.time = Number(point)
        this.type = ctype
        this.data = description || `Cue point data ${this.time}`
    }

    getCueItem(){
        return this;
    }

    setViewed(isShown) {
        this.shown = isShown
    }

}

export default CuePoint