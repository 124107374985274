import PropTypes from 'prop-types';

/**
 * Custom React hook for managing local or session storage.
 *
 * @param {Object} options - Hook configuration options.
 * @param {(string[]|Object)} options.keys - An array of keys or an object with key-value pairs.
 * @param {any} options.defaultValue - The default value to return if a key is not found.
 * @param {string} [options.storageType='local'] - The storage type ('local' or 'session').
 * @returns {Object} - Object containing functions for getting and setting values in storage.
 */
const useStorage = ({ keys, defaultValue, storageType = 'local' }) => {
  const storage = storageType === 'local' ? localStorage : sessionStorage;

  const get = (key) => {
    try {
      const storedValue = storage.getItem(key);
      return storedValue ? JSON.parse(storedValue) : defaultValue;
    } catch (error) {
      console.error(`Error getting ${key} from ${storageType} storage: `, error);
      return defaultValue;
    }
  };

  const set = (key, value) => {
    try {
      const valueToStore = JSON.stringify(value);
      storage.setItem(key, valueToStore);
    } catch (error) {
      console.error(`Error setting ${key} to ${storageType} storage: `, error);
    }
  };

  // If keys is an array, return an object with get and set functions for each key
  if (Array.isArray(keys)) {
    const storageFunctions = {};
    keys.forEach((key) => {
      storageFunctions[key] = {
        get: () => get(key),
        set: (value) => set(key, value),
      };
    });
    return storageFunctions;
  }

  // If keys is an object, return an object with get and set functions for each key
  if (typeof keys === 'object' && keys !== null) {
    const storageFunctions = {};
    Object.keys(keys).forEach((key) => {
      storageFunctions[key] = {
        get: () => get(key),
        set: (value) => set(key, value),
      };
    });
    return storageFunctions;
  }

  // If keys is not an array or object, return get and set functions for a single key
  return {
    get: () => get(keys),
    set: (value) => set(keys, value),
  };
};

// Prop types for the useStorage hook
useStorage.propTypes = {
  keys: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.string),
    PropTypes.objectOf(PropTypes.string),
  ]).isRequired,
  defaultValue: PropTypes.any.isRequired,
  storageType: PropTypes.oneOf(['local', 'session']),
};

export default useStorage;
