import React from 'react';
import PropTypes from 'prop-types';

// Create a context to require all SVGs in the flags directory
const requireContext = require.context('../../../assets/img/flags', false, /\.svg$/);

// Create a map of imports from the context
const flagMap = requireContext.keys().reduce((map, filePath) => {
  const fileName = filePath.replace('./', '').replace('.svg', '');
  map[fileName] = requireContext(filePath);
  return map;
}, {});

const FlagIcon = ({ code, alt = code, size = 24, ...props }) => {
  // Get the SVG file from the map based on the code
  const src = flagMap[code]?.default || '/flags/default.svg'; // Provide a default SVG or fallback

  return (
    <img
      {...props}
      alt={alt}
      width={size}
      height={size}
      src={src}
    />
  );
};

FlagIcon.propTypes = {
  code: PropTypes.string.isRequired,
  alt: PropTypes.string,
  size: PropTypes.number,
};

export default FlagIcon;
