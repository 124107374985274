import React from 'react'
import Celogo from '../../../assets/img/ce_logo_skill_comp.png';
import './skillcertificate.scss';
import teck_uk from "../../../assets/img/teck_uk.png"
// import SocialShare from '../socialShare/SocialShare';
import * as moment from 'moment-js';
import t from '../../../translation/useTranslate';

const SkillCertificate = ({ printRef, completedDate, userName, courseName, totalTime,cmoment, social }) => {

  const pageStyles = {
    '@media print': {
      size: 'landscape', // Not supported for inline styles
      margin: '1cm',
      zoom: '80%'
    }
  };
  return (
    <div className='skill-certificate row' ref={printRef}  style={{ ...pageStyles['@media print'] }}>
      <div className='col-lg-4 image-details'>
            <img src={Celogo} className='brand-logo' />
      </div>
    <table className='skill-certificate-table'>
    <tr>
        <td align="center" className='image-details skill-cer-td' > 
           <div className='sub-logo'><img src={teck_uk} className='brand-logo' /></div> 
           <br/><br/>
           <div className='sub-logo'><img src={teck_uk} className='brand-logo' /></div>
           <br/><br/>
           <div className='sub-logo'><img src={teck_uk} className='brand-logo' /></div>
        </td>
        <td align="left" className='skill-certificate-details skill-cer-td' >
         <div className='inner-skill-certificate-details'>
              <div className='section'>
                <p className='crs-h'>{"CERTIFICATE OF COMPLETION"}</p> 
                <p className='lrn-name'>{userName}</p>
                <br/>
              </div>
              <div className='section'>
                  <p className='crs-h'>HAS SUCCESSFULLY COMPLETED THE PROGRAM</p> 
                  <p className='lrn-name m-0'>{courseName}</p>
                  <p className='crs-h'>PLANNER</p> 
                  <br/><br/>
              </div>
              <div className='section'>
                  <p className='details m-0'><span className='crs-h'>PERIOD</span>JANUARY-DECEMBER 2024</p>
                  <p className='details m-0'><span className='crs-h'>DATE OF ISSUE</span>{completedDate}</p>
                  <p className='details m-0'><span className='crs-h'>CONTINUOUS EDUCATION HOURS</span> {moment(totalTime).format('HH') + ' hr'}</p>
                  <p className='details m-0'><span className='crs-h'>CERTIFICATE NUMBER</span> { window?.ce?.platform_scorm?.cid ||''}</p>
                  <br/>
                  <br/> <br/> <br/>
                </div>
                  <div className='section'>
                    <div className='footer'>
                        <div className='personal'>
                            <hr className='hr-line'/>
                          <p className='m-0'>Kara</p>
                            <p className='m-0'>Skill Dynamic</p>
                            <p className='m-0'>Role</p>
                        </div>
                        <div>
                            <hr className='hr-line'/>
                            <p className='m-0'>Kara</p>
                            <p className='m-0'>Skill Dynamic</p>
                            <p className='m-0'>Role</p>
                        </div>
                    </div>
                  </div>
                  {/* {social && <SocialShare
                    completedDate={completedDate}
                    userName={userName}
                    courseName={courseName}
                    totalTime={cmoment}
                    style={{ top: '0%' }}
                    />
                    } */}
              </div>
        </td>
    </tr>
    </table>
    </div>

    
    // <div className='skill-certificate row' ref={printRef}>
    //     <div className='col-lg-4 image-details'>
    //        <img src={Celogo} className='brand-logo' />
    //        <br/>
    //        {/* <img src={Celogo} className='brand-logo' /> */}
    //     </div>
    //     <div className='col-lg-8 skill-certificate-details'>
    //         <img src={Celogo} className='brand-logo' />
    //        <p className='crs-h'>CERTIFICATE OF COMPLETION</p> 
    //        <p className='lrn-name'>DHEENATHAYALAN M</p>
    //        <br/>
    //        <p className='crs-h'>HAS SUCCESSFULLY COMPLETED THE PROGRAM</p> 
    //        <p className='lrn-name m-0'>SCREENING</p>
    //        <p className='crs-h'>PLANNER</p> 
    //        <br/><br/>
    //        <p className='details m-0'><span className='crs-h'>PERIOD</span>JANUARY-DECEMBER 2024</p>
    //        <p className='details m-0'><span className='crs-h'>DATE OF ISSUE</span>JANUARY 16 | 2024</p>
    //        <p className='details m-0'><span className='crs-h'>CONTINUOUS EDUCATION HOURS</span> 10:00 hr</p>
    //        <p className='details m-0'><span className='crs-h'>CERTIFICATE NUMBER</span> CE523765</p>
    //        <br/>
    //        <br/> <br/> <br/>
    //        <div className='footer'>
    //             <div className='personal'>
    //                 <hr/>
    //                 <p className='m-0'>kara</p>
    //                 <p className='m-0'>Skill Dynamic</p>
    //                 <p className='m-0'>Role</p>
    //             </div>
    //             <div>
    //                 <hr/>
    //                 <p className='m-0'>kara</p>
    //                 <p className='m-0'>Skill Dynamic</p>
    //                 <p className='m-0'>Role</p>
    //             </div>
    //         </div>
    //     </div>
        
    // </div>
  )
}

export default SkillCertificate