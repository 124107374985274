import React, {useEffect, useState} from 'react'
import PropTypes from 'prop-types'
import Acc from '@material-ui/core/Accordion';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import Typography from '@material-ui/core/Typography';
import { AddCircle } from '@material-ui/icons';
import DeleteIcon from '@material-ui/icons/Delete';
import UploaderPanel from '../UploaderPanel/UploaderPanel';
import useForceUpdate from 'use-force-update';
import Datahelper from '../../../utils/DataHelper'
import ACLHelper from '../../../utils/ACLHelper';
import { getPlaceHolderImg } from '../../../utils/MediaConfig';
import useToastNotifications from '../../../utils/hooks/useToastNotifications';

const CardStackCarouselPanel = ({items, handleProperties,id,handleCeAssets, ...rest}) => {
    const forceUpdate = useForceUpdate()
    const [expanded, setExpanded] = useState(0);
    const [list, setList] = useState(JSON.parse(JSON.stringify(items)))
    const {showWarning} = useToastNotifications();

    const handleChange = (panel) => (event, isExpanded) => {
        setExpanded(isExpanded ? panel : false);
    };

    /** Delete rules 
     * minimum 3 slide should be there 
     */
    const handleDelete = (event, index) => {
        if (list?.length > 3) {
            const removed = list.filter((e, i) => i !== index)
            setList(removed)
            handleProperties({items: removed, ...rest})
            forceUpdate()
            return;
        }
        showWarning("Minimum item limit reached. No further deletions allowed");
        event.stopPropagation()
    }

    const addCarousels = () => {
            list.push({
                cover: getPlaceHolderImg(200)
            })
            setList(list)
            handleProperties({items: list, ...rest})
            forceUpdate()
    }

    useEffect(() => {
        handleProperties({items: list, ...rest})
    }, [list])

    /**
     * 
     * @param {*} data 
     * only file name sending here in player component its concat with default path
     */
    const handleMedia =(data,index)=>{
        try{
            if(data){
                const updateList=[...list]
                updateList[index] = {...updateList[index],cover:data.name};
                handleCeAssets(list[index]?.cover,data?.name,{items:updateList,...rest});
                setList( updateList );
                handleProperties({items:updateList});
                forceUpdate()
            }
        }
        catch(e){
            console.log(e);
        }
    }

    const handleStyle =(e,index)=>{
        list[index] = {...list[index],styles:{...list[index]?.styles||{},[e.target.name]:e?.target?.value}};
        setList( [...list] );
        handleProperties({items:[...list]});
    }
    

    return (
        <div className='card-stack-panel-container'>
            <div className={`card-prop-main-ui`}>
                {
                   list && (list.length > 0) && list.map((el, index) => {
                        return <Acc key={index} expanded={expanded ===  index} onChange={handleChange(index)}>
                            <AccordionSummary
                                aria-controls="panel1a-content"
                                id="panel1a-header"
                            >
                                <Typography className={`label-bold`}>{`Card ${index + 1}`} </Typography>
                                <DeleteIcon className='delete-prop-accordion' onClick={(e) => handleDelete(e, index)}/>
                            </AccordionSummary>
                            <AccordionDetails>
                                <div className="card-prop-file">
                                    {ACLHelper.isFeatureEnabled(4,2) && <>
                                    <label htmlFor="formFile" className="label">Browse Carousel Image</label>
                                    <UploaderPanel accept={'image'} fileName={list[index]?.cover} name={`${id}_${Datahelper.getTime()}-${index+1}`} styles={{background:list[index]?.styles?.background || "center  / contain no-repeat",bg_color:list[index]?.styles?.bg_color||"rgba(0, 0, 0, 0)"}}  setUploadedData={(e)=>handleMedia(e,index)} bgChange={(e)=>handleStyle(e,index)} pChange={(e)=>handleStyle(e,index)}/>
                                    </>}
                                </div>
                            </AccordionDetails>
                        </Acc>
                    })
                }
                <div className='add-new-slide' title={'Add Card'} onClick={addCarousels}><AddCircle className='add-card'  /> Add New Card</div>

            </div>
        </div>
    )
}

CardStackCarouselPanel.propTypes = {
    items: PropTypes.array,
    infinite: PropTypes.bool,
    /** handleCeAssets - This function handles the CE Library asset usage update actions ( previousAsset | newAsset | updatedProps ).  */
    handleCeAssets : PropTypes.func,
}

export default CardStackCarouselPanel
