import React, { useRef, useState } from 'react'
import Button from "@material-ui/core/Button";
import "./tabtoplay.scss";
import useForceUpdate from "use-force-update";
import PropTypes from 'prop-types'

/**
 * 
 * @param {*} tabData 
 * @returns lists of title and video url in every object
 */

/**
 * TabToPlay component will have different tabs based on topics
 * will play separate video based on topic
*/
const TabToPlay = ({tabData}) => {
    const [isShowen, setShown] = useState(false);
    const forceUpdate = useForceUpdate();
    const [videoUrl, setUrl] = useState("");
    const videoRef = useRef("");
    const buttonRef = useRef("");
    const changeTab = (e, el) => {
      el.disabled = true;
      setShown(true);
      setUrl(el.video);
      if (videoRef.current) {
        videoRef.current.load();
      }
      forceUpdate();
    };
    return (
        <div className='tab-play-container'>
            {tabData ? <div className="main-container row">
            {tabData?.length > 0 && tabData.map((el, i) => (
                <div className="col-md-6" key={el.id}>
                <Button
                    variant="contained"
                    disabled={el.disabled}
                    onClick={(e) => changeTab(e, el)}
                    ref={buttonRef}>
                    {el.title}
                </Button>
                </div>
            ))}
            {isShowen ? (
                <div className="video-container">
                <video ref={videoRef} width="500" autoPlay>
                    <source src={videoUrl} type="video/mp4" />
                </video>
                </div>
            ) : (
                ""
            )}
            </div> : <p>component data improper</p>}
      </div>
    )
}

TabToPlay.propTypes = {
    /** TabData will have properties like video url and title for tabs in each object.*/
    tabData: PropTypes.array.isRequired
}

export default TabToPlay
