import React, { useEffect, useState } from 'react';
import './radioselect.scss'
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormControl from '@material-ui/core/FormControl';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import Close from '@material-ui/icons/Close';
import PropTypes from 'prop-types'
import ContentTracking from '../../../core/progress/ContentTracking';
import PlayerConstants from '../../../utils/PlayerConstants';
import Instruction from '../../ui/Instruction/Instruction';
import t from '../../../translation/useTranslate';


/**
 * 
 * @param {*} title 
 * @returns quiz title of component
 */

/**
 * 
 * @param {*} explanation 
 * @returns will describe the explanation about answer correct/incorrect
 */

/**
 * 
 * @param {*} question 
 * @returns question string from the object
 */

/**
 * 
 * @param {*} evaluate 
 * @returns will evalaute the answer being correct/wrong
 */

/**
 * 
 * @param {*} choice 
 * @returns array of objects which will contain the multiple choice for the question
 */

/**
 * 
 * @param {*} handler 
 * @returns handler func for tracking progress whether completed or not
 */

/**
 * RadioSelect is component which will be usefull for the test assesement/quiz
*/
const RadioSelect = ( { title, description, question, track, handler, head, isQuiz, evaluate, correct, cename, explanation, choice, instructions, options, answer } ) => {

  if ( choice === undefined && options !== undefined ) {
    choice = options;
  } else {
    console.debug( "Choices not found" )
  }
  const [ value, setValue ] = React.useState( 0 );
  const [ selected, setSelected ] = useState( false );
  const [ instruction, setInstruct ] = useState( instructions );
  const [status, setStatus] = useState({completed: false, text: PlayerConstants.COMPONENT_CONSTANTS.STATUS_INCOMPLETE})

  const UPDATE_STATUS = PlayerConstants.COMPONENT_CONSTANTS.UPDATE_STATUS
  const SAVE_PROGRESS_STATUS = PlayerConstants.COMPONENT_CONSTANTS.SAVE_PROGRESS_STATUS

  useEffect( () => {
    setValue( '' )
  }, [] )

  /**
  * Manage the component update 
  * progress logic in this method
  * 
  * Update the view status when ever the user interacts
  * Update the progess status ONLY ONCE, when status completes
  * 
  */
  const updateProgress = ( currentItem ) => {

    /**
     * View status, will update while navigating to next topic
     */
    track.state = { ...track.state, c: currentItem }
    /**
     * Progress updated as and when its completed
     */
    if ( currentItem === 'true' ) {
      track.status = 1
    } else {
      track.status = 0
    }
    handler( { type: SAVE_PROGRESS_STATUS, id: track.id, name: cename } )

    /**
     * If anything to be intimated always
     * call this
     */
    handler({ type: UPDATE_STATUS, id: track.id, name: cename })

  }

  const handleRadioChange = ( e ) => {
    setValue( Number( e.target.value ) );
    setSelected( true );
    updateProgress('');
    if(Number( e.target.value ) === Number(answer)){
      updateProgress('true')
    }
  }

  return (
    <>
    <Instruction isInstruction={instruction?.enabled} completed={(track.status === 1 || status.completed)} title={(track.status === 1 || status.completed) ? PlayerConstants.COMPONENT_CONSTANTS.STATUS_COMPLETE : PlayerConstants.COMPONENT_CONSTANTS.STATUS_INCOMPLETE} classText={`${(track.status === 1 || status.completed) && PlayerConstants.COMPONENT_CONSTANTS.COMPLETED_CLASS}`} text={(track.status === 1 || status.completed) ? PlayerConstants.COMPONENT_CONSTANTS.INSTRUCTIONS_PASSED : instruction?.text}/>
      <div className='answer-select-div'>
        <caption className='title'>{ title ? title : ( head ) ? head : 'Question' }</caption>
        <div className='question' dangerouslySetInnerHTML={ { __html: question } }></div>
        <RadioGroup
          row
          aria-label="Options"
          name="radio-options"
          value={ value }
          onChange={ handleRadioChange }
        >
          { choice?.length > 0 ? choice.map( ( el ) => <FormControlLabel
            value={ el?.value }
            control={ <Radio /> }
            label={ el?.text || el?.title } key={ el?.id } /> ) : <>
            <FormControlLabel value="true" control={ <Radio /> } label="True" />
            <FormControlLabel value="false" control={ <Radio /> } label="False" />
          </> }
        </RadioGroup>
        { explanation ? selected && <div className='response-main'>
          <p>{ value === answer ? <span><CheckCircleIcon /> {t("CORRECT")}</span> : <span><Close />{t("INCORRECT")}</span> }</p>
          <div>{ value === answer ? <div dangerouslySetInnerHTML={{__html:explanation.right}}></div>  : 
          <div dangerouslySetInnerHTML={{__html:explanation.wrong}}></div> }
          </div>
        </div> : selected && <div className='response-main'>
          <p>{ value === "true" ? <span><CheckCircleIcon />{t("CORRECT")}</span> : <span><Close />{t("INCORRECT")}</span> }</p>
          <p>{ description }</p>
        </div> }
      </div>
    </>
  )
}

RadioSelect.defaultProps = {
  instructions: {
    text: 'Select True or False',
    enabled: false
  },
  explanation: {
    wrong: "Information:wrong answer",
    right: "Information:right answer",
  },
  options: [ { "id": 1, "title": "True 1", "value": 1 }, { "id": 2, "title": "False 2", "value": 2 } ],
  answer:1
}

RadioSelect.propTypes = {
  /** Heading title text of the component */
  title: PropTypes.string,
  /** Description text is used for displaying explanation over the topic  */
  description: PropTypes.string,
  /** Question for the radioSelect component  */
  question: PropTypes.string,
  /** options array  */
  options: PropTypes.array,
  /** right or wrong information */
  explanation: PropTypes.object,
  /** answer id in number */
  answer: PropTypes.number,

  /** Tracking the component progress */
  track: PropTypes.instanceOf( ContentTracking ),
  /** Func description for tracking*/
  handler: PropTypes.func
}

export default RadioSelect

