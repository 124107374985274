import React from 'react';
import PropTypes from 'prop-types';
import { IconButton as MuiIconButton } from '@mui/material';
import Icons from './Icons';
import './muiicons.scss';

/**
 * IconButton component
 * 
 * A reusable button component that dynamically renders a Material-UI icon based on the `iconName` prop.
 * This component centralizes icon usage and ensures consistency across the application.
 * 
 * @param {Object} props - The props for the component.
 * @param {string} props.iconName - The name of the icon to be rendered. Must match a key in the `icons` mapping.
 * @param {function} [props.onClick] - Optional click handler for the button.
 * @param {string} [props.color] - The color of the icon. Can be any valid CSS color string.
 * @param {string} [props.size='small'] - The size of the button. One of 'small', 'medium', 'large'.
 * @param {Object} [props.props] - Additional props to be passed to the underlying MUI IconButton component.
 * The rendered IconButton component, or null if the specified icon is not found.
 * 
 */
const MuiIcons = ({ iconName, onClick=null, name='', color="#707070",preText=null, size = 'small',className='', ...props }) => {
  const Icon = Icons[iconName];

  if (!Icon) {
    console.warn(`Icon "${iconName}" not found`);
    return null;
  }

  return (
    <MuiIconButton 
      onClick={onClick} 
      size={size} 
      name={name}
      sx={{ color: color, backgroundColor: 'transparent !important' }}
      className={`common-mui-btn ${className}`}
      {...props}
    >
     {preText && <span className='pre-text'>{preText}</span>} <Icon style={{color:color}} size={size}  />
    </MuiIconButton>
  );
};

MuiIcons.propTypes = {
  iconName: PropTypes.string.isRequired,
  onClick: PropTypes.func,
  /** * The color of the icon. Can be any valid CSS color string.*/
  color: PropTypes.string,
  /*** The size of the button.
   * One of 'small', 'medium', 'large'.
   * @default 'small'
   */
  size: PropTypes.oneOf(['small', 'medium', 'large']),
};

export default MuiIcons;
