import * as types from '../reducers/types'


// keeping this as initial will add in future
const initialState = {
    user: {},
    module: {},
    platform: {},
    acl:{}
}


const userInfoReducer = (state = initialState, action) => {
    switch (action.type) {
        case types.STORE_USER_INFO:
            return {
                ...state,
                user: action.info.ceuser,
                module: action.info.cemodule,
                platform: action.info.platform_scorm
            }
        /** Data Associated for easy selection
         * pass the key and pick the object
        */
        case types.USER_ACL_RES:
            let aclobj ={}
            if(action?.info.status === 'success'){
                let roles = {};
                let features = {};
                let acl = {};
                try{
                    action.info?.data[0]?.features?.forEach((feature)=>{
                        features[feature.id] = feature;
                    })
                    action.info?.data[0]?.roles?.forEach((role)=>{
                        roles[role.id] = role
                    })
                    action.info?.data[0]?.acl?.forEach((item)=>{
                        acl[item.role] = item
                    })
                    aclobj.features = features
                    aclobj.roles = roles
                    aclobj.acl = acl
                    aclobj.acl['-1'] = {};
                    aclobj.acl['-1'].access = aclobj.acl['0'].access;
                    aclobj.acl['-1'].role = -1; 
                }catch(e){
                    console.log(e);
                }
            }
            return {
                ...state,
                acl:aclobj
            }
        default:
            return state
    }
}

export default userInfoReducer