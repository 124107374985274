import React, { useState, useCallback, useEffect,useRef } from 'react'
import Video from '../Video/Video'
import './videohotspot.scss';
import ComponentConstants from '../../../utils/ComponentConstants';
import Close from '@material-ui/icons/Close';
import AppButton from "../../ui/AppButton/AppButton"
import MuiIcons from '../../ui/MuiIcons/MuiIcons';
import BuildHelper from '../../../utils/BuildHelper';
import Instruction from '../../ui/Instruction/Instruction';
import PlayerConstants from '../../../utils/PlayerConstants';
import DataHelper from '../../../utils/DataHelper';
import AudioPlayer from "react-h5-audio-player";

/**
 * VideoHotspot component allows learners to interact with video content that contains hotspots (cue points)
 * where additional information is displayed, and progress is tracked.
 * 
 * @param {string} src - Video source URL
 * @param {string} cename - Component name for tracking
 * @param {number} topic_id - ID of the current topic
 * @param {Array} items - Array of hotspot items (cue points) to display
 * @param {function} handler - Function to handle status updates and interactions
 * @param {Object} instructions - Instructional content
 * @param {string} poster - Poster image for the video
 * @param {boolean} forward_rewind - Flag to enable/disable forward/rewind controls
 * @param {Object} track - Tracking information for the user's progress
 * @param {string} intro - Introductory content for the video
 * @param {string} description - Video description content
 * @param {string} summary - Summary content displayed at the end
 * @param {string} warning - Warning message displayed when necessary
 */
const VideoHotspot = ({ src, cename,e_title, topic_id, items, wrng_audio, handler,instructions, poster, forward_rewind,track, intro,in_audio, description, summary,warning }) => {
  
    /**
   * State management for the video player, hotspots, and user interaction tracking.
   * @typedef {Object} Action
   * @property {number|null} cIdx - Current hotspot index
   * @property {number} cDuration - Current video duration
   * @property {Object|null} player - Video player instance
   * @property {Array} hotspot - Hotspot data (cue points) for video interaction
   * @property {Array} viewedItems - Items the user has viewed
   * @property {boolean} hasStart - Flag indicating if the video has started
   * @property {Object|null} content - Current content (intro, summary, etc.)
   */
  const [action, setAction] = useState({
    cIdx:null,
    cDuration:0,
    player:null,
    hotspot:JSON.parse(JSON.stringify(items)),
    viewedItems:[],
    hasStart:false,
    content:null,
    hasCont:false
  });
  const audioRef = useRef(null);

  const { hotspot, currentIDX, currentContent, viewedItems, player, currentDuration, hasCont } = action;

  const [status, setStatus] = useState({
    completed: false,
    text: PlayerConstants.COMPONENT_CONSTANTS.STATUS_INCOMPLETE
  });
  const [instruction, setInstruct] = useState(instructions);
  const UPDATE_STATUS = PlayerConstants.COMPONENT_CONSTANTS.UPDATE_STATUS
  const SAVE_PROGRESS_STATUS = PlayerConstants.COMPONENT_CONSTANTS.SAVE_PROGRESS_STATUS

   /**
   * useEffect hook that initializes or resets the hotspot data and player state
   * whenever the component props (items, intro, description, summary, warning) change.
   */
  useEffect(() => {
    if(!BuildHelper.isLearner()){
      setAction((prevState) => ({
        ...prevState,
        hotspot:JSON.parse(JSON.stringify([...items])),
        // currentContent:{ info: intro },
        cIdx:null,
        viewedItems:[],
      }))
      if(action?.player) action?.player?.currentTime(0);
    }
  }, [items,intro, description, summary, warning,wrng_audio, in_audio]);

   /**
     * Manage the component update 
     * progress logic in this method
     * 
     * Update the view status when ever the user interacts
     * Update the progess status ONLY ONCE, when status completes
     * 
     */
   const updateProgress = (status) => {

    /**
     * View status, will update while navigating to next topic
     */
    track.state = { ...track.state }
    /**
     * Progress updated as and when its completed
     */

    // if (!track.status) {

      track.status = status
      setStatus({ completed: true, text: PlayerConstants.COMPONENT_CONSTANTS.STATUS_COMPLETE })
      if (track.status) {
        handler({ type: SAVE_PROGRESS_STATUS, id: track.id, name: cename })
      }
    // }
    /**
     * If anything to be intimated always
     * call this
     */
    handler({ type: UPDATE_STATUS, id: track.id, name:cename })

  }

  // Handle double-click event
  const handleDoubleClick = useCallback(() => {
    if (!player) return;
    player.isFullscreen() ? player.exitFullscreen() : player.requestFullscreen();
  }, [player]);

  // set up the double-click listener after the player is initialized
  useEffect(() => {
    if (player) {
      const videoElement = player.el().querySelector('video');
      if (videoElement) {
        videoElement.addEventListener('dblclick', handleDoubleClick);

        // cleanup listener on component unmount or player change
        return () => {
          videoElement.removeEventListener('dblclick', handleDoubleClick);
        };
      }
    }
  }, [player]);

  const replacePlaceholders = (values) => {
    return e_title?.replace(/\{(\w+)\}/g, (_, key) => values[key] || 0);
  };
  

  /**
   * handleClick function checks for cue points in the current video duration and updates
   * the viewed items and hotspots accordingly. Pauses the video at the hotspot.
   */
  const handleClick = () => {
    if (!hotspot || !currentDuration?.t) return; // Early exit for invalid data
  
    const index = hotspot.findIndex(
      ({ point, show }) =>
        point && currentDuration.t >= point[0] && currentDuration.t <= point[1]
    );
  
    if (index === -1) return; // No valid hotspot found
  
    const videoElement = player?.el()?.querySelector('video');
    if (videoElement) videoElement.pause();
  
    const updatedCuepoints = hotspot.map((item, i) =>
      i === index ? { ...item, show: true } : item
    );
  
    const selectedCue = updatedCuepoints[index];
    const hasAudio = selectedCue?.audio && selectedCue.audio !== -1;
 
  
    setAction((prevState) => {
      // handle audio playback if available
      if (hasAudio) {
        setTimeout(() => {
          audioRef.current.audio.current.play();
          handleContinue(true, selectedCue);
        }, 1500);
      }
  
      // exit fullscreen if active
      if (player?.isFullscreen()) {
        player.exitFullscreen();
      }
  
      // Update state
      return {
        ...prevState,
        hotspot: updatedCuepoints,
        currentContent: selectedCue,
        viewedItems: hasAudio
          ? prevState.viewedItems
          : [...prevState.viewedItems, selectedCue],
        currentIDX: hasAudio ? prevState.currentIDX : prevState.viewedItems.length,
      };
    });
  };
  

  const handleContinue=(has,newPoint)=>{
    setAction((prevState) => ({
      ...prevState,
      hasCont:has
    }));
    let auplayer = audioRef.current.audio.current;
    auplayer.onended = function () {
      setAction((prevState) => {
        const hasAdd=prevState?.viewedItems?.some(e=>newPoint?.id === e?.id)
       return {
        ...prevState,
        hasCont:false,
        viewedItems:(newPoint && !hasAdd) ? [...prevState?.viewedItems,newPoint] : prevState?.viewedItems
      }
      });
    }
  }

  /**
   * handleStepClick function allows users to manually navigate through hotspots
   * by clicking on the step indicators (e.g., 'Start', 'Step 1', etc.)
   */
  const handleStepClick = (content, index, step = null) => {
    setAction((prevState) => {
      const updatedList = [...prevState.viewedItems];
      const hasAudio = content?.audio && content?.audio !== -1;
  
      // check if the content is already in the viewedItems list
      const isAlreadyAdded = updatedList.some((item) => content?.point[0] === item?.point[0]);
  
      // add content to the list if not already added
      const newViewedItems = !isAlreadyAdded ? [...updatedList, { ...content, show: true }] : updatedList;
  
      // check if all items are viewed
      const hasCompleted = newViewedItems.length === items?.length;
  
      let finalViewedItems = newViewedItems;
  
      if (hasCompleted && step) {
        finalViewedItems = newViewedItems.map((item, idx) =>
          idx === newViewedItems.length - 1 ? { ...item, isEnd: true } : item
        );
      }
  
      // play audio 
      if (hasAudio) {
        setTimeout(() => {
          if (audioRef?.current) {
            audioRef.current.audio.current.play();
            handleContinue(true, finalViewedItems[finalViewedItems.length - 1]);
          }
        }, 1500);
      }
  
      return {
        ...prevState,
        currentContent: { ...content, isEnd: false, step },
        currentIDX: index,
        viewedItems: !hasAudio ? finalViewedItems : prevState?.viewedItems,
      };
    });
  
    // Handle video playback
    if (!step) {
      const videoElement = player?.el()?.querySelector('video');
      if (videoElement) videoElement.pause();
      if (player) player.currentTime(Number(content?.point[0]));
    }
  };
  

  /**
   * handleListening function disables pointer events during cue points to avoid
   * interaction when the overlay is active.
   */
  const handleListening = useCallback(
    (payload) => {
      const index = hotspot.findIndex(({ point, show }) =>{
       return point && !show && payload.t >= point[0] && payload.t <= point[1]
    });
      const videoElement = payload.p.el().querySelector('video');
      videoElement.style.pointerEvents = index > -1 ? 'none' : 'auto';
    },
    [hotspot]
  );

  const getAudioUrl =(path)=>{
    return DataHelper.getResourcePath(3,path);
  }

  /**
   * handleAction function manages different video event types such as video start, progress update,
   * cue point handling, and saving component properties.
   */
  const handleAction = useCallback((vData, cue) => {
    if (!vData) return; // Safeguard for undefined or null vData

    const handlerData = vData?.data;

    switch (vData?.type) {
      case '1':
        setAction((prevState) => ({
          ...prevState,
          hasStart:true,
          player: handlerData,
          currentContent: { info: intro, audio:in_audio, title:'INFO' },
        }));
        if(handlerData) handlerData.pause();
        setTimeout(() => {
          audioRef.current.audio.current.play();
          handleContinue(true);
        }, 1500);
        break;
      case '2':
        setAction((prevState) => {
          const newViewedItems = [...prevState?.viewedItems];
          const isCompleted = newViewedItems.length === prevState?.hotspot?.length;
          const values = {
            total_viewed: newViewedItems?.length,
            total_point: items?.length,
          };
          if (isCompleted) {
            updateProgress(1);
            if(newViewedItems.length>0)newViewedItems[newViewedItems?.length-1]={...newViewedItems[newViewedItems?.length-1],isEnd: true}
            // else newViewedItems=[{ isEnd: true }];
            return {
              ...prevState,
              currentContent: { info: summary, isEnd: true,title:replacePlaceholders(values)  },
              currentIDX: -1,
              viewedItems: [...newViewedItems],
            };
          } else {
            if(wrng_audio){
              setTimeout(() => {
                audioRef.current.audio.current.play();
                handleContinue(true);
              }, 1500);
            }
            return {
              ...prevState,
              currentContent: { info: warning, isEnd: true, hasTry: true,title:replacePlaceholders(values), audio:wrng_audio},
            };
          }
        });
        break;

      case 'SAVE_COMP_PROPS':
        handler({
          type: ComponentConstants?.SAVE_COMP_PROPS,
          data: {
            cename,
            topic_id,
            cduration: handlerData?.cduration,
            isEdit: handlerData?.isEdit,
          },
        });
        break;
      case '3': //timeupdat
      setAction((prevState) => ({
        ...prevState,
        currentDuration: handlerData,
        player: prevState?.player ? prevState?.player: handlerData?.p
      }));
        handleListening(handlerData)
        break;
      default:
        return; 
    }
  }, [player, intro, summary, items?.length, hotspot?.length, viewedItems?.length ])


  const handleEndClick=()=>{
    if(!viewedItems[Number(viewedItems?.length-1)]?.isEnd)return;
    if (viewedItems?.length === hotspot?.length) {
      const values = {
        total_viewed: viewedItems?.length,
        total_point: items?.length,
      };
      setAction((prevState) => ({
        ...prevState,
        currentIDX: null,
        currentContent: { info: summary, isEnd: true, title:replacePlaceholders(values) },
        viewedItems: prevState?.viewedItems?.map((item, index) =>
          index === prevState?.viewedItems.length - 1 ? { ...item, isEnd: true } : item
        ),
      }));
    }else {
      setAction((prevState) => ({
        ...prevState,
        currentContent: { info: warning, isEnd: true, hasTry: true },
      }));
    }
  }


  const handleStart = (step) => {
    setAction((prevState) => {
      const viewedCount = prevState?.viewedItems?.length || 0;
      const totalItems = items?.length || 0;
      const hasCompleted = viewedCount === totalItems;
      const values = {
        total_viewed: viewedCount,
        total_point: totalItems,
      };
  
      // determine `currentContent` based on `step` and `hasCompleted`
      const currentContent = step
        ? {
            info: hasCompleted ? summary : warning,
            isEnd: true,
            hasTry: true,
            title: replacePlaceholders(values),
            hasStepEnd:hasCompleted,
          }
        : null;
  
      // // update `viewedItems` conditionally
      // const updatedViewedItems = hasCompleted && step
      //   ? [
      //       ...prevState?.viewedItems,
      //       prevState?.viewedItems?.[viewedCount - 1] || {},
      //     ].map((item, index) =>
      //       index === viewedCount - 1 ? { ...item, isEnd: true } : item
      //     )
      //   : prevState?.viewedItems;
  
      return {
        ...prevState,
        currentContent,
        currentIDX: null,
        // viewedItems: updatedViewedItems,
      };
    });

    if (!step && player && !(player.currentContent?.isEnd)) {
      player.play();
    }
  };
  
  const handleInfoClick=(content)=>{
    setAction(prv=>({
      ...prv,
      currentContent:content
    }));
  }

  const handleReview=()=>{
    setAction(prv=>({...prv,viewedItems:[]}));
    handleInfoClick({ info: intro,audio:in_audio, title:'INFO'});
  }

  const handleReset=()=>{
    updateProgress(0)
    setAction(prv=>({...prv,viewedItems:[]}));
    handleInfoClick({ info: intro,audio:in_audio, title:'INFO' });
  }

  const endSummary=()=>{
    return <>
      <div className='result-summary p-2'>
      {items.map((e,index)=>{
         const isSelected = viewedItems?.some(viewedItem => viewedItem?.id === e?.id);
        return <div className='hotspot-container' onClick={()=>handleStepClick(e, index,{has:isSelected})}>
               {/* <span> {e?.title}</span>  */}
               { <span className='img-container'> {e?.sch ? <img src={e?.sch}/>: e?.title} {isSelected ? <MuiIcons iconName='tick' className="selected-item" color='var(--primary-color-1)' /> : <MuiIcons iconName='cancel' color='var(--bs-red)' className="selected-item" />} </span>  }
        </div>
      })}
      </div>
    </>
  }

  const overLay = useCallback(() => {
    if (!currentContent) return null;
    //destructure properties from currentContent for easier access.
    const { 
      title, desc, info, step, isEnd, hasStepEnd, audio 
    } = currentContent;
  
    const hasAudio = audio !== -1 && audio;
    const hasStart = info;
    const hasCompleted = viewedItems.length >= items.length;
    const canProceed = !hasCont || !hasAudio; // audio completion check
  
    // function to render the title section with step and audio indicators.
    const renderTitle = () => {
      if (title && !hasStepEnd) {
        return (
          <div className='overlay-title p-3 m-0'>
            <div dangerouslySetInnerHTML={{ __html: title }} />
            {step && (
              <span className='icon-cnt'>
                {step.has ? (
                  <MuiIcons iconName='tick' className="selected-item" color='var(--primary-color-1)' />
                ) : (
                  <MuiIcons iconName='cancel' color='var(--bs-red)' className="selected-item" />
                )}
              </span>
            )}
            {hasAudio && <MuiIcons iconName='audio_play' className="icon-pulse" />}
          </div>
        );
      }
      return null;
    };
  // function to render the description content.
    const renderDescription = () => (
      desc && <div className="content-item p-3" dangerouslySetInnerHTML={{ __html: desc }} />
    );
  // function to render the informational content if available.
    const renderInfo = () => (
      hasStart && (
        <div className='d-flex info-cnt'>
          {!isEnd && <MuiIcons size='medium' iconName='tuch' />}
          <div className="py-3" dangerouslySetInnerHTML={{ __html: info }} />
        </div>
      )
    );
  // function to render the audio player if audio exists.
    const renderAudio = () => (
      hasAudio && (
        <div className='audio-container'>
          <AudioPlayer
            src={getAudioUrl(audio)}
            preload='metadata'
            volume={0.5}
            controls={false} // Hide controls
            progressBar={false}
            muted={false}
            loop={false}
            ref={audioRef}
          />
        </div>
      )
    );
    // function to render action buttons based on the content state.
    const renderButtons = () => (
      <div className='btn-container'>
        {isEnd && (
          <>
            {(!hasCompleted||hasStepEnd) && track?.status !== 1 &&(
              <AppButton disabled={!canProceed} onClick={handleReset}>
                Rewatch
              </AppButton>
            )}
            {(hasStepEnd || (hasCompleted && !hasStepEnd)) && (
              <AppButton disabled={!canProceed} onClick={handleReview}>
                Recap
              </AppButton>
            )}
          </>
        )}
        <AppButton
          size="small"
          disabled={!canProceed}
          onClick={() => handleStart(step)}
        >
          {isEnd ? 'Close' : 'Continue'}
        </AppButton>
      </div>
    );
  
    return (
      <div className='overlay-render-vid-hot'>
        <div className="add-on-overlay-vid-hot" style={{ backgroundColor: "#fff" }}>
          <div className="head-item">
            {renderTitle()}
            <MuiIcons iconName='close' className="close-btn" onClick={() => handleStart()} />
          </div>
          {renderDescription()}
          {renderInfo()}
          {isEnd && !hasStepEnd && endSummary()}
          {renderAudio()}
          {renderButtons()}
        </div>
      </div>
    );
  }, [currentContent, items, viewedItems, hasCont]);
  
  



  const videoLRN = React.useMemo(() => {
    return (
      <div
        onClick={handleClick}
        className={`${currentContent ? 'popup-hide' : ''} video-area`}
      >
        <Video
          src={src}
          handleHotspot={(prop)=>handleAction(prop)}
          poster={poster}
          hasCue
          forward_rewind={forward_rewind}
          hasHotspot
        />
      </div>
    );
  }, [handleClick, currentContent, src, poster, forward_rewind,hotspot, items]);
  

  return (<>
    <Instruction isInstruction={instruction?.enabled} completed={(track.status === 1 || status.completed)} title={(track.status === 1 || status.completed) ? PlayerConstants.COMPONENT_CONSTANTS.STATUS_COMPLETE : PlayerConstants.COMPONENT_CONSTANTS.STATUS_INCOMPLETE} classText={`${(track.status === 1 || status.completed) && PlayerConstants.COMPONENT_CONSTANTS.COMPLETED_CLASS}`} text={(track.status === 1 || status.completed) ? PlayerConstants.COMPONENT_CONSTANTS.INSTRUCTIONS_PASSED : instruction?.text} />
    <div className="videxplo-container">
    {videoLRN}

      {action?.hasStart && (<div className="stepper">

        <div
          className={`step ${viewedItems?.length > 0 ? 'active-bar' : 'active-action'} visible-action`}
        >
          <div className="step-circle" onClick={() => handleInfoClick({ info: intro,audio:in_audio, title:'INFO' })}>Start</div>
        </div>

        {viewedItems?.map((e, index) => {
          const isActive = currentIDX === index;
          // const isCompleted = currentIDX > index;
          return (
            <div
              key={index}
              className={`
            step 
            ${e?.show ?  'visible-action' : ''} 
            ${isActive ? 'active-action' : ''} 
            ${viewedItems?.[viewedItems.length - 1]?.isEnd ? 'visible-action active-bar' : ''} 
            ${(currentIDX === index && currentContent?.desc) ? 'active-action' : ''}
          `}
            >
              <div className="step-circle" onClick={() => handleStepClick(e, index)}>{index + 1}</div>
            </div>
          );
        })}

        {(
          <div
            className={`step ${viewedItems[Number(viewedItems?.length - 1 || 0)]?.isEnd ? 'visible-action active-bar' : ''} end-step`}
          >
            <div className="step-circle" onClick={() => handleEndClick()}>End</div>
          </div>
        )}
      </div>)}
      {description && <div className="content-item p-3" dangerouslySetInnerHTML={{ __html: description }} />}
      {currentContent && overLay()}
    </div>
    </>
  )
}

export default VideoHotspot