import React, {useEffect, useState} from 'react'
import PropTypes from 'prop-types'
import CheckIcon from '@material-ui/icons/Check';
import ErrorOutlineIcon from '@material-ui/icons/ErrorOutline';
import Tooltip from '@material-ui/core/Tooltip';
import './instruction.scss'
import t from'../../../translation/useTranslate';
const Instruction = ({classText, text,enabled, title, completed, isInstruction, name}) => {
   const [isCompleted, setIsCompleted] = useState(completed)
   useEffect(()=>{
    if(!isCompleted){
        setIsCompleted(completed)
    }
},[completed])
    
    return (
        <div className={`${isInstruction && "visible"} instruction-text`}>
             <p className={`instruct-panel ${classText}`}><span>{text}</span></p> 
            <Tooltip title={t(title.toUpperCase())} placement="right">
                {isCompleted ? <CheckIcon className='status-completed' /> : <ErrorOutlineIcon className={`topic-complete`}/> }
            </Tooltip>
        </div>
    )
}

Instruction.propTypes = {
    classText: PropTypes.string,
    text: PropTypes.string,
    title: PropTypes.string,
    completed: PropTypes.bool,
    isInstruction: PropTypes.bool
}

export default Instruction
