import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import { createMuiTheme, ThemeProvider } from '@material-ui/core/styles';
import store from './redux/store';
import * as courseInfoAction from './redux/actions/courseInfoAction';
import * as courseTrackingAction from './redux/actions/courseTrackingAction';
import * as userInfoAction from './redux/actions/userInfoAction';
import * as courseEditAction from './redux/actions/courseEditAction';
// import PlayerConstants from './utils/PlayerConstants';
import CExApi from './core/lrs/CExApi';
// import SCORMWrapper from './core/lrs/SCORMWrapper';
import BuildHelper from './utils/BuildHelper';
import DataHelper from './utils/DataHelper';
// import * as serviceWorker from './serviceWorker';
import App from './App';
import './index.css';

// Constants
const roleId = Number(window?.ce?.ceuser?.role_id || 4);
const isCEAuthor = roleId !== 4;
const theme = createMuiTheme({
  palette: {
    primary: { main: '#ffffff' },
    secondary: { main: '#4caf50' },
  },
  typography: { fontFamily: 'WorkSansSemiBold' },
  breakpoints: {
    values: {
      xs: 0,
      sm: 480,
      md: 973,
      lg: 1366,
      xl: 1920,
    },
  },
});

// Initialize BuildHelper
BuildHelper.initialize({
  scorm: window?.ce?.isScormPack,
  roleId,
});

// Helper Functions
const addPartnerClass = () => {
  const playerType = 'egadd'; // This should be dynamically set
  switch (playerType) {
    case "techuk": return "techuk";
    case "egadd": return "egadd";
    case "ioe": return "ioe";
    default: return 'ceplayer';
  }
};


// Function to handle requestProgress dispatch
const requestProgressIfLearner = async () => {
  if (!window?.ce) return;
  const hasScorm=window?.ce?.isScormPack || window?.ce?.isStandalone
  if(hasScorm) DataHelper.initializeSCORM(userInfoAction, courseTrackingAction , store.dispatch);
  if (roleId === 4 && !(hasScorm) && window?.ce) {
    await store.dispatch(courseInfoAction.requestProgress());
  }
};

  requestProgressIfLearner();

// Initialize CExApi and dispatch actions
const initializeCExApi = () => {
  if (!window?.ce) return;

  const cexapi = new CExApi(window.ce.ceuser, window.ce.cemodule, window.ce.platform_scorm, BuildHelper.isOnScorm());
  cexapi.initialise();
  
  store.dispatch(courseTrackingAction.intialiseLrs(cexapi));
  store.dispatch(userInfoAction.storeUserInfo(window.ce));
  
  if (isCEAuthor) {
    store.dispatch(userInfoAction.userAclRequest(window.ce));
    store.dispatch(courseEditAction.setEditState(true));
  }
  
  
  

};

initializeCExApi();

const courseInfoIfNeeded = () => {
    store.dispatch(courseInfoAction.requestCoursInfo(1));
};

// Initialize SCORM if applicable
const initializeSCORM = () => {
  try {
    if (!window?.ce) return;
    /** 
     * Proceed only if the content is either a SCORM package or running in standalone mode.
     * Retrieve the SCORM suspend data from the courseTracking state, which contains progress information.
     * Decrypt the progress data using the SCORM decryption function to obtain user progress and metadata.
     * If the decrypted data contains a 'lan' (language) key, store the language in localStorage as 'defaultLn'.
     * Dispatch an action to update the course progress in the store, including decrypted progress and configuration data.
     * Call 'courseInfoIfNeeded'
     */
    const { ce } = window || {};
    const { isScormPack, isStandalone, player_config_json } = ce || {};
    const { courseTracking } = store?.getState();

    if (isScormPack || isStandalone) {
      const spData = courseTracking?.scorm?.getSuspendData();

        const progressDataArray = new Uint8Array(spData?.split(','));

        const decryptedData = courseTracking?.scorm?.decryptProgress(progressDataArray);

        if (decryptedData?.lan) {
           localStorage.setItem(`${BuildHelper.configId()}_LN`, JSON.stringify(decryptedData?.lan));
         }

        const sco = { sco: { ...(decryptedData || {}) } };
        store.dispatch(courseTrackingAction.responseProgress([{ ...sco, player_config_json }]));
        
      courseInfoIfNeeded();
    }
    if (BuildHelper.isLearner()|| BuildHelper.isConfig()) {
      const supplimentaryRequestType = BuildHelper.isOnScorm() ? -1 : 0;
      store.dispatch(courseInfoAction.requestSupplimentaries(supplimentaryRequestType));
    }

  } catch (error) {
    console.error("SCORM Initialization Error:", error);
  }
};

initializeSCORM();


if(!(window?.ce?.isScormPack || window?.ce?.isStandalone)&& !BuildHelper.isLearner())courseInfoIfNeeded();

// Clean up console logs in production
if (process?.env?.NODE_ENV !== "development") {
  console.log = () => {};
  console.debug = () => {};
  console.warn = () => {};
}

// Add partner class to root element
const rootEle = document.getElementById('root')
rootEle.classList.add(addPartnerClass())

// Render application
ReactDOM.render(
  <Provider store={store}>
    <ThemeProvider theme={theme}>
      <App />
    </ThemeProvider>
  </Provider>,
  rootEle
);
// serviceWorker.register();

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
// reportWebVitals();
