import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import BuildHelper from '../utils/BuildHelper';
import DataHelper from '../utils/DataHelper';


/**
 * Retrieves the default language.
 * If not set, checks the available languages and sets the default language if only one is available.
 * Falls back to 'en_US' if there are issues retrieving or setting the language.
 * 
 */
export const getLanguage = () => {
  try {
    if (!BuildHelper.defaultLanguage()) {
      const languages = BuildHelper?.languageList()?.languages || []
      if (languages?.length === 1) {
        localStorage.setItem(`${BuildHelper.configId()}_LN`, JSON.stringify(languages[0]?.lan || 'en_US'))
        return languages[0]?.lan || 'en_US'
      }
    }
    return  (BuildHelper.isLearner() ? BuildHelper?.defaultLanguage() : 'en_US')
  } catch (e) {
    return 'en_US'
  }
}
/**
 * Initializes the i18n library with translations.
 * Loads translations from the provided JSON or fetches them from the DataHelper.
 * Sets the initial language and fallback language.
 * .
 */
export const initializeI18n = async (lan,json) => {

  const defaultLanguage = lan|| "en_US";//en_US

  try {
    // Check if the translations for the default language are already cached;
    const translations = json || await DataHelper?.fetchTranslations();

    const resources = {
      [defaultLanguage]: {
        translation: translations[defaultLanguage],
      },
    };

    await i18n
      .use(initReactI18next)
      .init({
        resources,
        lng: defaultLanguage,
        fallbackLng: 'en_US',
        interpolation: {
          escapeValue: false,
        },
      });

    console.log('i18n initialized successfully');
    return i18n;
  } catch (error) {
    console.error('Error initializing i18n:', error);
    throw error;
  }
};
/**
 * Changes the current language of the i18n instance.
 * Loads the translations for the new language and sets it as the active language.
 * 
 */
export const changeLanguage = async (newLanguage, json) => {
  try {
    // Fetch translations and cache them
    const translations = json || await DataHelper?.fetchTranslations();

    i18n.addResources(newLanguage, 'translation', translations[newLanguage]);
    i18n.changeLanguage(newLanguage);

    console.log(`Language changed to: ${newLanguage}`);
  } catch (error) {
    console.error('Error changing language:', error);
    throw error;
  }
};
