import React from 'react'
import PropTypes from 'prop-types'
import CourseCardFlip from './CourseCardFlipPanel'

const CourseImageFlipPanel = ({items, handleProperties, cename,id,handleCeAssets }) => {
    return <CourseCardFlip id={id} items={items} onlyImage={true} handleProperties={handleProperties} cename={cename} handleCeAssets={handleCeAssets}/>
}

CourseImageFlipPanel.propTypes = {
    /** items array used for having list of cardflip data */
    items: PropTypes.array,
    /** function handler used to save the edited props */
    handleProperties: PropTypes.func
}

export default CourseImageFlipPanel
