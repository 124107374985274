import React from 'react'
import PropTypes from 'prop-types'
import OrderedListPanel from './OrderedListPanel'

const UnorderedListPanel = ({snippetOne,snippetTwo,list, handleProperties}) => {
    return <OrderedListPanel snippetOne={snippetOne} snippetTwo={snippetTwo} list={list} handleProperties={handleProperties}/>
}

UnorderedListPanel.propTypes = {
    list: PropTypes.array,
    handleProperties: PropTypes.func
}

export default UnorderedListPanel
