import React, { useEffect, useState } from 'react'
import Acc from '@material-ui/core/Accordion';
import useForceUpdate from 'use-force-update';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import Typography from '@material-ui/core/Typography';
import DeleteIcon from '@material-ui/icons/Delete';
import UploaderPanel from '../UploaderPanel/UploaderPanel';
import CKeditor from '../CKeditor/CKeditor';
import PropTypes from 'prop-types'
// import PlayerConstants from '../../../utils/PlayerConstants';
import { TextField } from '@mui/material';
import { AddCircle } from '@material-ui/icons';


const ImageMapPanel = ( { mappings, handleProperties, handleCeAssets, cename, image } ) => {
    const [ expanded, setExpanded ] = useState( 0 );
    const [ list, setMapList ] = useState( JSON.parse( JSON.stringify(mappings) ) )
    const forceUpdate = useForceUpdate()

    const handleDelete = ( e, index ) => {
        const removed = list.filter( ( e, i ) => i !== index )
        setMapList(removed)
        handleProperties({image: image, mappings: removed})
        forceUpdate()
        e.stopPropagation()
    }

    const handleChange = ( panel ) => ( event, isExpanded ) => {
        setExpanded( isExpanded ? panel : false );
    };

    const eventHandlers = ( value, i ) => {
        list[ i ][ 'content' ] = value
        setMapList(list)
        forceUpdate()
        handleProperties({image: image, mappings: list})
    }

    const handlePoints = (e, i) => {
        list[ i ][ 'map' ][e.target.name] = e.target.value
        setMapList(list)
        handleProperties({image: image, mappings: list})
    }

    useEffect(() => {
        handleProperties({image: image, mappings: list})
     }, [list]);

    const addAccordion = () => {
        // if(max_count > list.length) {
            list.push({
                id: list.length + 1,
                isOpen: false,
                content: 'description',
                map: {
                    x: 50,
                    y: 50
                }
            })
            setMapList(list)
            handleProperties({image: image,mappings: list})
            forceUpdate()
        // }
    }

    return ( <div className='imagmap-prop-container'>
        <div className={ `` }>
            <div className='row mt-15'>
                <div className="mb-3 col-md-12">
                    <label htmlFor="formFile" className="form-label">Browse Image</label>
                    <UploaderPanel accept={'image'}  fileName={''} />
                </div>
            </div>
            {
                ( mappings.length > 0 ) && mappings.map( ( el, index ) => {
                    return <Acc key={ index } expanded={ expanded === index } onChange={ handleChange( index ) }>
                        <AccordionSummary
                            aria-controls="panel1a-content"
                            id="panel1a-header"
                        >
                        <Typography className={ `` }>{ index + 1 }  </Typography> <DeleteIcon className='delete-prop-tabs' onClick={ ( e ) => handleDelete( e, index ) } />
                        </AccordionSummary>
                        <AccordionDetails>
                            <div className='map-prop-x plotting'>
                                <TextField id="outlined-basic" spellCheck={false} name='x' onChange={(e) => handlePoints(e, index)} label="X-axis" variant="outlined" defaultValue={el.map.x}/>
                            </div>
                            <div className='map-prop-y plotting'>
                                <TextField id="outlined-basic" spellCheck={false} name='y' onChange={(e) => handlePoints(e, index)} label="Y-axis" variant="outlined" defaultValue={el.map.y}/>
                            </div>
                            <p className='description-text'>Description</p>
                            <CKeditor data={ el.content } eventHandler={ ( e ) => eventHandlers( e, index ) } />
                        </AccordionDetails>
                    </Acc>
                } )
            }
                    <span title={'Add Accordion'} onClick={addAccordion}><AddCircle className='add-mapping'  /></span>
        </div> 
        </div> )
}



ImageMapPanel.propTypes = {
    /** items array used for having list of tabs section */
    mappings: PropTypes.array,
    /** function handler used to save the edited props */
    handleProperties: PropTypes.func,
    /** function handler used to update the Assets */
    handleCeAssets : PropTypes.func,
 }
export default ImageMapPanel