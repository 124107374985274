import React, { useState } from 'react';
import PropTypes from 'prop-types';
import TextField from '@mui/material/TextField';
import "./customtextfield.scss";

const CustomTextField = ({ id, label, className, variant, name, value, onChange, maxLength, type, disabled, placeholder }) => {
  const [isError, setIsError] = useState(false);
  let inputProps = {};
  const handleChange = (e) => {
    const { value } = e.target;

    // Update state to indicate whether there's a validation error
    setIsError((inputProps?.pattern ? !new RegExp(inputProps.pattern).test(value) : false));
    if(type==="number" && value > (maxLength||value+2)){
      setIsError(true);
      return;
    }
    // Call the onChange callback with the updated value
    onChange(e);
  };

  
    // Define input props based on type
    switch (type) {
      // case 'email':
      //   inputProps = {
      //     type:type,
      //     inputMode: 'email',
      //     pattern: /^[\w.-]+@[a-zA-Z\d.-]+\.[a-zA-Z]{2,}$/,
      //     error:"test"
      //   };
      //   break;
      case 'phone':
        inputProps = {
          type:type,
          pattern:false,
          error:"test"
        };
        break;
      case 'number':
          inputProps = {
            type: type,
            error: "max",
            max: maxLength,
            min: 1,
            error:`Please enter a value between 1 and ${maxLength||"numbers"}.`
          };
          break;
      case 'comma-number':
        inputProps = {
          type:type,
          pattern:`^[,0-9]+$`,
          error:"Only comma separated number are allowed"
        };
        break;
      default:
        inputProps = {
          type:type,
          pattern:false,
          error:"A valid Resat course on attempts is needed. Please fill it in"
        };
        break;
    }

  return (
    <TextField 
      className={`${className || ""} custom-ce-text-box`}
      id={id}
      name={name}
      label={label}
      variant={variant}
      type={type}
      inputProps={inputProps} 
      value={value}
      onChange={handleChange}
      disabled={disabled}
      placeholder={placeholder}
      error={isError||false} 
      helperText={isError ? `${inputProps?.error||"error"}` : ''} // Display error message if there's a validation error
    />
  );
};

CustomTextField.propTypes = {
  id: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
  variant: PropTypes.oneOf(['standard', 'outlined', 'filled']),
  value: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
  maxLength: PropTypes.number,
  type: PropTypes.oneOf(['text', 'password', 'email', 'number', 'phone', 'comma-number']),
  disabled: PropTypes.bool,
  placeholder: PropTypes.string,
};

CustomTextField.defaultProps = {
  variant: 'standard',
  type: 'text',
  disabled: false,
};

export default React.memo(CustomTextField);
