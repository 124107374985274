import React,{createRef} from 'react';
import './skillhome.scss';
import AppButton from '../../components/ui/AppButton/AppButton';
import MenuIcon from '@mui/icons-material/Menu';
import TreeMenu from '../../components/ui/TreeMenu/TreeMenu';

const SkillHome = ({img, homewrapper, crsName, disabled, onClick, btnText, bgContent, crsMap}) => {
  const crsstr = createRef();

const handleMaun=()=>{
    const wrapper = homewrapper.current;
    const structure=crsstr.current;
    wrapper.classList.toggle('half-resume');
    structure.classList.toggle('open-structure');
}

  return (
      <div className='skill-home-container' ref={homewrapper}>
         <MenuIcon className='crs-menu-icon' onClick={handleMaun} />
          <div className='skill-container' ref={bgContent} >
          <div  className='course-detail'>
              <h2 className='crs'>Course</h2>
              <h1 className='crs-name'>{crsName}</h1>
              <br/>
              <AppButton theme={"secondary1"} size={'large'} disabled={disabled} onClick={()=>onClick()} >{btnText}</AppButton>
           </div>
              <div className="image-container" id="imageContainer" >
                  <img className="image" src={img} />
              </div>
          </div>
          <div className='crs-structure' ref={crsstr}><TreeMenu isHome={true} /></div> 
      </div>
  )
}

export default SkillHome