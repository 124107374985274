const StmtElements = () => {

    const xapi_actions = {
        LAUNCH: "launch",
        TOPIC: "topic",
        PAUSE: "pause",
        PLAY: "play",
        CC: "cc",
        COMPLETED: "completed",
        TXTCONTENT: "txtcontent",
        TOPICDONE: "topicdone",
        INTERACTED : "interacted",
        SCORED:"scored"
    }

    const base_verbs = {
        "abandoned": {
          "id": "https://w3id.org/xapi/adl/verbs/abandoned",
          "display": {
            "en-US": "abandoned"
          }
        },
        "answered": {
          "id": "http://adlnet.gov/expapi/verbs/answered",
          "display": {
            "en-US": "answered"
          }
        },
        "asked": {
          "id": "http://adlnet.gov/expapi/verbs/asked",
          "display": {
            "en-US": "asked"
          }
        },
        "attempted": {
          "id": "http://adlnet.gov/expapi/verbs/attempted",
          "display": {
            "en-US": "attempted"
          }
        },
        "attended": {
          "id": "http://adlnet.gov/expapi/verbs/attended",
          "display": {
            "en-US": "attended"
          }
        },
        "commented": {
          "id": "http://adlnet.gov/expapi/verbs/commented",
          "display": {
            "en-US": "commented"
          }
        },
        "completed": {
          "id": "http://adlnet.gov/expapi/verbs/completed",
          "display": {
            "en-US": "completed"
          }
        },
        "exited": {
          "id": "http://adlnet.gov/expapi/verbs/exited",
          "display": {
            "en-US": "exited"
          }
        },
        "experienced": {
          "id": "http://adlnet.gov/expapi/verbs/experienced",
          "display": {
            "en-US": "experienced"
          }
        },
        "failed": {
          "id": "http://adlnet.gov/expapi/verbs/failed",
          "display": {
            "en-US": "failed"
          }
        },
        "imported": {
          "id": "http://adlnet.gov/expapi/verbs/imported",
          "display": {
            "en-US": "imported"
          }
        },
        "initialized": {
          "id": "http://adlnet.gov/expapi/verbs/initialized",
          "display": {
            "en-US": "initialized"
          }
        },
        "interacted": {
          "id": "http://adlnet.gov/expapi/verbs/interacted",
          "display": {
            "en-US": "interacted"
          }
        },
        "launched": {
          "id": "http://adlnet.gov/expapi/verbs/launched",
          "display": {
            "en-US": "launched"
          }
        },
        "mastered": {
          "id": "http://adlnet.gov/expapi/verbs/mastered",
          "display": {
            "en-US": "mastered"
          }
        },
        "passed": {
          "id": "http://adlnet.gov/expapi/verbs/passed",
          "display": {
            "en-US": "passed"
          }
        },
        "preferred": {
          "id": "http://adlnet.gov/expapi/verbs/preferred",
          "display": {
            "en-US": "preferred"
          }
        },
        "progressed": {
          "id": "http://adlnet.gov/expapi/verbs/progressed",
          "display": {
            "en-US": "progressed"
          }
        },
        "registered": {
          "id": "http://adlnet.gov/expapi/verbs/registered",
          "display": {
            "en-US": "registered"
          }
        },
        "responded": {
          "id": "http://adlnet.gov/expapi/verbs/responded",
          "display": {
            "en-US": "responded"
          }
        },
        "resumed": {
          "id": "http://adlnet.gov/expapi/verbs/resumed",
          "display": {
            "en-US": "resumed"
          }
        },
        "satisfied": {
          "id": "https://w3id.org/xapi/adl/verbs/satisfied",
          "display": {
            "en-US": "satisfied"
          }
        },
        "scored": {
          "id": "http://adlnet.gov/expapi/verbs/scored",
          "display": {
            "en-US": "scored"
          }
        },
        "shared": {
          "id": "http://adlnet.gov/expapi/verbs/shared",
          "display": {
            "en-US": "shared"
          }
        },
        "suspended": {
          "id": "http://adlnet.gov/expapi/verbs/suspended",
          "display": {
            "en-US": "suspended"
          }
        },
        "terminated": {
          "id": "http://adlnet.gov/expapi/verbs/terminated",
          "display": {
            "en-US": "terminated"
          }
        },
        "voided": {
          "id": "http://adlnet.gov/expapi/verbs/voided",
          "display": {
            "en-US": "voided"
          }
        },
        "waived": {
          "id": "https://w3id.org/xapi/adl/verbs/waived",
          "display": {
            "en-US": "waived"
          }
        }
      }

    const base_activity = {
        "course": {
            "id": "https://contentenablers.net/compliancelearning/course/",
            "definition": { "name": { "en-US": "" }, "description": { "en-US": "" } }
        },
        "topic": {
            "id": "https://contentenablers.net/compliancelearning/topic/",
            "definition": { "name": { "en-US": "" }, "description": { "en-US": "" } }
        },
        "topicdone": {
            "id": "https://contentenablers.net/compliancelearning/topic-completed/",
            "definition": { "name": { "en-US": "" }, "description": { "en-US": "" } }
        },
        "video": {
            "id": "https://contentenablers.net/compliancelearning/video/",
            "definition": { "name": { "en-US": "" }, "description": { "en-US": "" } }
        },
        "interactivity": {
            "id": "https://contentenablers.net/compliancelearning/inteactivity/",
            "definition": { "name": { "en-US": "" }, "description": { "en-US": "" } }
        },
        "widget": {
            "id": "https://contentenablers.net/compliancelearning/widget/",
            "definition": { "name": { "en-US": "" }, "description": { "en-US": "" } }
        },
        "supplement": {
            "id": "https://contentenablers.net/compliancelearning/suppliment/",
            "definition": { "name": { "en-US": "" }, "description": { "en-US": "" } }
        },
        "update": {
            "id": "https://contentenablers.net/compliancelearning/update/",
            "definition": { "name": { "en-US": "" }, "description": { "en-US": "" } }
        },
        "cenote": {
            "id": "https://contentenablers.net/compliancelearning/cenote/",
            "definition": { "name": { "en-US": "" }, "description": { "en-US": "" } }
        },
        "txtcontent": {
            "id": "https://contentenablers.net/compliancelearning/txtcontent/",
            "definition": { "name": { "en-US": "" }, "description": { "en-US": "" } }
        },
        "interacted": {
          "id": "https://contentenablers.net/compliancelearning/interacted/",
          "definition": { "name": { "en-US": "" }, "description": { "en-US": "" } }
      },"scored": {
        "id": "https://contentenablers.net/compliancelearning/scored/",
        "definition": { "name": { "en-US": "" }, "description": { "en-US": "" } }
    }
    }

    return {
      base_verbs, base_activity, xapi_actions
    }


}

export default StmtElements;