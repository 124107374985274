import React from 'react'
import './login.scss'
import TextField from '@material-ui/core/TextField';
import { withStyles } from '@material-ui/core/styles';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import { green } from '@material-ui/core/colors';


const GreenCheckbox = withStyles({
    root: {
      color: green[400],
      '&$checked': {
        color: green[600],
      },
    },
    checked: {},
  })((props) => <Checkbox color="default" {...props} />);

const CssTextField = withStyles({
    root: {
        width: '100%',
      '& label': {
        color: 'rgb(141 141 141 / 54%)'
       },
      '& label.Mui-focused': {
        color: 'rgb(141 141 141 / 54%)'
      },
      '& label.MuiFormLabel-filled': {
        color: 'rgb(141 141 141 / 54%)',
        top: '0',
        marginTop: '-3px'
      },
      '& .MuiInputLabel-outlined': {
          fontSize: '14px',
          marginTop: "-6px"
      },
      '& .MuiOutlinedInput-root': {
          height: '40px',
          fontSize: '14px',
          color: '#000',
        '& fieldset': {
           fontSize: '14px'
        },
        '&:hover fieldset': {
          borderColor: '#e6e6e6',
        },
        '&.Mui-focused fieldset': {
          borderColor: '#e6e6e6',
        }
      }
    }
  })(TextField);

const Login = props => {
    const [state, setState] = React.useState({
        checkedG: false,
      });

    const handleChange = (event) => {
        setState({ ...state, [event.target.name]: event.target.checked });
    };
    return (
        <>
            <div className='login-container'>
                <h3 className='welcome-text'>Welcome to CELMS<sup>TM</sup></h3>
                <div className='login-form'>
                    <div className='form-main'>
                        <p className='version-text'>LMS v.2.0.1</p>
                        <form autoComplete="off" className='form-tag'>
                            <div className='email-input input-main'>
                                <CssTextField label="Username" variant="outlined" />
                            </div>
                            <div className='password-input input-main'>
                                <CssTextField label="Password" type='password' variant="outlined" />
                            </div>
                            <div className='checkbox-input'>
                                <FormControlLabel
                                    control={<GreenCheckbox checked={state.checkedG} onChange={handleChange} name="checkedG" />}
                                    label="Keep me signed in until I signed out"
                                />
                            </div>
                            <div className='submit-btn'>
                                <button className='login-btn'>LOG IN</button>
                                <p className='forgot-password'>Forgot Password ?</p>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </>
    )
}

export default Login
