import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import PlayerConstants from "../../../utils/PlayerConstants";
import DataHelper from "../../../utils/DataHelper";
import Instruction from "../../ui/Instruction/Instruction";
import ContentTracking from "../../../core/progress/ContentTracking";
import ImageTag from "../../ui/ImageTag";
import "../../../assets/scss/flipcard.scss";
import CommonModal from "../../ui/CommonModal/CommonModal";
import Carousels from "../Carousels/Carousels";

const UPDATE_STATUS = PlayerConstants.COMPONENT_CONSTANTS.UPDATE_STATUS;
/**
 *
 * @param {*} items
 * @returns array of items that will have image content description in object
 */

/**
 * ImageGallery used to flip card between front and back
 * which contains text and image
 */
const ImageGallery = (props) => {
  const {
    items,
    rowimg,
    track,
    handler,
    height,
    instructions,
    cename,
    tp_status,
  } = props;
  const contentLayout = document.querySelector(".content");
  const [status, setStatus] = useState({
    completed: false,
    text: PlayerConstants.COMPONENT_CONSTANTS.STATUS_INCOMPLETE,
  });
  const [onPreview, setOnPreview] = useState(null);
  const [selectedItem, setSelectedItem] = useState(0);


  /**
   * Manage the component update
   * progress logic in this method
   *
   * Update the view status when ever the user interacts
   * Update the progess status ONLY ONCE, when status completes
   *
   */
  const updateProgress = () => {
    /**
     * View status, will update while navigating to next topic
     */
    track.state = { ...track.state, c: 1 };
    /**
     * Progress updated as and when its completed
     */

    if (!track.status) {
      track.status = 1;
      if (track.status) {
        setStatus({
          completed: true,
          text: PlayerConstants.COMPONENT_CONSTANTS.STATUS_COMPLETE,
        });
        handler({
          type: UPDATE_STATUS,
          id: track.id,
          name: cename,
          isolated: true,
        });
      }
    }
     /**
     * If anything to be intimated always
     * call this
     */
    //  handler({ type: UPDATE_STATUS, id: track.id, name: props.cename })
  };
  
  contentLayout?.addEventListener("scroll", () => {
    DataHelper.scrollCompletionDebouncing(
      "section[name=ImageGallery]",
      updateProgress,
      true
    );
  });

  useEffect(() => {
    if (tp_status > -1) updateProgress();
  }, [tp_status]);

  const handleClick = (event, el, index) => {
    setSelectedItem(index || 0);

    setOnPreview(el);

    event.stopPropagation();
  };

  const handleClose = (event) => {
    setOnPreview(null);
    event.stopPropagation();
  };
  // making the prop to Carousels render
  const payload = {
    autoPlay: false,
    showArrows: true,
    isTextSeprate: true,
    showThumbs: false,
    showStatus: false,
    infiniteLoop: true,
    carousalData:
      items?.map((e) => {
        return {
          ...e,
          image: e?.img || "",
          description: `<span style="color:white;background-color:transparent">${e?.desc}</span>`,
          styles: { position: "center / contain no-repeat" },
        };
      }) || [],
    dynamicHeight: false,
    selectedItem: selectedItem,
    defaultHeight: 500,
  };

  return (
    <>
      <Instruction
        isInstruction={false}
        completed={track.status === 1 || status.completed}
        title={
          track.status === 1 || status.completed
            ? PlayerConstants.COMPONENT_CONSTANTS.STATUS_COMPLETE
            : PlayerConstants.COMPONENT_CONSTANTS.STATUS_INCOMPLETE
        }
        classText={`${
          (track.status === 1 || status.completed) &&
          PlayerConstants.COMPONENT_CONSTANTS.COMPLETED_CLASS
        }`}
      />
      <div className="flipcard-main-container image-gallery-modal-carousel">
        {onPreview && onPreview?.img?.trim() !== "*" && (
          <CommonModal
            isOpen
            className={'transparent-model'}
            onClose={handleClose}
            bgColor={"transparent"}
            CloseButton={false}
            content={
              <>
                <Carousels {...payload} />
              </>
            }
            width="65%"
            height="80%"
            shadow="none"
          />
        )}
        <div className={`gallery-grid g-col-${rowimg || 3}`}>
          {items &&
            items?.length > 0 &&
            items?.map((el, index) => {
              return (
                <div
                  // className={`c g-col-${rowimg || 3}`}
                  key={el.id}
                >
                  <div className="front">
                    <div
                      className="front-card-container"
                      // onClick={(e) => handleClick(index)}
                    >
                      <div className="flip-image">
                        <div onClick={(e) => handleClick(e, el, index)}>
                          <ImageTag
                            img={el?.img || "*"}
                            position={
                              el?.styles?.position ||
                              "center  / contain no-repeat"
                            }
                            styles={{
                              borderRadius: 10,
                              backgroundColor: "#ffffff",
                            }}
                            bgColor={el?.styles?.color || "rgba(0, 0, 0, 0)"}
                            maxHeight={height}
                            minHeight={height}
                          />
                        </div>
                        <div
                          className="text-content"
                          dangerouslySetInnerHTML={{ __html: el?.desc || "" }}
                        ></div>
                      </div>
                    </div>
                  </div>
                </div>
              );
              // return (
              //   <div
              //     className={`col-sm-12 col-md-4 col-lg-${rowimg || 3}`}
              //     key={el.id}
              //   >
              //     <div className="front">
              //       <div
              //         className="front-card-container"
              //         // onClick={(e) => handleClick(index)}
              //       >
              //         <div className="flip-image">
              //           <div onClick={(e) => handleClick(e, el, index)}>
              //             <ImageTag
              //               img={el?.img || "*"}
              //               position={
              //                 el?.styles?.position ||
              //                 "center  / contain no-repeat"
              //               }
              //               styles={{
              //                 borderRadius: 10,
              //                 backgroundColor: "#ffffff",
              //               }}
              //               bgColor={el?.styles?.color || "rgba(0, 0, 0, 0)"}
              //               maxHeight={height}
              //               minHeight={height}
              //             />
              //           </div>
              //           <div
              //             className="text-content"
              //             dangerouslySetInnerHTML={{ __html: el?.desc || "" }}
              //           ></div>
              //         </div>
              //       </div>
              //     </div>
              //   </div>
              // );
            })}
        </div>
      </div>
    </>
  );
};

ImageGallery.propTypes = {
  /** Items having array of image and back text content */
  items: PropTypes.array,
  /** Tracking the component progress */
  track: PropTypes.instanceOf(ContentTracking),
  /** Func description for tracking*/
  handler: PropTypes.func,
};

export default ImageGallery;
