import DataHelper from '../../utils/DataHelper'
import * as types from '../reducers/types'
import ContentTracking from "../../core/progress/ContentTracking";
import PlayerConstants from '../../utils/PlayerConstants';
import BuildHelper from '../../utils/BuildHelper';

const initialState = {
    courseId: -1,
    flatten: {},
    course: {},
    courseOverivew: [],
    courseAttributes: {},
    supplementaries: {},
    section: {},
    topic: {},
    nav: {},
    sideBar: true,
    isLoading: true,
    drawerData: { value: false, data: [], topic: '', width: '' },
    quiz: [],
    resumeQuiz: 0,
    videoCurrTime: 0,
    addon: [],
    transition:null
}

const courseInfoReducer = (state = initialState, action) => {
    switch (action.type) {
        case types.COURSE_INFO_REQ:
        case types.CLONE_LEARNING_AID_REQ:
        case types.SUPPLIMENTARIES_REQ:
        case types.CRS_DURATION:
            return {
                ...state,
                courseId: action.payload,
                isLoading: true,
            }
        case types.COURSE_INFO_RES:
            state.flatten={};
            state.topic={}
            iteratelist(action.payload.data.coursedata, 0, '', action.payload.topicdata, state.flatten , action?.payload?.data?.CfgClientComm )

            let tpKeys = Object.keys(state.flatten)
            let firstChild = tpKeys.find(c => {
                if (state.flatten[c].lrncontent) {
                    return state.flatten[c].lrncontent.length > 0
                }
            })
            // state.flatten[firstChild].status = 0

            return {
                ...state,
                courseOverivew: action.payload.data.coursedata,
                courseAttributes: action.payload.data.courseattributes,
                isLoading: false,
            }

        case types.SUPPLIMENTARIES_RES:
            let allSupplementaries = action?.payload?.data||{};
            let updates = [], vignettes = [], notes = [];
            if (allSupplementaries?.length > 0) {
                allSupplementaries?.forEach(el=>{
                    if(el?.stype===1) updates.push(el);
                    if(el?.stype===2) notes.push(el);
                    if(el?.stype===3) vignettes.push(el);
                })
             }
             allSupplementaries={
                updates: updates||[],
                vignettes: vignettes||[],
                notes: notes||[],
            }
            /**
             * If `config` uses `getConfigSupplementaries` to retrieve the core course IDs,
             * we then call the CE assets based on those IDs.
             */
             if(BuildHelper.isConfig() && !BuildHelper.isLearner()){
                allSupplementaries=action?.payload
             }
            return {
                ...state,
                supplementaries: {...allSupplementaries},
                isLoading:false
            }
        case types.SELECTED_SECTION:
            return {
                ...state,
                section: action.section
            }
        case types.SELECTED_TOPIC:
            let tp = {};
            tp = action.topic;
            /** 
             * Topic player (curator preview) get the topic id from parms and find the topic
             * Topicid from the launcher setting it in platform_scorm
             * From platform_scorm set the topicId
             */
            console.log('-------DataHelper.topicplayer()------> . ',DataHelper.topicplayer())
            if (DataHelper.topicplayer()) {
                try {
                    if (window?.ce?.platform_scorm?.topicId) {
                        let topicId = window?.ce?.platform_scorm?.topicId;
                        let flatten = state.flatten;
                        let node = {}
                        for (let key in flatten) {
                            if (flatten[key]?.id === topicId) {
                                console.debug("Topic found for preview====", flatten[key])
                                node = flatten[key];
                            }
                        }
                        if (state.flatten[node?.nodeId]) {
                            action.topic = state.flatten[node?.nodeId]
                            tp = action.topic
                        }
                    }
                } catch (e) {
                    console.log(e);
                }
            }

            // console.log(tp.status,"------!!!!-------> ",action)
            if(tp.status === -1  || tp.status === undefined)    {
                tp.status = 0;
                state.flatten[tp.nodeId].status = 0; 
            }
            else if (!!tp.status)  {

            }
            else    {
                // do nothing
            }
            //tp.status = 0;
            //state.flatten[tp.nodeId].status = 0; 

            return {
                ...state,
                nav: prevNextItems(action.topic, state.flatten, false),
                topic: tp
            }
        case types.SET_SIDEBAR:
            return {
                ...state,
                sideBar: action.value
            }
        case types.SET_DRAWER:
            return {
                ...state,
                drawerData: action.value,
            }
        case types.RESUME_QUIZ:
            return {
                ...state,
                resumeQuiz: action.value
            }

        case types.SAVE_COMMENT_RES:
            /**
             * Handle data based on variable names
             *  configuration and courses
             */
            let lrnid = action?.payload?.data?._id ;
            const hasCustomize=action?.payload?.data?.configid;
            if(hasCustomize) lrnid=action?.payload?.data?.lrnid;
            const logdata = state.topic.lrncontent.find((item) => {
                return lrnid === item._id
            })
            if(hasCustomize){
             action.payload.data.logs=action?.payload?.data?.comments?.map(log=>{return{...log,comments:log?.content}})
            }
            logdata.logs = [...(action?.payload?.data?.logs||[])]
            return {
                ...state,
            }
            break;
        case types.WYSIWYG_LEARNING_AID:
            let id = action.payload?.id
            const props = state?.topic?.lrncontent?.find((item) => {
                return id === item?._id
            })
           if(props)updateProps(props, action.payload.data)
            return {
                ...state,

            }
            break;
        case types.VIDEO_CURRENT_TIME:
            return {
                ...state,
                videoCurrTime: action.time
            }
        case types.TRANSLATION_JSON:
            return {
                ...state,
                transition: action?.payload
            }
        case types.SET_ASSIGNED_TEMPLATE:
            let new_tp = getUpdatedTopic(action?.payload, state.topic, state.flatten)
            return {
                ...state,
                topic: new_tp
            }
            break;
        case types.LEARNING_AID_ADDED_RES:
            let nlrns_topic = getUpdatedTopic(action?.payload, state.topic, state.flatten)
            return {
                ...state,
                topic: nlrns_topic,
                isLoading:false
            }
            break;
        case types.LEARNING_AID_REMOVED_RES:
            let rm_topic = { ...state.topic }
            if (action.payload?.status === 'success') {
                rm_topic.markup = action.payload?.data?.markup
                rm_topic.lrncontent = action.payload?.data?.lrncontent
                state.flatten[rm_topic.nodeId].markup = action.payload?.data?.markup
                state.flatten[rm_topic.nodeId].lrncontent = action.payload?.data?.lrncontent
                // console.debug("====",rm_topic.lrncontent.length, state.flatten[rm_topic.nodeId].lrncontent.length)
            }
            return {
                ...state,
                topic: rm_topic
            }
            break;
        /**
         * if Learning Aid is cloning very first time new topic will send as reponse 
         * if topic cloned already only learning aid update will happend then response will be topic
         * Topic response does not having the nodeId
         */
        case types.LEARNING_AID_CLONED_RES:
            let cloned_topic = { ...state.topic };
            if (action?.payload?.status === 'success') {
                console.debug("Topic Cloned ========", action.payload?.data?.topic)
                // clone,templateid, topicid,id these properties are updated in API
                let topic = action.payload?.data?.topic;
                cloned_topic.templateid = topic.templateid;
                cloned_topic.lrncontent = topic.lrncontent;
                cloned_topic.topic = topic.id;
                cloned_topic.id = topic.id;
                state.flatten[cloned_topic.nodeId] = cloned_topic;
                // console.debug("state.topic",state.topic,"cloned",topic)

            }

            return {
                ...state,
                topic: {...cloned_topic},
                isLoading:false
            }
            break;

        case types.SET_VIDEO_ADDON:
            let addonItems = [];
            if (action.payload?.status === 'success') {
                addonItems = action.payload?.data
            }

            return {
                ...state,
                addon: addonItems,
            }

            break;
        case types.UPDATE_TOPIC:
            let current_tp = { ...state.topic };
            if (action.payload?.status === 'success') {
                current_tp.title = action.payload?.data?.topicname;
                current_tp.duration = action.payload?.data?.duration;
                state.flatten[current_tp.nodeId]={...state.flatten[current_tp.nodeId],...current_tp};
            }

            return {
                ...state,
                topic: current_tp
            }
            break;

        case "UPDATE_MARKUP":
            let tempFlatten = { ...state.flatten };
            let tempTopic = { ...state.topic,markup:action.payload?.markups };
            tempFlatten[action.payload?.nodeId] = { ...tempFlatten[action.payload?.nodeId], markup: action.payload?.markups }
            return {
                ...state,
                flatten: {...tempFlatten},
                topic:{...tempTopic}
            }
            break;
        case types.RESPONSE_LRN_STATUS_IN_BULK:
            return {
                ...state,
                flatten: {...action.payload}
            }
        case types.UPDATE_CRS_DURATION:
           const duration=action.payload;
            let updateDuration= state.courseAttributes;
            updateDuration.duration=duration?.totalDuration;
            return {
                ...state,
                courseAttributes: {...updateDuration},
                isLoading: false
            }
        case types.ADD_NEW_LRN_IN_PUBLISH:
            let payload=action?.payload
            //constructing the add learning content payload for publish
            const updatePayload={
                status:"success",
                data:{...state.topic,
                    lrncontent:[...state.topic.lrncontent,payload?.data],
                    markup:payload?.markup||""
                }
            }
            let newPLrn = getUpdatedTopic(updatePayload, state.topic, state.flatten);
            return{
                ...state,
                topic: {...newPLrn},
                isLoading:false
            }
            break;
        case types.DELETE_LRN_IN_PUBLISH:
           let deletePayload=action.payload;
           // constructing the delete lrn content at the topic
           let deletedTopic=deleteLrnInStructure(deletePayload,state?.topic)
           state.flatten[state?.topic?.nodeId]=deletedTopic;
            return{
                ...state,
                topic:deletedTopic,
            }
        default:
            return state;
    }
}

const getUpdatedTopic = (payload, topic, flatten) => {
    let new_tp = { ...topic }
    if (payload.status === 'success') {
        new_tp.markup = payload?.data?.markup
        /** only for the configuration reponse will contain topic id
         * For core course dont replace topic id
         */
        if (!DataHelper.isCourse()) {
            new_tp.id = payload?.data?.id
        }
        new_tp.lrncontent = mapContentTracking(payload?.data?.lrncontent)
        flatten[new_tp.nodeId].markup = payload?.data?.markup
        flatten[new_tp.nodeId].lrncontent = mapContentTracking(payload?.data?.lrncontent)
    }
    return new_tp
}

//publish - Delete lrn from the topic structure  
const deleteLrnInStructure =(payload,tp)=>{
    let lrnList=tp?.lrncontent?.filter(e=>e?._id !== payload?.lrnid);
     return{
        ...tp, 
        markup : payload?.markup||"",
        lrncontent:lrnList
     }
}

/** 
 * Applied template of lrns items does not have content tracking 
 */

const mapContentTracking = (lrns) => {
    // we doing different compid creation  role_id 1 and 4 (now we following it same as while 1)
    try{
        const mapped = lrns.map((el, index) => {
            let cid =
            PlayerConstants.getComponentByName(el?.name)?.id +
            ":" +
            el?.compid;
             el.props['track'] = new ContentTracking(cid, 0, {})
             return el;
        });
        return mapped
    }catch(e){}
    // const mapped = lrns.map((el, index) => {
    //     el.props['track'] = new ContentTracking(`${index + ":" + el?.compid}`, 0, {})
    //     return el;
    // });
    // return mapped
}

const updateProps = (compProps, newProps) => {
    try{
    for (const key in newProps) {
        if (Object.hasOwnProperty.call(newProps, key)) {
            const newValue = newProps[key];
            compProps.props[key] = newValue;

        }
    }
  }catch(e){}
}


const prevNextItems = (node, flatten, isVisited) => {
    let navItem = {};
    const keys = Object.keys(flatten);
    const index = keys.indexOf(node.nodeId);
    navItem.prev = getItem(flatten, keys, index - 1);
    navItem.next = getItem(flatten, keys, index + 1);

    /**
     * Finds the nearest non-empty previous topic in a list starting from a given node index.
     * 
     * startIndex - The index to start searching from.
     * Recursively search for the previous non-empty topic
    */
    const findNonEmptyPrev = (startIndex) => {
        
        if (startIndex < 0) {  
            return null;
        }
        // Get the item at the current node.
        const prevItem = getItem(flatten, keys, startIndex);
        if (!DataHelper.checkEmptyTopic(prevItem?.lrncontent)) {
            return findNonEmptyPrev(startIndex - 1);
        }
        // if no non-empty previous topic is found
        return prevItem;
    };

    if(!DataHelper.checkEmptyTopic(navItem?.prev?.lrncontent) && !isVisited ){
        navItem.prev = findNonEmptyPrev( index - 1);
        return navItem;
    }
    return navItem;
};

/**
 * Flatten used to find the previous and next item
 * Look for topic content then return found item
 */

const getItem = (flatten, keys, index) => {
    let item = {};
    let found = false;
    let items = keys.slice(index, keys.length);
    items.forEach((key) => {
        if (!found) {
            if (flatten[key].lrncontent) {
                found = true;
                item = flatten[key];
            }
        }
    });
    return item;
};

const updateTpProgress = (ctopic, percentage, flatList) => {
    const flatten = Object.assign({}, flatList);
    flatten[ctopic.nodeId].p = percentage
    return flatten[ctopic.nodeId];
}


const iteratelist = (list, cnt, parent, topics, flat, CfgClientComm) => {
    DataHelper.listIterator(list, cnt, parent, topics, flat, CfgClientComm)
}


export default courseInfoReducer