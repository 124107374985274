import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import UploaderPanel from '../UploaderPanel/UploaderPanel';
import Datahelper from '../../../utils/DataHelper'
import RichTextEditor from '../RichTextEditor/RichTextEditor';
import Slider from '@mui/material/Slider';
import ACLHelper from '../../../utils/ACLHelper';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import InputLabel from '@mui/material/InputLabel';
import FormControl from '@mui/material/FormControl';
import './BackgroundImagePanel.scss';
// import TextField from '@material-ui/core/TextField';
// import { FormControlLabel, Radio, RadioGroup, FormLabel } from '@material-ui/core';
// import Box from '@mui/material/Box';
// import ContentCopyIcon from '@mui/icons-material/ContentCopy';


/**
 * 
 * BackgroundImage Panel editor panel used to modify the propertis
 */

const BackgroundImagePanel = ({ bgImageUrl, header, description, styleObj, handleProperties, imageFileName, backgroundPosition, background, textPosition, cename, id, opacity, backgroundColor, profileImage, padding, handleCeAssets }) => {
    const [data, setData] = useState({ bgImageUrl, header, description, styleObj, backgroundColor, imageFileName, opacity, padding, background, textPosition, backgroundPosition, profileImage })
    const isQuotes = cename === 'Quotes'

    const handleChange = (e) => {
        setData({
            ...data,
            [e.target.name]: e?.target?.value,
            bgImageUrl:bgImageUrl
        })
    }

    const quotesProps = (data) => {
        try {
            let props = {
                profileImage: data?.profileImage,
                quotes: data?.description,
                imagePosition: data?.imagePosition,
                backgroundObj: {
                    img: data?.bgImageUrl,
                    // size:data."cover",
                    color: data?.backgroundColor,
                    opacity: data?.opacity,
                    background: data?.background
                },
                styleObj: {
                    padding: data?.padding,
                    textPosition: data?.textPosition,
                    imageSize: data?.styleObj.imageSize,
                    imagePosition: data?.styleObj.imagePosition,
                },
            }
            return props;
        } catch (e) { }
    }

    const UpdateHandleProperties = (data) => {
        if (!isQuotes) {
            delete data?.profileImage
            handleProperties(data);
        } else {
            let props = quotesProps(data);
            handleProperties(props);
        }
    }

    useEffect(() => {
        UpdateHandleProperties(data)
    }, [data]);

    /**
    * 
    * @param {} value 
    * data html string used for description of the component
    */
    const eventHandlers = (value) => {
        setData({
            ...data,
            description: value
        })
        UpdateHandleProperties(data)
    }

    const handleMedia = (uploaded) => {
        try {
            let item = { ...data, bgImageUrl: uploaded.name };
            let props = item
            if (isQuotes) props = quotesProps(item);
            handleCeAssets(data?.bgImageUrl, uploaded?.name, props);
            handleProperties({...item});
            setData({...item});
        } catch (e) {
            console.debug(e);
        }
    }

    const handleProfileMedia = (uploaded) => {
        try {
            let item = { ...data, profileImage: uploaded?.name };
            let props = item;
            if (isQuotes) props = quotesProps(item);
            handleCeAssets(data?.profileImage, uploaded?.name, props);
            setData(item);
        } catch (e) { }
    }

    const imagePositionChange = (e) => {
        setData({
            ...data,
            background: e.target.value||""
        })
        UpdateHandleProperties(data)

    }
    // const backgroundSelection = [
    //     { name: '', value: 'select' },
    //     { name: 'Actual Size', value: 'center  / contain no-repeat' },
    //     { name: 'Place Left', value: ' left top / cover no-repeat' },
    //     { name: 'Place Right', value: 'right top / cover no-repeat' },
    //     { name: 'Spread', value: ' 100% 100% / cover no-repeat' },
    //     { name: 'Center & Spread', value: '50% 50% / cover no-repeat' },
    //     { name: 'Repeat', value: 'center / contain repeat' },
    // ]

    const textPositionChange = (value) => {
        setData({
            ...data,
            textPosition: value
        })
        UpdateHandleProperties(data)

    }

    const profileImagePositionChange = (value) => {
        setData({
            ...data,
            styleObj: {
                ...data?.styleObj,
                imagePosition: value
            }
        })
        UpdateHandleProperties(data)
    }

    const handleChangeImageSize = (e) => {
        setData({
            ...data,
            styleObj: {
                ...data?.styleObj,
                [e.target.name]: e.target.value
            }
        });
        UpdateHandleProperties(data)
    }

    return (
        <div className='topic-desc-prop-container'>
            <div className='row'>
                {ACLHelper.isFeatureEnabled(4, 2) &&
                    <>
                        <div className="col-lg-12 col-md-12 col-sm-12" >
                            <div className='col-lg-12 col-md-12 col-sm-12'>
                                <label className='label'>Browse Background Image</label>
                                <UploaderPanel accept={'image'} fileName={data?.bgImageUrl} name={`${id}_${Datahelper.getTime()}-image`} setUploadedData={handleMedia} styles={{background:data.background || "50% 50% / cover no-repeat",backgroundColor:backgroundColor||"rgba(7, 13, 89, 0.6)"}} bgChange={handleChange} pChange={imagePositionChange} />
                            </div>
                        </div>
                        {/* <div className="col-lg-6 col-md-12 col-sm-12 mt-2  mb-4">
                            <FormControl size='medium' >
                                <InputLabel className='label' id="demo-select-small">Image Position</InputLabel>
                                <Select
                                    labelId="demo-select-small"
                                    id="demo-select-small"
                                    value={data.background || "50% 50% / cover no-repeat" }
                                    label="Image Position"
                                    onChange={(e) => imagePositionChange(e)}
                                    className="dropdown"
                                >
                                    {backgroundSelection.map((item, idx) => <MenuItem key={idx} className='select-option-list' disabled={item.name.trim().length === 0} value={item.value}>{item.name}</MenuItem>)}

                                </Select>
                            </FormControl>
                        </div> */}
                        <div className='col-lg-6 col-md-12 col-sm-12 mt-2 mb-4'>
                            <FormControl size='medium' >
                                <InputLabel id="demo-select-small">Align Text</InputLabel>
                                <Select
                                    labelId="demo-select-small"
                                    id="demo-select-small"
                                    value={data?.textPosition}
                                    label="Align Text"
                                    onChange={(e) => textPositionChange(e.target.value)}
                                    className="dropdown"
                                >
                                    <MenuItem className='select-option-list' value='flex-start'>Top</MenuItem>
                                    <MenuItem className='select-option-list' value='center'>Middle</MenuItem>
                                    <MenuItem className='select-option-list' value='flex-end'>Bottom</MenuItem>

                                </Select>
                            </FormControl>
                        </div>

                        {/* <div className="col-lg-6 col-md-12 col-sm-12 mt-2">
                            <div className='topic-head-prop-background' >
                                <TextField type="color" id="outlined-basic" name="backgroundColor" label="Background Color" variant="outlined" defaultValue={backgroundColor||"#070d59"} onChange={handleChange} />
                            </div>
                        </div>
                        <div className="col-lg-3 col-md-12 col-sm-12" >
                            <label className='label' id="text-radio-buttons-group-label">Opacity</label>
                            <Slider className="ce-slider" value={Number(opacity)} name="opacity" onChange={handleChange} aria-label="Default" valueLabelDisplay="auto" step={0.10}
                                min={0.0}
                                max={1.0} />
                        </div> */}

                        <div className="col-lg-6 col-md-12 col-sm-12"  >
                            <label className='label' id="text-radio-buttons-group-label">Padding</label>
                            <Slider className="ce-slider" value={Number(padding) || 3} name="padding" onChange={handleChange} aria-label="Default" valueLabelDisplay="auto" step={1}
                                min={0}
                                max={10} />
                        </div>
                        {isQuotes && <>
                            <div className='col-lg-12 col-md-12 col-sm-12'>
                                <label className='label'>Browse Profile Image</label>
                                <UploaderPanel accept={'image'} fileName={data?.profileImage} name={`${id}_${Datahelper.getTime()}-image`} setUploadedData={handleProfileMedia} styles={false} />
                            </div>

                            <div className='col-lg-6 col-md-12 col-sm-12 mt-2 mb-4'>
                                <FormControl size='medium' >
                                    <InputLabel id="demo-select-small">ProfileImage Position</InputLabel>
                                    <Select
                                        labelId="demo-select-small"
                                        id="demo-select-small"
                                        value={data?.styleObj?.imagePosition}
                                        label="Align Text"
                                        onChange={(e) => profileImagePositionChange(e.target.value)}
                                        className="dropdown"
                                    >
                                        <MenuItem className='select-option-list' value='left'>Top Left</MenuItem>
                                        <MenuItem className='select-option-list' value='center'>Top Center</MenuItem>
                                        <MenuItem className='select-option-list' value='half'>left</MenuItem>
                                        <MenuItem className='select-option-list' value='half square'>square Left</MenuItem>
                                    </Select>
                                </FormControl>
                            </div>
                            <div className="col-lg-6 col-md-12 col-sm-12" >
                                <label className='label' id="text-radio-buttons-group-label">Profile Image Size</label>
                                <Slider className="ce-slider" value={Number(styleObj?.imageSize || 30)} name="imageSize" onChange={handleChangeImageSize} aria-label="Default" valueLabelDisplay="auto" step={10}
                                    min={30}
                                    max={130} />
                            </div></>}
                         </>
                         }
                <div className='col-lg-12 col-md-12 col-sm-12' >
                    <p className='description-text label-bold'>Description</p>
                    <RichTextEditor data={description} eventHandler={eventHandlers} />
                </div>
            </div>

        </div>
    )
}

BackgroundImagePanel.propTypes = {
    bgImageUrl: PropTypes.string,
    header: PropTypes.string,
    description: PropTypes.string,
    /** function handler used to save the edited props */
    handleProperties: PropTypes.func,
    /** handleCeAssets - This function handles the CE Library asset usage update actions ( previousAsset | newAsset | updatedProps ).  */
    handleCeAssets: PropTypes.func,
}

export default BackgroundImagePanel
