import * as types from '../reducers/types'

export function ceAssetsRequest(payload) {
    return {
        type : types.CE_ASSETS_LIBARARY_REQ,
        payload
    }
}

export function ceAssetsResponse(payload) {
    return {
        type : types.CE_ASSETS_LIBARARY_RES,
        payload
    }
}

export function ceAssetsWishlistRequest(payload) {
    return {
        type : types.CE_ASSETS_WISHLISTS_REQ,
        payload
    }
}

export function ceAssetsWishlistResponse(payload) {
    return {
        type : types.CE_ASSETS_WISHLISTS_RES,
        payload
    }
}

export function ceClientAssets(payload) {
    return {
        type : types.CE_CLIENT_ASSETS,
        payload
    }
}

export function ceAssetsLinkedRequest(payload) {
    return {
        type : types.CE_ASSETS_LINKED_REQ,
        payload
    }
}
export function ceAssetsLinkedResponse(payload) {
    return {
        type : types.CE_ASSETS_LINKED_RES,
        payload
    }
}


export function getArchiveCourseRequest() {
    return {
        type : types.GET_ARCHIVE_COURSES_REQ,
    }
}

export function setArchiveCourseResponse(payload) {
    return {
        type : types.GET_ARCHIVE_COURSES_RES,
        payload
    }
}

export function getArchiveSearchRequest(payload) {
    return {
        type : types.GET_ARCHIVE_SEARCH_REQ,
        payload
    }
}

export function getArchiveSearchCrcIdRequest(payload) {
    return {
        type : types.GET_SEARCH_BY_CID_REQ,
        payload
    }
}

export function setArchiveSearchCrcIdResponse(payload) {
    return {
        type : types.SET_SEARCH_BY_CID_RES,
        payload
    }
}

export function setArchiveSearchResponse(payload) {
    return {
        type : types.GET_ARCHIVE_SEARCH_RES,
        payload
    }
}
export function getQuizQuestionsRequest(payload) {
    return {
        type : types.GET_QUIZ_QUESTIONS_REQUEST,
        payload
        
    }
}
export function getQuizQuestionsReponse(payload) {
    return {
        type : types.GET_QUIZ_QUESTIONS_RESPONSE,
        payload
    }
}
export function getQuizAddQuestionsRequest() {
    return {
        type : types.GET_QUIZ_ADD_QUESTIONS_REQUEST,
        
    }
}
export function getQuizAddQuestionsReponse(payload) {
    return {
        type : types.GET_QUIZ_ADD_QUESTIONS_RESPONSE,
        payload
    }
}

export function getQuizUpdateQuestionsRequest() {
    return {
        type : types.GET_QUIZ_ADD_QUESTIONS_REQUEST,
        
    }
}
export function getQuizUpdateQuestionsReponse(payload) {
    return {
        type : types.GET_QUIZ_ADD_QUESTIONS_RESPONSE,
        payload
    }
}


export function addQuizInBankReq(payload) {
    return {
        type : types.ADD_QUIZ_QUESTION_REQ,
        payload
    }
}

export function addedQuizResponse(payload) {
    return {
        type : types.QUIZ_QUESTIONS_RES,
        payload
    }
}
