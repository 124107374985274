import * as types from '../reducers/types'


export const storeUserInfo = (info) => {
    return {
        type: types.STORE_USER_INFO,
        info
    }
}

export const userAclRequest = (info) => {
    return {
        type: types.USER_ACL_REQ,
        info
    }
}

export const userAclResponse = (info) => {
    return {
        type: types.USER_ACL_RES,
        info
    }
}