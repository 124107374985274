
import React, { useState } from 'react'
import './Attachments.scss'
import PropTypes from 'prop-types'
import ContentTracking from '../../../core/progress/ContentTracking';
import PlayerConstants from '../../../utils/PlayerConstants';
import Instruction from '../../ui/Instruction/Instruction';
import DataHelper from '../../../utils/DataHelper';


const UPDATE_STATUS = PlayerConstants.COMPONENT_CONSTANTS.UPDATE_STATUS
const SAVE_PROGRESS_STATUS = PlayerConstants.COMPONENT_CONSTANTS.SAVE_PROGRESS_STATUS

const Attachments = ( { items, track, handler, cename, instructions } ) => {

    const [ viewedList, setViewedList ] = useState( [] )
    const [ instruction, setInstruct ] = useState( instructions )
    const [ status, setStatus ] = useState( { completed: false, text: PlayerConstants.COMPONENT_CONSTANTS.STATUS_INCOMPLETE } )

    const getDocsUrl = (path)=>{
        return DataHelper.getResourcePath(6,path);
    }


    const handleClick = ( item ) =>{
        window.open(getDocsUrl(item?.path), '_blank');
        /**progress tracking start*/
        if ( viewedList.indexOf( item ) === -1 ) {
            viewedList.push( item )
        }
        setViewedList( [ ...viewedList ] );

        updateProgress()
        /** progress update end */
    };

    const updateProgress = () => {
        /**
         * View status, will update while navigating to next topic
         */
        track.state = { ...track.state, }
        /**
         * Progress updated as and when its completed
         */
        if ( !track.status ) {
            track.status = ( viewedList.length === items.length ) ? 1 : 0;
            if ( track.status ) {
                setInstruct( {
                    ...instruction,
                    text: `You have completed ${ cename }, you can proceed to next section`,
                    className: 'completed'
                } )
                setStatus( { completed: true, text: PlayerConstants.COMPONENT_CONSTANTS.STATUS_COMPLETE } )
                handler( { type: SAVE_PROGRESS_STATUS, id: track.id, name: cename } )
            }
        }
        /**
         * If anything to be intimated always
         * call this
         */
        handler( { type: UPDATE_STATUS, id: track.id, name: cename, isolated: true  } )

    }

    const getNameByExe = (type)=>{
        let types =  {
            "doc":"word",
            "pdf":"pdf",
            "xlsx":"xlsx",
            "ppt":"ppt",
            "pptx":"ppt",
            "xls":"xlsx",

        }
        return types[type] ? types[type] : 'word'
    }

    // default extension doc
    const getExtension = (item)=>{
        let ex = 'doc'
        try{
            ex = item?.path.split('.').pop();
        }catch(e){
            console.log(e)
        }
        return ex
    }


    return (
      <>
        <Instruction
          isInstruction={instruction?.enabled}
          completed={track.status === 1 || status.completed}
          title={
            track.status === 1 || status.completed
              ? PlayerConstants.COMPONENT_CONSTANTS.STATUS_COMPLETE
              : PlayerConstants.COMPONENT_CONSTANTS.STATUS_INCOMPLETE
          }
          classText={`${
            (track.status === 1 || status.completed) &&
            PlayerConstants.COMPONENT_CONSTANTS.COMPLETED_CLASS
          }`}
          text={
            track.status === 1 || status.completed
              ? PlayerConstants.COMPONENT_CONSTANTS.INSTRUCTIONS_PASSED
              : instruction?.text
          }
        />
        <div className="row attachment-items">
          {items &&
            items?.length > 0 &&
            items?.map((item) => {
              return (
                <div key={item.id}  className="file-list col-sm-12 col-md-12 col-lg-12">
                  <div className='file-item'>
                    <div  className="download-note" dangerouslySetInnerHTML={{ __html: item?.notes }}/>
                    <div className="icon-box">
                      <img 
                        src={require("../../../assets/img/" + getNameByExe(getExtension(item)) +".png").default} 
                        title={getNameByExe(getExtension(item))}
                        className="icons"
                        onClick={() => handleClick(item)}
                        role="button" 
                        tabIndex="9991" // Makes the image focusable
                        onKeyDown={(event) => {
                          if (event.key === "Enter" || event.key === " ") {
                            event.preventDefault(); // Prevents default scroll behavior for Space
                            handleClick(item); // Trigger the click handler
                          }
                        }}
                      />
                    </div>
                  </div>
                </div>
              );
            })}

        </div>
      </>
    );
}

Attachments.defaultProps = {
    instructions: {
        text: 'Please click the icons to download',
        enabled: false
    }
}



Attachments.propTypes = {
    /** items contains array of content text area */
    items: PropTypes.array.isRequired,
    /** Tracking the component progress */
    track: PropTypes.instanceOf( ContentTracking ),
    /** Func description for tracking*/
    handler: PropTypes.func
}

export default Attachments