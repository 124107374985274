import styled from 'styled-components'


export const ContainerHead = styled.div`
height: 100%;

`

export const Imgtag = styled.img`
    width: 100%;
    object-fit: contain;
    min-height: 400px;
    height: 100%;
    @media (max-width: 768px) {
        width: 97.5%;
    }
`

export const Links = styled.a`
    color: var(--primary-color-1);
    text-decoration: none;
    position: absolute;
    top: 95%;
    @media (max-width: 768px) {
        bottom: 0;
        margin: 10px 0
    }
`

export const SecondHeader = styled.h2`
    font-weight: bolder;
    margin-top: 15px;
    @media (max-width: 768px) {
        margin-top: 15px;
    }
`

export const SnippetDesc = styled.p`
    margin-top: 15px;
    line-height: var(--global-line-height);
    padding: 12%;
    color: #ffff;
    line-height: 2;
    font-size: 14px;
    @media (max-width: 768px) {
        margin-bottom: 35px;
        padding: 0 25px;
    }
`

