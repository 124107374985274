import React from 'react';
import PropTypes from 'prop-types';
import './scharcertificate.scss';
// import SocialShare from '../socialShare/SocialShare';
// import * as moment from 'moment-js';
/**
 * ScharCertificate Component
 * 
 * This component is used to display a certificate for a course completion.
 */
const ScharCertificate = ({ printRef, completedDate, userName, courseName, totalTime,cmoment, social }) => {
  return (
    <div className='shar-certificate-container'>
      <div className='schar-certificate' ref={printRef}>
        <div className='certificate'>
          <div className='crs-username-box'>
            <h1 className='schar-crs-username'>{userName}</h1>
          </div>
          <div className='crs-name-box'>
            {/* render HTML content in courseName */}
            <span className='schar-crs-name' dangerouslySetInnerHTML={{ __html: courseName }} />
          </div>
          <div className="totaltime">
            <p className="com-title">
              {`Completion Date: `}
              <span className="completed-date">{completedDate}</span>
            </p>
          </div>
          {/* {social && <SocialShare
            completedDate={completedDate}
            userName={userName}
            courseName={courseName}
            totalTime={cmoment}
            style={{ top: '0%' }}
            />
            } */}
        </div>
      </div>
    </div>
  );
}

//prop types for the component
ScharCertificate.propTypes = {
  printRef: PropTypes.oneOfType([
    PropTypes.func, 
    PropTypes.shape({ current: PropTypes.instanceOf(Element) })
  ]).isRequired, 
  completedDate: PropTypes.string.isRequired, // Completion date of the course
  userName: PropTypes.string.isRequired, // User's name
  courseName: PropTypes.string.isRequired, // Name of the course
  totalTime: PropTypes.string, // Total time spent on the course
};

//default prop values for the component
ScharCertificate.defaultProps = {
  totalTime: 'N/A', 
};

export default ScharCertificate;
