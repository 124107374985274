import React, { useState } from "react";
import { StackedCarousel, ResponsiveContainer } from 'react-stacked-center-carousel';
import Fab from '@material-ui/core/Fab';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import ArrowForwardIcon from '@material-ui/icons/ArrowForward';
import '../../../assets/scss/cardStack.scss'
import PropTypes from 'prop-types'
import "react-h5-audio-player/lib/styles.css";
import ContentTracking from "../../../core/progress/ContentTracking";
// import Tooltip from '@material-ui/core/Tooltip';
// import ErrorOutlineIcon from '@material-ui/icons/ErrorOutline';
import PlayerConstants from '../../../utils/PlayerConstants';
import Instruction from "../../ui/Instruction/Instruction";
import DataHelper from "../../../utils/DataHelper";
import { getPlaceHolderImg } from "../../../utils/MediaConfig";
import ImageTag from "../../ui/ImageTag";

/**
 * 
 * @param {*} items 
 * @returns Array of images which is used to map into carousel 
 */

/**
 * CardStackCarousel displays as stacked images above the images will have look feel as 3d carousel
*/
const CardStackCarousel = (props) =>{
    const {items,track,handler, instructions} = props
    const ref = React.useRef();
    const [index,setViewedIndex] = useState(0);
    const [viewedList,setViewedList] = useState([]);
    const [status, setStatus] = useState({completed: false, text: PlayerConstants.COMPONENT_CONSTANTS.STATUS_INCOMPLETE})
    const [instruction, setInstruct] = useState(instructions)


    const UPDATE_STATUS = PlayerConstants.COMPONENT_CONSTANTS.UPDATE_STATUS
    const SAVE_PROGRESS_STATUS = PlayerConstants.COMPONENT_CONSTANTS.SAVE_PROGRESS_STATUS
    
    const goToNextItem =()=>{
        if(items.length >= index){
            let cin = index;
            cin++;
            setViewedIndex(cin);
            setViewedList([...viewedList,index]);
            updateProgress(index)
        }
        ref.current.goNext()
    }

        /**
     * Manage the component update 
     * progress logic in this method
     * 
     * Update the view status when ever the user interacts
     * Update the progess status ONLY ONCE, when status completes
     * 
     */
         const updateProgress = (currentItem) => {

            /**
             * View status, will update while navigating to next topic
             */
            track.state = { ...track.state, c: currentItem }
            /**
             * Progress updated as and when its completed
             */
            try {
                if (!track.status) {
                    track.status = (viewedList.length === items.length) ? 1 : 0;
                    if (track.status) {
                        setInstruct({
                            ...instruction,
                            text: `You have completed this interactivity, you can proceed to next section`,
                            className: 'completed'
                          })
                        setStatus({completed: true, text:PlayerConstants.COMPONENT_CONSTANTS.STATUS_COMPLETE})
                        handler({ type: SAVE_PROGRESS_STATUS, id:track.id, name:props.cename })
                    }
                }
            } catch (error) {
                console.log('Carousal data error ', error)                
            }
            /**
             * If anything to be intimated always
             * call this
             */
             handler({ type: UPDATE_STATUS, id:track.id, name:props.cename})
             
        }

        const visibleSlide = () => {
            if(items?.length < 5) {
                return items.length
            } else {
                return 5
            }
        }

        updateProgress()
        
    return <>
            <Instruction isInstruction={instruction?.enabled} completed={(track.status === 1 || status.completed)} title={(track.status === 1 || status.completed) ? PlayerConstants.COMPONENT_CONSTANTS.STATUS_COMPLETE : PlayerConstants.COMPONENT_CONSTANTS.STATUS_INCOMPLETE} classText={`${(track.status === 1 || status.completed) && PlayerConstants.COMPONENT_CONSTANTS.COMPLETED_CLASS}`} text={(track.status === 1 || status.completed) ? PlayerConstants.COMPONENT_CONSTANTS.INSTRUCTIONS_PASSED : instruction?.text}/>
            <div className='stacked-container' style={{ width: '100%', position: 'relative' }}>
                <ResponsiveContainer carouselRef={ref} render={(parentWidth, carouselRef) => {
                            let currentVisibleSlide = 3;
                            if (parentWidth <= 1440) currentVisibleSlide = 3;
                            else if (parentWidth <= 1080) currentVisibleSlide = 1;
                            return (
                                <StackedCarousel
                                        ref={carouselRef}
                                        data={items}
                                        carouselWidth={parentWidth}
                                        fadeDistance={0.5}
                                        slideWidth={350}
                                        slideComponent={Slide}
                                        slidesToShow={2}
                                        maxVisibleSlide={3}
                                        currentVisibleSlide={3}
                                        useGrabCursor={true}
                                        isCenterSlide={false}
                                />
                )}}/>
            <Fab onClick={() => ref.current.goBack()} className='arrow-back'>
                <ArrowBackIcon />
            </Fab>
            <Fab onClick={() =>goToNextItem() } className='arrow-forward'>
                <ArrowForwardIcon />
            </Fab>
      </div>
        </>
}

const getImagePath = (path)=>{
    return DataHelper.getResourcePath(0,path);
}

const Slide = React.memo(
    function (props) {
        const { data, dataIndex } = props;
        if (data[dataIndex]) {
            var { cover,styles } = data[dataIndex]
        }
        return (
            <>
            {data[dataIndex] && cover && <div style={{width: '100%', height: 300, userSelect: "none"}} key={dataIndex}>
                <ImageTag 
                img={cover}
                position={styles?.background||"center  / contain no-repeat"}
                styles={{borderRadius: 10,backgroundColor:styles?.bg_color||"#ffff"}}
                bgColor={styles?.bg_color||"rgba(0, 0, 0, 0)"}
                minHeight={300}
                maxHeight={300}
                />
                
                {/* <img
                    style={{height: '100%', width: '100%', objectFit: 'contain', borderRadius: 10, background: '#ffffff'}}
                    draggable={false}
                    src={getImagePath(cover)}
                    alt={'card'+dataIndex}
                    onError={(e)=>{e.target.onerror = null; e.target.src=getPlaceHolderImg(200)} }
                /> */}
            </div>}
            </>
        )
    }
);

CardStackCarousel.defaultProps = {
    instructions: {
        text: 'Use the arrows to review each image to continue.',
        enabled: false
    }
}

CardStackCarousel.propTypes = {
    /** Items having array of images to render into it */
    items: PropTypes.array.isRequired,
    /** Tracking the component progress */
    track:PropTypes.instanceOf(ContentTracking),
    /** Func description for tracking*/
    handler:PropTypes.func
}

export default CardStackCarousel