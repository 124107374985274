import './scrollbutton.scss'; 
import { useState, useEffect, useCallback } from 'react';
import useDebounce from '../../../utils/useDebounce';
import KeyboardDoubleArrowUpIcon from '@mui/icons-material/KeyboardDoubleArrowUp';
import KeyboardDoubleArrowDownIcon from '@mui/icons-material/KeyboardDoubleArrowDown';

/**
 * ScrollButton Component
 * @param {Object} props - Component props
 * @param {string} props.position - Position of the button on the screen (default: 'bottom-right')
 * @param {Object} props.style - Additional styles for the button
 * @param {string} props.targetSelector - css selector for the target element to track scrolling (default: '.content')
 */

const ScrollButton = ({
  position = 'bottom-right',
  style = {},
  targetSelector = '.content',
}) => {
  const [isTopDirection, setIsTopDirection] = useState(false);
  const [scrollPercentage, setScrollPercentage] = useState(0);

  const handleScroll = useCallback(() => {
    try {
      const targetElement = document.querySelector(targetSelector);
      if (targetElement) {
        const currentScroll = targetElement.scrollTop;
        const totalHeight = targetElement.scrollHeight - targetElement.clientHeight;
        const scrolled = (currentScroll / totalHeight) * 100;

        if (scrolled !== scrollPercentage) {
          setIsTopDirection(scrolled >= 85); // Check if scrolled more than 85%
          setScrollPercentage(scrolled);

          const thresholdHeight = 953;
          if (targetElement.scrollHeight === thresholdHeight && targetElement.clientHeight === thresholdHeight) {
            setScrollPercentage(-1);
          }
        }
      }
    } catch (e) {
      console.error("Error in handleScroll:", e);
    }
  }, [targetSelector, scrollPercentage]);

  /**
   * Scroll the target element to the top or bottom smoothly
   */
  const scrollToTop = useCallback(() => {
    try {
      const targetElement = document.querySelector(targetSelector);
      if (targetElement) {
        const currentScroll = targetElement.scrollTop;
        targetElement.scrollTo({
          top: isTopDirection ? 0 : currentScroll + 600,
          behavior: 'smooth',
        });
      }
    } catch (e) {
      console.error("Error in scrollToTop:", e);
    }
  }, [targetSelector, isTopDirection]);

  // useDebounce hook to debounce the handleScroll function
  const debouncedScroll = useDebounce(handleScroll, 100);

  // useEffect hook to add and clean up scroll event listeners
  useEffect(() => {
    const targetElement = document.querySelector(targetSelector);
    if (targetElement) {
      targetElement.addEventListener('scroll', debouncedScroll);
      return () => {
        targetElement.removeEventListener('scroll', debouncedScroll);
      };
    }
  }, [targetSelector, debouncedScroll]);

  const buttonStyle = {
    ...style,
    color: `${(window?.ce?.theme)!=="ce_theme" ?'#fff':'var(--primary-color-2)'}`,
    //Dynamic button style, including a border that changes color based on scroll percentage
    border:`${0+(5-0)*(scrollPercentage / 100)}px solid rgba(255, 255, 255, 1)`//  // minBorderSize + (maxBorderSize - minBorderSize) * (scrollPercentage / 100);
  };

  return (
    scrollPercentage !== -1 && (
      <button
        className={`scroll-to-top-btn ${position} ${!isTopDirection && 'visible'}`}
        onClick={scrollToTop}
        style={buttonStyle}
      >
        {isTopDirection ? <KeyboardDoubleArrowUpIcon /> : <KeyboardDoubleArrowDownIcon />}
      </button>
    )
  );
};

export default ScrollButton;
