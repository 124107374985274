import React, {useEffect} from 'react'
import PropTypes from 'prop-types'
import CloseIcon from '@material-ui/icons/Close';
import './drawer.scss'
import TradeTutor from '../TradeTutor/TradeTutor';
import { useSelector, useDispatch } from 'react-redux';
import * as courseInfoAction from '../../../redux/actions/courseInfoAction'
import t from '../../../translation/useTranslate';


/**
 * Drawer pass the notesdata json statically for the sections including notes, vignettas and updates
 * will inject the sections dynamically when the user selects
*/

const Drawer = ({isEditorMode}) => {
    const dispatch = useDispatch()
    const isDraw = useSelector(state => state.courseInfo.drawerData)
    const userInfo = useSelector(state => state.userInfo)

    const toggleDrawer = (isOpened) => (event) => {
        if (
          event.type === "keydown" &&
          (event.key === "Tab" || event.key === "Shift")
        ) {
          return;
        }
        dispatch(courseInfoAction.setDrawer({...isDraw, value: isOpened, width: ''}))
      };
      const drawerRef = React.useRef(null);
      useEffect(() => {
        if (isDraw.value && drawerRef.current) {
          console.log("Drawer opened, attempting focus...");
          drawerRef.current.setAttribute("tabindex", "-1"); // Ensure focusable
          drawerRef.current.focus();
          console.log("Focus applied to drawer:", drawerRef.current);
        }
      }, [isDraw.value]);

      const getTopic=()=>{
        switch (isDraw.topic) {
            case "CourseMap":
                return t("COURSE_MAP"); 
            case "Certificate":
                return t("CERTIFICATE"); 
            default:
                return isDraw.topic;
        }
      }
    return (
        <div className='drawer-component' >
            <div className="overlay-container"  >
                <div id="overlay" className={`${isDraw.value ? "open" : ""}`} onClick={toggleDrawer(false)}></div>
                <div id="drawer-menu" className={`${isDraw.value ? "open" : ""}`} style={{width: isDraw.width}}>
                    <div className='ce-drawer-header' ref={drawerRef}>
                        <h3 className='ce-drawer-text-header'>{getTopic()}</h3>
                        <button className='ce-drawer-close-icon' onClick={toggleDrawer(false)}><CloseIcon /></button>
                    </div>
                    <div className={`ce-notes-main-ui ${isDraw.topic !== 'notes' ? isDraw.topic: ''}`}>
                        <TradeTutor topic={isDraw.topic}/>
                    </div>
                </div>
            </div>
        </div>
    )
}

Drawer.defaultProps = {
    /** Boolean  */
    isEditorMode: false
}

Drawer.propTypes = {
    /** Boolean value whether it should be displayed or not */
    isEditorMode: PropTypes.bool
}

export default Drawer
