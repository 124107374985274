import React from 'react';
import PropTypes from 'prop-types';
import SweetAlert from 'react-bootstrap-sweetalert';
import './customsweetalert.scss';

/**
 * Reusable SweetAlert component with custom options.
 * @param {boolean} warning - Determines if the alert has a warning icon.
 * @param {boolean} show - Controls the visibility of the alert.
 * @param {string} title - The title of the alert.
 * @param {function} onConfirm - Callback function triggered when the confirm button is clicked.
 * @param {string} confirmBtnText - Text for the confirm button.
 * @param {ReactNode} children - Content inside the alert.
 */
const CustomSweetAlert = ({ warning = false,closeOnClickOutside=false, showCancel=false, success=false , show, title = '', onConfirm, confirmBtnText = 'OK', children, onCancel,cancelBtnText='Cancel' }) => {
  const treeHeaderElement = document.querySelector('.tree-header');
const direction = treeHeaderElement
  ? getComputedStyle(treeHeaderElement).getPropertyValue('direction').trim()
  : 'ltr'; 
  return (
    <div style={{ direction }} className={`${direction}-custom-SweetAlert`}>
      <SweetAlert
        warning={warning}
        success={success}
        style={{ direction }} 
        showCancel={showCancel}
        show={show}
        closeOnClickOutside={closeOnClickOutside}
        title={title||''}
        onConfirm={onConfirm}
        confirmBtnText={confirmBtnText}
        onCancel={onCancel}
        cancelBtnText={cancelBtnText}
      // openAnim={{ name: 'showSweetAlert', duration: 1000 }}
      // closeAnim={{ name: 'hideSweetAlert', duration:300 }}
      // customButtons={ <React.Fragment>
      //   <button onClick={this.onCancel}>Cancel</button>
      //   <button onClick={this.onConfirm}>Info</button>
      //   <button onClick={this.onConfirm}>Confirm</button>
      // </React.Fragment>}
      >
      {children||<></>}
      </SweetAlert>
      </div>
    );
  };
  

CustomSweetAlert.propTypes = {
  warning: PropTypes.bool,
  show: PropTypes.bool.isRequired,
  title: PropTypes.string,
  onConfirm: PropTypes.func.isRequired,
  confirmBtnText: PropTypes.string,
  // children: PropTypes.node,
};

export default CustomSweetAlert;
