import React, { useState, useCallback, useEffect } from 'react';
import CustomCheckbox from '../CustomCheckbox/CustomCheckbox';
import RichTextEditor from '../../../editor/components/RichTextEditor/RichTextEditor';
import { TextField } from '@material-ui/core';
import CustomTextField from '../CustomTextField/CustomTextField';
import "./quizconfig.scss";
// import ResizableTextArea from '../ResizableTextArea/ResizableTextArea';
// import TextareaAutosize from '@mui/base/TextareaAutosize';

//define the glob handling global settings values 'min' and 'threshold'.
const QuizConfig = ({ configObj, handleConfig, max_select, glob, randomization, handleReset }) => {
  const [cofType, setCofType] = useState(1);
  const [data, setData] = useState({});

  useEffect(() => {
    // If form is not present, take default data
    setData(configObj || defaultConfig);
  }, [configObj])

  const handleProp = useCallback((e, type, mode) => {
    const updatedProps = { ...data };
    // types 0 - normal text | 1 - boolean check box | 2 - normal text array return || 3 normal number
    function setValue() {
      switch (type) {
        case 0:
          return { value: e?.target?.value || "" };
        case 1:
          return { value: e?.target?.checked || false };
        case 2:
          return { value: [e?.target?.value] || [""] };
        case 3:
          return { value: [e?.target?.value] || [""] };
        default:
          return {};
      }
    }

    try {
      switch (mode) {
        case 1:
          updatedProps.config[e.target.name] = { ...setValue() };
          break;
        case 2:
          updatedProps.validation.success.rule[e.target.name] = { ...setValue() };
          break;
        case 3:
          updatedProps.validation.fail.rule[e.target.name] = { ...setValue() };
          break;
        case 4:
          updatedProps.validation[e.target.name] = { ...setValue() };
          break;
        default:
          return null;
      }
      setData({ ...updatedProps });
      handleConfig({ ...updatedProps });
    } catch (e) {
      console.error(e);
    }
  }, [data]);

  const handleValidation = (e) => {
    try {
      const updatedProps = { ...data };
      updatedProps.validation[e.target.name] = e.target.value || "";
      setData({ ...updatedProps });
      handleConfig({ ...updatedProps });
    } catch (e) { }
  }


  const renderQuizConfiguration = () => {
    switch (cofType) {
      case 1:
        return <QuizOptions config={data?.config} handleProp={handleProp} cofType={1} glob max_select={max_select} randomization={randomization} />;
      case 2:
        return <QuizSuccess success={data?.validation?.success} handleProp={handleProp} cofType={2} />;
      case 3:
        return <QuizFailure fail={data?.validation?.fail} handleProp={handleProp} cofType={3} />;
      default:
        return null;
    }
  };

  const valuePresentCheck = (val, alt) => {
    if (val || val === false || val==='') {
      return val
    }
    return alt
  }

  const clearQuizData=()=>{
    handleReset();
    handleConfig({ ...defaultConfig });
  }

  return (
    <div className='quiz-config-container'>
      <div className='clear-quiz'><span onClick={()=>clearQuizData()}>Clear Quiz Settings</span></div>
      <div className='attempt-options'>
        <div className='attempt-option'>
          <CustomTextField
            id="outlined-textarea"
            label="Max attempt"
            variant="outlined"
            value={data?.validation?.attempt || null}
            name="attempt"
            onChange={(e) => handleValidation(e, 0, 3)}
            maxLength={30}
            type="number"
            placeholder="Enter Custom Text Field"
          />
        </div>
        <div className='attempt-option'>
          <CustomTextField
            id="outlined-textarea"
            label="Min score"
            variant="outlined"
            value={valuePresentCheck(data?.validation?.min, glob?.min) ||null}
            name="min"
            onChange={(e) => handleValidation(e, 0, 4)}
            maxLength={100}
            type="number"
            placeholder="Enter Custom Text Field"
          />
        </div>
      </div>
      <div className="btn-group inner-btng" role="group" aria-label="Basic example">
        <button type="button" className={`${cofType === 1 && 'active '} btn-g`} onClick={() => setCofType(1)}>Options</button>
        <button type="button" className={`${cofType === 2 && 'active '} btn-g`} onClick={() => setCofType(2)}>Success</button>
        <button type="button" className={`${cofType === 3 && 'active '} btn-g`} onClick={() => setCofType(3)}>Failure</button>
      </div>
      {renderQuizConfiguration()}
    </div>
  )
}


const valuePresentCheck = (val, alt) => {
  if (val || val === false) {
    return val
  }
  return alt
}

const QuizOptions = ({ config, handleProp, cofType, max_select, randomization, glob }) => (
  <>
    <div className='quiz-upper-container'>
      <CustomCheckbox
        label={'Randomize'}
        checked={valuePresentCheck(config?.rand_ques?.value, randomization) || false}
        name="rand_ques"
        onChange={(e) => handleProp(e, 1, cofType)}
      />
      {valuePresentCheck(config?.rand_ques?.value, randomization) &&
        <div className='question-text label'>
          only
          <TextField
            id="filled-number"
            type="number"
            className="max-questions"
            InputLabelProps={{ shrink: true }}
            InputProps={{
              inputProps: {
                max: 50 || 1,
                min: 0
              }
            }}
            value={valuePresentCheck(config?.sel_ques?.value, max_select)}
            variant="filled"
            name='sel_ques'
            onChange={(e) => handleProp(e, 3, cofType)}
          />
          questions
        </div>
      }
      <CustomCheckbox
        label={'Auto check'}
        checked={config?.ans_dtype?.value || false}
        name="ans_dtype"
        onChange={(e) => handleProp(e, 1, cofType)}
      />
      <CustomCheckbox
        label={'Quiz pass threshold'}
        checked={valuePresentCheck(config?.ques_thrash?.value, glob.strict) || false}
        name="ques_thrash"
        onChange={(e) => handleProp(e, 1, cofType)}
      />
      <CustomCheckbox
        label={'Accept only right answer'}
        checked={config?.acpt_only_corct_ans?.value || false}
        name="acpt_only_corct_ans"
        // className="px-1"
        onChange={(e) => handleProp(e, 1, cofType)}
      />
      <div className='attempt-options'>
        {config?.ans_dtype?.value && <div className='attempt-option'>
          <CustomTextField
            id="outlined-textarea"
            label="show answer attempt"
            variant="outlined"
            value={config?.correct_ans?.value}
            name="correct_ans"
            onChange={(e) => handleProp(e, 0, cofType)}
            maxLength={10}
            type="number"
            placeholder="Enter Custom Text Field"
          />
        </div>}
      </div>

    </div>
    {/* {config?.acpt_only_corct_ans?.value && <div className='attempt-option py-2'>
          <CustomTextField 
              id="outlined-textarea"
              label="Question Try Count"
              variant="outlined"
              value={config?.ques_tcnt?.value||""}
              name="ques_tcnt"
              onChange={(e)=>handleProp(e,0,cofType)}
              maxLength={10}
              type="number"
              placeholder="Enter Custom Text Field"
            />
          </div> } */}
    {/* <div className='report-card'>
      <label className='label' >Client Message :</label>
      <RichTextEditor
        styles={{ minHeight: 80 }} 
        data={config?.card_msg?.value||""}
        eventHandler={(e) => handleProp({target:{name:"card_msg",value:e}},0,cofType)}
        />
      </div> */}
  </>
);

const QuizSuccess = ({ success, handleProp, cofType }) => (
  <>
    <div className='quiz-upper-container'>
      <CustomCheckbox
        label={'Lock Quiz '}
        checked={success?.rule?.lock?.value || false}
        name="lock"
        onChange={(e) => handleProp(e, 1, cofType)}
      />
      <CustomCheckbox
        label={'Send Reponses In Mail'}
        checked={success?.rule?.send_respmail?.value || false}
        name="send_respmail"
        onChange={(e) => handleProp(e, 1, cofType)}
      />
      <CustomCheckbox
        label={'Send Mail To User On Attempt'}
        checked={success?.rule?.send_atmptmail_touser?.value || false}
        name="send_atmptmail_touser"
        onChange={(e) => handleProp(e, 1, cofType)}
      />
    </div>
    <div className='py-2'>
      <CustomTextField
        id="outlined-textarea"
        label="Mail Information"
        variant="outlined"
        value={success?.rule?.mail?.value}
        name="mail"
        onChange={(e) => handleProp(e, 0, cofType)}
        // maxLength={15}
        type="text"
      />
    </div>
    <div className='report-card'>
      <label>Report card message</label>
      <RichTextEditor
        styles={{ minHeight: 80 }}
        data={success?.rule?.card_msg?.value || ""}
        eventHandler={(e) => handleProp({ target: { name: "card_msg", value: e } }, 0, cofType)}
      />
    </div>
  </>
);

const QuizFailure = ({ fail, handleProp, cofType }) => (
  <>
    <div className='failure-at'>
      <CustomCheckbox
        label={'Send Responses In Mail'}
        checked={fail?.rule?.send_respmail?.value || false}
        name="send_respmail"
        onChange={(e) => handleProp(e, 1, cofType)}
      />
      <CustomCheckbox
        label={'Send Mail To User On Attempt'}
        checked={fail?.rule?.send_atmptmail_touser?.value || false}
        name="send_atmptmail_touser"
        onChange={(e) => handleProp(e, 1, cofType)}
      />
      <CustomCheckbox
        label={'Lock course'}
        checked={fail?.rule?.lock_crs?.value || false}
        name="lock_crs"
        onChange={(e) => handleProp(e, 1, cofType)}
      />
    </div>
    <div className='attempt-options'>
      <div className='attempt-option'>
        <CustomTextField
          id="outlined-textarea"
          label="Reset course on attempts"
          variant="outlined"
          value={fail?.rule?.rcoa?.value}
          name="rcoa"
          onChange={(e) => handleProp(e, 0, cofType)}
          // maxLength={15}
          type="comma-number"
          placeholder="Comma separated values"
        />
      </div>
      <div className='attempt-option'>
        <CustomTextField
          id="outlined-textarea"
          label="Mail template id's"
          variant="outlined"
          value={fail?.rule?.mail?.value}
          name="mail"
          onChange={(e) => handleProp(e, 0, cofType)}
          // maxLength={15}
          type="comma-number"
          placeholder="Comma separated values"
        />
      </div>
    </div>
    <div className='report-card'>
      <label>Report card message</label>
      <RichTextEditor
        styles={{ minHeight: 80 }}
        data={fail?.rule?.card_msg?.value ?? ""}
        eventHandler={(e) => handleProp(({ target: { name: "card_msg", value: e } }), 0, cofType)}
      />
    </div>
    {/* <div className='attempt-option'>
              <CustomTextField 
                id="outlined-textarea"
                label="Reset quiz on attempts"
                variant="outlined"
                value={fail?.rule?.rqoa?.value}
                name="rqoa"
                onChange={(e)=>handleProp(e,0,cofType)}
                // maxLength={15}
                type="comma-number"
                placeholder="Comma separated values"
              />
            </div>
            <div className='attempt-option'>
            <CustomTextField 
                id="outlined-textarea"
                label="Lock attempt no"
                variant="outlined"
                value={fail?.rule?.lock?.value}
                name="lock"
                onChange={(e)=>handleProp(e,0,cofType)}
                maxLength={10}
                type="number"
                placeholder="Comma separated values"
              />
            </div> */}
  </>
);


export const defaultConfig = {
  "config": {
    "no_ques": {
      // "value": 3
    },
    "sel_ques": {
      // "value": 3
    },
    "rand_ques": {
      // "value": true
    },
    "acpt_only_corct_ans": {
      // "value": true
    },
    "ques_tcnt": {
      // "value": 2
    },
    "ques_thrash": {
      // "value": 2
    },
    "ans_dtype": {
      // "value": "2"
    },
    "correct_ans": {
      "value": [
        // "22"
      ]
    },
    "card_msg": {
      // "value": "testing"
    }
  },
  "type": 1,
  "validation": {
    "attempt": null,
    "success": {
      "rule": {
        "mail": {
          "value": []
        },
        "client_msg": {
          "value": ""
        },
        "lock": {
          "value": false
        },
        "send_respmail": {
          "value": false
        },
        "send_atmptmail_touser": {
          "value": false
        }
      }
    },
    "fail": {
      "rule": {
        "rqoa": {
          "value": []
        },
        "rcoa": {
          "value": []
        },
        "mail": {
          "value": ""
        },
        "lock": {
          "value": ""
        },
        "save_score": {
          "value": false
        },
        "send_respmail": {
          "value": false
        },
        "send_atmptmail_touser": {
          "value": false
        },
        "client_msg": {
          "value": ""
        },
        "lock_crs": {
          "value":false
        }
      },
      "options": {
        "ans_disp": 1
      }
    },
    "min": null
  }
}



export default QuizConfig