import { useState, useEffect } from "react";
import useForceUpdate from 'use-force-update';
import ReactCardFlip from "react-card-flip";
import UndoIcon from '@material-ui/icons/Undo';
import RedoIcon from '@material-ui/icons/Redo';
import './coursecardflip.scss'
import PropTypes from 'prop-types'
import { getPlaceHolderImg, pathCreater } from "../../../utils/MediaConfig";
import ContentTracking from "../../../core/progress/ContentTracking";
import Tooltip from '@material-ui/core/Tooltip';
import ErrorOutlineIcon from '@material-ui/icons/ErrorOutline';
import PlayerConstants from '../../../utils/PlayerConstants';
import Instruction from "../../ui/Instruction/Instruction";
import DataHelper from "../../../utils/DataHelper";
import ImageTag from "../../ui/ImageTag";
import t from "../../../translation/useTranslate";
import BuildHelper from "../../../utils/BuildHelper";


/**
 * 
 * @param {*} items 
 * @returns array of items that will have  front image and back content description in object 
 */

/**
 * CourseCardFlip used to flip card between front and back 
 * which contains text and image
*/
const CourseCardFlip = (props) => {
  const { items, track, handler, instructions, cename } = props
  const forceUpdate = useForceUpdate()
  // const [Flipped, setFlipped] = useState(items);
  const [viewedList, setViewedList] = useState([])
  const [status, setStatus] = useState({completed: false, text: PlayerConstants.COMPONENT_CONSTANTS.STATUS_INCOMPLETE})
  const [instruction, setInstruct] = useState(instructions)
  const [Flipped, setFlipped] = useState(items);

  const UPDATE_STATUS = PlayerConstants.COMPONENT_CONSTANTS.UPDATE_STATUS;
  const SAVE_PROGRESS_STATUS = PlayerConstants.COMPONENT_CONSTANTS.SAVE_PROGRESS_STATUS;

  const handleClick = (index) => {
    Flipped[index].flipped = !Flipped[index].flipped
    // setFlipped(Flipped)
    forceUpdate();

    /** progress update start */
    if (viewedList.indexOf(Flipped[index].id) === -1) {
      viewedList.push(Flipped[index].id)
    }
    setViewedList([...viewedList]);
    updateProgress(index)
    /** progress update end */

  }

  useEffect(()=>{
    let data =items;
    if(!track.status && BuildHelper.isLearner() && items?.some(e=>e?.flipped)){
      data = items?.map(e=>{return{...e,flipped:false}})
    }
   setFlipped(data)
  },[items])

  /**
       * Manage the component update 
       * progress logic in this method
       * 
       * Update the view status when ever the user interacts
       * Update the progess status ONLY ONCE, when status completes
       * 
       */
  const updateProgress = (currentItem) => {

    /**
     * View status, will update while navigating to next topic
     */
    track.state = { ...track.state, c: currentItem }
    /**
     * Progress updated as and when its completed
     */
    if (!track.status) {
      track.status = (viewedList.length === Flipped.length) ? 1 : 0;
      if (track.status) {
        setInstruct({
          ...instruction,
          text: `You have completed ${cename}, you can proceed to next section`,
          className: 'completed'
        })
        setStatus({completed: true, text:PlayerConstants.COMPONENT_CONSTANTS.STATUS_COMPLETE})
        handler({ type: SAVE_PROGRESS_STATUS, id: track.id, name: props.cename })
      }
    }
    /**
     * If anything to be intimated always
     * call this
     */
    handler({ type: UPDATE_STATUS, id: track.id, name: props.cename })

  }

  // useEffect(() => {
  //   console.debug("Course Card Items",items)
  // }, [items])

  return <>
  <Instruction isInstruction={instruction?.enabled} completed={(track.status === 1 || status.completed)} title={(track.status === 1 || status.completed) ? PlayerConstants.COMPONENT_CONSTANTS.STATUS_COMPLETE : PlayerConstants.COMPONENT_CONSTANTS.STATUS_INCOMPLETE} classText={`${(track.status === 1 || status.completed) && PlayerConstants.COMPONENT_CONSTANTS.COMPLETED_CLASS}`} text={(track.status === 1 || status.completed) ? PlayerConstants.COMPONENT_CONSTANTS.INSTRUCTIONS_PASSED : instruction?.text}/>
  <div className='cardflip-main-container'>
    <div className='row'>
      {Flipped && Flipped?.length > 0 && Flipped?.map((el, index) => {
        return <div className="col-sm-12 col-md-12 col-lg-4" key={el.id} onClick={(e) => handleClick(index)}>
          <ReactCardFlip
            isFlipped={el.flipped}
            className='cardFlip'
          >
            <div className="front" >
              <div className='front-card-container' >
                <div className='flip-image'>
                  <div className="flip-img-tag">
                     {/* <img  style={{objectFit:el?.imageFit || 'contain' }} src={DataHelper.getResourcePath(0,el.frontImage)} alt="front" onError={(e)=>{e.target.onerror = null; e.target.src=getPlaceHolderImg(200)} } /> */}
                     <ImageTag
                    img={el?.frontImage}
                    bgColor={el?.color||""}
                    position={String(el?.imageFit).includes("/") ? el?.imageFit : `center  / ${el?.imageFit||"contain"} no-repeat`}
                    maxHeight={250}
                    minHeight={250}
                    //  opacity={opacity}
                    />
                  </div>
                  <div className='middle-content-title'>
                    <p className='para-line'></p>
                    <p className='main-title'>{el.title}</p>
                  </div>
                  <button className='flip-next' data-count={index}><RedoIcon />{t("MORE")}</button>
                </div>
              </div>
            </div>
            <div className="back" >
              <div className='backside-flip-container'>
                <div className='flip-back-text'>
                  <div className='back-content'>
                    {/* <h2 className='title-heading'>{el.title}</h2> */}
                    <div className='description' dangerouslySetInnerHTML={{__html: el.description}}></div>
                  </div>
                  <button className='flip-back' data-count={index}><UndoIcon />{t("BACK")}</button>
                </div>
              </div>
            </div>

          </ReactCardFlip>
        </div>
      })}
    </div>
  </div>
  </>
}

CourseCardFlip.defaultProps = {
  instructions: {
    text: 'Click on each card for more information.',
    enabled: false
  }
}

CourseCardFlip.propTypes = {
  /** Items having array of front card image and back text content */
  items: PropTypes.array,
  /** Tracking the component progress */
  track: PropTypes.instanceOf(ContentTracking),
  /** Func description for tracking*/
  handler: PropTypes.func
}

export default CourseCardFlip