import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import PropTypes from 'prop-types'
import LinearProgress  from '../LinearProgress/LinearProgress';
import EditIcon from '@material-ui/icons/Edit';
import * as courseEditAction from '../../../redux/actions/courseEditAction'
import DataHelper from '../../../utils/DataHelper';
import styled from 'styled-components'
import BuildHelper from '../../../utils/BuildHelper';

/**
 * Headertopic component will be shown on the top where each topic title shown
*/


const HeaderTopic = () => {
    const courseInfo = useSelector(state => state.courseInfo);
    const courseTrack = useSelector(state => state.courseTracking)
    const userInfo = useSelector(state => state.userInfo);
    const globalStyle = useSelector(state => state.globalStyle);
    const [style,setStyle] = useState(globalStyle?.topic);
    const [progress, setProgress] = useState(0);
    const [isEditEnabled,setEditEnabled] = useState(false);

    const dispatch = useDispatch()
    /**
     * Calculates the completion progress of a specific topic within course content.
     * @param {*} content - The content object containing course tracking information.
     * @returns 
     */
    const contentProgress = ( content ) => {
        try{
            // Return 0 if content or courseTracking is not provided
            if (!content || !content.courseTracking) return 0;
            const { courseTracking: { topic } } = content;
            const findTopic = content?.courseTracking?.topic?.find(el => el?.topicId === courseInfo?.topic?.id)
            // Return 0 if the specific topic or its content is not found
            if (!findTopic || !findTopic.content) return 0;
            const completedContent = findTopic.content.filter(el => el.status === 1).length;
            const totalContent = findTopic.content.length;
            // Return the completion progress percentage
            return (completedContent / totalContent) * 100;
        }catch(e){}
      }

      const sliderProgress = () => {
        setTimeout(() => {
            if(courseTrack.courseTracking) {
                let p = contentProgress(courseTrack);
                setProgress( p );
            }
          }, 300)
      }

    useEffect(() => {
        if(BuildHelper.isLearner()){
            const content = document.querySelector('.content')
            content?.addEventListener('scroll', sliderProgress)
    
            return () => {
                content?.removeEventListener('scroll', sliderProgress)
            }
        }
    }, [])

    const handleTopicEdit = () =>{
        setEditEnabled(true);
        let panelObj = {
            name:"Edit Topic"
        }
        dispatch( courseEditAction.toggleDrawer( { open: true, target:{}, itemInfo:panelObj, panel:8, drawerWidth:25 } ) )
    }

    /** Role ID 
     * 1- is Editor
     * 4- Player
      */
    const isEditorMode =()=>{
        return Number(userInfo?.user?.role_id) === 1 && DataHelper.isCourse() ? true:false
    }


    useEffect(()=>{
        setStyle(globalStyle?.topic);
    },[globalStyle])

    return (
        <>
        <div className='header-div'>
            <Heading fontSize={style?.fs} color={style?.color}>
            {!isEditorMode() && courseInfo?.topic?.title  }
            {isEditorMode() && <div> {courseInfo?.topic?.title } 
                <EditIcon className='edit-prop-accordion' onClick={() => handleTopicEdit()}/>
            </div> }
            </Heading>
            <BottomLine></BottomLine>
        </div>
        {/* {DataHelper.topicplayer()+'---------'} */}
        {/* {!DataHelper.topicplayer() && <LinearProgress progress={progress}/>} */}
        <LinearProgress progress={100}/>
        </>
    )
}

    // header-title 
   export const Heading = styled.h1`
        width: 90%;
        font-size:${(props) => props?.fontSize}px;
        color: ${(props) => props?.color};
        font-weight: 700;
        font-family: var(  --global-font-family-medium);
        margin-top: 7px;
        
    `
    export  const BottomLine = styled.p`
    width: 75px;
    margin-top: 15px;
`
    

HeaderTopic.propTypes = {
    /** CourseInfo will fetch the title and displayed in this component */
    courseInfo: PropTypes.object
}

export default HeaderTopic