import React, { useEffect, useRef, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import * as courseEditAction from '../../../redux/actions/courseEditAction'
import TextField from '@material-ui/core/TextField';
import TEMPLATE_MODEL from '../../../utils/TemplateModel';
import LearnAidItem from '../../../utils/LearnAidItem';
import Accordion from '@material-ui/core/Accordion';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import Typography from '@material-ui/core/Typography';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import './searchlrnaids.scss'
import ReactDom from "react-dom";
import ContentTracking from '../../../core/progress/ContentTracking';
import PlayerConstants from '../../../utils/PlayerConstants';
import CloseIcon from '@material-ui/icons/Close';
import CustomSweetAlert from '../../../components/ui/CustomSweetAlert/CustomSweetAlert'


const SearchLearningAids = ({ data }) => {

    
    const dispatch = useDispatch();
    const courseEdit = useSelector(state => state.courseEdit);
    const [selected, setSelected] = useState({ course: { _id: "0", coursename: '' }, type: 0, qry: '' })
    const [isAddConfirm, setIsAddConfirm] = useState(false);
    const [searchResults, setSearchResult] = useState([]);
    const [expanded, setExpanded] = React.useState(0);
    const courseInfo = useSelector(state => state.courseInfo);
    const [selectedLElement,setSelectedLElement] = useState({})
    const previewRef = useRef();
    const [preview,setPreview] = useState({});
    const [crcList,setCrcList] = useState([]);
    const [isLegacy, setCIsLegacy] = useState(data.name === "Legacy interactivity");

    useEffect(() => {
        setSearchResult(courseEdit.searchListLrns);
    }, [courseEdit.searchListLrns])

    useEffect(() => {
        setCIsLegacy(data.name === "Legacy interactivity");
        if (data.name !== "Legacy interactivity") setCrcList(courseEdit.courseList);
        setSelectedLElement({});
    }, [courseEdit?.courseList,data]);

    useEffect(() => {
        if (Object.keys(selectedLElement).length > 0) {
            previewLearningAid(selectedLElement)
        }
    }, [selectedLElement])

    let lrlist = [];
    let exclusion = [20, 22, 8]
    if (courseEdit.courseList && courseEdit.courseList.length > 0) {
        const list = TEMPLATE_MODEL;
        for (var key in list) {
            let obj = { ...list[key], id: Number(key), status: 2, name:(PlayerConstants?.LRN_RE_NAME[list[key]?.name]||list[key]?.name) }
            if (!exclusion?.find(item => { return key == item })) {
                lrlist.push(new LearnAidItem(obj))
            }
        }
    }
    const lrnaidList = lrlist.sort((a, b) => a.name.localeCompare(b.name))

    const searchLearningElements = (e) => {
        console.log(selected)
        dispatch(courseEditAction.searchLearningAids({ cid: selected.course._id, lrname: selected.type, searchstr: selected.qry }))
    }

    const lrnaidSelectHandler = (e) => {
        setSelected({ ...selected, type: e.target.value })
    }
    const handleQueryInput = (e) => {
        setSelected({ ...selected, qry: e?.target?.value?.replace(/^\s+/, '') })
    }
    const onCourseChange = (e) => {
        setSelected({ ...selected, course: e });
        if (isLegacy) {
            let arr = [], obj = {}
            try {
                e?.lrncontent.forEach((el) => {
                    let index = arr.findIndex((c) => c?.name === el?.name);
                    if (index > -1) {
                        arr[index] = { ...arr[index], lrnaids: arr[index]?.lrnaids?.push(el) }
                    } else {
                        let lrn = [el]
                        obj = { name: el?.name, lrnaids: lrn };
                    }
                    arr.push(obj);

                })
            } catch (err) {}
            setSearchResult(arr);
            }
    }
    const onAddCancel = () => {
        setIsAddConfirm(false)
    }

    const closeDrawer = () => {
        dispatch(courseEditAction.toggleDrawer({ open: false, drawerWidth: 0 }))
    }

    const addLearningAid = () => {
        try {
            dispatch(courseEditAction.addExistingLearningAid(selectedLElement))
            setIsAddConfirm(false)
            setSelected({})
            closeDrawer()
            setSelectedLElement({});
        } catch (e) {
            console.log(e);
        }
    }
    const openAcc = (el) => {
        if (expanded === el) {
            setExpanded(0)
        }else{
            setExpanded(el);
        }
    }

    const renderLeaningElements = (lrains) => {
        return (<ul className='learing-element-list'>
            {lrains?.map((lrn, index) => {
                return <li key={index} className={lrn?._id === preview?._id ? "flex-item selected-le-item" : "flex-item"} onClick={() => selectLearningElement(lrn)}>
                    <div>{lrn?._id} </div>
                    {/* <div>{lrn.comments}</div> */}
                    <div>{lrn?.createdAt}</div>
                </li>
            })}
        </ul>)
    }


    const selectLearningElement = (lrn)=>{
        setSelectedLElement(lrn);
        setPreview(lrn)
        console.log("lrn",lrn,lrn?._id);
        console.log("selectedLElement",selectedLElement);
    }




    const getHighestCompId = ()=>{
        let max = 0;
        if(courseInfo?.topic?.lrncontent && courseInfo?.topic?.lrncontent.length > 0){
            courseInfo?.topic?.lrncontent.forEach((lrn)=>{ 
                if(max < lrn?.compid) { 
                    max = lrn.compid 
                } 
            })
        }
        return max;
    }

    // highest compid - e.g 3 from current topic lrns content 
    // markup - e.g "<section name='TopicHeader' compId='com_0'></section><section name='CourseCardFlip' compId='com_2'></section><section  name='Accordion' compId='com_3'></section>"
    // found course id - e.g 130
    // current course id - e.g Currently rendered in UI 
    // searched learning element id -  e.g Selected Learning Aid from found list
    // current topic id - e.g Current Topic Selected 
    // let sample = {
    //     "maxcmpid":3,
    //     "markup":"<section name='TopicHeader' compId='com_0'></section><section name='CourseCardFlip' compId='com_2'></section><section  name='Accordion' compId='com_3'></section>",
    //     "foundcourseid":"61460c0741b7c912e9a6538b"
    //     "currentcourseid":"616d1ae6424d275113d542b9",
    //     "lrnid":"620654f68cd19b2eb4be78be",
    //     "currenttopicid":"616d1ae6424d275113d542bb",
    //    }

    /** Properties should be changed */
    const addSearchedLearningAid = () =>{
        // const data ={
        //     "maxcmpid":getHighestCompId(),
        //     "markup":courseInfo?.topic?.markup,
        //     "foundcourseid":selected?.course?._id,
        //     "currentcourseid":courseInfo?.courseOverivew[0]?.id,
        //     "lrnid":selectedLElement?._id,
        //     "currenttopicid":courseInfo?.topic?.id,
        // };
        setIsAddConfirm(true);
        // dispatch(courseEditAction.addExistingLearningAid(selectedLElement))
        // setSelectedLElement({});
    }

    const previewLearningAid = (lrn)=>{
        try{
            const Component = getComponent(lrn);
            let cid = PlayerConstants.getComponentByName(lrn.name).id +  ":" + lrn.compid;
            let trackobj = new ContentTracking(cid, 0, {})
            const cmpProps = Object.assign(lrn?.props, {track:trackobj,handler:function(){},cename:lrn.name})
            ReactDom.render(<Component {...cmpProps} />,document.getElementById('preview'))
        }catch(e){
            console.log("e")
        }
    }
    const getComponent = (lrn) => {
        try{
            return require(`../../../components/content/${lrn?.name}/${lrn?.name}`).default
        }catch(e){
            console.log(e);
        }
    }
    useEffect(() => {
        try{
            if (data?.name === "Legacy interactivity") {
                let interactivity = []
                let crcName=courseInfo?.courseAttributes?.coursename;
                let legacyCrc = courseEdit.legacyCrcStructure;
                    Object.keys(legacyCrc).forEach((e, index) => {
                        if (legacyCrc[e]?.title.includes(crcName)) return interactivity[index] = { ...legacyCrc[e], _id: legacyCrc[e].id };
                    })
                setCrcList(interactivity);
            }
        }catch(e){}
    }, [courseEdit?.legacyCrcStructure]);

    return <div className='lrnaid-search-panel'>
        {!isLegacy && <div className='row'>
            <div className='col-lg-6 col-md-6 col-sm-6 pdl20'>
                <TextField id="outlined-basic"
                    name='name'
                    inputProps={{ maxLength: 30 }}
                    label="Enter content to search"
                    variant="outlined"
                    onChange={(e) => handleQueryInput(e)} />
                    {(selected?.qry?.length < 4 || (Number(selected?.course?._id) === 0)) && <p className='error py-1 m-0'>Please enter at least 4 characters, select your course, click Search Learning elements</p>}
            </div>
           <div className='col-lg-6 col-md-6 col-sm-6'>
                <select className='collection' id="collection" onChange={lrnaidSelectHandler}   >
                    <option value={''}>All Learning elements</option>
                    {lrnaidList && lrnaidList.map((el, index) => <option value={el.name} key={index} >{el.name}</option>)}
                </select>
            </div>
        </div>}

        <div className='row'>
            <div className='col-lg-6 col-md-6 col-sm-6'>
                {!isLegacy && <div className='title'>Search in :  {selected?.course.title}</div>}
                <ul className='courselist'>
                    {(crcList && crcList.length > 0) ? crcList.map((el, index) => {
                        return el?.status !== -1 && <li key={index} className={el._id === selected?.course._id ? 'course-li-active' : 'course-li'} onClick={() => onCourseChange(el, index)}>{!isLegacy ? el?.coursename : el.title}</li>
                    }): <p>Contracted courses are not found in this course.</p>}
                </ul>

                {!isLegacy && <button className='btn-add' disabled={((Number(selected?.course?._id) === 0) || selected?.qry?.length < 4)} onClick={() => { searchLearningElements(true) }}>
                    Search Learning elements</button>}
                {/* Preview conatiner start*/}
                {/* {(searchResults.length > 0) && Object.keys(selectedLElement).length > 0 &&
                   <div className='preview-container'>
                        <h6 className='preview-title'>Learning Element Preview</h6>
                        <div  id='preview' ref={previewRef}> </div> 
                   </div>} */}
                {/* Preview conatiner end */}
            </div>

            <div className='col-lg-6 col-md-6 col-sm-6 topic-header'>
                {!isLegacy && <div className='title'></div>}
                {
                    (searchResults.length > 0) ? <> {Object.keys(selectedLElement).length > 0 ?
                        <div className='preview-container'>
                            <div className='preview-head'>
                                <h6 className='preview-title'> {selectedLElement.name} - Learning Element Preview</h6>
                                <CloseIcon className='close-pre' onClick={() => { setSelectedLElement({}) }} />
                            </div>
                            <div id='preview' ref={previewRef}></div>
                        </div>
                        : searchResults.map((lrnelemts, index) => {
                            return (lrnelemts?.lrnaids?.length > 0 && <Accordion key={index} expanded={expanded === lrnelemts} onChange={() => openAcc(lrnelemts)}>
                                <AccordionSummary
                                    expandIcon={<ExpandMoreIcon />}
                                    aria-controls={`panel-${lrnelemts}-content`}
                                    id={`panel-${lrnelemts}-header`}
                                >
                                    <Typography className='lrn-name' >{PlayerConstants?.LRN_RE_NAME[lrnelemts?.name]||lrnelemts?.name}  </Typography>
                                </AccordionSummary>
                                <AccordionDetails>
                                    <Typography component="div">
                                        {renderLeaningElements(lrnelemts.lrnaids)}
                                    </Typography>
                                </AccordionDetails>
                            </Accordion>)
                        })}</> :(Number(selected?.course?._id) !== 0) ? <div className='loading'><h3> No learning elements found</h3></div> :<></>
                }


            </div>
        </div>
        <div className='row mb10 mr5 mt10'>
            <div className='col-lg-6 col-md-6 col-sm-6'>
                {/* <button className='col-lg-8 col-md-8 col-sm-8 btn-add' disabled={((!selected?.course.coursename.length > 0) && (!selected?.qry.length > 0))} onClick={() => { searchLearningElements(true) }}>
                    Search Learning elements</button> */}
            </div>
            <div className='col-lg-12 col-md-12 col-sm-12 selected-element'>
                <div className='row'>
                    <div className='col-lg-12 col-md-12 col-sm-12'>
                        <div className='btn-items'>
                            {searchResults.length > 0 &&
                                <button className='btn-add'
                                    disabled={Object.keys(selectedLElement).length === 0}
                                    onClick={() => { addSearchedLearningAid() }}>Add Selected Element</button>
                            }
                            <button className='btn-cancel' onClick={closeDrawer}>Cancel</button>
                        </div>
                    </div>
                </div>
            </div>
        </div>

       <div className='warning-popup'>
         <CustomSweetAlert
             warning
            showCancel
            show={isAddConfirm}
            confirmBtnText="Add"
            confirmBtnBsStyle="success"
            title="Are you sure you want to add this as Learning Aid ?"
            text="Are you sure you want to add this as Learning Aid ?"
            onConfirm={() => addLearningAid()}
            onCancel={() => onAddCancel()}/>
        </div>
    </div>
}
export default SearchLearningAids;