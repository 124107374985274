import React, { Component } from 'react'
import Tooltip from '@material-ui/core/Tooltip';
import ClickAwayListener from '@material-ui/core/ClickAwayListener';
import '../../../assets/scss/imagemap.scss'
import { pathCreater } from "../../../utils/MediaConfig";
import PropTypes from 'prop-types'
import ContentTracking from '../../../core/progress/ContentTracking';
import ErrorOutlineIcon from '@material-ui/icons/ErrorOutline';
import PlayerConstants from '../../../utils/PlayerConstants'
import Instruction from '../../ui/Instruction/Instruction';

const UPDATE_STATUS = PlayerConstants.COMPONENT_CONSTANTS.UPDATE_STATUS
const SAVE_PROGRESS_STATUS = PlayerConstants.COMPONENT_CONSTANTS.SAVE_PROGRESS_STATUS
const contentLayout = document.querySelector('.content')

/**
 * ImageMap component will be able to mark the points and can display some message over to it
*/
export default class ImageMap extends Component {
    constructor(props) {
        super(props)
        const { image, mappings,track,handler, instructions} = this.props
        this.state = {
           image: image, 
           items: mappings,
           isOpen: false,
           track:track,
           handler:handler,
           viewed:[],
           currIndex: null,
           completed: false,
           status: PlayerConstants.COMPONENT_CONSTANTS.STATUS_INCOMPLETE,
           instructions: instructions
        }
    }

    
    componentDidMount () {
        contentLayout.addEventListener('scroll', () => {
            this.handleClose(this.state.currIndex)
        })
    }

    setStateItems = (index,flag)=>{
        let items = [...this.state.items];
        items[index] = {...items[index], isOpen: flag};
        this.setState({ items:items });
    }

    handleClose = (index) => {
        this.setStateItems(index,false)
    };
    
    handleOpen = (index) => {
        if (this.state.items[index].isOpen) {
            this.setStateItems(index, false)
        } else {
            this.setStateItems(index,true)
            /**progress tracking start*/
            if(this.state.viewed.indexOf(index) === -1){
                this.state.viewed.push(index);
                this.updateProgress(index);
            }
            this.setState({
                viewed: [...this.state.viewed],
                currIndex: index
        })
        }

    /** progress update end */
    };


      /**
     * Manage the component update 
     * progress logic in this method
     * 
     * Update the view status when ever the user interacts
     * Update the progess status ONLY ONCE, when status completes
     * 
     */
    updateProgress = (currentItem) => {
        let track = this.state.track
        /**
         * View status, will update while navigating to next topic
         */
         track.state = { ...track.state, c: currentItem }
        /**
         * Progress updated as and when its completed
         */

        if (!track.status) {
            track.status = ( this.state.viewed.length === this.state.items.length) ? 1 : 0;
            if (track.status) {
                this.setState({
                    ...this.state,
                    completed: true,
                    status: 'completed',
                    instructions: {
                        text: `You have completed ${this.props.cename}, you can proceed to next section`,
                        className: 'completed',
                        enabled: true
                    }
                })
            this.state.handler({ type: SAVE_PROGRESS_STATUS, id:track.id, name:this.props.cename })
            }
        }
        /**
         * If anything to be intimated always
         * call this
         */
        this.state.handler({ type: UPDATE_STATUS, id:track.id, name:this.props.cename })

    }

    componentWillUnmount() {
        contentLayout.removeEventListener('scroll', () => {
            this.handleClose(this.state.currIndex)
        })
    }

    render() {
        const { image, items, completed, status, instructions, track } = this.state
        return <>
        <Instruction isInstruction={instructions?.enabled} completed={(track.status === 1 || completed)} title={(track.status === 1 || completed) ? PlayerConstants.COMPONENT_CONSTANTS.STATUS_COMPLETE : PlayerConstants.COMPONENT_CONSTANTS.STATUS_INCOMPLETE} classText={`${(track.status === 1 || completed) && PlayerConstants.COMPONENT_CONSTANTS.COMPLETED_CLASS}`} text={(track.status === 1 || completed) ? PlayerConstants.COMPONENT_CONSTANTS.INSTRUCTIONS_PASSED : instructions?.text}/>
        <div className="container image-map-comp">
                <div className='image-comp-container'>
                    <img className='imag-tag' src={pathCreater(0) + image} useMap="#image-map" alt="" onError={(e)=>{e.target.onerror = null; e.target.src=`${pathCreater(0) + '../topics/61460c0741b7c912e9a65392/_kgrrjmM5Z4Dy9SC_aoN3tosYWnozjZ45.png'}`}} />
                    <div className='image-mapper-plot'>
                        {items?.length > 0 && items?.map((el, index) => {
                            return <ClickAwayListener onClickAway={() => this.handleClose(index)} key={el.id}>
                            <Tooltip
                                className='image-map-tooltip'
                                PopperProps={{
                                    disablePortal: true,
                                }}
                                onClose={() => this.handleClose(index)}
                                open={el.isOpen}
                                disableFocusListener
                                disableHoverListener
                                disableTouchListener
                                title={
                                    <>
                                    <p className='tooltip-snippet' dangerouslySetInnerHTML={{__html: el.content}}></p>
                                    </>
                                }
                                arrow
                            >
                            <span style={{top: `${el?.map?.x}%`, left: `${el?.map?.y}%`}} className={`plot-points`} data-value='plotOne' onClick={() => this.handleOpen(index)}>{index + 1}</span> 
                            </Tooltip>
                        </ClickAwayListener>
                        })}
                    </div>
                </div>
            </div>
            </>
    }
}

ImageMap.defaultProps = {
    instructions: {
        text: 'Click on each hotspot on image for more information',
        enabled: false
    }
}

ImageMap.propTypes = {
    /** Items include the plotpoints of the image */
    items: PropTypes.array,
    /** Image url for the background */
    image: PropTypes.string,
    /** Tracking the component progress */
    track: PropTypes.instanceOf(ContentTracking),
    /** Func description for tracking*/
    handler:PropTypes.func
}