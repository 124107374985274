import { useEffect,useRef,useState } from 'react'
import './search.scss'
import SearchIcon from '@mui/icons-material/Search';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close'
import Mark from "mark.js";
import { useSelector } from 'react-redux';
import SearchList from '../SearchList/SearchList';
import t from '../../../translation/useTranslate';
import MuiIcons from "../../ui/MuiIcons/MuiIcons"


/**
 * search component will recieve the data from the course and courses topic content
 * By taking both inputs will make search based on only topic.
 * after the topic has been selected will highlight words based on users search query
*/
const Search = (  ) => {

    const courseInfo = useSelector( state => state.courseInfo );
    const [ searchOverLay, setSearchOverLay ] = useState( false );
    const [ word, setSearchedWord ] = useState( '' );
    const [ searchobj, setSearchContent ] = useState([]);
    const [strText, setText] = useState('')
    const searchRef = useRef('')

    /**
     * Here we are taking the flatten course object for search along topic and content
     * makes easier to iterate all topic and topic content
    */

    useEffect( () => {
        if ( courseInfo.flatten && searchobj.length === 0) {
            genarateData( courseInfo.flatten );
        }
    }, [ Object.keys( courseInfo.flatten ).length > 0 ] );


    /** Data planned for search 
     * implementation TODO
     */
    function genarateData ( flatten ) {
        for ( var key in flatten ) {
            if(flatten[key].lrncontent) {
                searchobj.push({
                    id: key,
                    title: flatten[ key ].title,
                    content: flatten[ key ].lrncontent !== null ? getContent( flatten[ key ].lrncontent ) : ''
                })
            }
        }

        setSearchContent( searchobj );
    }

    function getContent ( tps ) {
        try{
            const contentMapped = tps?.map( el => {
                return getText( el?.props )
            });
            return contentMapped.join('');
        }
        catch(e){
            console.log(e);
        }
    }


    function getText ( props ) {
        var str = '';
        for ( var key in props ) {
            if ( key !== 'track' && key !== 'cename' && key !== 'handler' && key !== 'src' && key !== 'poster' && key !== 'image') {
                let item = props[ key ];
                if ( item && !Array.isArray( item ) && typeof item !== 'object' ) {
                    str += item
                } else if ( Array.isArray( item ) ) {
                    item.forEach( ( el ) => {
                        if(el.content) {
                            str += el.content
                        }
                        if(el.title) {
                            str += el.title
                        }
                        if (el.description) {
                            str += el.description
                        }
                        if(typeof el === 'string') {
                            str += el
                        }
                    } )
                }
            }
        }
        return str;
    }

    /**
     * Mark.js is the new library for highlighting the search query over the content area
    */

    const highLightMark = ( query ) => {
        try {
            let markInstance = new Mark( document.querySelector( ".main-layout-container" ) )
            markInstance.unmark( {
                done: () => {
                    markInstance.mark( query );
                }
            } );
        } catch ( e ) {
            console.debug( 'expection raised', e )
        }
    }



    const onChangeHandler = ( e ) => {
        setSearchedWord(e.target.value)
    }

    const openSearch = () => {
        setSearchedWord('');
        setSearchOverLay( true );
        setTimeout(() => {
            searchRef.current.focus()
        }, 100)
    }

    const closeSearch =() =>{
        setSearchedWord('');
        setSearchOverLay( false );
        var instance = new Mark(document.querySelector( ".main-layout-container"));
        instance.unmark();
    }

    const handleMark = (query) => {
        highLightMark(query)
        setSearchOverLay(false)
    }

    return <>
        { !searchOverLay ? <div className='search-icon'>
                <div onClick={ () => openSearch() }>
                    <IconButton edge="start" color="inherit" className='search-btn' aria-label="menu" sx={ { mr: 2 } }>
                            <SearchIcon />
                    </IconButton>
                </div>
        </div> : <div className="search-over-lay">
            <div className="s-head">
                <div onClick={ () => closeSearch( ) }>
                    {/* <CloseIcon className="close-btn" /> */}
                    <MuiIcons iconName='close' color='#fff' />
                </div>
            </div>
            {/* <p className={'matched-query-length'}>Matched Queries: </p> */}
            <div className="s-content">
                <div className="search-area">
                    <input type="text" ref={searchRef} className="txt-box" onChange={ ( e ) => onChangeHandler( e ) } placeholder={t("SEARCH")}/>
                </div>
                <SearchList query={word} courses={searchobj} flatten={courseInfo.flatten} highlight={handleMark}/>
            </div>
        </div> }
    </>
}

export default Search



