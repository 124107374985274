import React from 'react'
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';
import './profile.scss'
import TextField from '@material-ui/core/TextField';
import { withStyles } from '@material-ui/core/styles';
import LinkedInIcon from '@material-ui/icons/LinkedIn';
import FacebookIcon from '@material-ui/icons/Facebook';
import { Link } from 'react-router-dom';

const CssTextField = withStyles({
    root: {
        width: '100%',
      '& label': {
        color: 'rgb(141 141 141 / 54%)'
       },
      '& label.Mui-focused': {
        color: 'rgb(141 141 141 / 54%)'
      },
      '& label.MuiFormLabel-filled': {
        color: 'rgb(141 141 141 / 54%)',
        top: '0',
        marginTop: '-3px'
      },
      '& .MuiInputLabel-outlined': {
          fontSize: '14px',
          marginTop: "-6px"
      },
      '& .MuiOutlinedInput-root': {
          height: '40px',
          fontSize: '14px',
          color: '#000',
        '& fieldset': {
           fontSize: '14px'
        },
        '&:hover fieldset': {
          borderColor: '#e6e6e6',
        },
        '&.Mui-focused fieldset': {
          borderColor: '#e6e6e6',
        }
      }
    }
  })(TextField);

const Profile = (props) => {
    return (
        <>
        <div className='profile-container'>
            <div className='main-content-profile'>
                <Link  to='/course'><h5><ChevronLeftIcon /> Back to DashBoard</h5></Link>
                <div className='profile-card'>
                    <div className='row'>
                        <div className='col-md-3'>
                           <div className='image'>
                               <img style={{width: '100%', maxHeight: '320px', objectFit: 'cover'}} src="https://www.eaglewharfmarina.com/wp-content/uploads/2018/11/james-union.jpeg" alt="profile-img" />
                           </div>
                        </div>
                        <div className='col-md-3'>
                            <div className='name-input input-main'>
                                <p className='name-placeholder'>Name</p>
                                <p className='name-text'>Ben Watson</p>
                            </div>
                            <div className='designation-input input-main'>
                                <CssTextField label="Designation" variant="outlined" />
                            </div>
                            <div className='company-input input-main'>
                                <CssTextField label="Company Name" variant="outlined" />
                            </div>
                            <div className='icon'>
                                <span className='link-icon'><LinkedInIcon /></span> <span className='facebook-icon'><FacebookIcon /></span>
                            </div>
                        </div>
                        <div className='col-md-3'>
                            <div className='email-input input-main'>
                                <CssTextField label="Email Id" type='email' variant="outlined" />
                            </div>
                            <div className='phone-input input-main'>
                                <CssTextField label="Phone No" variant="outlined" />
                            </div>
                            <div className='address-input input-main text-area'>
                                <CssTextField label="Address"  variant="outlined" />
                            </div>
                        </div>
                        <div className='col-md-3'>
                            <div className='about-input input-main text-area'>
                                <CssTextField label="About me" variant="outlined" />
                            </div>
                        </div>
                    </div>
                </div>
                <div className='btn-group'>
                        <button className='btn-cancel'>
                            Cancel
                        </button>
                        <button className='btn-save'>
                            Save
                        </button>
                </div>
            </div>
        </div>
        </>
    )
}

Profile.propTypes = {

}

export default Profile
