import React, { useState, useEffect, useCallback } from 'react';
import io from 'socket.io-client';
import PlayerConstants from './utils/PlayerConstants';
import {useDispatch } from 'react-redux';
import * as utilityAction from '../src/redux/actions/utilityAction'

const SOCKET_URL =PlayerConstants?.SOCKET_PATH || "";
const isStaging = PlayerConstants?.STG_HOSTNAMES?.indexOf(window?.location?.hostname) !== -1;
// Fetching the path based on the type
const getSocketPath = (type) => {
  switch (type) {
    case "publish":
      return isStaging ? "/xapice/cea-notofications/" : "/cea-notofications/"
    default:
      return ""
  }
}

const transports = isStaging ? "polling" : "websocket";

const SocketConnection = (userId, clientId, type) => {
  const [serverResponse, setServerResponse] = useState(null);
  const [error, setError] = useState(null);
  const [socket, setSocket] = useState(null);
  const dispatch = useDispatch();


  // function to initialize the socket connection
  const initializeSocket = useCallback(() => {
    const socketInstance = io(SOCKET_URL, { path: getSocketPath(type), transports: [transports] });
    // Event listeners
    socketInstance.on('error', (error) => {
      setError(error);
    });

    socketInstance.on('ping', () => {
      console.log('Ping server');
    });

    /**
     * we might pass other params to server 
     * as now this, if server needs to know anything
     *
     * */
    socketInstance.on('connect', () => {
      console.log('Connected to server');
      socketInstance.emit('signal', { action: 'subscribe', params: { userid: userId, clientid: clientId } });
    });

    socketInstance.on('reconnect', (attempt) => {
      console.log('Reconnection attempt:', attempt);
    });

    /**
     * 
      Listen to notification from the server
      use payload object received to differentiate various notifications
      action will have the value
    * */
    socketInstance.on('notifications', (data) => {
      try {
        // Based on the action, we trigger the corresponding actions
        if (window && window?.ce && window?.ce?.platform_scorm && data?.payload?.data?.action === "publish") {
           window.ce.platform_scorm.ispublished = true;
          // Based on the CIDs, check if available, we are doing an auto-refresh.
          if (data?.payload?.data?.cids?.includes(window?.ce?.platform_scorm?.cid || "") && Number(window?.ce?.ceuser?.role_id)===4 ) {
              dispatch(utilityAction.socketWarning(1));
          }
        }
        setServerResponse(data);
      } catch (e) {
        console.error('Error handling notification:', e);
      }
      // Disconnect after receiving the first notification
      socketInstance.disconnect();
    });

    setSocket(socketInstance);
  }, [userId, clientId, type]);

  useEffect(() => {
    // clean up socket connection on component unmount
    return () => {
      if (socket) {
        socket.disconnect();
      }
    };
  }, [socket]);
  
  const connect = () => {
    if (!socket) {  //If the socket is not already initialized, it calls 
      initializeSocket();
    } else {
      socket.connect();
    }
  };

  return {
    connect,
    response: serverResponse,
    error,
    socket
  };
};

export default SocketConnection;
/**
* 
* To trigger and test a push from server to clients
* call this url ( will disable after testing )
* https://tradecompliance.training/xapice/api/schive/shoutout
* 
* in live - https://beta-cached.contentenablers.com/api/signal/shoutout
* */
