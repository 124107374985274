import React, { useState, useEffect, useRef} from 'react'
import PropTypes from 'prop-types'
import './templatepanel.scss'
// import Slider from "react-slick";
// import CheckCircleIcon from '@material-ui/icons/CheckCircle';
// Import css files
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { useDispatch, useSelector } from 'react-redux';
import { Button } from '@material-ui/core';
import * as courseEditAction from '../../../redux/actions/courseEditAction'
import PlayerConstants from '../../../utils/PlayerConstants';
import ContentTracking from '../../../core/progress/ContentTracking';
import ReactDom from "react-dom";
import CustomSweetAlert from "../../../components/ui/CustomSweetAlert/CustomSweetAlert";
import AppButton from '../../../components/ui/AppButton/AppButton';
import * as courseInfoAction from '../../../redux/actions/courseInfoAction'
import DataHelper from '../../../utils/DataHelper';

const TemplatePanel = ({ templates, panel }) => {


    const courseInfo = useSelector(state => state.courseInfo);
    const courseEdit = useSelector(state => state.courseEdit);
    const userInfo = useSelector(state => state.userInfo);
    const previewRef = useRef();

    const [selectedTp, setSelectedTp] = useState(0);
    const [iscmAlert, setConfirmAlert] = useState(false);
    const [isSuccess, setSuccessAlert] = useState(false);
    const [topicList, setTopicList] = useState(false);
    const dispatch = useDispatch();
    const isCloneView= panel === 13
    // console.debug( "courseInfo", courseInfo );
    // let tps = []
    // if (courseEdit.tpList && courseEdit.tpList.length > 0 && panel === 3 ) {
    //     tps = courseEdit.tpList.map((tp, index) => {
    //         let temp = tp;
    //         temp.name = temp.name || 'Template ' + (index + 1);
    //         temp.img = ''
    //         return temp;
    //     });
    //     setTopicList(tps);
    // }
    // const sliderConfig = {
    //     dots: false,
    //     infinite: true,
    //     arrows: true,
    //     speed: 500,
    //     slidesToShow: tps.length < 5 ? tps.length : 5,
    //     slidesToScroll: 1,
    //     centerMode: true
    // };
    // const [settings, setSettings] = useState(sliderConfig);



    const selectTemplate = (item) => {
        setSelectedTp(item);
    }
    /**
     * 
     * @param {*} item 
     * id to compid  
     */
    // const selectTemplates = ( item ) => {
    //     if(item?.cmpdata){
    //         if(item?.cmpdata.length > 0){
    //             let mapped = item?.cmpdata.map((el)=>{
    //                 let obj = {...el,compid:Number(el.id)}
    //                 delete obj.id;
    //                 return obj;
    //             });
    //             item.cmpdata = mapped;
    //             setSelectedTp(item);
    //         }

    //     }
    // }

    const assignTemplate = () => {
        setConfirmAlert(true);
    }

    /**
     * User data added for Testing in local 
     * Remove test user in live
     */

    const getUserinfo = () => {
        let user = {
            "uid": '618d141d9da34f0ca5af333c',
            "role": 1, "status": 1, "userinfo": null,
            "clients": [], "username": "Schive",
            "email": "Schive@Schive.com",
            "password": "U2FsdGVkX1/G83xbVr/tNfKlIaouShEB7LzGlIVQn1E=",
            "firstname": "Schive", "lastname": "Smb"
        }
        return window.location.href.includes("localhost") ? user : userInfo.user
    }


    const userConfirmation = () => {
        /**
         * Apply content template flow changes (13/08/24) based on Kim's request.
         * Complete template replacement to attach the existing LRN template inside.
         * status default 1
         * 
         * learning aid add Specific Handling for Quizzes:
         * - When a quiz learning aid is added, it requires special processing because quizzes have a separate quiz bank.
         * - During apply template, quiz items are removed and replaced with the quiz question `_id` due to this separation.
         * 
         */
         selectedTp.cmpdata=selectedTp?.cmpdata?.map((item)=>{
            let {...obj} = { ...item };
            // Handle quizzes
            if((obj?.name||obj?.cname)==='Quizzes'){
                obj.props.items=obj?.props?.items?.map(quiz=>quiz?._id)
            }else if (['Video', 'ArchiveVideo'].includes(obj?.name || obj?.cname)) {  // Handle video and archive video
                obj.props.cuepoints = [];
            }
            obj.status=1;
            return {...obj}
        })
        let payload={ 
            tpl: selectedTp,
            configid:window?.ce?.rlm?.config_id,
            tid: courseInfo.topic.id, 
            type:window?.ce?.ceuser?.type,
            user: getUserinfo() 
        }

        dispatch(courseEditAction.assignTemplate(payload));

        // dispatch(courseEditAction.assignTemplate(data));
        // get the response from server take ids and fill in the learning
        setConfirmAlert(false);
        /** On success show this message */
        //setSuccessAlert(true);

        closeDrawer();
    }

    const closeDrawer = () => {
        dispatch(courseEditAction.toggleDrawer({ open: false, drawerWidth: 0 }))
    }

    const onUserCancel = () => {
        closeDrawer();
        setConfirmAlert(false);
    }

    const deselection = () => {
        setSelectedTp(0);
        dispatch(courseEditAction.toggleDrawer({ open: false, drawerWidth: 0 }))
    }

    useEffect(()=>{
        setSelectedTp(0)
        if(isCloneView){
            setTopicList(courseEdit?.personalizehistory||[]);
            if (!Array.isArray(courseEdit?.personalizehistory)) {
                dispatch(courseEditAction.getPersonalizeHistory());
            }      
        }else if(courseEdit.tpList && courseEdit.tpList.length > 0 && panel === 3 ) {
            let tps = courseEdit.tpList.map((tp, index) => {
                let temp = tp;
                temp.name = temp.name || 'Template ' + (index + 1);
                temp.img = ''
                return temp;
            });
            setTopicList(tps);
        }
    },[courseEdit?.personalizehistory, panel])
    
    useEffect(() => {
        if (selectedTp?.cmpdata?.length > 0) {
            selectedTp?.cmpdata.forEach((element,index) => {
                let refId=`preview${index}`
                previewLearningAid(element,refId)
            });
        }
    }, [selectedTp, selectedTp?.cmpdata])


    const previewLearningAid = (lrn,refId)=>{
        try{
            const Component = getComponent(lrn);
            let cid = PlayerConstants.getComponentByName(lrn.name).id +  ":" + lrn.compid;
            let trackobj = new ContentTracking(cid, 0, {})
            const cmpProps = Object.assign(lrn?.props, {track:trackobj,handler:function(){},cename:lrn.name})
            ReactDom.render(<Component {...cmpProps} />,document.getElementById(refId))
        }catch(e){}
    }
    const getComponent = (lrn) => {
        try{
            return require(`../../../components/content/${lrn?.name}/${lrn?.name}`).default
        }catch(e){
            console.log(e);
        }
    }
    
    const topicSelected = (element) =>{
        try{
            dispatch(courseEditAction.toggleDrawer({open:false, drawerWidth: 0}))
            // Find the node that matches the selected topic ID
            const entry = Object.entries(courseInfo.flatten).find(([key, value]) => value.id === selectedTp._id);
            // Dispatch the action to select the topic with the found node
            if (entry[1]) {
                dispatch(courseInfoAction.selectedTopic(entry[1]));
                setTimeout(() => {
                    DataHelper.scrollToLrnElement(element);
                }, 800);
            } else {
                console.warn(`Topic with ID ${selectedTp._id} not found.`);
            }
        }catch(e){

        }
    }

    return (
        <>
            <div className="template-panel">
            {selectedTp !== 0 && !isCloneView && <div className='apply-temp'>
            <p className="instructions">Applying a template will replace the present learning elements in this topic, and will be replaced with the selected template contents.</p>
                    <div className="tp-btn-holders">
                        <Button variant="outlined" className="assign-btn mr10"
                            color="secondary"
                            disabled={selectedTp === 0 ? true : false}
                            onClick={() => assignTemplate()}>Assign</Button>
                        <div className='warning-popup'>
                        <CustomSweetAlert warning
                            showCancel
                            show={iscmAlert}
                            confirmBtnText="Assign"
                            confirmBtnBsStyle="success"
                            title="Are you sure you want to apply this template? Doing so will append the learning elements from the selected template to this topic."
                            text="Topic you are editing will be replaced with the new content from selected template."
                            onConfirm={() => userConfirmation()}
                            onCancel={() => onUserCancel()}/>
                        </div>
                        <CustomSweetAlert success
                            show={isSuccess}
                            onConfirm={() => setSuccessAlert(false)}
                            title="Template Assigned Successfully"/>
                    </div>
                </div>}
                <div className='row template-container'>
                <div className='col-lg-3 col-md-6 col-sm-6'>
                {topicList.length > 0 ? <ul className='courselist'>
                    { topicList?.map((tp, i) => {
                        return <li className={`template ${selectedTp?._id === tp?._id ? "selected" : ''} `} key={i} onClick={() => selectTemplate(tp)}>
                                    <div className={`tplbox`} >
                                        <div className="tp-card-body">
                                            <div className="card-title">{tp?.name}</div>
                                        </div>
                                    </div>
                                </li>
                     })}
                </ul>:<p class="fw-bold text-nowrap">{panel===3 ? 'Content templates are not found in this course.' : 'List Of Customization are not found in this course.'}</p>}
                </div>
                {selectedTp !== 0 &&  <div className='col-lg-9 col-md-6 col-sm-6 courselist'>
                {selectedTp?.cmpdata?.length > 0 ? selectedTp?.cmpdata?.map((e,index)=>{
                    return <div className='preview'>
                        {/* <h4 className='lrn-name'>{e.name}</h4> */}
                       {isCloneView &&  <div className='clone-header-box'><p className='clone-lrn-name'>{PlayerConstants?.LRN_RE_NAME[e?.name]??e?.name}</p> <AppButton theme="primary1_transparent" onClick={()=>topicSelected(e)}>View this in topic</AppButton></div>}
                         <div  className='preview-lrn' id={`preview${index}`} ref={previewRef}></div>
                        {isCloneView && <hr/>}
                    </div>
                }):<p> The template is an empty</p>}
                </div>}
                </div>
            </div>
        </>
    );
}

TemplatePanel.defaultProps = {
    templates: [
        { id: 1, name: "Template 1", content: [{ id: 1, name: "FlipCard" }] },
        { id: 2, name: "Template 2", content: [{ id: 1, name: "Topic Header" }, { id: 1, name: "Image Map" }] },
        { id: 3, name: "Template 3", content: [{ id: 1, name: "Carousel" }, { id: 1, name: "Image Map" }] },
        { id: 4, name: "Template 4", content: [{ id: 1, name: "Carousel" }, { id: 1, name: "Tabs" }] },
        { id: 5, name: "Template 5", content: [{ id: 1, name: "Carousel" }, { id: 1, name: "Stack Card" }] }]
}

TemplatePanel.propTypes = {
    templates: PropTypes.array
}

export default TemplatePanel;