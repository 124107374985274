/* eslint-disable default-case */

import './scrolllayout.scss';
import { useEffect, useState } from 'react';
import ScrollSection from './ScrollSection';
import { useSelector, useDispatch } from 'react-redux';
import * as courseTrackingAction from '../../redux/actions/courseTrackingAction'
import  * as moment from 'moment-js'
import DataHelper from '../../utils/DataHelper';
import ComponentConstants from '../../utils/ComponentConstants';
import * as courseInfoAction from '../../redux/actions/courseInfoAction'
import * as courseEditAction from '../../redux/actions/courseEditAction'
import ACLHelper from '../../utils/ACLHelper';
import BuildHelper from '../../utils/BuildHelper';

const ScrollLayout = () => {
    const courseInfo = useSelector(state => state.courseInfo);
    const trackInfo = useSelector(state => state.courseTracking);
    const userInfo = useSelector(state => state.userInfo);
    const {courseEdit, utility} = useSelector(state => state);
    const [lastTopicUpdate, setLastTopicUpdate] = useState(false);
    const [elapsedTime, setElapsedTime] = useState(trackInfo.courseTracking?.scorm?.ll ? Math.floor(Number(trackInfo.courseTracking?.scorm?.ll?.split('^')[0])) : 1000);
    const scorm=trackInfo?.courseTracking?.scorm;
    let topicTracking = trackInfo?.courseTracking?.topic?.find(item => { return item?.topicId === courseInfo?.topic?.id });
    const dispatch = useDispatch();

    useEffect(() => {
        const startTime = performance.now();
    
        const intervalId = setInterval(() => {
          const currentTime = performance.now();
          const newElapsedTime = Math.floor((currentTime - startTime) + elapsedTime); 
          if(Number(courseInfo?.flatten[1]?.p)!==100 || courseInfo?.flatten[1]?.status!==1){
            setElapsedTime(newElapsedTime);
          }
        }, 1000); // Update elapsed time every second
    
        return () => {
          clearInterval(intervalId);
        };
      }, [utility?.isCloned,courseInfo?.flatten, trackInfo ]);

    // useEffect(() => {

    // }, [courseInfo?.topic, trackInfo, utility?.isCloned]);

    // updating publish latest data in courseEditingInfo reducer
    const updateLatestPublish=(val)=>{
        dispatch(courseEditAction.publishCourseInfo(val));
    }
    

    const eventHandlers = (data) => {
        checkTopicCompletion(data);
        const isCompleted = (courseInfo?.flatten[1]?.p==100 || courseInfo?.flatten[1]?.status==1);
        if(userInfo.user.role_id  === 4) {
            // eslint-disable-next-line default-case
            switch (data.type) {
                case 'SAVE':
                        dispatch(courseTrackingAction.saveCourseProgress(trackInfo))
                    break;
    
                case 'SAVE_PROGRESS_STATUS':
                        dispatch(courseTrackingAction.saveCourseProgress(trackInfo))
                    break;
                default :
                if(data.isolated && isCompleted){
                    dispatch(courseTrackingAction.saveCourseProgress(trackInfo))
                }
                    break;
            }

        }
        else if(!lastTopicUpdate && isCompleted){
            dispatch(courseTrackingAction.saveCourseProgress(trackInfo))
            setLastTopicUpdate(true);
        }

        if (!BuildHelper.isLearner() && ACLHelper.isFeatureEnabled(1,2)) {
            switch (data.type) {
                case ComponentConstants.WYSIWYG:
                    dispatch(courseInfoAction.wysiwygLearningAid({ id: data.topic_id, data: data?.props }))
                    break;

                case ComponentConstants?.SAVE_COMP_PROPS:
                    let curItem = courseInfo?.topic?.lrncontent?.find(item => { return item?._id === data?.data?.topic_id })
                    dispatch(courseEditAction.toggleDrawer({ open: true, target: {}, itemInfo: curItem, panel: 2, drawerWidth: 25 }));
                    if(data?.data.cename === "Video" || data?.data.cename === "ArchiveVideo" || data?.data.cename === "VideoHotspot" ){
                        dispatch(courseEditAction.addsupplimentary({isActive:true,cduration:data?.data?.cduration,isEdit:data?.data?.isEdit}));
                    };
                    break;
                case ComponentConstants?.SAVE_COMP_PROGRESS_PROPS:
                    let currentLrn = courseInfo?.topic?.lrncontent?.find(item => { return item?._id === data?.topic_id });
                   if(DataHelper.isPublish()){
                      DataHelper.publishFormatting({...data?.props,_id:currentLrn?._id||"",name:data?.data?.cename||""},courseEdit,courseInfo, updateLatestPublish);
                      return;
                    }
                    dispatch( courseInfoAction.saveLearningAid( { id:currentLrn._id, data: data?.props } ) );
                    break;

            }
        }

    }

    /**
     * Progress completion check
     * keeping it here to avoid  dependancy of other 
     * if anything to be added, it has to be injected
     */
    const checkTopicCompletion = (data) => {
        try {
            /**
             * Create string for LRS
             */
            if (Number(topicTracking?.status) < 1) {

            }

            console.debug("topicTracking",topicTracking.flatId)
            const completed = topicTracking.content.filter(item => { return item.status === 1 })
            const incomplete = topicTracking.content.filter(item => { return item.status === 0 })

            const percentage = completed.length / topicTracking.content.length

            if((completed.length === topicTracking.content.length) && Number(topicTracking.status) < 1) {
// Need to remove this updation of percentage
                // topicTracking.percent = 100;
                // topicTracking.status = 1;

               
                if ( courseInfo.topic.children && courseInfo.topic.children.length > 0) {
                    let completedLrn = courseInfo.topic.children.filter( el => {
                        return Number(el.p) === 100
                    } )
                     let isLRNCompleted=false
                    try{
                         isLRNCompleted=courseInfo?.topic?.lrncontent?.every((e) => { return (Number(e?.status) === 1)})||false;
                        if(!courseInfo?.topic?.lrncontent > 0 || !courseInfo?.topic?.lrncontent || (courseInfo?.topic?.lrncontent?.length===1 && courseInfo?.topic?.lrncontent[0]?.name==="TopicHeader" && courseInfo?.topic?.lrncontent[0]?.props?.description?.includes("Lorem Ipsum is simply dummy text"))){
                            isLRNCompleted=true
                        };
                    }catch(e){ isLRNCompleted = true }
                   
                    if ( completedLrn.length === courseInfo.topic.children.length && isLRNCompleted) {
                        courseInfo.topic.p = 100;
                        courseInfo.topic.status = 1;
                        topicTracking.percent = 100;
                        topicTracking.status = 1;
                    }
                }else if ( !courseInfo.topic.children ) {
                    courseInfo.topic.p = 100;
                    courseInfo.topic.status = 1;
                    topicTracking.percent = 100;
                        topicTracking.status = 1;
                }

                handleStatements({type:'DONE', name:'RLM Topic'}, courseInfo.topic)
            }
            else if (Number(topicTracking.status) === 0) {
                topicTracking.percent = courseInfo.topic.p = Math.round(percentage * 100)
                handleStatements(data, courseInfo.topic)

            }

            const isCourseCompleted=DataHelper.isAllTpCompleted(courseInfo?.flatten);

            /** Added for updating the topnode = parentNode */
            updateTopNodeStatus(courseInfo.flatten,topicTracking.flatId)
            /**
             * Setting last visited topic
            */
            trackInfo.courseTracking.visited = Object.assign( trackInfo.courseTracking.visited,{last: topicTracking.flatId});
            trackInfo.courseTracking.scorm = Object.assign(trackInfo.courseTracking.scorm, {ll:generateScormData(trackInfo.courseTracking),sp:generateSuspenData(),ls:isCourseCompleted ? '1':'0'});
            //console.log('% ', percentage, 'INC: ',topicTracking.percent, incomplete.length, 'C: ', completed.length, 'TRC_CNT: ', topicTracking.content.length, 'TRC_STS: ', topicTracking.status)
           
            /** SCORM */
            if ( window.ce?.isScormPack === true && trackInfo?.scorm && courseInfo?.flatten ) {
                // trackInfo?.scorm?.setSuspendData( DataHelper.getLessonStatus( trackInfo?.flatten ) );
                //3 - Incomplete and 1- Completed
                const statusCode =isCourseCompleted ? 1:3;
                // trackInfo?.scorm?.setLessonStatus(statusCode);
                // trackInfo?.scorm?.setLastAccessedTopic(courseInfo?.topic?.nodeId)
                userInfo.user.firstname = trackInfo.scorm.getStudentName();

            }
        
        }
        catch (e) {
            console.warn('Issue found completion of learning aids :: ', e)
        }
    }

    /** checking for the childrens in the node items 
     * if all children completed then setting parent node as completed
     * In children atlease one visisted then saying parent as visited 
     */
    const updateTopNodeStatus = (flatten,nodeId) =>{
        try{
            
            // let pNodeId = nodeId.substr(0,nodeId.length-2);
            let pNodeId = nodeId.substr(0, nodeId.lastIndexOf('.'));
            let pScormNode = trackInfo?.courseTracking?.topic.find(item => { return item?.flatId === pNodeId });
            if(flatten[pNodeId]?.children){
                const completed = flatten[pNodeId]?.children.filter((el)=>{
                    return Number(el.p) === 100
                });
                /**
                 * Scenario 1:All childrens are completed check for own lrncontents status then give completion
                 * Scenario 2:No lrncontents for the node then if all the childrens completed make node as completed
                 */
                if(completed.length === flatten[pNodeId].children.length){
                    let parntStatus = 1;
                    if(flatten[pNodeId].lrncontent && flatten[pNodeId].lrncontent.length > 0 && DataHelper.checkEmptyTopic(flatten[pNodeId].lrncontent))    {
                        let progress = trackInfo.courseTracking.topic.find(parnt => { return parnt.flatId === pNodeId })
                        let parentProgres = progress.content.filter(pval => { return pval.status === 1 })
                        parntStatus = (parentProgres.length === progress.content.length ? 1 : 0)
                    }
                    if(parntStatus){
                        flatten[pNodeId].p =  100;
                        pScormNode.percent = 100;
                    }
                    flatten[pNodeId].status = parntStatus
                    pScormNode.status = parntStatus
                    // if(pNodeId.match(/\./g)){
                        if(pNodeId.match(/\./g) && pNodeId.match(/\./g).length > 1 && Number(flatten[pNodeId.slice(0,-2)]?.p) !== 100){
                            updateTopNodeStatus(flatten, pNodeId)
                        }
                    // }
                }else{
                    flatten[pNodeId].status = 0;
                    pScormNode.status = 0;
                }
            }
                
            /** courseName Node check TODO */

            const crsNode = Object.keys(flatten)[0]

                // console.debug("crsNode",crsNode)


                if(flatten[crsNode]){
                    const completed = flatten[crsNode].children.filter((node)=>{
                        return Number(node.p) === 100
                    });

                    // console.debug("completed",completed.length,flatten[crsNode].children.length )

                    if(completed.length === flatten[crsNode].children.length ){
                        flatten[crsNode].p = 100
                        // courseInfo.courseTracking.topic[0].percent = 100
                        trackInfo.courseTracking.topic[0].percent = 100
                        flatten[crsNode].status = 1
                        // courseInfo.courseTracking.topic[0].status = 1
                        trackInfo.courseTracking.topic[0].status = 1
                    }
                }
            /** courseName Node */


        }catch(e){
                console.log(e);
        }
           
    }

    const generateScormData = (track) =>{
        if(window?.ce?.isStandalone){
            return;
        }
        // 19487^1.2.1^^^010217030317030317^65  Need to chk in lms
        // let lsplit = trackInfo?.courseTracking?.scorm?.ll ? trackInfo?.courseTracking?.scorm?.ll?.split('^')[0] : elapsedTime.toFixed(0);
        console.log(elapsedTime)
        let ll = `${elapsedTime}^${track.visited.last}^^^${createDateString()}^${courseProgress( window.ce?.isScormPack ? track?.topic : courseInfo?.flatten )}`;
        console.log("ll",ll)
        return ll;
    }
    /**
     * generate suspension data based on the topic's status.
     * 'c' - Completed topic
     * 'v' - Visited topic
     * 'n' - Non-visited topic
     * 
     * 
     * @returns {string} suspend - a string representing the status of each topic in the course.
    */
    const generateSuspenData =()=>{
        let suspend =''
        let flatten = courseInfo.flatten;
        for(let key in flatten){
            if(flatten[key]?.status === 1){
                suspend+='c'
            }
           else if(flatten[key]?.status === 0){
                suspend+='v'
            } else{
                suspend+='n'
            }
            
        }
        return suspend;
    }


    /** Need to calculate time spent */

    const getTimeSpent = ()=>{
        // console.log(moment,moment(new Date()).milliseconds(),"time",moment().time().)
        return moment().time();
    }

    const courseProgress = ( flatten ) => {
        let completed = 0;
        Object.keys( flatten ).forEach( ( key ) => {
          if (Number(flatten[ key ]?.percent) === 100 || Number(flatten[ key ]?.p) === 100 ) {
            completed++
          }
        } );

        // console.debug("In Scroll Layout=======",completed,Object.keys( flatten ).length)
        return Math.round(( completed / Object.keys( flatten ).length ) * 100 )
    }

    const createDateString = ()=>{

        var today = new Date();
	    var d = today.getDate();
		var m = today.getMonth()+1; 
		var y = today.getFullYear().toString().substr(-2);
        var date = getTwoDigit(d)+''+getTwoDigit(m)+''+y

        let dList = []
        for(var i=0;i<3;i++){
            dList.push(date)
        }
        return dList.join('');
    }

    const getTwoDigit =(num)=>{
    	if(num<10) num  = "0"+num ;
   		return num ;
   	}



    const handleStatements = (action, topic) => {
    try{
           // function to track lrn ID based on track ID and topic ID
          function lrnId(){
            const foundTopic = Object.entries(courseInfo?.flatten)?.find(([key, value]) => value?.id === topic?.id);
            const foundLrn = foundTopic?.[1]?.lrncontent?.find((content) => content?.props?.track?.id === action?.id) || {};
            if(foundLrn?._id){
               return foundLrn?._id
            }
          }

        let stmetObj = { id: topic.id, file: action.name, name: topic.title,ln_id:trackInfo?.location_id||"0",lrn_type:action.id.split(":")[0] || '0'};
        if(action?.track){
            stmetObj.track=action?.track;
            stmetObj.lrn_id=lrnId()||'';
            trackInfo.lrs.trackActivity('scored', 0,stmetObj) 
        } 
        switch (action.type) {
            case 'DONE':
                if(userInfo.user.role_id === 4)    {
                    trackInfo.lrs.trackActivity('topicdone', -1,stmetObj)
                }
                break;
            case 'UPDATE_STATUS':
                // function to track lrn ID based on track ID and topic ID
                const foundTopic = Object.entries(courseInfo?.flatten)?.find(([key, value]) => value?.id === topic?.id);
                const foundLrn = foundTopic?.[1]?.lrncontent?.find((content) => content?.props?.track?.id === action?.id) || {};
               
                stmetObj={...stmetObj,lrn_id:lrnId()||""};
                //(action.name !== 'Video' ) && (action.name !== 'Audio' &&
                if( userInfo.user.role_id === 4)     {
                    trackInfo.lrs.trackActivity('interacted', 0,stmetObj)
                }
                break;
        }
    }
    catch(e){
        console.log("error in statements");
    }
    }

    return <div className="content-transition">
        <ScrollSection tracking={topicTracking} topicFrame={courseInfo?.topic} handlers={eventHandlers} key={courseInfo.topic.id} />
    </div>

}

export default ScrollLayout



