import React,{useEffect, useState} from 'react';
import PropTypes from 'prop-types';
import DragandMatchPanel from "./DragandMatchPanel";

const ReorderingListPanel = ({id,description,explanation,answer,list, styleObj ,handleProperties,cename}) => {
    const [data, setData] = useState({ description, explanation, styleObj, answer, list });

    useEffect(()=>{
        handleProperties(data);
    },[data])

    const handleChange=(val)=>{
        const updateProp={
            ...data,
            list:val?.bucket,
            description:val?.description,
            explanation:val?.explanation,
            styleObj:val?.styleObj,
            answer:val?.bucket.map(e=>e?.id)
        }
        setData(updateProp);
        handleProperties(updateProp);
    }
    const props={
        description:description,
        bucket: list,
        explanation,
        drags:null,
        styleObj,
        handleProperties:handleChange,
        cename,
        id
      }

  return <DragandMatchPanel  {...props}/>
}

ReorderingListPanel.propTypes = {
    /** choice contains oder contents*/
    list: PropTypes.array,
    /** array contains answer*/
    answer: PropTypes.array,
    /** string it will contain the description  */
    description: PropTypes.string,
    /** explanation it will contain the question  explanation */
    explanation: PropTypes.object,
    /** Func description for tracking*/
    handler: PropTypes.func,
    /** styleObj it will contain styles */
    styleObj: PropTypes.object,
}

export default ReorderingListPanel