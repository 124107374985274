import React ,{useState,useEffect} from 'react'
import PropTypes from 'prop-types'
import TextField from '@material-ui/core/TextField';
import RichTextEditor from '../RichTextEditor/RichTextEditor';

/**
 * 
 * TopicHeaderPanel editor used to modify the propertis of TopicHeader component
 */

const TopicHeaderPanel = (props) => {
    const { header, description , title, styleObj, name, handleProperties} = {...props}
    const [data, setData]= useState({ header, description , name, title, styleObj})

    const handleChange = (e) => {
        if(!header)    {
            delete data.header
        }
        else {
            delete data.title 
        }
        setData({
            ...data,
            [e.target.name]: e.target.value
        })
    }

    const handleColor= (e) => {
        setData({
            ...data,
            'styleObj':{
                ...styleObj,
                [e.target.name]: e.target.value
            }
        }) 
    }

    useEffect(() => {
        handleProperties(data)
     }, [data]);

     /**
     * 
     * @param {} value 
     * data html string used for description of the component
     */
    const eventHandlers = (value) => {
        let contentValue = value==="<p></p>\n" ? '<p>Empty content</p>' : value
        setData({
            ...data,
            description: contentValue
        })
        handleProperties(data)
    }



    return (
    <div className='topic-head-prop-container'>
        {/* <div className='topic-head-prop-title'>
            <TextField id="outlined-basic" spellCheck={false} name={header ? 'header': 'title'} label="Content title" variant="outlined" value={ header ? header: title} onChange={(e)=>{ handleChange(e) }}/>
        </div> */}
        <div className='topic-head-prop-description x-align'>
           <label className='label dis_label'>Content</label>
            <RichTextEditor data={data?.description||''} eventHandler={eventHandlers}  />
        </div>
        <div className='row'>
       
        {styleObj && <> <div className='topic-head-prop-background col-md-6'>
            <TextField type='color' id="outlined-basic" spellCheck={false} name='background' label="Background" variant="outlined" defaultValue={data?.styleObj?.background||"#000"} onChange={handleColor}/>
        </div>
        <div className='topic-head-prop-color col-md-6'>
            <TextField type='color' id="outlined-basic" spellCheck={false} name='color' label="Text color" variant="outlined" defaultValue={data?.styleObj?.color||"#fff"} onChange={handleColor}/>
        </div> </>}
        <div className='col-md-8'></div>
        </div>
    </div>
    )
}

TopicHeaderPanel.propTypes = {
    /** Heading title of the component */
    header: PropTypes.string,
    /** title content */
    title: PropTypes.string,
    /** related extended content of the component  */
    description: PropTypes.string,
    /** function handler used to save the edited props */
    handleProperties: PropTypes.func
}

export default TopicHeaderPanel
