import React, { useState, useCallback, useEffect } from 'react';
import PropTypes from 'prop-types'
import { useDebouncedCallback } from 'use-debounce';
import HoverSelector from './Hoverselector';
import styles from './highlight.scss';
import ElementHighlighter from './ElementHighlighter';
// import LearningAidList from '../LearningAidList/LearningAidList';



const HoverHighlighter = ({ children, disabled, classes, highlightStyle, placement, ...rest }) => {

    const [target, setTarget] = useState(undefined);

    useEffect(() => {
        if (disabled) {
            setTarget(undefined);
        }
    }, [disabled]);

    const handleMouse = event => {
        let comptarget = null;
        if (event._targetInst) {
            if (event._targetInst.memoizedProps.compid) {
                comptarget = event._targetInst
            }
        }

        if (!comptarget) {
            return;
        }
        setTarget({
            element: event.target,
            cmp: comptarget.memoizedProps,
            root:comptarget
        });

    }

    const _handleElement = useCallback((element) => {
    }, []);
    const handleElement = useDebouncedCallback(_handleElement, target ? 60 : 0);
    
    return (
        <>
            <HoverSelector onElementChange={() => { }} 
            onMouseOver={handleMouse} onMouseLeave={handleMouse} disabled={disabled}
            className={ !disabled && styles.active ? 'styles.highlighter' : 'styles.highlighter' }
            >
            {children}
            </HoverSelector>
            {target && disabled ? <div >
                <ElementHighlighter classes={classes} style={highlightStyle} placement={placement} target={target}  /> 
                </div>   : ''}
        </>
    )


{/* <div className='highlighter'><h3>Hi there : {target.cmp.name} ::  {disabled} :: {styles.active }</h3></div>  */}
}

HoverHighlighter.prototype = {
    disabled: PropTypes.bool,
    placement: PropTypes.object,
    classes: PropTypes.string,
    highlightStyle: PropTypes.string
}

export default HoverHighlighter;