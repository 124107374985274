import React, { useState, useMemo, useCallback } from 'react';
import PropTypes from 'prop-types';
import useDebounce from '../../../utils/useDebounce';

/**
 * ResizableTextArea Component
 * 
 * A reusable text area component that can resize based on the number of rows
 * and includes validation for minimum length with a warning message.
 * 
 *  @param
 * - minRows (number): Minimum number of rows for the textarea.
 * - value (string): Current value of the textarea.
 * - onChange (function): Callback function to handle change events.
 * - placeholder (string): Placeholder text for the textarea.
 * - style (object): Custom styles to apply to the textarea.
 * - className (string): Additional CSS classes to apply to the textarea.
 * - disabled (bool): Whether the textarea is disabled.
 * - minLength (number): Minimum length for the textarea value.
 */

const ResizableTextArea = ({
    minRows,
    value,
    onChange,
    placeholder,
    style,
    className,
    disabled,
    minLength,
    name
}) => {
    const [showWarning, setShowWarning] = useState(false);
    const [inputValue, setInputValue] = useState(value);

    // Memoize the merged style to prevent recalculating it on each render
    const mergedStyle = useMemo(() => ({
        width: '100%',
        minHeight: `${minRows * 20}px`, // Adjust based on minRows
        ...style,
    }), [minRows, style]);


     // Debounce the validation logic to improve performance
     const debouncedValidate = useDebounce((newValue) => {
        if (Number(minLength) && newValue?.length < minLength) {
            setShowWarning(true);
        } else {
            setShowWarning(false);
        }
    }, 300);


    // Memoize the handleChange function to avoid recreating it on each render
    const handleChange = useCallback((e) => {
        const newValue = e?.target?.value;
        setInputValue({...e});
        onChange(e);
        debouncedValidate(newValue);
    }, [onChange, debouncedValidate]);

    // WarningMessage component to display minimum length warning
    const WarningMessage = useMemo(() => {
        return ({ minLength }) => (
            <div className="warning" style={{ color: 'red', fontSize: '0.8em' }}>
                Minimum length is {minLength} characters.
            </div>
        );
    }, []);

    return (
        <div style={{ width: '100%' }}>
            <textarea
                rows={minRows}
                value={inputValue?.target?.value ?? value}
                name={name}
                onChange={handleChange}
                className={`textarea ${className || ''}`}
                placeholder={placeholder}
                style={mergedStyle}
                disabled={disabled}
            />
            {showWarning && <WarningMessage minLength={minLength} />}
        </div>
    );
};

ResizableTextArea.propTypes = {
    minRows: PropTypes.number,
    value: PropTypes.string.isRequired,
    onChange: PropTypes.func.isRequired,
    placeholder: PropTypes.string,
    style: PropTypes.object,
    className: PropTypes.string,
    disabled: PropTypes.bool,
    minLength: PropTypes.number,
};

ResizableTextArea.defaultProps = {
    minRows: 3,
    placeholder: '',
    style: {},
    className: '',
    disabled: false,
    minLength: 0,
};

export default ResizableTextArea;
