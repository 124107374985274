import React from 'react';
import PropTypes from 'prop-types';
import { useState } from "react"

import './multiselect.scss'

/**
 * 
 * @param {*} props 
 * @returns object item for multiselect which will contain options, answer , choice etc
 */

/**
 * Multiselect component will be  able to select the answers more than one.
*/
const MultiSelect = ( props ) => {

  let qObj = props;

  const [ selected, setSelectedAnswer ] = useState( [] );
  const [ isFeed, setFeedBack ] = useState( false );
  const [ isSubmitted, setSumit ] = useState( false );

  const getCheckedItem = ( e ) => {
    let cId = e.target.value;
    e.target.checked ? selected.push( cId ) : selected.splice( selected.indexOf( cId ), 1 )
    setSelectedAnswer( [ ...selected ] );
  }

  const submitAnswer = () => {
    setSumit( true );
    if ( qObj && qObj.correct !== undefined ) {
      setFeedBack(isEqual( selected, [ qObj.correct ] ) )
      console.log('feedback', isFeed)
    }
  }

  /**
   * Checking the question whether 
  */

  const isEqual = ( ar1, ar2 ) => {
    console.debug( ar1.sort().toString(), "========= ", ar2.sort().toString() )
    if(ar1.sort().toString() === ar2.sort().toString()) {
      props.evaluate({mark: 1, answer: true})
    } else {
      props.evaluate({mark: 0, answer: false})
    }
    return ar1.sort().toString() === ar2.sort().toString();
  }

  /**
   * Button styles will changes based if it is valid to submit or not
   * when it comes to disable it becomes greyed out
   * when Button is valid it shows as green
  */
  const getBtnStyles = () =>{
    if(selected.length === 0)
      return {backgroundColor:"#ddd",cursor:'default'}
    else
      return {backgroundColor:"green",cursor:'pointer'}
  }

  return <div className='multi-select-container'>
    <h4 className='title'>{props.head}</h4>
    { qObj && <p>{ qObj.question }</p> }
    <div >
      { qObj && qObj.choice && qObj.choice.length > 0 && qObj.choice.map( ( item, index ) => {
        return <div className="chbox-group" key={ item.id + "_" + index } id={item.id + "_" + index }>
            <input type="checkbox" value={ item.id } onChange={ ( e ) => getCheckedItem( e ) } id={ item.id } />
            <label htmlFor={ item.id }></label>
            <span>{item.text}</span>
          </div>
      } ) }
      <button className="cus-submit" 
      onClick={ () => submitAnswer() } 
      disabled={ selected.length === 0 }
      style={getBtnStyles()}
      >Submit</button>
    </div>
    { selected.length > 0 && isSubmitted && <p>Response:{ " " + isFeed }</p> }
  </div>
}

export default MultiSelect

MultiSelect.propTypes = {
  props: PropTypes.object
}

MultiSelect.defaultProps = {
  item: {
    "id": "1",
    "type": "MultiSelect",
    "dovalidate": true,
    "question_img": "",
    "correct": "A,C",
    "question": "Which of the following would you consider to be a suspicious contact?",
    "head": "Select the correct option",
    "subhead": "What should you do?",
    "validation": "0",
    "choice": [
      {
        "id": "A",
        "text": "Instruct your team to proceed as directed by the U.S. Air Force, purchase airline tickets to the foreign location, and, once there, perform the engineering and repair services. Providing such services to U.S. persons is not a defense service and, as such, requires no ITAR authorization."
      },
      {
        "id": "B",
        "text": "Request that the U.S. Air Force provide personnel to supervise the company employees while in the foreign country. Having U.S. government supervisors onsite will provide the necessary ITAR authorization to perform the services."
      },
      {
        "id": "C",
        "text": "Consult with your export control experts to determine if the export qualifies for an exemption under the ITAR or would require a license or other authorization prior to proceeding."
      }
    ],
    "explanation": {
      "right": "",
      "wrong": ""
    }
  }
}
