import React, { useEffect, useRef, useState } from 'react'
import PropTypes from 'prop-types'
import './topicdescription.scss'
import ErrorOutlineIcon from '@material-ui/icons/ErrorOutline';
import Tooltip from '@material-ui/core/Tooltip';
import PlayerConstants from '../../../utils/PlayerConstants';
import DataHelper from '../../../utils/DataHelper';
import Instruction from '../../ui/Instruction/Instruction';

const debouncing = (selector, callback, isLongContent) => {
    const isView = isLongContent || null
    setTimeout(() => {
        let element = document.querySelector(selector);
        let position = element?.getBoundingClientRect();
        const isMobile = (window.matchMedia('max-width: 768px').matches || window.matchMedia('max-height: 450px').matches)
            // checking for partial visibility
        if((position?.top <= window.innerHeight && position?.bottom >= 0) && (isMobile || isView)) {
            callback()
        } else {
            // checking whether fully visible
            if(position?.top >= 0 && (position?.bottom <= window.innerHeight)) {
                callback()
            }
        }
    
    }, 100)
}


const UPDATE_STATUS = PlayerConstants.COMPONENT_CONSTANTS.UPDATE_STATUS
const SAVE_PROGRESS_STATUS = PlayerConstants.COMPONENT_CONSTANTS.SAVE_PROGRESS_STATUS

/**
 * 
 * @param {*} Header 
 * @returns heading title content
 */

/**
 * 
 * @param {*} description 
 * @returns content description text for component
 */

/**
* TopicDescription component will display the title followed by description
*/
const TopicDescription = ({ Header , description,track, handler, cename, tp_status}) => {
    const contentLayout = document.querySelector('.content')
    const [status, setStatus] = useState({completed: false, text: PlayerConstants.COMPONENT_CONSTANTS.STATUS_INCOMPLETE})
    const topicRef = useRef('')

    /**
     * Manage the component update 
     * progress logic in this method
     * 
     * Update the view status when ever the user interacts
     * Update the progess status ONLY ONCE, when status completes
     * 
     */
    

    const updateProgress = () => {
        /**
         * View status, will update while navigating to next topic
         */
        track.state = { ...track.state, c: 1 }
        /**
         * Progress updated as and when its completed
         */

        if (!track.status) {
            track.status = 1;
            if (track.status) {
                setStatus({completed: true, text:PlayerConstants.COMPONENT_CONSTANTS.STATUS_COMPLETE})
                handler({ type: UPDATE_STATUS, id: track.id, name: cename, isolated: true })
            }
        }
        /**
         * If anything to be intimated always
         * call this
         */
        // handler({ type: UPDATE_STATUS, id: track.id, name:cename })

    }
    
    // componentObserver('section[name=TopicDescription]', updateProgress)
    contentLayout?.addEventListener('scroll', ()=> {
        DataHelper.scrollCompletionDebouncing('section[name=TopicDescription]', updateProgress, true)
    })

    useEffect(()=>{
        if(tp_status > -1) updateProgress();
    },[tp_status])

    return (
        <>
        <Instruction isInstruction={false} completed={(track.status === 1 || status.completed)} title={(track.status === 1 || status.completed) ? PlayerConstants.COMPONENT_CONSTANTS.STATUS_COMPLETE : PlayerConstants.COMPONENT_CONSTANTS.STATUS_INCOMPLETE} classText={`${(track.status === 1 || status.completed) && PlayerConstants.COMPONENT_CONSTANTS.COMPLETED_CLASS}`}/>
        <div className='snippet-div' ref={topicRef}>
            <div className='snippet-div-main'>
                {/* <h2 className='snippet-head-tag'>{Header}</h2> */}
                <div className='snippet-description'>
                    <div className='text-content' dangerouslySetInnerHTML={{ __html: description }}></div>
                </div>
            </div>
        </div>
        </>
    )
}

TopicDescription.propTypes = {
    /** Heading title of the component */
    Header: PropTypes.string,
    /** Text content of component */
    description: PropTypes.string,
    /**  Tracking the component progress */
    track:PropTypes.object,
    /** Func description for tracking*/
    handler:PropTypes.func
}

export default TopicDescription