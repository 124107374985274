import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import Datahelper from '../../../utils/DataHelper'
import { FormLabel } from '@material-ui/core';
import UploaderPanel from '../UploaderPanel/UploaderPanel';
import Acc from '@material-ui/core/Accordion';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import Typography from '@material-ui/core/Typography';
import { AddCircle } from '@material-ui/icons';
import DeleteIcon from '@material-ui/icons/Delete';
import RichTextEditor from '../RichTextEditor/RichTextEditor';
import ACLHelper from '../../../utils/ACLHelper';

const AttachmentsPanel = ( { items, handleProperties, cename, id } ) => {

    const [ expanded, setExpanded ] = useState( 0 );
    const [ list, setList ] = useState( JSON.parse( JSON.stringify( items ) ) )

    useEffect( () => {
        handleProperties( { items: list } )
    }, [ list ] )

    let max_count = 4

    const handleChange = ( panel ) => ( event, isExpanded ) => {
        setExpanded( isExpanded ? panel : false );
    };


    const handleDelete = ( e, index ) => {
        const removed = list.filter( ( e, i ) => i !== index )
        setList( removed )
        handleProperties( { items: removed } )
        e.stopPropagation()
    }

    const addAccordion = () => {
        if ( max_count > list.length ) {
            list.push( {
                id: list.length + 1,
                notes: 'Some notes about attachment',
                path: 'Somepath.doc'
            } )
            setList( list )
            handleProperties( { items: list } )
        }
    }

    /**
     * Depends on the order of static template data extensions kept
     * Find the extension from uploaded file then find the index and update the path 
     */
    const handleMedia = ( data ) => {
        // console.log( "uploaded", data )
        try {
            if ( data ) {
                let fname = data.name?.split( '.' )[ 0 ]
                let id = fname?.split( '-' )[ 1 ]
                let index = Number( id ) - 1;
                list[ index ] = { ...list[ index ]}
                list[ index ].path = data.name
                setList(list);
                handleProperties( { items: list } )
            }
        }
        catch ( e ) {
            console.log( e );
        }

    }

    const eventHandlers = ( value, i ) => {
        list[ i ][ 'notes' ] = value
        setList( list )
        handleProperties( { items: list } )
    }

    return <div className='accordion-prop-container'>
        <div className={ `` }>
            {
                list && ( list.length > 0 ) && list.map( ( el, index ) => {
                    return <Acc key={ index } expanded={ expanded === index } onChange={ handleChange( index ) }>
                        <AccordionSummary
                            aria-controls="panel1a-content"
                            id="panel1a-header"
                        >
                            <><Typography className={ `label-bold` }>{ 'Attachment' }  { index + 1 } </Typography>
                                <DeleteIcon className='delete-prop-accordion' onClick={ ( e ) => handleDelete( e, index ) } />
                            </>
                        </AccordionSummary>
                        <AccordionDetails>
                        { ACLHelper.isFeatureEnabled( 4, 2 ) && 
                            <UploaderPanel accept={ '.pptx, .pdf, .doc,.docx,.ppt, .xls, .xlsx,' } fileName={list[index]?.path} name={ `${ id }_${ Datahelper.getTime() }-${ index + 1 }` } setUploadedData={ handleMedia } />
                        }
                            <p className="label">Description</p>
                            <RichTextEditor data={ el.notes } eventHandler={ ( e ) => eventHandlers( e, index ) } />
                        </AccordionDetails>
                    </Acc>
                } )
            }
             <div className='add-new-slide' title={ 'Add Accordion' } onClick={ addAccordion }><AddCircle className='add-accordion' /> Add New Attachment</div>

        </div>
    </div>


}

AttachmentsPanel.propTypes = {
    /** accordion panel array data list  */
    items: PropTypes.array,
    /** function handler used to save the edited props */
    handleProperties: PropTypes.func
}

export default AttachmentsPanel