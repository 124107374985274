import React,{useEffect, useState} from 'react'
import { SnippetDesc, ContainerHead, Imgtag } from './styles'
import './Imagetext.scss'
import PropTypes from 'prop-types'
import { pathCreater } from '../../../utils/MediaConfig'
import ContentTracking from "../../../core/progress/ContentTracking";
import Tooltip from '@material-ui/core/Tooltip';
import ErrorOutlineIcon from '@material-ui/icons/ErrorOutline';
import PlayerConstants from '../../../utils/PlayerConstants';
import Instruction from '../../ui/Instruction/Instruction'
import DataHelper from '../../../utils/DataHelper'

/**
 * @boolean
 * isLongContent prop flag is for tracking the long content area of component .
 * will complete the progress when it sees the root element will not wait for scrolling till end
*/

const debouncing = (selector, callback, isLongContent) => {

    const isView = isLongContent || null
    setTimeout(() => {
        let element = document.querySelector(selector);
        let position = element?.getBoundingClientRect();
        const isMobile = window.matchMedia('max-width: 768px').matches
            // checking for partial visibility
        if((position?.top < window.innerHeight && position?.bottom >= 0) && (isMobile || isView)) {
            callback()
        } else {
            // checking whether fully visible
            if(position?.top >= 0 && position?.bottom <= window.innerHeight) {
                callback()
            }
        }
    
    }, 100)
}



const UPDATE_STATUS = PlayerConstants.COMPONENT_CONSTANTS.UPDATE_STATUS
const SAVE_PROGRESS_STATUS = PlayerConstants.COMPONENT_CONSTANTS.SAVE_PROGRESS_STATUS

/**
 * 
 * @param {*} items 
 * @returns image and textcontent in array of each item
 */

/**
 * 
 * @param {*} cename 
 * @returns name of component
 */

/**
 * Imagetext component contains one side text and Image
*/
const ImageText = ({ items,track,handler,cename,textPosition, tp_status }) => {
    const contentLayout = document.querySelector('.content')
    const [status, setStatus] = React.useState({completed: false, text: PlayerConstants.COMPONENT_CONSTANTS.STATUS_INCOMPLETE})
      /**
     * Manage the component update 
     * progress logic in this method
     * 
     * Update the view status when ever the user interacts
     * Update the progess status ONLY ONCE, when status completes
     * 
     */
  const updateProgress = () => {

    /**
     * View status, will update while navigating to next topic
     */
    track.state = { ...track.state, c:1}
    /**
     * Progress updated as and when its completed
     */

    if (!track.status) {
      track.status = 1;
      if (track.status) {
        setStatus({completed: true, text:PlayerConstants.COMPONENT_CONSTANTS.STATUS_COMPLETE})
        handler({ type: SAVE_PROGRESS_STATUS, id: track.id, name:cename })
      }
    }
    /**
     * If anything to be intimated always
     * call this
     */
    // handler({ type: UPDATE_STATUS, id: track.id, name:cename })

}

    // componentObserver('section[name=ImageText]', updateProgress)
    contentLayout.addEventListener('scroll', ()=> {
        DataHelper.scrollCompletionDebouncing('section[name=ImageText]', updateProgress, true)
    })

    const getImageUrl = (path)=>{
        return DataHelper.getResourcePath(0,path);
      }

      useEffect(()=>{
        if(tp_status > -1) updateProgress();
    },[tp_status])

  
    return (
        <>
        <Instruction isInstruction={false} completed={(track.status === 1 || status.completed)} title={(track.status === 1 || status.completed) ? PlayerConstants.COMPONENT_CONSTANTS.STATUS_COMPLETE : PlayerConstants.COMPONENT_CONSTANTS.STATUS_INCOMPLETE} classText={`${(track.status === 1 || status.completed) && PlayerConstants.COMPONENT_CONSTANTS.COMPLETED_CLASS}`}/>
            <div className='image-div'>
                {items?.length > 0 &&  items.map(el => <ContainerHead className='layout image-text' key={el.id}>
                    <div className={`${(el.isImageRight ||!el?.isHalf)  ? 'row':'row-reverse'} ${(!el?.isHalf) && 'center-img'}`} style={{background:el?.bg_color || '#414141'}}>
                        <div className={`${el.isHalf ? 'col-md-6 col-lg-6 ' : 'col-md-12 col-lg-12 '} col-sm-12 col-12 img-section`}>
                            <div className='image-main'>
                                {el.imageUrl && <Imgtag loading='lazy' src={getImageUrl(el.imageUrl)} 
                                // onError={(e)=>{e.target.onerror = null;
                                // e.target.src=getPlaceHolderImg(600)} } 
                                 /> }
                            </div>
                        </div>
                        <div 
                        style={{alignItems:el?.textPosition}}
                        className={`${el.isHalf ? 'col-md-6 col-lg-6 ' : 'col-md-12 col-lg-12 '}col-sm-12 col-12 custom-text-position text-section`}
                        >
                            {/* <h2 className='title-head'>{el.title}</h2> */}
                            <SnippetDesc  className='text-content' dangerouslySetInnerHTML={{__html: el.description}}>
                                
                            </SnippetDesc>
                        </div>
                    </div>
                </ContainerHead>)  }
            </div>
            </>
    )
}

ImageText.propTypes = {
    /** Items will include image url, content text and whether it should be half or not */
    items: PropTypes.array.isRequired,
    /** Tracking the component progress */
    track:PropTypes.instanceOf(ContentTracking),
    /** Func description for tracking*/
    handler:PropTypes.func
}

export default ImageText
