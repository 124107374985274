import React from 'react'
import PropTypes from 'prop-types';
import CeAssets from'../../../assets/img/CeAssets.png';
import DataHelper from '../../../utils/DataHelper';

/**
 * 
 * @param {*} vignettes 
 * @returns returns array of image items for showing the vignettes url
 */

/**
 * Vignettes contains the feed images as content and urls
*/
const Vignettes = ({topic, vignettes}) => {
    return (
        <div className={`vignette-container row ${topic === 'vignettes' ? 'vignette-show': ''} `}>
            {vignettes?.length > 0 ? vignettes?.map((el, index) => <div key={index} className={'col-sm-6 col-md-6 data-' + topic } style={{position: 'relative', padding: '0 2rem'}}>
                <a href={el?.content?.path} target={'_blank'} >
                    <img src={DataHelper.getSupplementarieFilePath(el?.content?.thumb)} onError={(e)=>{e.target.onerror = null; e.target.src=CeAssets} }/> 
                    <p className='overlay-block-content'>{el?.content?.title}</p>
                </a>
            </div>) : <h3 className='no-supplimentarie'>No New Vignettes</h3> }
        </div>
    )
}

Vignettes.propTypes = {
    /** Topic of current section */
    topic: PropTypes.string,
     /** vignettes will contain array of content images related to vignettes */
    vignettes: PropTypes.array
}

export default Vignettes
