import React, { useEffect } from 'react'
import Acc from '@material-ui/core/Accordion';
import useForceUpdate from 'use-force-update';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import Typography from '@material-ui/core/Typography';
import DeleteIcon from '@material-ui/icons/Delete';
import EditIcon from '@material-ui/icons/Edit';
import TextField from '@material-ui/core/TextField';
import CheckIcon from '@material-ui/icons/Check';
import { AddCircle } from '@material-ui/icons';
import UploaderPanel from '../UploaderPanel/UploaderPanel';
// import CKeditor from '../CKeditor/CKeditor';
import PropTypes from 'prop-types'
import PlayerConstants from '../../../utils/PlayerConstants';
import Datahelper from '../../../utils/DataHelper'
import RichTextEditor from '../RichTextEditor/RichTextEditor';
import ACLHelper from '../../../utils/ACLHelper';
import { FormLabel } from '@material-ui/core';
import { getPlaceHolderImg } from '../../../utils/MediaConfig';
import Checkbox from '@mui/material/Checkbox';
import AccordionItem from '../../../components/ui/AccordionItem/AccordionItem'; // Assuming your AccordionItem component is in a separate file
import { DndProvider } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';
import { FormControlLabel, Radio, RadioGroup } from '@material-ui/core';
/**
 * 
 * Tabs editor used to modify the propertis of Tabs player component
 */

const TabsPanel = ({items, handleProperties, cename,id, handleCeAssets}) => {
    const [expanded, setExpanded] = React.useState(0);
    const forceUpdate = useForceUpdate()
    const [editMode, setEdit] = React.useState(null);
    const [list, setTabList] = React.useState(JSON.parse(JSON.stringify(items)))
    const [drag, setDrag] =  React.useState(false);
    let max_count = 0

    useEffect(()=>{
        handleProperties({items:list})
    },[list]);

    const item = Object.values(PlayerConstants.COMPONENTS_LIST).filter((el) => el.name === cename)
    max_count = item[0].max_count
    const handleChange = (panel) => (event, isExpanded) => {
        setExpanded(isExpanded ? panel : false);
        setEdit(null)
    };

    const handleChangeInput = (e, id) => {
        list[id][e.target.name] = e.target.value
        setTabList(list)
        handleProperties({items: list})
    }

    const handleEdit = (e, index) => {
        setEdit(index)
        e.stopPropagation()
    }

    const handleSave = (e, index) => {
        setEdit(null)
        e.stopPropagation()
        setTabList(list)
        handleProperties({items: list})
    }

    const handleDelete = (e, index) => {
        const removed = list.filter((e, i) => i !== index)
        setTabList(removed)
        handleProperties({items: removed})
        forceUpdate()
        e.stopPropagation()
    }

    const addTabs = () => {
        if(max_count > list.length) {
            const maxId = Math.max(...list?.map(item => item?.id), 0)||1;
            list.push({
                id: maxId + 1,
                title: 'Title',
                imageUrl: getPlaceHolderImg(200),
                description: 'Description'
            })
            setTabList(list)
            handleProperties({items: list})
            forceUpdate()
        }
    }

    /**
     * 
     * @param {} value 
     * data html string used for description of the component
     */
    const eventHandlers = (value, i) => {
        list[i]['description'] = value
        setTabList(list)
        handleProperties({items: list})
    }

    const handleMedia =(data,index)=>{
        try{
            if(data){
                // let fname = data.name?.split('.')[0]
                // let id = fname?.split('-')[1]
                // let index = Number(id)-1;
                list[index] = {...list[index],imageUrl:data.name}
                handleCeAssets(list[index]?.imageUrl,data.name,list);
                setTabList(list);
                handleProperties({items:list});
            }
        }
        catch(e){
            console.log(e);
        }
    }
    const handleStyle = (e,index) => {
        list[index][e.target.name] = e?.target?.value||""
        setTabList(list)
        handleProperties({items:list})
        forceUpdate()
    }

    const handleUpdateAccList = (e, listData) => {
        setTabList([...listData]);
        handleProperties({items:[...listData]});
    }

    const videoPathChange = (e,index) => {
        list[index][e.target.name] = e.target.value
        setTabList(list)
        handleProperties({items:list})
        forceUpdate()
    }

    return (
        <div className={`card-prop-main-ui acccordion-item-list`}>
        <div className="acccordion-item-switch"><FormControlLabel label={'Allow reorder'} className="ck-check-box" labelPlacement="end" name="isCharacter" control={<Checkbox checked={drag} onChange={(e) => setDrag(!drag)} />} /> </div>
        <DndProvider backend={HTML5Backend}>
        {/* <div className={``}> */}
            {
                (list.length > 0) && list.map((el, index) => {
                    return (<AccordionItem
                    className='inner-summary'
                    key={index}
                    // name={el?.title}
                    index={index}
                    el={el}
                    data={list}
                    listUpdate={handleUpdateAccList}
                    expanded={expanded}
                    handleAccordionChange={handleChange}
                    isDrag={drag}
                    
                    summary={{
                        title: { name: "title", },
                        delete: { min: 1 }
                    }}
                    dynamicDetailsComponent={<>{ACLHelper.isFeatureEnabled(4, 2) &&
                        <div className="mb-3 col-md-12">
                            <UploaderPanel accept={'image'} 
                                fileName={list[index]?.imageUrl} 
                                name={`${id}_${Datahelper.getTime()}-${index + 1}`} 
                                setUploadedData={(el) => handleMedia(el, index)} 
                                styles={{
                                    po:false,
                                    igColor:list[index]?.igColor ||"rgba(0, 0, 0, 0)",
                                  }}
                                  bgChange={(e) => handleStyle(e,index)}
                            />
                        </div>}

                    <p className="label">Content</p>
                    {/* <CKeditor data={el.description} eventHandler={(e) => eventHandlers(e, index)}/> */}
                    <RichTextEditor data={el.description} eventHandler={(e) => eventHandlers(e, index)} />
                    {/* <div className='row mt-15'>
                    <div className="mb-3 col-md-12">
                    <TextField id="outlined-basic" spellCheck={false} 
                    name={`${id}_${index+1}_video`} 
                    label="Video url" variant="outlined"
                    // defaultValue={el.title}
                    placeholder="https://assets.contentenablers.com/rlm/videos/../topics/61460c0741b7c912e9a6539a/o63kpEk_38aYlWY-_transcoded-ewMpA7Ns2Q8eH8Ds-EGADD_CS_03_ITAR.mp4"
                    onClick={(e) => e.stopPropagation()} 
                    onChange={(e) =>videoPathChange(e,index)}/>
                    </div>
                </div> */}
                    <div className='row mt-15'>
                        {/* <div className="mb-3 col-md-6 ">
                        <label htmlFor="formFile" className="form-label">Browse Video</label>
                        <UploaderPanel accept={'video/*'} name={`${id}_${index+1}_video`} setUploadedData={handleImage}/>
                    </div> */}
                </div> </>}
                    />
                    )
                
                })
            }
            <div className='add-new-slide' title={'Add Tabs'} onClick={addTabs}><AddCircle className='add-tabs'  /> Add New Tab</div>
        {/* </div> */}
        </DndProvider>
        </div>
    )
}

TabsPanel.propTypes = {
   /** items array used for having list of tabs section */
   items: PropTypes.array,
   /** function handler used to save the edited props */
   handleProperties: PropTypes.func,
   /** function handler used to update the Assets */
   handleCeAssets : PropTypes.func,
}


export default TabsPanel
