import React, { useEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import BuildHelper from "../../../utils/BuildHelper";
import DataHelper from '../../../utils/DataHelper';
import { getPlaceHolderImg } from "../../../utils/MediaConfig";

// Convert hex color to RGBA with opacity
const hexToRGB = (hexColor, opacity) => {
  try {
    const hex = hexColor.replace('#', '');
    const r = parseInt(hex.slice(0, 2), 16);
    const g = parseInt(hex.slice(2, 4), 16);
    const b = parseInt(hex.slice(4, 6), 16);
    const rgbaColor = `rgba(${r}, ${g}, ${b}, ${opacity})`;
    return rgbaColor || hexColor || 'transparent';
  }catch(e){}
}

// Get background image URL using DataHelper
const getBgImageUrl = (img) => {
  return DataHelper.getResourcePath(0, img);
}

const ImageTag = (props) => {
  const { img, bgColor, children, maxHeight, minHeight, position, styles, nonAspect, opacity,key} = props;
  const containerRef = useRef();
  const [isValidImage, setIsValidImage] = useState(true);

  const isValidUrl = (url) => {
    if(img==="*") return false
    const urlRegex = /^(ftp|http|https):\/\/[^ "]+$/;
    return url && urlRegex.test(url);
};

  let BackgroundImg = styled.div`
    background: ${BuildHelper.isOnScorm || isValidUrl(getBgImageUrl(img)) ? `url(${getBgImageUrl(img) || getPlaceHolderImg(200) || ""}) ${position || '50% 50% / cover no-repeat'}`: null};
    width: 100%;
    height: 100%;
    position: absolute;
    ${styles || {}}
`;

const Layer = styled.div`
    background-color: ${String(bgColor).includes("rgba") ? bgColor : hexToRGB(bgColor, opacity || 0.9)};
    color: white;
    position: absolute;
    width: 100%;
    height: 100%;
    padding: 20px;
    box-sizing: border-box;
`;

  const continuerStyle = {
    position: 'relative',
    width: '100%',
    height: "100%", //  default  height value
    overflow: 'hidden',
    maxHeight: maxHeight ?`${maxHeight}px` : '100vh !important',
    minHeight: minHeight ?`${minHeight}px` : '100% !important'
  };
  
  const [commonComponentStyle, setCommonComponentStyle] = useState({...continuerStyle});
  const hashedBackgroundPosition = position ? position : null;

  useEffect(() => {
    if (img && !nonAspect) {
      const imgs = new Image();
      imgs.src = getBgImageUrl(img);
      imgs.onload = () => {
        setIsValidImage(true);
        // Calculate the aspect ratio of the image
        const aspectRatio = imgs.width / imgs.height;

        // Calculate the height based on the aspect ratio
        const newHeight = containerRef && `${containerRef?.current?.clientWidth / aspectRatio}px`;
          setCommonComponentStyle({
            ...commonComponentStyle,
            height: newHeight || "100%",
            maxHeight: maxHeight ?( maxHeight ==="unset" ? maxHeight : `${maxHeight}px`) : '90vh !important',
            minHeight: minHeight ? `${minHeight}px` : '100% !important',
          });
      };
      imgs.onerror = () => {
        // fallback image or handle the error 
        const fallbackImage = getPlaceHolderImg(200); // set a fallback image URL
        setIsValidImage(false);
        BackgroundImg = styled(BackgroundImg)`
          background: url(${fallbackImage || ""}) ${position || '50% 50% / cover no-repeat'}
        `;
      };
    }
  }, [img , maxHeight, hashedBackgroundPosition, position, bgColor, minHeight]);

  const defaultImageStyles={
    background:"#e9ecef",
    position:"absolute",
    width:"100%",
    height:"100%"
  }

  return (
    <div className="common-image" style={{ ...commonComponentStyle }} ref={containerRef}>
      {/* to using an img element with the onError event to check image */}
      <img
        src={getBgImageUrl(img)}
        alt="Preview"
        style={{ display: 'none' }}
        onLoad={()=>setIsValidImage(true)}
        onError={()=>setIsValidImage(false)}
      />
      { isValidImage  ?  <BackgroundImg /> : <div style={{...defaultImageStyles,...styles}}/> }
      <Layer>
        {children && children}
      </Layer>
    </div>
  );
};

ImageTag.propTypes = {
  // Image URL or '*' for no image
  img: PropTypes.string,
  // Background color for the layer
  bgColor: PropTypes.string,
  // Opacity of the layer
  // opacity: PropTypes.number,
  // Additional content within the layer
  children: PropTypes.node,
  // Maximum height of the container
  // maxHeight: PropTypes,
  // Minimum height of the container
  // minHeight: PropTypes,
  // Background position of the image
  position: PropTypes.string,
  // Additional styles for the component
  styles: PropTypes.object,
  // Flag to ignore aspect ratio calculations
  nonAspect: PropTypes.bool,
};

export default ImageTag;
