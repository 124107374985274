import React, { useState, useEffect } from 'react'
import DeleteIcon from '@material-ui/icons/Delete';
import { TextField } from '@material-ui/core';
import useForceUpdate from 'use-force-update';
import UploaderPanel from '../UploaderPanel/UploaderPanel';
import PropTypes from 'prop-types'
import Datahelper from '../../../utils/DataHelper'
import RichTextEditor from '../RichTextEditor/RichTextEditor';
import ACLHelper from '../../../utils/ACLHelper';
import { useSelector } from 'react-redux';
import Typography from '@material-ui/core/Typography';
import Acc from '@material-ui/core/Accordion';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import PlayerConstants from '../../../utils/PlayerConstants';
import EditIcon from '@material-ui/icons/Edit';
import CheckIcon from '@material-ui/icons/Check';
import { FormControlLabel } from '@material-ui/core';
import Checkbox from '@mui/material/Checkbox';
import CustomCheckbox from '../../../components/ui/CustomCheckbox/CustomCheckbox';
// import { TextField } from '@material-ui/core';
// import { pathCreater } from '../../../utils/MediaConfig';

/**
 * 
 * Videopanel editor is used to upload the video url and other properties handled by the video component
 * 
 * handleCeAssets - This function handles the CE Library asset usage update actions ( previousAsset | newAsset | updatedProps ).                  
 * 
 */

const VideoPanel = ({ readable, src, poster, cuepoints,handleProperties, description, videoMedia, posterMedia, id, handleCeAssets, cename,ccid, forward_rewind }) => {
    const [data, setData] = useState({ readable, src, cuepoints, description, videoMedia, posterMedia, ccid, poster, forward_rewind })
    const forceUpdate = useForceUpdate()
    const { courseEdit: { isAddVideoSupplimentaries } } = useSelector((state) => state);
    const [expanded, setExpanded] = useState(-1);
    const [editMode, setEdit] =useState(null);
    const [editCue, setEditCue] =useState(null);
    const [isPoster, setIsPoster] =useState(["","*"].includes(poster || "") ? false : true);
    // const [currentTime, setCurrentTime] = useState(0);

    // const getCurrentTime = (src) => {
    //     const currentVideo = document.querySelector(`video[src="${pathCreater(1) + src}"]`)
    //     setCurrentTime(currentVideo.currentTime)
    // }

    // useEffect(() => {
    //     try{
    //         getCurrentTime(src)
    //         if(currentTime > 0 && cuepoints.length > 0 ) {
    //             if(currentTime !== cuepoints[cuepoints.length - 1]['time']){
    //                 cuepoints.push({
    //                     id: cuepoints.length + 1,
    //                     time: Math.floor(Number(currentTime)),
    //                     type: 1,
    //                     shown: false
    //                 })
    //                 forceUpdate()
    //             }
    //         }
    //     }
    //     catch(err){
    //         console.log(err);
    //     }
        
    // }, [currentTime])

    // const handleAddCue = (e) => {
    //     if(e === '') {
    //         return
    //     } else {
    //         cuepoints.push({
    //             id: cuepoints.length + 1,
    //             time: Number(e)
    //         })
    //     }
    //     handleProperties(data)
    //     forceUpdate()
    // }

    useEffect(() => {
        console.debug("============= data inside hooks ================",data)
        handleProperties(data)
    }, [data])

    /**
     * 
     * @param {} value 
     * data html string used for description of the component
     */
    const eventHandler = (value) => {
        setData({
            ...data,
            description: value
        })
        // handleProperties(data)
    }    
    const handlePoster = (uploaded) => {
        try{
            let prop={...data,poster:uploaded?.name}
            handleCeAssets(data?.poster,uploaded.name,prop);
            setData(prop)
            handleProperties(prop);
        }catch(e){
            console.debug(e);
        }
    }

    // const assetsId = (src) => {
    //     try {
    //         let list = src.split('/');
    //         let nameList = list[list.length - 1].split('.');
    //         let FileName = -1;
    //         if (nameList?.length > 0) {
    //             FileName = nameList[0]
    //         }
    //         return FileName;
    //     } catch (e) {
    //         console.debug(e);
    //     }
    // }

    // const checkCuePoint = (pre,crt) => {

    //     if (pre !== crt) {
    //         let preAssetId = -1; let crtAssetId = -1;
    //         const path = PlayerConstants.ASSET_HOST[1]

    //         if (pre.includes(path)) preAssetId = assetsId(pre);
    //         if (crt.includes(path)) crtAssetId = assetsId(crt);

    //         if (crtAssetId === -1 || crtAssetId === -1) {
    //             return false
    //         }
    //         return true 
    //     }
    //     return false
    // }


        const handleVideo = (uploaded) => {
            try {
                if(cename==="ArchiveVideo"){
                    setData({
                        ...data,
                        src: uploaded?.name,cuepoints:[] ,content:uploaded?.ctext||undefined,
                        ccid:uploaded?.ccid || -1
                    })
                    handleProperties({ ...data, 
                        src: uploaded?.name,cuepoints:[],
                        content:uploaded?.ctext||undefined,
                        ccid:uploaded?.ccid || -1});
                }else{
                    let prop={
                        ...data,
                        src: uploaded?.name,content:uploaded?.ctext||undefined,
                    }
                 handleCeAssets(data?.src, uploaded.name,prop);
                 setData(prop);
                 handleProperties(prop);
                }
                

            } catch (e) {
                console.debug(e);
            }
        }


    const deleteCue = (e, index) => {
        try {
                const removed = cuepoints?.filter((e, i) => i !== index);
                setData({
                    ...data,
                    cuepoints: [...removed]
                })
                handleProperties({...data, cuepoints: [...removed]})
        } catch (e) {
            console.debug(e);
        }
    }


    useEffect(() => {
        if (isAddVideoSupplimentaries?.isActive && isAddVideoSupplimentaries?.cduration && isAddVideoSupplimentaries?.isEdit === -1) {
            let newCue = {
                description: "Content",
                title:'Title' ,
                point:isAddVideoSupplimentaries.cduration,
                ctype: 0,
            }
            let newCuePoint =data?.cuepoints || []
            newCuePoint.push(newCue);
            setData({
                ...data,
                cuepoints: newCuePoint
            });
            handleProperties({ ...data, cuepoints: newCuePoint });
            setExpanded(cuepoints?.length - 1);
        }else if(isAddVideoSupplimentaries?.isEdit !== -1){
            cuepoints?.forEach((e,i)=>{
            if(Number(e?.point)===Number(isAddVideoSupplimentaries?.isEdit?.time)){
                setExpanded(i);
            }});
        }
    }, [isAddVideoSupplimentaries]);

    const handleChange = (panel) => (event, isExpanded) => {
        setExpanded(isExpanded ? panel : false);
        setEdit(null);
    };

    const cueContentEventHandler = (value, idx) => {
        try {
            let updatedCue = data?.cuepoints;
            updatedCue[idx].description=value
            setData({
                ...data,
                cuepoints: updatedCue
            })
            handleProperties({ ...data, cuepoints: updatedCue });
        } catch (e) {
            // console.debug(e);
        }
    }


    
    
    const handleEdit = ( e, index ) => {
        setEdit( index )
        e.stopPropagation()
    }

    const handleSave = ( e, index ) => {
        setEdit( null )
        e.stopPropagation()
    }
    
    const handleChangeInput = ( e, id ) => {
        try {
            let updatedCue = data?.cuepoints;
            updatedCue[id].title = e?.target?.value;
            setData({
                ...data,
                cuepoints: updatedCue
            })
            handleProperties({ ...data, cuepoints: updatedCue });
        } catch (e) {
            // console.debug(e);
        }
    }

    const handleQue=(e,index)=>{
        let updatedCue =data?.cuepoints;
        updatedCue[index].point=e?.target?.value||"";
        setData({...data,cuepoints: [...updatedCue]})
        handleProperties({ ...data, cuepoints: [...updatedCue] });
    }

    const handleFirstFrame=(e)=>{
      try{
        if(!e){
            let prop={...data,poster:"*"}
            handleCeAssets(data?.poster,"*",prop);
            setData(prop)
            handleProperties(prop);
        }
        setIsPoster(e);
      }catch(e){
        console.debug(e);
      }
    }
    const handleForward=(e)=>{
        let prop={...data,[e.target.name]:e?.target?.checked};
        setData({...prop})
        handleProperties({...prop});
    }


    return (
        <div className='video-prop-container'>
            {!isAddVideoSupplimentaries?.isActive && <div className='row'>
                {ACLHelper.isFeatureEnabled(4, 2) &&
                    <div className="mb-3 col-lg-12 col-md-12 col-sm-12">
                        <label htmlFor="video" className="label">Browse Video</label>
                        <UploaderPanel accept={'video'} fileName={data?.src} name={`${id}_video`} setUploadedData={handleVideo} />
                    </div>}

                    <div className="mb-3 col-lg-12 col-md-12 col-sm-12 poster-check">
                      <div className='d-flex justify-content-between'>
                        <CustomCheckbox
                        label={'Select a poster for this video'}
                        checked={isPoster}
                        name="rand_ques"
                        onChange={(e) => handleFirstFrame(e.target.checked)}
                      />
                     <CustomCheckbox
                        label={'Fast Forward & Rewind'}
                        checked={data?.forward_rewind ?? true}
                        name="forward_rewind"
                        onChange={(e) => handleForward(e)}
                    /> </div>
                        {/* <FormControlLabel label={'Select a poster for this video'} className="ck-check-box" labelPlacement="end" control={<Checkbox checked={isPoster} onChange={(e) => handleFirstFrame(e.target.checked)} />} /> */}
                        {isPoster && <><UploaderPanel accept={'image'} fileName={data?.poster} name={`${id}_${Datahelper.getTime()}-poster`} setUploadedData={handlePoster} /> </>}
                    </div>
            </div>}
            {cename === "Video" && (!isAddVideoSupplimentaries?.cduration) &&
                <div className='video-prop-readable'>
                    <label className='label-bold'>Description</label>
                    <RichTextEditor data={description ? description : readable} eventHandler={eventHandler} height={200} />
                </div>}
            <div className='inner-accordion-continuer x-align'>
                {cuepoints?.length > 0 && isAddVideoSupplimentaries?.isActive && cuepoints?.map((el, index) => {
                    return el.type !== 5 &&( <Acc key={index} expanded={expanded === index} onChange={handleChange(index)}>
                        <AccordionSummary
                          className='inner-summary'
                            aria-controls="panel1a-content"
                            id="panel1a-header"
                        >
                            <> 
                            { editMode === index ? <>
                                    <TextField id="outlined-basic" className='list-title' spellCheck={ false } name='title'  defaultValue={el?.title} onClick={ ( e ) => e.stopPropagation() } onChange={ ( e ) => handleChangeInput( e, index ) } />
                                    <CheckIcon className='save-prop-accordion' onClick={ ( e ) => handleSave( e, index ) } />
                                </> : <>
                                <Typography className={ `label-bold` }>{el?.title} {index + 1}</Typography>
                                <EditIcon className='edit-prop-accordion' onClick={ ( e ) => handleEdit( e, index ) } /> <DeleteIcon className='delete-prop-accordion' onClick={(e) => deleteCue(e, index)} /> </> }
                            </>
                        </AccordionSummary>
                        <AccordionDetails>
                            <TextField
                                id="outlined-basic"
                                className='que-point-edit-area' 
                                spellCheck={ false } name='title'  
                                defaultValue={el?.point}
                                onClick={ ( e ) => e.stopPropagation() }
                                onChange={ ( e ) => handleQue( e, index ) }
                                disabled={editCue !== index}
                                InputProps={{
                                    endAdornment:editCue === index ? <CheckIcon className='save-icon' onClick={ ( e ) => setEditCue( null ) } /> : <EditIcon className='edit-icon' onClick={ ( e ) => setEditCue(index ) } />,
                                    startAdornment:   <p className='label'>CuePoint:</p>
                                }}
                                />
                                 {editCue===-1 && <p className='d-flex'>Please enter a number with up to two decimal places followed by ".59"</p>}
                            <RichTextEditor data={el?.description} eventHandler={(e) => cueContentEventHandler(e, index)} />
                        </AccordionDetails>
                    </Acc>)
                })
                }
            </div>
        </div>
    )
}

VideoPanel.propTypes = {
    /** video source url of the component */
    src: PropTypes.string,
    /** poster thumbnail image for video */
    poster: PropTypes.string,
    /** related extended content of the component  */
    description: PropTypes.string,
    /** function handler used to save the edited props */
    handleProperties: PropTypes.func,
    /** function handler used to update the Assets */
    handleCeAssets: PropTypes.func,
}

export default VideoPanel
