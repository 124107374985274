import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import AssetsCard from './AssetsCard';
import FolderSpecialIcon from '@mui/icons-material/FolderSpecial';
import AssetsPlayer from './AssetsPlayer';
import PropTypes from 'prop-types'

const Wishlist = ({ type, link }) => {

  const ceAssetsInfo = useSelector(state => state.ceAssetsInfo);
  const [SelectedList, setSelectedList] = useState([]);
  const [selectedContent, setSelectedContent] = useState(null);

  const handleSubmit = (item) => {
    if (item) { link(selectedContent) }
  }
  useEffect(() => {
    if(ceAssetsInfo?.wishlist)
    if(ceAssetsInfo?.wishlist?.length > 0){
      setSelectedList(ceAssetsInfo?.wishlist[0])
    }
  }, [ceAssetsInfo?.wishlist])

  return (
    <>
      <div className='list-container'>
        <div className="row">
          <div className="col-sm-2 folder-name">
            <ul className='list-box'>
              {ceAssetsInfo?.wishlist?.map((item, index) => {
                return (
                  <li className={`list-item ${item?._id === SelectedList?._id && 'active-list'}`} key={index} onClick={() => setSelectedList(item)}><FolderSpecialIcon className='back-icon' /> {item?.name}</li>
                )
              })
              }
            </ul>
          </div>
          <div className="col-sm-10 wishlist-card">
            <div className='row inner-row'>
              {SelectedList?.list?.length === 0 ? <p>No data found</p> :
                <AssetsCard data={SelectedList?.list} isloading={true} type={type} handleSelection={(item) => setSelectedContent(item)} listType={'wishlist'} />}
            </div>
            {selectedContent && <AssetsPlayer
              type={type} handle={() => setSelectedContent(null)}
              content={selectedContent}
              handleSubmit={(item) => handleSubmit(item)} />}
          </div>
        </div>
      </div>
    </>
  )
}


Wishlist.propTypes = {
  /** type of the assets */
  type: PropTypes.string,
  /** link Assets function  */
  link: PropTypes.func
}

export default Wishlist