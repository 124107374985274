const PredefinedTemplate = {
    "TopicHeader": {
        "Definition TopicHeader": {
            // "description": "<p style=\"text-align:left;\"></p>\n<p style=\"text-align:inherit;\"><span style=\"font-size: 28px;\"><strong>Definition</strong></span></p>\n<p style=\"text-align:left;\"></p>\n<p style=\"text-align:inherit;\"><span style=\"font-size: 24px;\">(a) Significant military equipment means articles for which special export controls are warranted because of their capacity for substantial military utility or capability.</span></p>\n<p style=\"text-align:left;\"></p>\n<p style=\"text-align:inherit;\"><span style=\"font-size: 24px;\">(b) Significant military equipment includes:</span></p>\n<ul>\n<li style=\"text-align:left;\"><span style=\"font-size: 24px;\">(1) Items in § 121.1 of this subchapter that are preceded by an asterisk; and</span></li>\n<li style=\"text-align:left;\"><span style=\"font-size: 24px;\">(2) All classified articles enumerated in § 121.1 of this subchapter.</span></li>\n</ul>\n<p style=\"text-align:start;\"></p>\n<p style=\"text-align:inherit;\"><a href=\"https://www.ecfr.gov/current/title-22/chapter-I/subchapter-M/part-120/subpart-C/section-120.36\" target=\"_blank\"><span style=\"color: rgb(247,182,51);font-size: 24px;\"><strong>22 CFR 120.36</strong></span></a>&nbsp;</p>\n",
            "styleObj": {
                "boxShadow": "none",
                "margin": 0,
                "borderRadius": 0,
                "background": "#5893d4",
                "color": "#ffffff",
                "fontStyle": "normal",
                "fontSize":'36px'
            }
        },
        "CTA TopicHeader": {
            // "description": "<p style=\"text-align:center;list-style-position:inside;\"><span style=\"font-size: 30px;\"><strong>Contact your trade compliance experts for more information about your company’s policies and procedures when exporting technical assistance from Belgium.</strong></span>&nbsp;</p>\n",
            "styleObj": {
                "boxShadow": "none",
                "margin": 0,
                "borderRadius": 0,
                "background": "#f7b633",
                "color": "#ffffff",
                "fontStyle": "normal",
                "fontSize":'36px'
            }
        },
        "For Example TopicHeader": {
            // "description": "<p style=\"text-align:left;\"><span style=\"font-size: 28px;\"><strong>For example</strong></span></p>\n<p style=\"text-align:start;\"><span style=\"font-size: 24px;\">If your products are primarily for use in the automotive industry, a large order from a food business must be identified as a red flag and your trade compliance expert notified of the request.</span></p>\n<p style=\"text-align:start;\"><span style=\"font-size: 24px;\">Similarly, offers to make payments through channels such as cash rather than normal banking channels must be identified as a red flag.</span></p>\n<p style=\"text-align:start;\"><span style=\"font-size: 24px;\">Also, be wary of requests to pay for goods using multiple sources especially when some of those sources do not show a direct link to the entity purchasing the goods. </span></p>\n",
            "styleObj": {
                "boxShadow": "none",
                "margin": 0,
                "borderRadius": 0,
                "background": "#01c988",
                "color": "#ffffff",
                "fontStyle": "normal",
                "fontSize":'36px'
            }
        },
        "CTA 1 (Awareness)":{
            // "description": "<p style=\"text-align:center;list-style-position:inside;\"><span style=\"font-size: 30px;\"><strong>We know the United States fought its Civil War in large part over slavery, and it resulted in the Thirteenth Amendment in 1865, abolishing slavery in the U.S. and all its territories. And, in 1981, Mauritania became the last country on Earth to abolish slavery.</strong></span>&nbsp;&nbsp;</p>\n",
            "styleObj": {
                "boxShadow": "none",
                "margin": 0,
                "borderRadius": 0,
                "background": "#03337c",
                "color": "#ffffff",
                "fontStyle": "normal",
                "fontSize":'36px'
            }
        },
        "CTA 2 (Practitioner)":{
            // "description": "<p style=\"text-align:center;list-style-position:inside;\"><span style=\"font-size: 30px;\"><strong>The principal point here is to make sure you carefully understand how all of the various aspects of your hardware, data, and associated services are categorized and controlled by referring to the proper parts of the ITAR and USML.</strong></span>&nbsp;</p>\n",
            "styleObj": {
                "boxShadow": "none",
                "margin": 0,
                "borderRadius": 0,
                "background": "#f7b633",
                "color": "#ffffff",
                "fontStyle": "normal",
                "fontSize":'36px'
            }
        }

    },
"ScenarioBlock":{
    "SB1 - Default":{
        "styleObj":{
             // ScenarioBlock Background Color
            "sb_bg":null,
            // Text Background Color
            "t_bg":null,
            // Dialogue Background Color
            "dia_bg":null,
            // Text Color
            "t_c":null,
            // Button Background Color
            "btn_bg":null,
            // Button Text Color
            "btn_t_c":null,
            // Font Size
            "fnt_s":null,
            "name":"SB1(default)"
        }
    },"SB2 - Transparent":{
        "styleObj":{
            "sb_bg":"rgba(7, 13, 89, 0.8)",
            "t_bg":"rgba(0, 0, 0, 0)",
            "dia_bg":"rgba(0, 0, 0, 0)",
            "t_c":"#fff",
            "btn_bg":"#198754",
            "btn_t_c":"#fff",
            "fnt_s":"30",
            "fw":"bold",
            "ui":2,
          }
    }
}


}

export default PredefinedTemplate;