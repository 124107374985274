import React from 'react';
import PropTypes from 'prop-types';
import styled, { css, keyframes } from 'styled-components';

const sizeStyles = {
  small: {
    radioSize: '12px',
    checkSize: '6px',
    fontSize:'0.7rem'
  },
  medium: {
    radioSize: '16px',
    checkSize: '8px',
    fontSize:'1.2rem'
  },
  large: {
    radioSize: '20px',
    checkSize: '10px',
    fontSize:'1.5rem'
  },
};


/**
 * RadioSelect Component
 * @param {Object} props - Component properties
 * @param {Array} props.options - List of radio options with label and value
 * @param {String} props.name - Name attribute for radio buttons
 * @param {Function} props.onChange - Callback function when radio button is changed
 * @param {String} props.selectedValue - Currently selected value
 * @param {String} props.size - Size of the radio buttons (small, medium, large)
 * @param {String} props.color - Color of the selected radio button
 * @param {String} props.direction - Direction of the radio group (vertical, horizontal)
 */


const RadioGroupContainer = styled.div`
display: flex;
flex-direction: ${({ direction }) => (direction === 'horizontal' ? 'row' : 'column')};
`;

const RadioLabel = styled.label`
position: relative;
padding-left: 16px;
margin-bottom: 12px;
margin-right: ${({ direction }) => (direction === 'horizontal' ? '20px' : '0')};
cursor: pointer;
font-size: 22px;
user-select: none;
display: flex;
align-items: center;
`;

const RadioInput = styled.input`
position: absolute;
opacity: 0;
cursor: pointer;
`;

const RadioCustom = styled.div`
position: relative;
height: ${({ size }) => sizeStyles[size].radioSize};
width: ${({ size }) => sizeStyles[size].radioSize};
background-color: #eee;
border-radius: 50%;
transition: background-color 0.3s, transform 0.3s;
transform: scale(${({ checked }) => (checked ? 1.2 : 1)});
border: 0.6px solid ${({ color }) => color};
display: flex;
align-items: center;
justify-content: center;

&:after {
  content: "";
  position: absolute;
  display: none;
  width: ${({ size }) => sizeStyles[size].checkSize};
  height: ${({ size }) => sizeStyles[size].checkSize};
  border-radius: 50%;
  background: white;
}

${({ checked, color }) =>
  checked &&
  css`
    background-color: ${color};
    &:after {
      display: block;
      border: 1.9px solid ${color};
    }
  `}
`;

const RadioText = styled.span`
margin-left: 10px;
font-size:1.2rem
`;

const rippleEffect = keyframes`
from {
  transform: scale(0);
  opacity: 1;
}
to {
  transform: scale(4);
  opacity: 0;
}
`;

const RippleContainer = styled.div`
position: absolute;
margin-top:4px;
top: 0;
left: 0;
height: ${({ size }) => sizeStyles[size].radioSize};
width: ${({ size }) => sizeStyles[size].radioSize};
overflow: hidden;
border-radius: 50%;
`;

const Ripple = styled.div`
position: absolute;
border-radius: 50%;
background: rgba(0, 0, 0, 0.1);
width: 100%;
height: 100%;
animation: ${rippleEffect} 0.6s linear;
opacity: 0;
pointer-events: none;
`;


const RadioSelect = ({ options, name, onChange, selectedValue, size, color, direction }) => {
  const handleChange = (e) => {
    onChange(e);
  };
  return (
    <RadioGroupContainer direction={direction}>
      {options.map((option) => (
        <RadioLabel key={option.value} direction={direction}>
          <RadioInput
            type="radio"
            name={name}
            value={option.value}
            checked={selectedValue === option.value}
            onChange={handleChange}
          />
          <RippleContainer size={size}>
            <RadioCustom checked={selectedValue === option.value} size={size} color={color} />
            <Ripple />
          </RippleContainer>
          <RadioText>{option.label}</RadioText>
        </RadioLabel>
      ))}
    </RadioGroupContainer>
  );
};

RadioSelect.propTypes = {
  options: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string.isRequired,
      value: PropTypes.string.isRequired,
    })
  ).isRequired,
  name: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
  selectedValue: PropTypes.string.isRequired,
  size: PropTypes.oneOf(['small', 'medium', 'large']),
  color: PropTypes.string,
  direction: PropTypes.oneOf(['vertical', 'horizontal']),
};
// Default props for RadioSelect Component
RadioSelect.defaultProps = {
  size: 'medium',
  color: 'var(--primary-color-1)',
  direction: 'horizontal',
};

export default RadioSelect;
