import React from 'react'
import Grow from "@material-ui/core/Grow";
import Paper from "@material-ui/core/Paper";
import Popper from "@material-ui/core/Popper";
import MenuItem from "@material-ui/core/MenuItem";
import MenuList from "@material-ui/core/MenuList";
import { makeStyles } from "@material-ui/core/styles";
import './editmenudropdown.scss'
import { useDispatch } from 'react-redux';
import * as courseEditAction from '../../../redux/actions/courseEditAction'
import ClickAwayListener from "@material-ui/core/ClickAwayListener";

const useStyles = makeStyles((theme) => ({
    root: {
      display: "flex"
    },
    paper: {
      marginRight: theme.spacing(2)
    }
  }));

const MenuDropdown = ({isOpen, anchor, handleParent, menuData}) => {
    const classes = useStyles();
    const dispatch = useDispatch();
    const handleResolution = (name) => {
      switch(name){
        case 'Mobile':
          return{
            name,
            width: 450,
            class:'_mobile'
          }
        case 'Tablet':
          return{
            name,
            width: 768,
            class:'_tablet'
          }
        case 'Laptop':
          return {
            name,
            width: 1024,
            class:'_labtop'
          }
        default:
          return {
            name: 'Desktop',
            class:'_desktop'
          }
      }
    }
    const handleClose = (event, el) => {
      if (anchor.current && anchor.current.contains(event.target)) {
        return
      }
      if (el?.type) {
        handleParent(handleResolution(el.type), el.type)
      } else if(el?.editDrawer){
        dispatch(courseEditAction.toggleDrawer({open: true, itemInfo: el.editDrawer, panel: el.editDrawer.panel, drawerWidth: el.editDrawer.width}))
      }
      dispatch(courseEditAction.isMenuDropDown(false))
    };

    function handleListKeyDown(event) {
        if (event.key === "Tab") {
          event.preventDefault();
          dispatch(courseEditAction.isMenuDropDown(false))
        }
    }

    
    return (
        <div className={`menu-list-container ${classes.root}`}>
             <Popper
          open={isOpen}
          anchorEl={anchor?.current}
          role={undefined}
          transition
          disablePortal
        >
          {({ TransitionProps, placement }) => (
            <Grow
              {...TransitionProps}
              style={{
                transformOrigin:
                  placement === "bottom" ? "center top" : "center bottom"
              }}
            >
              <Paper>
                <ClickAwayListener onClickAway={handleClose}>
                  <MenuList
                    autoFocusItem={isOpen}
                    id="menu-list-grow"
                    onKeyDown={handleListKeyDown}
                  >
                    {menuData.map((el, index) => <MenuItem key={index} onClick={(e) => handleClose(e, el)}>{el?.image && <img className='comp-icon' src={require(`../../../assets/img/${el.image}`).default} alt=""/>} {el.name}</MenuItem>)}
                  </MenuList>
                </ClickAwayListener>
              </Paper>
            </Grow>
          )}
        </Popper>
        </div>

    )
}

export default MenuDropdown
