import React from 'react'
import HTMLFlipBook from "react-pageflip";
import "./flippages.scss";
import PropTypes from 'prop-types'
import { pathCreater } from '../../../utils/MediaConfig';

/**
 * 
 * @param {*} cover 
 * @returns background image to cover the flip page
 */

/**
 * 
 * @param {*} items 
 * @returns array of images required to fill up the pages.
 */

/**
 * FlipPages will display as book like structure and render the images as user swipes
*/
const FlipPages = ({cover, items}) => {
    return (
        <div className='flip-page-container'>
            <div className="main-page-container">
                {items ? <HTMLFlipBook
                    showCover={false}
                    width={300}
                    height={300}
                    style={{ margin: "0 auto" }}>
                    { cover ? <img src={pathCreater(0) + cover} alt="" /> : ''}
                    {(items?.length > 0) && items?.map((el) => (
                    <div className="demoPage" key={el.id}>
                        <img src={pathCreater(0) + el.image} alt="" />
                    </div>
                    ))}
                </HTMLFlipBook> : <p> component data is improper</p>}
            </div>
      </div>
    )
}

FlipPages.propTypes = {
    /** Cover is used to bind the outer area of the book */
    cover: PropTypes.string,
    /** Items will display as pages of book  */
    items: PropTypes.array
}

export default FlipPages
