import React from 'react';
import PropTypes from 'prop-types';
import './quotes.scss';
import BackgroundImage from '../BackgroundImage/BackgroundImage';


const Quotes = ({ profileImage, quotes, backgroundObj, styleObj, track, handler, cename }) => {

const props={
  bgImageUrl:backgroundObj?.img,
  description:quotes,
  backgroundSize: backgroundObj?.size,
  textPosition: styleObj?.textPosition,
  backgroundColor: backgroundObj?.color,
  opacity:backgroundObj?.opacity,
  padding:styleObj?.padding,
  background:backgroundObj?.background,
  profileImage:profileImage,
  styleObj:styleObj,
  cename:cename,
  track:track,
  handler:handler
}
  return <BackgroundImage {...props}/>
  


}

Quotes.propTypes = {
  /** backgroundObj of the component */
  backgroundObj: PropTypes.object,
  /** profileImage of the component */
  profileImage: PropTypes.string,
  /** Heading title of the component */
  header: PropTypes.string,
  /** quotes content of component */
  quotes: PropTypes.string,
  /** Func description for tracking*/
  handler: PropTypes.func
}

export default Quotes