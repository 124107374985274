// AccordionItem.js
import React, { useState } from 'react';
import PropTypes from 'prop-types';
import Accordion from '@material-ui/core/Accordion';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import Typography from '@material-ui/core/Typography';
import EditIcon from '@material-ui/icons/Edit';
import DeleteIcon from '@material-ui/icons/Delete';
import CheckIcon from '@material-ui/icons/Check';
import { useDrag, useDrop } from 'react-dnd';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import InputLabel from '@mui/material/InputLabel';
import FormControl from '@mui/material/FormControl';
import './accordionItem.scss';
import { getPlaceHolderImg } from "../../../utils/MediaConfig";
import DataHelper from '../../../utils/DataHelper';
import useToastNotifications from '../../../utils/hooks/useToastNotifications';


// Refer to PropTypes section at the bottom for prop types

const AccordionItem = ({
  el,
  index,
  data,
  name,
  listUpdate,
  className,
  handleAccordionChange,
  expanded,
  summary,
  dynamicDetailsComponent,
  isDrag,
  bgImg
}) => {
  const [editMode, setEdit] = React.useState(null);
  const { showWarning} = useToastNotifications();

  const handleEdit = (event, index) => {
    setEdit(index)
    event.stopPropagation()
  }

  const handleDelete = (event, index) => {
    if ((summary?.delete?.min || 1) < data?.length) {
      const removed = data?.filter((e, i) => i !== index)
      listUpdate(event, removed);
    } else {
      showWarning("Minimum item limit reached. No further deletions allowed");
    }
    event.stopPropagation();
  }

  const handleChange = (event, index) => {
    data[index] = { ...data[index], [event.target.name]: event?.target?.value };
    listUpdate(event, data);
  }


  const [{ isDragging }, drag] = useDrag({
    type: 'ACCORDION_ITEM',
    item: { index },
    collect: (monitor) => ({
      isDragging: !!monitor.isDragging(),
    }),
  });

  const [, drop] = useDrop({
    accept: 'ACCORDION_ITEM',
    // hover: (draggedItem, monitor) => {
    //   const draggedIndex = draggedItem.index;
    //   const hoveredIndex = index;
    //   if (draggedIndex !== hoveredIndex) {
    //     handleReorderItemLocally(draggedIndex, hoveredIndex, monitor);
    //     draggedItem.index = hoveredIndex;
    //   }
    // },
    drop: (draggedItem, monitor) => {
      const draggedIndex = draggedItem.index;
      const hoveredIndex = index; // This is the index where the item is dropped
  
      if (draggedIndex !== hoveredIndex) {
        handleReorderItemLocally(draggedIndex, hoveredIndex);
        draggedItem.index = hoveredIndex;
      }
    },
  });

  const getBgImageUrl = (path) => {
    return DataHelper?.getResourcePath(0, path) || '';
}

  const handleReorderItemLocally = (sourceIndex, destinationIndex) => {
    const newList = [...data];
    const [movedItem] = newList.splice(sourceIndex, 1);
    newList.splice(destinationIndex, 0, movedItem)
    // const temp = newList[sourceIndex];
    // newList[sourceIndex] = newList[destinationIndex];
    // newList[destinationIndex] = temp;
    listUpdate(null, newList); // Update the list locally
  };

  const  isDragStyle=(isDrag && expanded !== index);
  return (
    <>
    <div div ref={(node) => isDragStyle && drag(drop(node))}
      className={`${isDragStyle && "co-accordion-item"} ${isDragging ? 'dragging' : ''}`}
    >
      <Accordion key={index + 1} expanded={expanded === index} onChange={handleAccordionChange(index)}>
        <AccordionSummary className={className} aria-controls="panel1a-content" id="panel1a-header">
          {editMode === index ? (
            <>
              <textarea
                id="outlined-basic"
                className="list-title acc-title"
                spellCheck={false}
                name={summary?.title?.name || "title"}
                defaultValue={el?.[summary?.title?.name||'title'] || ""}
                rows="1"
                onClick={(e) => e.stopPropagation()}
                onChange={(e) => {
                  summary?.title?.func ? summary?.title?.func(e, index) : handleChange(e, index)
                }}
              />
              <CheckIcon className="save-prop-accordion" onClick={(e) => handleEdit(e, null)} />
            </>
          ) : (
            <>
            {(el?.bg_image||bgImg)&& <div className='circle-preview-img-box'> <img className='preview-item-img' src={getBgImageUrl(el?.bg_image)} onError={(e) => { e.target.onerror = null; e.target.src = getPlaceHolderImg(200)}}
                        alt='drag-item' /></div>}
              {/* display mode */}
              <Typography className={`label-bold acc-title`}>{name} {summary?.title?.name ? el[summary?.title?.name || "title"] : (el?.title ||'')}</Typography>
              {/* render select component if specified in summary prop */}
              {summary?.select && <FormControl className='drag-target' variant="standard">
                <InputLabel className='target-label' id="demo-simple-select-standard-label">{summary?.select?.label || summary?.select?.name || ""}</InputLabel>
                <Select
                  labelId="demo-simple-select-standard-label"
                  id="demo-simple-select-standard"
                  name={summary?.select?.name || "type"}
                  value={el[summary?.select?.name || "type"]}
                  onChange={(e) => summary?.select?.func ? summary?.select?.func(e, index) : handleChange(e, index)}
                  label={summary?.select?.label || "Target"}
                >
                  {summary?.select?.menu && summary?.select?.menu?.map((e, i) => <MenuItem key={i} className='select-target-option-list' value={e?.value ?? ""}>{e?.name || ""}</MenuItem>)}
                </Select>
              </FormControl>}
              {/* edit and delete icons */}
              {summary?.custom?.element && summary?.custom?.element}
              {summary?.title && <EditIcon className="edit-prop-accordion" onClick={(e) => handleEdit(e, index)} />}
              {summary?.delete && <DeleteIcon className="delete-prop-accordion" onClick={(e) => handleDelete(e, index)} />}
            </>
          )}
        </AccordionSummary>
        <AccordionDetails>
          {(expanded === index && dynamicDetailsComponent) ? dynamicDetailsComponent : <></>}
        </AccordionDetails>
      </Accordion>
    </div>
    </>
  )
};

AccordionItem.propTypes = {
  // Individual item data
  el: PropTypes.object,
  // Index of the accordion item
  index: PropTypes.number,
  // The entire list of accordion items
  data: PropTypes.array,
  // Unique key for the React element
  key: PropTypes.string,
  // Name of the accordion item
  name: PropTypes.string,
  // Function to update the list of accordion items
  listUpdate: PropTypes.func,
  // Custom class name for styling
  className: PropTypes.string,

  // Function to handle accordion state changes
  handleAccordionChange: PropTypes.func,
  // Index of the currently expanded accordion item
  expanded: PropTypes.number,
  // Configuration for the accordion item
  summary: PropTypes.shape({
    // Configuration for the title section
    title: PropTypes.shape({
      // Name of the title property in the data
      name: PropTypes.string,
      // Function to handle title changes
      func: PropTypes.func,
    }),
    // Configuration for the delete action
    delete: PropTypes.shape({
      // Minimum number of items required before allowing deletion
      min: PropTypes.number,
    }),
    // Configuration for the select dropdown
    select: PropTypes.shape({
      // Label for the select dropdown
      label: PropTypes.string,
      // Name of the property in the data
      name: PropTypes.string,
      // Function to handle select changes
      func: PropTypes.func,
      // Array of menu options for the select dropdown
      menu: PropTypes.arrayOf(
        PropTypes.shape({
          // Display name of the option
          name: PropTypes.string,
          // Corresponding value of the option
          value: PropTypes.number,
        })
      ),
    }),
  }),
  // Component to render dynamic details
  dynamicDetailsComponent: PropTypes.node,
};


export default AccordionItem;
