import React, {useEffect, useState} from 'react'
import useForceUpdate from 'use-force-update';
import Acc from '@material-ui/core/Accordion';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import Typography from '@material-ui/core/Typography';
import TextField from '@material-ui/core/TextField';
import { AddCircle } from '@material-ui/icons';
import DeleteIcon from '@material-ui/icons/Delete';
import UploaderPanel from '../UploaderPanel/UploaderPanel';
import PropTypes from 'prop-types'
import PlayerConstants from '../../../utils/PlayerConstants';
import Datahelper from '../../../utils/DataHelper'
import RichTextEditor from '../RichTextEditor/RichTextEditor';
import ACLHelper from '../../../utils/ACLHelper';
import { getPlaceHolderImg } from '../../../utils/MediaConfig';
import useToastNotifications from '../../../utils/hooks/useToastNotifications';
// import { FormLabel } from '@material-ui/core';
// import Select from '@mui/material/Select';
// import MenuItem from '@mui/material/MenuItem';
// import InputLabel from '@mui/material/InputLabel';
// import FormControl from '@mui/material/FormControl';


/**
 * 
 * CoursecardFlipanel editor used to modify the flipcard component
 * 
 * handleCeAssets - This function handles the CE Library asset usage update actions ( previousAsset | newAsset | updatedProps ). 
 */

const CourseCardFlipPanel = ({items, onlyImage, handleProperties, cename,id, handleCeAssets}) => {
    const forceUpdate = useForceUpdate()
    const [expanded, setExpanded] = useState(0);
    const [list, setList] = useState(JSON.parse(JSON.stringify(items)))
    let maxCount = 0
    const compItems = Object.values(PlayerConstants.COMPONENTS_LIST).filter((el) => el.name === cename)
    maxCount = compItems[0].max_count
    const handleChange = (panel) => (event, isExpanded) => {
        setExpanded(isExpanded ? panel : false);
    };
    const {showWarning} = useToastNotifications();

    const handleChangeInput = (e, id) => {
        list[id][e.target.name] = e.target.value
        setList(list)
        handleProperties({items: list})
    }

    useEffect(() => {
        handleProperties({items: list})
    }, [list])

    const handleDelete = (event, index) => {
        if (list.length !== 1) {
            const removed = list.filter((e, i) => i !== index)
            setList(removed)
            handleProperties({items: removed})
            forceUpdate()
            return;
        }
        showWarning("Minimum item limit reached. No further deletions allowed");
        event.stopPropagation()
    }

    const addAccordion = () => {
        let newobj;
        if(list.length < maxCount) {
            if(cename==="CourseCardFlip"){
                newobj={
                title:'Title',
                description: 'Description',
                imageUrl: getPlaceHolderImg(200)}
            }else{
                newobj={ 
                frontImage: getPlaceHolderImg(200),
                backImage: "https://img.buzzfeed.com/buzzfeed-static/static/2014-04/enhanced/webdr06/4/16/enhanced-11136-1396643149-13.jpg?no-auto",
                flipBtnText: "click to flip",
                description: "Description",
                flipped: false
            }
            }
            const maxId = Math.max(...list?.map(item => item?.id), 0)||1;
            list.push({id: maxId + 1,...newobj})
            setList(list)
            handleProperties({items: list})
            forceUpdate()
        }
    }

    /**
     * 
     * @param {} value 
     * data html string used for description of the component
     */
    const eventHandlers = (value, i) => {
        list[i]['description'] = value
        setList(list)
        handleProperties({items: list})
    }

    /**
     * 
     * @param {*} data 
     * only file name sending here in player component its concat with default path
     */
    const handleMedia =(data,index)=>{
        try{
            if(data){
                const updateList=[...list]
                updateList[index] = {...updateList[index],frontImage:data.name}
                handleCeAssets(list[index]?.frontImage,data?.name,{items:updateList});
                setList(updateList);
                handleProperties({items:updateList})
            }
        }
        catch(e){
            console.log(e);
        }
    }

    const imageStyleChange = (e,index)=>{
        list[index][e.target.name] = e.target.value
        setList(list)
        handleProperties({items: list})
    }

    const ImageFitList = [
        { name: 'Actual Size', value: 'contain' },
        { name: 'Spread', value: 'cover' },
        // { name: 'Center & Spread', value: 'fill' },
    ]


   

    return (
        <div className='card-prop-container'>
            <div className={`card-prop-main-ui`}>
                {
                    (list && list.length > 0) && list.map((el, index) => {
                        return <Acc key={index} expanded={expanded ===  index} onChange={handleChange(index)}>
                            <AccordionSummary
                                aria-controls="panel1a-content"
                                id="panel1a-header"
                            >
                                <Typography className={ `label-bold`}>{`Card ${index + 1}`} </Typography>
                                <DeleteIcon className='delete-prop-card' onClick={(e) => handleDelete(e, index)}/>
                            </AccordionSummary>
                            <AccordionDetails>
                                { ACLHelper.isFeatureEnabled(4,2) && 
                                <div className="card-prop-file">
                                    {/* <label htmlFor="formFile" className="form-label">Browse Front Image</label> */}
                                    <UploaderPanel accept={'image'} 
                                    fileName={list[index]?.frontImage}  
                                    name={`${id}_${Datahelper.getTime()}-${index+1}`}  
                                    setUploadedData={(el)=>handleMedia(el,index)} 
                                    styles={{
                                        imageFit:String(el?.imageFit).includes("/") ? el?.imageFit : `${(el?.imageFit==="cover") ? " 100% 100%" : "center " } / ${el?.imageFit||"contain"} no-repeat`,
                                        color:el?.color ||"rgba(0, 0, 0, 0)",
                                      }}
                                      bgChange={(e) => imageStyleChange(e,index)}
                                      pChange={(e) => imageStyleChange(e,index)}
                                    />
                                </div>}
                                {/* <div className='card-prop-image-position'>
                                  <FormControl size='medium' >
                                    <InputLabel className='label' id="demo-select-small">Image Position</InputLabel>
                                        <Select
                                            labelId="demo-select-small"
                                            id="demo-select-small"
                                            value={el.imageFit || "contain" }
                                            label="Image Position"
                                            name="imageFit"
                                            onChange={(e) => imagePositionChange(e,index)}
                                            className="dropdown"
                                        >
                                            {ImageFitList.map((item, idx) => <MenuItem key={idx} className='select-option-list' disabled={item.name.trim().length === 0} value={item.value}>{item.name}</MenuItem>)}

                                        </Select>
                                  </FormControl>
                                </div> */}
                                {!onlyImage && <div className='card-prop-title'>
                                    <TextField id="outlined-basic" spellCheck={false} name='title' label="Title" variant="outlined" defaultValue={el.title} onChange={(e) => handleChangeInput(e, index)}/>
                                </div>}
                                <div className='card-prop-description'>
                                    <p className='description-text label'>Content</p>
                                    {/* <CKeditor data={el.description} eventHandler={(e) => eventHandlers(e, index)}/> */}
                                    <RichTextEditor data={el.description} eventHandler={(e) => eventHandlers(e, index)}/>
                                </div>
                            </AccordionDetails>
                        </Acc>
                    })
                }
                <div className='add-new-slide' title={'Add Card'} onClick={addAccordion}><AddCircle className='add-card'  />  Add New Card</div>

            </div>
        </div>
    )
}

CourseCardFlipPanel.propTypes = {
    /** items array used for having list of cardflip data */
    items: PropTypes.array,
    /** function handler used to save the edited props */
    handleProperties: PropTypes.func,
    /** function handler used to update the Assets */
    handleCeAssets : PropTypes.func,
}

export default CourseCardFlipPanel
