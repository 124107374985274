import React, { useState,useEffect, useMemo } from 'react'
import PropTypes from 'prop-types'
import './topicheader.scss'
import ContentTracking from '../../../core/progress/ContentTracking'
import ErrorOutlineIcon from '@material-ui/icons/ErrorOutline';
import Tooltip from '@material-ui/core/Tooltip';
import PlayerConstants from '../../../utils/PlayerConstants';
import Instruction from '../../ui/Instruction/Instruction';
import DataHelper from '../../../utils/DataHelper';
 

/**
 * 
 * @param {*} selector 
 * returns content area selector for tracking
 * @param {*} callback
 * returns for tracking the content area when scrolling
 * @param {*} isLongContent 
 * returns boolean value if the content area is large
 */

const debouncing = (selector, callback, isLongContent) => {
    const isView = isLongContent || null
    setTimeout(() => {
        let element = document.querySelector(selector);
        let position = element?.getBoundingClientRect();
        const isMobile = (window.matchMedia('max-width: 768px').matches || window.matchMedia('max-height: 450px').matches)
            // checking for partial visibility
        if((position?.top < window.innerHeight && position?.bottom >= 0) && (isMobile || isView)) {
            callback()
        } else {
            // checking whether fully visible
            if(position?.top >= 0 && position?.bottom <= window.innerHeight) {
                callback()
            }
        }
    
    }, 100)
}


const UPDATE_STATUS = PlayerConstants.COMPONENT_CONSTANTS.UPDATE_STATUS
const SAVE_PROGRESS_STATUS = PlayerConstants.COMPONENT_CONSTANTS.SAVE_PROGRESS_STATUS

/**
 * 
 * @param {*} header 
 * @returns header title of the component
 */

/**
 * 
 * @param {*} description 
 * @returns content description of the component
 */

/**
 * 
 * @param {*} cename 
 * @returns component name
 */

/**
 * 
 * @param {*} title 
 * @returns top heading title of the component
 */

/**
 * TopicHeader component will have two types of varient based 
 * on passing style "styleObj" props it may behave different 
*/
const TopicHeader = ({ header, description ,track,handler,cename, title, styleObj, tp_status}) => {
    const contentLayout = document.querySelector('.content')
    const [status, setStatus] = useState({completed: false, text: PlayerConstants.COMPONENT_CONSTANTS.STATUS_INCOMPLETE})
    
    /**
     * Manage the component update 
     * progress logic in this method
     * 
     * Update the view status when ever the user interacts
     * Update the progess status ONLY ONCE, when status completes
     * 
     */
     const updateProgress = () => {

        /**
         * View status, will update while navigating to next topic
         */
        track.state = { ...track.state, c:1}
        /**
         * Progress updated as and when its completed
         */
    
        if (!track.status) {
          track.status = 1;
          if (track.status) {
            setStatus({completed: true, text:PlayerConstants.COMPONENT_CONSTANTS.STATUS_COMPLETE})
            handler({ type: UPDATE_STATUS, id: track.id, name:cename, isolated: true })
          }
        }
        /**
         * If anything to be intimated always
         * call this
         */
        // handler({ type: UPDATE_STATUS, id: track.id, name:cename })
    
    }
    
    // componentObserver('section[name=TopicHeader]', updateProgress)
    contentLayout?.addEventListener('scroll', ()=> {
        DataHelper.scrollCompletionDebouncing('section[name=TopicHeader]', updateProgress, true)
    })

    const updateProps = ()  =>{
        //console.log(this)
    }

    
    useEffect(()=>{
        if(tp_status > -1) updateProgress();
    },[tp_status])

    useEffect(() => {
        // console.log("description  Changed !!!!")
    },
    [header, description ,  title, styleObj]
    )

   // memoize the dynamically generated style to prevent unnecessary re-renders.
    const descriptionContent = useMemo(() => {
        if (styleObj && Object?.keys(styleObj)?.length !== 0) {
          return (
            <div
              className="quote-para text-content"
              style={{ fontStyle: styleObj?.fontStyle, fontSize: styleObj?.fontSize }}
              dangerouslySetInnerHTML={{ __html: description }}
            />
          );
        } else {
          return (
            <div
              className="quote-para text-content"
              dangerouslySetInnerHTML={{ __html: description }}
            />
          );
        }
      }, [styleObj, description]);

    return (
        <>
        <Instruction isInstruction={false} completed={(track.status === 1|| status.completed)} title={(track.status === 1 || status.completed) ? PlayerConstants.COMPONENT_CONSTANTS.STATUS_COMPLETE : PlayerConstants.COMPONENT_CONSTANTS.STATUS_INCOMPLETE} classText={`${(track.status === 1 || status.completed) && PlayerConstants.COMPONENT_CONSTANTS.COMPLETED_CLASS}`}/>
            <div className='topic-div' style={styleObj}>
                <div className='topic-container'>
                    {/* <h2 className='topic-heading-tag'>{header ? header: (title) ? title: 'Heading Text Goes Here'} </h2> */}
                    {descriptionContent}
                </div>
            </div>
        </>
    )
}

TopicHeader.propTypes = {
    /** Heading title text of the component */
    header: PropTypes.string,
    /** Description text is used for displaying explanation over the topic  */
    description: PropTypes.string,
    /** Tracking the component progress */
    track:PropTypes.instanceOf(ContentTracking),
    /** Func description for tracking*/
    handler:PropTypes.func
}

export default TopicHeader