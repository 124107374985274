import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import Acc from '@material-ui/core/Accordion';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import Typography from '@material-ui/core/Typography';
import EditIcon from '@material-ui/icons/Edit';
import TextField from '@material-ui/core/TextField';
import CheckIcon from '@material-ui/icons/Check';
import { AddCircle } from '@material-ui/icons';
import DeleteIcon from '@material-ui/icons/Delete';
import UploaderPanel from '../UploaderPanel/UploaderPanel';
import RichTextEditor from '../RichTextEditor/RichTextEditor';
import Datahelper from '../../../utils/DataHelper';
import Checkbox from '@mui/material/Checkbox';
import { FormControlLabel } from '@material-ui/core';
import DataHelper from '../../../utils/DataHelper';
import { getPlaceHolderImg } from "../../../utils/MediaConfig";
import AccordionItem from '../../../components/ui/AccordionItem/AccordionItem'; // Assuming your AccordionItem component is in a separate file
import { DndProvider } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';
import ColorPickr from "../ColorPickr/ColorPickr";
import ResizableTextArea from '../../../components/ui/ResizableTextArea/ResizableTextArea';
import CustomTextField from '../../../components/ui/CustomTextField/CustomTextField';

/* ******slides*******
*
**2 type 
* 0-text-No responses
* 1-dialogue have the responses
* ****
* 
** next_slide -->  2 items there 
* scene-> pre slide id
* slide -> next jump
* ****
* 
** goto --> 3types next,slide,end ( next && slide have Continue-Button )
* 0-next --> auto taking
* 2-end --> end scenario --> try again (only for text mode )
*
* handleCeAssets - This function handles the CE Library asset usage update actions ( previousAsset | newAsset | updatedProps ). 
*
*/

const ScenarioBlockPanel = ({ scene, handleProperties, cename, id, handleCeAssets, is_track, title, styleObj }) => {

const [expanded, setExpanded] = useState(0);
const [childExpanded, setChildExpanded] = useState(null);
const [resChildExpanded, setResChildExpanded] = useState(null);
const [isResponseTitle, setIsResponseTitle] = useState(null);
const [drag, setDrag] = useState(false);
const [listData, setListData] = useState({scene,is_track,title,styleObj});


useEffect(() => { handleProperties({ ...listData }); }, [listData])



/* predefined  data for add and  -Start  */
let lastScene = scene[scene?.length - 2 || 0]
let sceneId = Math.max(...scene?.map(item => Number(item?.id)||scene?.length-1 ), 0)||1;
if(Number(sceneId) < lastScene?.slides?.length-1) sceneId= scene?.length-1;
const createScene = {
    id: `${sceneId+1||scene?.length}`,
    bg_image: lastScene?.bg_image,
    slides: [{
        id: `${sceneId+1||scene?.length}`, goTo: 0, type:lastScene?.slides[lastScene?.slides?.length-1]?.type||0, title: '', emotion: "happy", next_slide: null, responses: [],
        description: `<p>Add text to explain the situation your scenario will address.</p>`
    }], character: { src: lastScene?.character?.src }
}
const newResponse = {
    id: `${sceneId+1||1}`,
    goTo: 2, action: "continue", title: "Response", emotion: "happy",
    feedback: "<p>Sorry, try again.</p>",
    next_slide: null, description: "Scenario Travel next slides."
}
const EndScene = {
    id: -1, title: 'Scenario Completed !', next_slide: { scene: scene[0]?.id, slide: scene[0].slides[0]?.id },
    goTo: 1, slides: [], type: 0, responses: [],
    character: lastScene?.character?.src
}
/* predefined  data for add and end  -End */

useEffect(() => {
try {
    if (scene[scene.length - 1].id !== -1) {
        let updatedList = [...scene];
        updatedList?.push(EndScene)
        setListData({...listData, scene:[...updatedList]})
    }
} catch (e) { }
}, [scene])


// handling the end scene changes
const handleEndScene=(value)=>{
    let sceneList = listData.scene[expanded];
    sceneList['title'] = value;
    listData.scene[expanded]=sceneList;
    setListData({...listData, scene:listData.scene});
    // handleProperties({...listData, scene:listData.scene });
}


/* ---------------------------- scene  ------------------------------------- */
const handleChange = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
};

const handleChildAcc = (panel) => (event, isExpanded) => {
    setChildExpanded(isExpanded ? panel : false);
};

const addScene = () => {
try {
    let currentList = [...listData.scene]
    currentList?.splice(currentList?.length - 1, 0, {...createScene,slides:[{...createScene.slides[0],id:`${createScene?.slides[0]?.id||1}.1`}]});
    setListData({...listData,scene:[...currentList]});
    // handleProperties({...listData,scene: [...currentList] });
} catch (e) { }
}

const handleBgMedia = (data, index) => {
if (data) {
    const updatedList={...listData};
    let pre=updatedList?.scene[index]?.bg_image||"";
    updatedList.scene[index]['bg_image'] = data?.name;
    handleCeAssets(pre, data?.name, { scene: updatedList.scene })
    setListData({...updatedList,scene:updatedList.scene});
    // handleProperties({...listData, scene: listData.scene });
}
}


const handleCharacter = (data, index) => {
try {
    if (data) {
        const updatedList={...listData};
        const characterObj = updatedList.scene[index]?.character || {};
        let pre=characterObj?.src||"";
        updatedList.scene[index]['character'] = { ...characterObj, src: data?.name };
        handleCeAssets(pre, data?.name, { scene: updatedList.scene })
        setListData({...updatedList,scene:updatedList.scene});
        handleProperties({...updatedList,scene: updatedList.scene });
    }
} catch (e) { }
}


const handleSceneDelete = (e, id) => {
try {
    if (scene?.length > 2 && id !== -1) {
        const updatedList = listData.scene?.filter((item) => item?.id !== id);

        if (updatedList.length !== listData.scene.length) {
            setListData({...listData,scene:[...updatedList]});
            // handleProperties({...listData,scene: [...updatedList] });
            setExpanded(null)
        }
    }
} catch (e) {
    // Handle error if needed
}
e.stopPropagation();
}


/* ---------------------------- slides ------------------------------------- */
const addNewSlide = () => {
    let slide = listData.scene[expanded]?.slides;
    let sceneId=Number(listData.scene[expanded]?.id) ? listData.scene[expanded]?.id :expanded;
   let slideId = Math.max(...slide?.map(item =>  Number(item?.id?.split(".")[1]||slide?.length)), 0)||1;
   if(slideId < slide.length-1) slideId= slide?.length;
    slide?.push({ ...createScene?.slides[0], id:`${Number(sceneId)||1}.${Number(slideId)+1}` });
    listData.scene[expanded]['slides'] = slide;
    setListData({...listData,scene:[...listData.scene]});
    // handleProperties({...listData,scene:[...listData.scene] });
}

// const handleEdit = (e, index) => {
// setEdit(index)
//     e.stopPropagation()
// }
// const handleTitleSave = () => {
// setEdit(null)
// }

// const handleSlidesDelete = (e, exIdx, id) => {
//     const slides = listData.scene[exIdx]?.slides;
//     if (slides && slides.length > 1) {
//         const updatedSlides = slides.filter((slide) => slide?.id !== id);
//         if (updatedSlides.length !== slides.length) {
//             let updatedList = [...listData.scene];
//             updatedList[exIdx].slides = [...updatedSlides];
//             setListData([...updatedList]);
//             handleProperties({ scene: [...updatedList] });
//             setChildExpanded(null);
//         }
//     }
//     e.stopPropagation();
// }

// handling the audio
const handleAudioMedia=(data,index)=>{
    try{
        const updatedList=listData
        const slides = updatedList?.scene[expanded].slides;
        let pre= slides[childExpanded]?.audio||""
        slides[childExpanded]['audio'] =  data?.name;
        updatedList.scene[expanded]['slides'] = slides;
        // update the CeAssets
        handleCeAssets(pre, data?.name, { scene: updatedList.scene })
        setListData({...updatedList, scene:updatedList.scene});
        handleProperties({...updatedList, scene: updatedList.scene });
    }catch(e){}
 }
// handling the enable and disable state of the audio -1 or null not active
 const handleIsAudioMedia=(e,index)=>{
    let slides = listData.scene[expanded].slides;
    slides[childExpanded]['audio'] =e.target.checked ? "*" : -1;
    listData.scene[expanded]['slides'] = slides;
    // update state listData
    setListData({...listData, scene:listData.scene});
    // update the handleProperties
    handleProperties({...listData, scene: listData.scene });
 }

const handleIsCharacter = (e, index) => {
    listData.scene[index].character[e.target.name] = e.target.checked;
    setListData({...listData,scene:listData.scene});
    // handleProperties({...listData,scene:listData.scene });
}

const handleSlideBgMedia = (data, index) => {
if (data) {
    let slide = listData.scene[expanded]?.slides;
    let pre = slide[index]?.bg_image || '';
    slide[index]['bg_image'] = data?.name;
    listData.scene[expanded]['slides'] = slide;
    handleCeAssets(pre, data?.name, { scene: listData.scene });
    setListData({...listData, scene:listData.scene});
    handleProperties({...listData, scene: listData.scene });
}
}

const imageStyleChange = (e, index) => {
    const newList = [...listData.scene];
    let slide = newList[expanded]?.slides;

    if (!slide[index]?.styles) {
        slide[index].styles = {};
    }
    // Create a new key in 'styles' and assign the value
    slide[index]["styles"][e?.target?.name] = e?.target?.value || "";
    newList[expanded]['slides'] = slide;
    setListData({...listData, scene:newList});
    // handleProperties({...listData, scene:newList});
};


const handleDialogTrack=(e,index)=>{
    const newList = [...listData.scene];
    let slide = newList[expanded]?.slides;
    slide[index][e.target.name]=e?.target?.checked || false
    newList[expanded]['slides'] = slide;
    setListData({...listData, scene:[...newList]});
    // handleProperties({...listData, scene:[...newList]});
}


const handleOpacity = (e, index) => {
    listData.scene[index][e.target.name] = e.target.value;
    setListData({...listData, scene:listData.scene});
    // handleProperties({...listData, scene: listData.scene });
}
const imagePositionChange = (e, index) => {
    listData.scene[index]["background"] = e?.target?.value||'';
    setListData({...listData, scene:[...listData?.scene||[]]});
    // handleProperties({...listData, scene:[...listData?.scene||[]] });
}
const handleSlideCharacter = (data, index) => {
if (data) {
    let slide = listData.scene[expanded]?.slides;
    let pre = slide[index]?.character || '';
    slide[index]['character'] = data?.name;
    listData.scene[expanded]['slides'] = slide;
    handleCeAssets(pre, data?.name, { scene: listData.scene })
    setListData({...listData, scene:listData.scene});
    handleProperties({...listData, scene:listData.scene });
}
}

// const handleChangeSlidesTitle = (e, idx) => {
// let slides = listData.scene[expanded]?.slides;
// slides[idx][e?.target?.name] = e?.target?.value;
// listData.scene[expanded]['slides'] = slides;
// setListData(listData.scene);
// handleProperties({ scene: listData.scene });
// }

const handleTypeChange = (e, idx) => {
try {
    let slides = listData.scene[expanded]?.slides;
    slides[idx][e.target.name] = e.target.value;
    if (slides[idx]?.responses?.length < 1 && e?.target?.value === 1) {
        let response = slides[childExpanded]?.responses;
        let sceneId=Number(listData.scene[expanded]?.id) ? listData.scene[expanded]?.id :expanded;
        let slideId =slides[childExpanded]?.id?.split(".")?.length > 0 ? slides[childExpanded]?.id?.split(".")[slides[childExpanded]?.id?.split(".")?.length-1] :childExpanded;
        response.push({...newResponse,id:`${Number(sceneId)||1}.${Number(slideId)||1}.1`});
        slides[childExpanded]['responses'] = [...response];
    }
    listData.scene[expanded]['slides'] = [...slides];
    // handleProperties({...listData, scene:[...listData.scene] });
    setListData({...listData, scene:[...listData.scene]});
} catch (e) { }
}
const slideEventHandlers = (value) => {
    let slides = listData.scene[expanded].slides;
    slides[childExpanded]['description'] = value;
    listData.scene[expanded]['slides'] = slides;
    setListData({...listData, scene:listData.scene});
    // handleProperties({...listData, scene: listData.scene });
}

/* type 0 is an slides 1 is an response */
const handleGoTo = (value, type) => {
    try {
        let slides = listData.scene[expanded]?.slides;
        let responses = slides[childExpanded]?.responses;
        let obj = slides[childExpanded];
        let resObj = responses[resChildExpanded];
        let changedObj = {};
        if (type === 1) obj = resObj;
        if (value === 'end') {
            changedObj = { ...obj, next_slide: null, goTo: 2 }
        } else if (value === 'next') {
            changedObj = { ...obj, next_slide: null, goTo: 0 }
        } else {
            let id = value?.split(",");
            changedObj = {
                ...obj, next_slide: {
                    scene: id[0],
                    slide: id[1]
                }, goTo: 1
            };
        }
        if (type === 0) slides[childExpanded] = changedObj;
        else if (type === 1) responses[resChildExpanded] = changedObj;
        slides[childExpanded]['responses'] = responses;
        listData.scene[expanded]['slides'] = slides;
        setListData({...listData, scene:listData.scene});
        // handleProperties({...listData, scene: listData.scene });
    } catch (e) { }
}
const onHideCharacter = (e) => {
    let value = e.target.checked ? null : 'happy';
    let slides = listData.scene[expanded].slides
    slides[childExpanded]={...slides[childExpanded],emotion:value};
    listData.scene[expanded]['slides'] = slides;
    setListData({...listData, scene:listData.scene});
    // handleProperties({...listData, scene:listData.scene });
}

const handleUpdateAccList = (e, items) => {
listData.scene[expanded]['slides'] = [...items];
setListData({...listData, scene:[...listData.scene]});
// handleProperties({...listData, scene: [...listData.scene] });
}


/* ---------------------------- Response  ------------------------------------- */
const addNewResponse = () => {
    let slides = listData.scene[expanded]?.slides;
    let response = slides[childExpanded]?.responses;
    let sceneId=Number(listData.scene[expanded]?.id) ? listData.scene[expanded]?.id :expanded;
    let slideId =slides[childExpanded]?.id?.split(".")?.length > 0 ? slides[childExpanded]?.id?.split(".")[slides[childExpanded]?.id?.split(".")?.length-1] :childExpanded;
    let resId = Math.max(...response?.map(item => Number(item?.id?.split(".")[2]||response?.length)), 0)||1;
    if(resId < response.length-1) resId= response?.length;
    response.push({...newResponse,id:`${Number(sceneId)||1}.${Number(slideId)||1}.${Number(resId)+1||1}`});
    slides[childExpanded]['responses'] = response;
    listData.scene[expanded]['slides'] = slides;
    setListData({...listData, scene:listData.scene});
    // handleProperties({...listData, scene: listData.scene });
}

const handleChangeResponseTitle = (e, idx) => {
    let slides = listData.scene[expanded]?.slides;
    let response = slides[childExpanded]?.responses;
    response[idx][e?.target?.name] = e?.target?.value;
    slides[childExpanded]['responses'] = response;
    listData.scene[expanded]['slides'] = slides;
    setListData({...listData, scene:listData.scene});
    // handleProperties({...listData, scene: listData.scene });
}


const handleResChildAcc = (index) => {
    setResChildExpanded(resChildExpanded=== index? false : index);
}

const handleResponseEdit = (e, idx) => {
    setIsResponseTitle(idx)
    e.stopPropagation()
}

const handleResponseDelete = (e,ex,ch, id) => {
    try {
        const slides = listData.scene[ex]?.slides;
        const responses = slides[ch]?.responses;
    
        if (responses && responses.length > 1) {
          const updatedResponses = responses?.filter((response) => response?.id !== id);
    
          if (updatedResponses.length !== responses.length) {
            slides[ch].responses = updatedResponses;
            const updatedList = [...listData?.scene];
            updatedList[ex].slides = slides;
            setListData({...listData, scene:updatedList});
            // handleProperties({...listData, scene: updatedList });
            setResChildExpanded(null);
          }
        }
      } catch (e) {
        // Handle error if needed
      }
      e.stopPropagation();
}

const handleResponseTitleSave = (e, index) => {
    setIsResponseTitle(null)
    e.stopPropagation();
}

    const handleDialogTitle=(e, index)=>{
        let slides = listData.scene[expanded]?.slides;
        slides[index][e.target.name] = e?.target?.value;
        listData.scene[expanded]['slides'] = slides;
        setListData({...listData, scene:listData.scene});
    }
const responseEventHandlers = (value,resInd) => {
    let slides = listData.scene[expanded]?.slides;
    slides[childExpanded].responses[resInd]['description'] = value;
    listData.scene[expanded]['slides'] = slides;
    setListData({...listData, scene:listData.scene});
}

const feedBackEventHandlers = (value,resIdx) => {
    let slides = listData.scene[expanded]?.slides;
    slides[childExpanded].responses[resIdx]['feedback'] = value;
    listData.scene[expanded]['slides'] = slides;
    setListData({...listData, scene:listData.scene});
    // handleProperties({...listData, scene: listData.scene });
}

const handleTryAgain = (e) => {
    let value = e?.target?.checked ? "Try Again" : "continue"
    let slides = listData.scene[expanded]?.slides;
    slides[childExpanded].responses[resChildExpanded]['action'] = value;
    listData.scene[expanded]['slides'] = slides;
    setListData({...listData, scene:listData.scene});
    // handleProperties({...listData, scene: listData.scene });
}

/* type 0 is an slides 1 is an response */
const goToTag = (item, type, disabled) => {
    try {
        let goTo = item?.goTo === 0 ? 'next' : 'end'
        let value = item?.next_slide !== null ? `${item?.next_slide?.scene},${item?.next_slide?.slide}` : goTo;
        return <>
            <div className='d-flex label-check'>
                <label className='select-label'>Go to</label>
                {type === 1 && <FormControlLabel label={'Try Again'} className='ck-check-box' labelPlacement="end" control={<Checkbox checked={disabled} onChange={(e) => handleTryAgain(e)} />} />}
            </div>
            <select
                id="grouped-select"
                value={value}
                label="Go to"
                onChange={(e) => handleGoTo(e?.target?.value, type)}
                className={`group-select ${disabled && "disabled"}`}
                disabled={disabled || false}
            >
                <option className='select-option-listData.scene' value={'next'}>Next Slide</option>
                <option className='select-option-listData.scene' value={'end'}>End Scene</option>
                {listData.scene.map((sce, sIdx) => {
                    return sce.id !== -1 && <optgroup key={sIdx} label={`Scene ${sIdx + 1}`}>
                        {listData.scene[sIdx]?.slides.map((sli, xid) => {
                            let value = `${sce?.id},${sli?.id}`
                            return <option key={xid} className='select-option-listData.scene' value={value}> {`${sIdx+1}.${xid+1} ` } {sli?.title}</option>
                        })}
                    </optgroup>
                })}
            </select>
        </>
    } catch (e) { }
}

const Editor = (label, value, func, height) => {
    return <>
        <label className='label mt-3'>{label}</label>
        <RichTextEditor data={value} eventHandler={(e) => func(e)} styles={{ minHeight: height }} />
    </>
}

const getBgImageUrl = (path) => {
    return DataHelper?.getResourcePath(0, path) || '';
}
// const backgroundSelection = [
//     { name: '', value: 'select' },
//     { name: 'Actual Size', value: 'center  / contain no-repeat' },
//     { name: 'Place Left', value: ' left top / cover no-repeat' },
//     { name: 'Place Right', value: 'right top / cover no-repeat' },
//     { name: 'Spread', value: ' 100% 100% / cover no-repeat' },
//     { name: 'Center & Spread', value: '50% 50% / cover no-repeat' },
//     { name: 'Repeat', value: 'center / contain repeat' },
// ]
const userAgent = navigator?.userAgent?.toLowerCase()||[];
const colorPickrProp = {
    btn: {
      save: { isSave: true, name: 'Save', ishide: false },
      cancel: { isCancel: !userAgent.includes('firefox'), name: `&#x270E`, ishide: false, isch: true }
    },
    type: 'RGBA',
    icon:true
  };

// header section
const handleTrack=(e)=>{
   setListData({...listData, scene:listData.scene, is_track:e?.target?.checked})
}

const handleInfo=(e)=>{
    setListData({...listData, scene:listData.scene,[e.target.name]:e?.target?.value||""})
    e.stopPropagation();
 }

// handling the enable and disable state of the audio -1 or null not active
 const checkBoxUploader=(item,index)=>{
    return <div className='col-md-12 col-lg-2  v-small-uploader character'>
     <FormControlLabel label={'Select Audio'} className="ck-check-box" labelPlacement="end" name="isCharacter" control={<Checkbox checked={!(item?.audio===-1 || !item?.audio)} onChange={(e) => handleIsAudioMedia(e, index)} />} />
    {!(item?.audio===-1 || !item?.audio) && <UploaderPanel accept={'audio'} fileName={item?.audio} name={`${id}_${Datahelper.getTime()}-${index + 1}`} setUploadedData={(e) => handleAudioMedia(e, index)} />}
</div>
}

return (
<div className='scenario-block-prop-container'>
  {(styleObj?.t_c || styleObj?.fnt_s) && <p className='info-text'> Taking template styles. Your styles modification and all won't get affect the block.</p>}
    <div className={``}>
        <div className='header-action'>
            <div className='item'>
                <ResizableTextArea  minRows={1} value={title||""} name="title" onChange={(e)=>handleInfo(e)} placeholder="Enter Title"  />
            </div>
            <div className='item check-box-container'>
                 <FormControlLabel label={'Record Session'} className='ck-check-box' labelPlacement="end" name={"is_track"} control={<Checkbox checked={listData?.is_track||false} onChange={(e) => handleTrack(e)} />} />
            </div>
           
        </div>
        {/* Scene Accordion Starts */}
        {
            listData.scene && (listData.scene?.length > 0) && listData.scene?.map((el, index) => {
                return <Acc key={index} expanded={expanded === index} onChange={handleChange(index)}>
                    <AccordionSummary
                        aria-controls="panel1a-content"
                        id="panel1a-header"
                    >
                        {el?.bg_image && <div className='circle-preview-img-box'> <img className='preview-item-img' src={getBgImageUrl(el?.bg_image)} onError={(e) => { e.target.onerror = null; e.target.src = getPlaceHolderImg(200) }}
                            alt='drag-item' /></div>}
                        <Typography className={`label-bold`}>{el.id === -1 ? 'End Scene' : `Scene ${index + 1}`}</Typography>
                        <DeleteIcon className='delete-prop-accordion' onClick={(e) => handleSceneDelete(e, el.id)} />
                    </AccordionSummary>
                    <AccordionDetails className='scenario-detail'>
                        {expanded === index && <div className='row'>
                            <div className='col-md-12 col-lg-4 v-small-uploader'>
                                <label className='label'>Browse Background Image</label>
                                <UploaderPanel
                                    accept={"image"}
                                    fileName={listData.scene[index]?.bg_image || ""}
                                    name={`${id}_${Datahelper.getTime()}-${index + 1}`}
                                    setUploadedData={(e) => handleBgMedia(e, index)}
                                    styles={{
                                        background:listData.scene[index]?.background || "50% 50% / cover no-repeat",
                                        sbackgroundColor:el?.sbackgroundColor || 'rgba(7, 13, 89, 0.8)',
                                    }}
                                    bgChange={(e) => handleOpacity(e, index)}
                                    pChange={(e) => imagePositionChange(e, index)}
                                    />
                            </div>
                            {el.id !== -1 && <div className='col-md-12 col-lg-2  v-small-uploader character'>
                                <FormControlLabel label={'Select Character'} className="ck-check-box" labelPlacement="end" name="isCharacter" control={<Checkbox checked={el?.character?.isCharacter} onChange={(e) => handleIsCharacter(e, index)} />} />
                                {el?.character?.isCharacter && <UploaderPanel accept={'image'} fileName={listData.scene[index]?.character?.src} name={`${id}_${Datahelper.getTime()}-${index + 1}`} setUploadedData={(e) => handleCharacter(e, index)} />}
                            </div>}
                            <div className='col-md-12 col-lg-1 icon-picker sc-text-bgcolor pt-3'>
                              <label className='label p-0'>Text Area Background</label>
                                    <ColorPickr 
                                        color={el?.text_bgColor ||'rgba(255, 255, 255)'} 
                                        saveEvent={(v) => handleOpacity({target:{value:v,name:"text_bgColor"}},index)}
                                        {...colorPickrProp}
                                    />
                                {/* <TextField type="color" id="outlined-basic" name="text_bgColor" label="Text Area Background" variant="outlined" defaultValue={el?.text_bgColor || '#ffffff'} onChange={(e) => handleOpacity(e, index)} />
                                <label className='label' id="text-radio-buttons-group-label">Text Area Opacity</label>
                                <Slider className="ce-slider" value={Number(el?.text_opacity || 1)} name="text_opacity" onChange={(e) => handleOpacity(e, index)} aria-label="Default" valueLabelDisplay="auto" step={0.10}
                                    min={0.0}
                                    max={1.0} /> */}
                            </div>

                            {/* <div className='col-md-12 col-lg-2 text-area-property-bg-holder pt-4'>
                                <FormControl size='medium' >
                                    <InputLabel className='label' id="demo-select-small">Image Position</InputLabel>
                                    <Select
                                        labelId="demo-select-small"
                                        id="demo-select-small"
                                        value={listData.scene[index]?.background || "50% 50% / cover no-repeat"}
                                        label="Image Position"
                                        onChange={(e) => imagePositionChange(e, index)}
                                        className="dropdown"
                                    >
                                        {backgroundSelection.map((item, idx) => <MenuItem key={idx} className='select-option-listData.scene' disabled={item.name.trim().length === 0} value={item.value}>{item.name}</MenuItem>)}

                                    </Select>
                                </FormControl>
                                <div className='topic-head-prop-background' >
                                    <TextField type="color" id="outlined-basic" name="sbackgroundColor" label="Background Color" variant="outlined" defaultValue={el?.sbackgroundColor || '#ffffff'} onChange={(e) => handleOpacity(e, index)} />
                                </div>
                            </div> 
                            <div className='col-md-12 col-lg-2 text-area-property-bg-holder pt-4'>
                                <div>
                                    <label className='label' id="text-radio-buttons-group-label">Bg Image Opacity</label>
                                    <Slider className="ce-slider" value={Number(el?.slide_opacity || 0.1)} name="slide_opacity" onChange={(e) => handleOpacity(e, index)} aria-label="Default" valueLabelDisplay="auto" step={0.10}
                                        min={0.0}
                                        max={1.0} /></div>
                            </div>*/}
                            {el.id === -1 ? <div className='end_scene_editor ml-2'>{Editor("Title", el?.title, handleEndScene, 80)} </div> : <div className='col-lg-12 col-md-12 col-sm-12'>
                                <div className='slide-label'> <label className='label'>Slides </label>
                                    <FormControlLabel label={'Allow Reorder'} className="ck-check-box" labelPlacement="end" name="isCharacter" control={<Checkbox checked={drag} onChange={(e) => setDrag(!drag)} />} /> </div>
                                <div className='inner-accordion-continuer acccordion-item-listData.scene'>
                                    <div className={``}>
                                        {/* Slide Accordion Starts */}
                                        <DndProvider backend={HTML5Backend}>
                                            {
                                                listData.scene[index]?.slides?.length > 0 && listData.scene[index]?.slides?.map((item, idx) => {
                                                 let isDialog=item?.type === 1 
                                                 return <>
                                                        <AccordionItem
                                                            className='inner-summary'
                                                            key={index}
                                                            name={`${index + 1}.${idx + 1}`}
                                                            el={item}
                                                            data={listData.scene[index]?.slides}
                                                            listUpdate={handleUpdateAccList}
                                                            index={idx}
                                                            expanded={childExpanded}
                                                            handleAccordionChange={handleChildAcc}
                                                            isDrag={drag}
                                                            bgImg
                                                            summary={
                                                                {
                                                                    title: { name: "title", },
                                                                    delete: { min: 1 },
                                                                    select: { label: "Type", name: "type", func: handleTypeChange, menu: [{ name: "Text", value: 0 }, { name: "Dialogue", value: 1 }] }
                                                                }
                                                            }
                                                            dynamicDetailsComponent={<>
                                                                {<div className='row mt-4' key={index}>
                                                                    <div className='col-md-12 col-lg-4  v-small-uploader'>
                                                                        <label className='label'>Browse Background Image</label>
                                                                        <UploaderPanel
                                                                            accept={"image"}
                                                                            fileName={item?.bg_image || ""}
                                                                            name={`${id}_${Datahelper.getTime()}-${index + 1}`}
                                                                            setUploadedData={(el) => handleSlideBgMedia(el, idx)}
                                                                            styles={{
                                                                                position:
                                                                                item["styles"]?.position ||
                                                                                "select",
                                                                                color:
                                                                                item["styles"]?.color ||
                                                                                "rgba(7, 13, 89, 0.6)",
                                                                            }}
                                                                            bgChange={(e) => imageStyleChange(e, idx)}
                                                                            pChange={(e) => imageStyleChange(e, idx)}
                                                                            />
                                                                        
                                                                        {/* <UploaderPanel accept={'image'} fileName={item?.bg_image || ''} name={`${id}_${Datahelper.getTime()}-${index + 1}`} setUploadedData={(e) => handleSlideBgMedia(e, idx)} /> */}
                                                                    </div>
                                                                   {el?.character?.isCharacter&& isDialog  && <div className='col-md-12 col-lg-2  v-small-uploader'>
                                                                        <label className='label'>Browse Character Image</label>
                                                                        <UploaderPanel accept={'image'} fileName={item?.character || ''} name={`${id}_${Datahelper.getTime()}-${index + 1}`} setUploadedData={(e) => handleSlideCharacter(e, idx)} />
                                                                    </div>}
                                                                    {checkBoxUploader(item,idx)}
                                                                    {(isDialog && is_track) &&  <div className='col-md-12 col-lg-2'> <FormControlLabel label={'Ignore Recording'} className="ck-check-box track-ignore pt-2" labelPlacement="end" name="ig_track" control={<Checkbox checked={item?.ig_track||false} onChange={(e) => handleDialogTrack(e,idx)} />} /> </div>}
                                                                    <div className='col-md-12 col-lg-12'>
                                                                    {isDialog && <CustomTextField 
                                                                        id="outlined-textarea"
                                                                        label="Title"
                                                                        variant="outlined"
                                                                        value={item?.header || null}
                                                                        name="header"
                                                                        onChange={(e) => handleDialogTitle(e,idx)}
                                                                        // maxLength={30}
                                                                        type="text"
                                                                        placeholder="Dialog Title"
                                                                        />}
                                                                        {Editor("Dialog", item?.description, slideEventHandlers, 100)}
                                                                    </div>
                                                                    <div className='col-lg-6 col-md-12'>
                                                                        {item?.type === 0 && goToTag(item, 0)}
                                                                    </div>
                                                                    {(item?.type === 0 && el?.character?.isCharacter) && <div className='col-lg-6 col-md-12 char-check'>
                                                                        <FormControlLabel label={'Hide Character'} className="ck-check-box" labelPlacement="start" control={<Checkbox checked={item?.emotion === null} onChange={(e) => onHideCharacter(e)} />} />
                                                                    </div>}
                                                                </div>}
                                                                {isDialog && <div className='response-container'>
                                                                    {/* Response Accordion Start */}
                                                                    <label className='label'>Response</label>
                                                                    {item?.responses?.length > 0 && item?.responses?.map((response, rIdx) => {
                                                                        let tryAgain = response?.action === 'Try Again'
                                                                        return <Acc key={rIdx} expanded={resChildExpanded === rIdx} onChange={() => handleResChildAcc(rIdx)}>
                                                                            <AccordionSummary className='response-summary' aria-controls="panel1a-content" id="panel1a-header" >
                                                                                {isResponseTitle === rIdx ? <>
                                                                                    <TextField id="outlined-basic" className='listData.scene-title' spellCheck={false} name='title' defaultValue={response.title} onClick={(e) => e.stopPropagation()} onChange={(e) => handleChangeResponseTitle(e, rIdx)} />
                                                                                    <CheckIcon className='save-prop-accordion' onClick={(e) => handleResponseTitleSave(e, rIdx)} />
                                                                                </> : <><Typography className={`label-bold`}>{`${index + 1}.${idx + 1}.${rIdx + 1}`} {response?.title}</Typography>
                                                                                    <EditIcon className='edit-prop-accordion' onClick={(e) => handleResponseEdit(e, rIdx)} />
                                                                                    <DeleteIcon className='delete-prop-accordion' onClick={(e) => handleResponseDelete(e, index, idx, response.id)} /> </>}
                                                                            </AccordionSummary>
                                                                            <AccordionDetails>
                                                                                {resChildExpanded === rIdx && <>
                                                                                    <label className='label mt-3'>Response</label>
                                                                                    <RichTextEditor data={response?.description} eventHandler={(e) => responseEventHandlers(e, resChildExpanded)} styles={{ minHeight: 80 }} />
                                                                                    {/* {Editor("Response", response?.description, responseEventHandlers, 80)} */}
                                                                                    {/* {Editor("FeedBack", response?.feedback, feedBackEventHandlers, 80)} */}
                                                                                    <label className='label mt-3'>FeedBack</label>
                                                                                    <RichTextEditor data={response?.feedback} eventHandler={(e) => feedBackEventHandlers(e, resChildExpanded)} styles={{ minHeight: 80 }} />
                                                                                    <div className='row mt-4'>
                                                                                        <div className='col-lg-6 col-md-12 '>
                                                                                            {goToTag(response, 1, tryAgain)}
                                                                                        </div>
                                                                                        <div className='col-lg-6 col-md-12'>

                                                                                        </div>
                                                                                    </div></>}
                                                                            </AccordionDetails>
                                                                        </Acc>
                                                                    })}
                                                                    <br />
                                                                    {item?.responses?.length < 8 && <div className='add-new-slide' title={'Add Response'} onClick={addNewResponse}><AddCircle className='add-accordion' /> Add New Response </div>}
                                                                    {/* Response Accordion Ended */}
                                                                </div>}
                                                            </>} />
                                                    </>
                                                })}
                                        </DndProvider>
                                        <br />
                                        <div className='add-new-slide' title={'Add Slide'} onClick={addNewSlide}><AddCircle className='add-accordion' /> Add New Slide </div>
                                        {/* Slide Accordion Ended */}
                                    </div>
                                </div>
                            </div>}
                        </div>}
                    </AccordionDetails>
                </Acc>
            })
        }
        <div className='add-new-slide' title={'Add Scene'} onClick={addScene}><AddCircle className='add-accordion' /> Add New Scene</div>
        {/* Scene Accordion Ended */}
    </div>
</div>
)
}



ScenarioBlockPanel.propTypes = {
/** scene used for manage all dialogue,images && text */
scene: PropTypes.array,
/** function handler used to save the edited props */
handleProperties: PropTypes.func,
/** function handler used to update the Assets */
handleCeAssets: PropTypes.func,
}

export default ScenarioBlockPanel