import React from 'react';
import Acc from '@material-ui/core/Accordion';
import Typography from '@material-ui/core/Typography';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import UploaderPanel from '../UploaderPanel/UploaderPanel';
import { useEffect } from 'react';

const DragnDropPanel = ( { items, draggableItems, handleProperties, cename, id, handleCeAssets } ) => {
    const [ expanded, setExpanded ] = React.useState( 0 );
    const [ editMode, setEdit ] = React.useState( null );
    const [ draggableList, setDraggableList ] = React.useState( JSON.parse( JSON.stringify( draggableItems ) ) );


    useEffect(()=>{
        handleProperties({draggableItems:draggableList});
    },[draggableList]);

    const handleChange = ( panel ) => ( event, isExpanded ) => {
        setExpanded( isExpanded ? panel : false );
        setEdit( null )
    };
    // handleCeAssets - This function handles the CE Library asset usage update actions ( previousAsset | newAsset | updatedProps ). 
    const handleMedia = ( data,  index) => {
        try{
            if(data){
                const updatedDragList=[...draggableList];
                updatedDragList[index] = {...updatedDragList[index],image:data.name};
                handleCeAssets(draggableList[index].image,data.name,{draggableItems:updatedDragList});
                setDraggableList([...updatedDragList]);
                handleProperties({draggableItems:draggableList});
            }
        }catch(e){
            console.log("e",e)
        }
    }

    return (
        <div className='accordion-prop-container'>
            <div className={ `` }>
                {
                    ( Object.keys( draggableList ).length > 0 ) && Object.keys( draggableList ).map( ( el, index ) => {
                        return <Acc key={ index } expanded={ expanded === index } onChange={ handleChange( index ) }>
                            <AccordionSummary aria-controls="panel1a-content" id="panel1a-header">
                                { editMode === index ? <></> : <div key={index}><Typography >Box { el } Draggable Items</Typography></div> }
                            </AccordionSummary>
                            <AccordionDetails>
                                { draggableList[ expanded + 1 ].length > 0 && draggableList[ expanded + 1 ].map( (item,index) => {
                                    return ( <div className='row' key={expanded+'_'+item.id}>
                                        <div className='col-sm-12 col-md-12 col-lg-12'>
                                            <UploaderPanel accept={ 'image' } fileName={draggableList[index]?.image}  name={ `${ id }_${expanded}_${ index}` } setUploadedData={(e)=> handleMedia(e,index) } />
                                        </div>
                                    </div> )
                                } ) }
                            </AccordionDetails>
                        </Acc>
                    } )
                }

            </div>
        </div>
    )
}

export default DragnDropPanel